import React, { useEffect, useState } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import './AiContentTableRow.css'

export const AiContentTableRow = ({doc, aiContent, rowData, onDelete, onSelect, toDelete, markDeleted}) => {
  const [ deleted, setDeleted ] = useState(false)
  const [ cells, setCells ] = useState([])


  useEffect(() => {
    if (toDelete) {
      const id = setTimeout(() => {
        setDeleted(true)
        markDeleted(aiContent.id)
      }, 300)
      return () => { clearTimeout(id) }
    }

  }, [toDelete])

  useEffect(() => {
    const tempCells = []
    for (let i = 0; i < rowData.selectors.length; i++) {
      tempCells.push({
        align: rowData.aligns[i],
        data: rowData.selectors[i](aiContent, onSelect, onDelete),
        style: rowData.styles[i],
        className: rowData.classNames[i],
      })
    }
    setCells(tempCells)
  }, [aiContent])

  let key = 1

  return (
    <>
    {!deleted && 
      <TableRow key={doc + aiContent.id} hover={true} onClick={() => onSelect(aiContent.id)} className={'table-row'}>
          {cells.map( cell =>  <TableCell key={doc + aiContent.id + key++} style={cell.style ? cell.style : {}} className={cell.className ? cell.className : ""} align={cell.align ? cell.align : "left"}>{cell.data}</TableCell> )}
      </TableRow>
    }
    </>
  );
}
