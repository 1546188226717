import React, { useEffect, useState } from "react";
import { BaseFlashcardArray } from "./Base/BaseFlashcardArray";
import { useParams } from "react-router-dom";
import FlashcardService from "../../AiTutor/Services/FlashcardService";
import { useHistory } from "react-router-dom";
import { IconButton, Typography } from "@material-ui/core";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import CustomSpinner from "../../Components/Commons/Spinner";
import { logger } from "../../shared/Logger";
import { AiNps } from "../Components/AiNps";
import { AI_FLASHCARDS } from "../Ai/Containers/AiTutorPage";
import { DocumentName } from "../Components/DocumentName";

export const FlashcardList = () => {

  const { key } = useParams();
  const [flashcard, setFlashcard] = useState();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nps, setNps] = useState(false)


  useEffect(() => {
    
    if (key) {
      FlashcardService.fetch(key)
        .then(response => {

          if (response.data.status === "GENERATED") {

            const newCards = response.data.content.map((flaschard, index) => {

              return {
                id: index,
                frontText: flaschard.question? flaschard.question : flaschard.text,
                backText: flaschard.answer,
                pageNumber: flaschard.pageNumber,
              }

            });

            setFlashcard(response.data);
            setCards(newCards);
          }
          else {
            history.goBack();
          }

      }).catch(error => {
          logger.error("Error fetching flashcards with key:" + key, error)
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [key]);

  if (!key) {
    return null;
  }

  if (loading) {
    return (
      <>
        <div className="text-center">
          <CustomSpinner />
        </div>
      </>
    );
  }

  const handleLastFlashcard = () => {
    setNps(true)
  }

  return (
    <>
    <DocumentName name={flashcard?.documentName} backDest={'/ai/flashcards'}/>
    <BaseFlashcardArray cards={cards} FlashcardArrayStyle={{width: "100%"}} onLastFlashcard={handleLastFlashcard}/>
    { nps && <AiNps type={AI_FLASHCARDS}/> }
    </>

  );

}
