import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import clsx from 'clsx';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { Collapse, Typography, useTheme, IconButton, Card } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AiContentTableRow } from "./AiContentTableRow";
import { FormattedMessage } from "react-intl";
import './AiContentTableSection.css';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    padding: "0.6rem",
    marginBottom: "0.3rem"
  },
  table: {
    minWidth: "100%",
    padding: "0.5rem",
  },
  container: {
    padding: "0.6rem",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export const AiContentTableSection = ({doc, aiContent, columns, onDelete, onSelect, deletedId, markDeleted}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [expanded, setExpanded] = useState(true);
  const [ sectionClasses, setSectionClasses ] = useState("")

  let key = 1

  useEffect(() => {
    if (aiContent.length === 1 && deletedId === aiContent[0].id) {
      setSectionClasses("fade");
    }

  }, deletedId)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const rows = 
    aiContent.map(content => 
    <AiContentTableRow 
      key={content.id} 
      doc={doc} 
      rowData={{
        selectors: columns.map(col => col.selector),
        aligns:columns.map(col => col.rowAlign),
        classNames: columns.map(col => col.rowClassName),
        styles: columns.map(col => col.rowStyle),
      }} 
      aiContent={content} 
      onSelect={onSelect} 
      onDelete={onDelete} 
      toDelete={deletedId === content.id} 
      markDeleted={markDeleted}
    />
  )

  return (
        <Card className={`${classes.cardRoot} ${sectionClasses}`}>
          <div className="d-flex align-items-center">
              <IconButton aria-expanded={expanded} aria-label="show more" onClick={toggleExpanded}
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}>
                <ExpandMoreIcon />
              </IconButton> 
              <Typography variant='h6' >
                 {'📄 ' + doc}
              </Typography>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <TableContainer className="ai-content-table-container">
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                  {columns.map(col => 
                    <TableCell 
                      key={key++} 
                      align={col.align ? col.align : "center"} 
                      className={col.className ? col.className : ""} 
                      style={col.style ? col.style : {}}
                    >
                    {col.headerKey ? <FormattedMessage id={col.headerKey}/> : ""}
                  </TableCell>
                  )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Card>
  );

}
