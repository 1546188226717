import PropTypes from 'prop-types';

export const categories = [
    "ESSAYS",
    "LECTURE_NOTES",
    "MANDATORY_ASSIGNMENTS",
    "OTHER",
    "PAST_EXAMS",
    "PRACTICAL",
    "RULINGS",
    "SUMMARIES",
    "TUTORIAL_WORK"
];

export const sharedDocumentShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    category: PropTypes.oneOf(categories).isRequired,
    sharedBy: PropTypes.number.isRequired,
    sharedByUsername: PropTypes.string,
    pages: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    sharedTimes: PropTypes.number.isRequired,
    previewUrl: PropTypes.string.isRequired
});
