import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import SlideButton from './SlideButton'
import SliderWrapper from './SliderWrapper'
import useSliding from './useSliding'
import useSizeElement from './useSizeElement'
import './Slider.css'
import Item from './Item';

const Slider = ({ data, currentIndex, onChange, isSummary = false, answered = []}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [answeredArr, setAnseredArr] = useState(answered)
  const { width, elementRef } = useSizeElement();
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev,
    flexAmount,
    viewed,
    totalInViewport,
  } = useSliding(width, data.length);

  useEffect(() => {
    if (currentIndex != currentSlide) {
      setCurrentSlide(currentIndex)
      if (currentIndex + 1 > viewed + totalInViewport) {
        handleNext()
      }
      if (currentIndex < viewed) {
        handlePrev()
      }
    }
  }, [currentIndex])

  useEffect(() => {
    onChange(currentSlide)  
  }, [currentSlide])

  useEffect(() => {
    setAnseredArr(answered)
  }, [answered])

  const handleSelect = (index) => {
    setCurrentSlide(index)
  };


  return (
    <SliderWrapper hasNext={hasNext} hasPrev={hasPrev}>
      <div
        className={cx('slider')}
        style={{marginLeft: hasPrev ? "30px" : 0, marginRight: hasNext ? "30px" : 0}}
      >
        <div ref={containerRef} className={`slider__container ${isSummary ? "" : "px-4"}`} {...slideProps}>
          {data.map((datum) => 
          <Item 
            key={datum.num} 
            index={datum.num} 
            flexAmount={flexAmount} 
            first={datum.num == 0} 
            last={datum.num == data.length - 1} 
            onSelect={handleSelect} 
            elementRef={elementRef}
            currentIndex={isSummary ? -1 : currentSlide}
            isAnswered={answeredArr.includes(datum.num)}
            isCorrect={datum.correct ? datum.correct : false}
            isSummary={isSummary}
          />)} 
        </div>
      </div>
      {hasPrev && <SlideButton onClick={handlePrev} type="prev" />}
      {hasNext && <SlideButton onClick={handleNext} type="next" />}
    </SliderWrapper>
  );
};

export default Slider;
