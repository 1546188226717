import React, { useEffect, useState } from "react";
import { Grid, Typography, Switch, withStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";


const noCheckedOpts = {
    multipleChoice: false,
    trueFalse: false,
    gapFill: false,
    definition: false,
}

const defaultOpts = {
    multipleChoice: true,
    trueFalse: false,
    gapFill: false,
    definition: false,
}


const ZerocopySwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: 'var(--darkblue-color)',
    },
    '&$checked + $track': {
      backgroundColor: 'var(--darkblue-color)',
    },
  },
  checked: {},
  track: {},
})(Switch);


export const QuizOptions = ({onChange}) => {
  const [questionTypes, setQuestionTypes] = useState(defaultOpts);

  useEffect(() => {
    onChange(questionTypes)
  }, [questionTypes])

  const quizOption = (type) => {
    return (
      <>
        <Grid item xs={6} >
          <Typography 
            color='textPrimary'
            variant='h6'
          >
            <FormattedMessage id={`ai.quiz.option.${type}`}/>
          </Typography>
        </Grid>
        <Grid item xs={6} className={'d-flex justify-content-start align-items-end flex-column'} >
            <ZerocopySwitch checked={questionTypes[type]} onChange={handleChange} name={type} />
        </Grid>
      </>
    );
  }

  const handleChange = (event) => {
    if (!questionTypes[event.target.name]) {
      setQuestionTypes({ ...noCheckedOpts, [event.target.name]: event.target.checked });
    }
  };

  return (
      <Grid container spacing={1} >
        {Object.keys(questionTypes).map(type => quizOption(type))}
        <Grid item xs={12} style={{borderBottom: "solid 2px var(--darkblue-lighter-color)", marginBottom: "1.5rem"}}/>
      </Grid>
  );
}

