import { updateObject } from '../../shared/utils';

import * as actionTypes from '../actions/types';

const initialProfileState = {
  list: [],
  currentSection: -1,
  opened: false, // Modal is opened
  selectedChoices: [],
  previousChoices: undefined,
  submitting: false,
  universities: [],
  university: undefined
}

const clearData = (state, action) => {
  return updateObject(state, {
    list: [],
    currentSection: -1,
    selectedChoices: [],
    previousChoices: undefined,
    submitting: false,
    universities: []
  });
}

const profileFetchSuccess = (state, action) => {

  return updateObject(state, {
    list: action.sections,
    currentSection: 0,
    previousChoices: action.currentChoices
  })
}

const profileSaveSection = (state, action) => {
  return updateObject(state, {
    submitting: true
  });
}

const profileSaveSectionComplete = (state, action) => {
  return updateObject(state, {
    submitting: false
  });
}

const fetchUniversitiesSuccess = (state, action) => {
  return updateObject(state, {
    universities: action.list
  });
}

const fetchUniversitiesError = (state, action) => {
  return updateObject(state, {
    universities: []
  });
}

const fetchUniversitySuccess = (state, action) => {
  return updateObject(state, {
    university: action.university
  });
}

const fetchUniversityError = (state, action) => {
  return updateObject(state, {
    university: undefined
  });
}

/**
* Redux reducer for profile actions.
*/
export default function (state = initialProfileState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS: return clearData(state, action);
    case actionTypes.SECTIONS_NEXT: return {...state, currentSection: state.currentSection + 1}
    case actionTypes.PROFILE_SAVE_SECTION: return profileSaveSection(state, action);
    case actionTypes.PROFILE_SAVE_SECTION_ERROR:
    case actionTypes.PROFILE_SAVE_SECTION_SUCCESS: return profileSaveSectionComplete(state, action);
    case actionTypes.PROFILE_TOGGLE_MODAL: return {...state, opened: !state.opened}
    case actionTypes.PROFILE_FETCH_SUCCESS: return profileFetchSuccess(state, action);
    case actionTypes.PROFILE_FETCH_UNIVERSITIES_SUCCESS: return fetchUniversitiesSuccess(state, action);
    case actionTypes.PROFILE_FETCH_UNIVERSITIES_ERROR: return fetchUniversitiesError(state, action);
    case actionTypes.PROFILE_FETCH_UNIVERSITY_SUCCESS: return fetchUniversitySuccess(state, action);
    case actionTypes.PROFILE_FETCH_UNIVERSITY_ERROR: return fetchUniversityError(state, action);
    default:
      return state;
  }
};
