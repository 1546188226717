import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const LeaderboardItem = ({ index, name, counter }) => {

    const intl = useIntl();
    
    let positionClass = "";

    switch (index + 1) {
        case 1:
            positionClass = "leaderboard-first border border-secondary";
            break;
        case 2:
            positionClass = "leaderboard-second border border-secondary";
            break;
        case 3:
            positionClass = "leaderboard-third border border-secondary";
            break;
        default:
            break;
    }

    let printedName = name;

    if (name.includes("@")) {
        printedName = name.split("@")[0];
    }

    return (
        <div className={`leaderboard-item ${positionClass}`}>
            <div className="d-inline-block" style={{width: "10%"}}>{index + 1}</div>
            <div className="d-inline-block" style={{width: "70%"}}>{printedName}</div>
            <div className="d-inline-block text-right" style={{width: "20%"}}>{intl.formatNumber(counter)}</div>
        </div>
    );
}

LeaderboardItem.propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    counter: PropTypes.number.isRequired
}

export default LeaderboardItem;