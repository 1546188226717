import React from 'react';
import { NavigateNext } from '@material-ui/icons';
import './SlideButton.css'

const SlideButton = ({ onClick, type }) => (
  <button className={`slide-button slide-button--${type}`} onClick={onClick}>
    <span>
      <NavigateNext/>
    </span>
  </button>
);

export default SlideButton;
