import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { userResetPassword } from '../store/actions';

class ResetPassword extends React.PureComponent {

  componentDidMount() {

    if (this.props.match.params.resetPasswordKey !== undefined) {

      this.props.userResetPassword(this.props.match.params.resetPasswordKey, this.props.intl);
    }

  }

  render() {

    return (
        <Redirect to="/" />
    );
  };
}

function mapStateToProps(state) {
  return {

  };
}

const mapDispatchToProps = { userResetPassword }

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPassword));
