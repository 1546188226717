import api from '../Api';
import { getToken } from '../shared/utils';

class DeliveryDiscountCodeService {

    /**
     * Check if a discount code exists and it's valid.
     * @param {string} discountCode 
     */
    static validateCode(discountCode) {
        return api.post(`/v1/delivery/with_ads/promo_code`, {"discountCode": discountCode}, {headers: {'X-AUTH-TOKEN': getToken()}});
    }
}

export default DeliveryDiscountCodeService;