import PropTypes from 'prop-types';

export const fieldShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  labelEn: PropTypes.string.isRequired,
  labelNl: PropTypes.string.isRequired,
  labelFr: PropTypes.string.isRequired,
  minChoices: PropTypes.number.isRequired,
  maxChoices: PropTypes.number.isRequired,
  choices: PropTypes.array.isRequired
});
