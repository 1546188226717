
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const InviteFriendMailLinkTitle = (props) => {


    return (
        <Fragment>
            <div className={'mb-1 mb-sm-1 mb-md-0'}>
                <label className="font-weight-bold mb-0 mb-md-1" htmlFor={props.htmlFor}><FormattedMessage id="inviteFriends.mailLink" /></label>
                <br/>
                <span className="text-muted small d-sm-block d-md-inline mb-sm-2 mb-md-0"><FormattedMessage id="inviteFriends.mailLink.description" /></span>
            </div>
        </Fragment>
    );
}

export default InviteFriendMailLinkTitle;
