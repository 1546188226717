import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert, Spinner, Button } from 'reactstrap';
import AddressForm from './AddressForm';
import { GlobalError } from '../Messages/GlobalError';
import { InfoIcon } from '../Icons';

import './AddressModalForm.css';
import { useSelector } from 'react-redux';

const AddressModalForm = ({isOpen, toggle, onSubmit, isNew }) => {

  const intl = useIntl();
  const error = useSelector((state) => state.address.errorModal);
  const fieldsWithProblems = useSelector((state) => state.address.fieldsWithProblems);
  const isSubmitting = useSelector((state) => state.address.submitting);
  const support = useSelector(state => state.global.contactEmail);

  let extraContent = null;

  if (fieldsWithProblems && fieldsWithProblems.length > 0) {
    extraContent = (
      <ul style={{marginBottom: "0"}}>
      {fieldsWithProblems.map(f =>
        <li key={f.name}><FormattedMessage id={"address.field." + f.name} /></li>
      )}
      </ul>
    );
  }

  if (error?.errorCode === 208) {
    error.errorParams = {
      link: (text) => (<a className="alert-link" href="https://www.bpost.be/en/address-validation-tool" target="blank" rel="noopener noreferrer">{text}</a>)
    };
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} id="addressFormModal" data-test="modal-address-form">
      <ModalHeader toggle={toggle} tag="h4">
        <FormattedMessage id={isNew?"address.create.title":"address.edit.title"} />
      </ModalHeader>
      <ModalBody>
        <span data-test="msg-address-error">
          <GlobalError error={error} extraContent={extraContent} />
        </span>
        <AddressForm intl={intl} onSubmit={onSubmit} allowBack={false} />

        <Alert color="info">
          <div className='d-flex'>
            <InfoIcon width="32" height="32" />
            <small className='ml-1'>
              <FormattedMessage id="address.validation.info" values={{
                bpost: (text) => (<a  className="alert-link" href={'https://www.bpost.be/en/address-validation-tool'} target="blank" rel="noopener noreferrer">{text}</a>),
              }}/>
            </small>
          </div>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="btn-md" disabled={isSubmitting} data-test="btn-save-address" form='address-form'>
          <FormattedMessage id="address.btn.save"/>
          {isSubmitting?<Spinner size="sm" className="ml-2"/>:null}
        </Button>
        <Button color="secondary" outline onClick={toggle}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );

};

AddressModalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired
}

export default AddressModalForm;
