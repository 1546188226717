import React from 'react';
import PropTypes from 'prop-types';

import svgImage from '../../images/master_card.svg';

export const MasterCardIcon = (props) => {
  return (
    <img src={svgImage}
    alt={props.alt}
    title={props.title}
    style={{"width": props.width, "height": props.height}}
    className={props.className}/>
  );
};

MasterCardIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

MasterCardIcon.defaultProps = {
  width: "54px",
  height: "40px",
  alt: "Mastercard icon"
};
