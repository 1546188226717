import { put, select } from 'redux-saga/effects';
import api from '../../Api';
import { getToken } from './selectors';

import * as actions from '../actions';

export function* metadataFetchSaga(action) {

  const token = yield select(getToken);

  if (Array.isArray(action.key)) {
    try {
      const response = yield api.post("/v1/metadata", action.key, {headers: {'X-AUTH-TOKEN': token}});

      const list = response.data;

      for (let m of list) {
          yield put(actions.metadataFetchSuccess(m, action.valueType[action.key.indexOf(m.key)]));
      }

    } catch(error) {
      // Ignore error
    }
  } else {

    try {
      const response = yield api.get(`/v1/metadata/${action.key}`, {headers: {'X-AUTH-TOKEN': token}});
      yield put(actions.metadataFetchSuccess(response.data, action.valueType));
    } catch(error) {
      // Ignore error
    }

  }

}

export function* featuresEnabledSaga(action) {

    const request = {
      "features": action.features
    }

    try {
      const token = yield select(getToken);
      const response = yield api.post("/v1/features/enabled", request, {headers: {'X-AUTH-TOKEN': token}});

      const list = response.data;

      yield put(actions.featuresEnabledFetchSuccess(list));

    } catch(error) {
      // Ignore error
    }
}
