import React, { Fragment, useEffect, useState } from 'react';
import ListPagination from '../Commons/ListPagination';
import DeliveryItem from './DeliveryItem';
import { DeliveryService } from '../../Services';
import { getToken, globalErrorHandling } from '../../shared/utils';
import { DeliveryListEmpty } from './DeliveryListEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import { error as errorNotification } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import CustomSpinner from '../Commons/Spinner';

import './DeliveryList.css';
import { ToggleIcon } from '../Icons';

const DeliveryList = (props) => {

    const pageSize = 5;
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [onlyPendingDeliveries, setOnlyPendingDeliveries] = useState(true);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);

    const intl = useIntl();

    const dispatcher = useDispatch();

    useEffect(() => {
      setLoading(true);
      
      DeliveryService.list(getToken(), currentPage, pageSize, onlyPendingDeliveries)
      .then(response => {
        
        setLoading(false);
        setList(response.data.data);
        setTotal(response.data.total);

      }).catch(error => {
        
        setLoading(false);
        
        const errorObj = globalErrorHandling(error);

        let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
        if (msg === errorObj.errorKey) {
          msg = intl.formatMessage({ id: "deliveries.msg.error"});
        }
        
        dispatcher(errorNotification({
          message: msg,
          position: 'tc',
          autoDismiss: 0,
        }));

      });

    }, [currentPage, onlyPendingDeliveries]);

    const onPageChange = (e, pageTotLoad) => {
      e.preventDefault();

      if (loading) {
        return;
      }

      setCurrentPage(pageTotLoad);
    }

    const toggleFilter = (e) => {
      e.preventDefault();

      if (loading) {
        return;
      }

      setCurrentPage(0);
      setOnlyPendingDeliveries(!onlyPendingDeliveries);
    }

    let content;

    if (loading) {
      content = (
        <CustomSpinner messageKey="deliveries.list.loading" />
      );
    } else if (total === 0) {
      content = (
        <DeliveryListEmpty />
      );
    } else { 
      content = list.map(item => 
        <DeliveryItem
          key={item.id}
          item={item}
          intl={intl} />
      );
    }

    let pagination = <ListPagination page={currentPage} totalItems={total} pageSize={pageSize} onPageChange={onPageChange} />;

    return (
      <Fragment>
        <div className='d-flex flex-column align-items-start flex-md-row align-items-md-center justify-content-between pb-2 border-bottom mb-3'>
          <h4 className='mb-md-0 mb-1'><FormattedMessage id="deliveries.list.title" /></h4>
          <DeliveryListFilter intl={intl} onlyPendingDeliveries={onlyPendingDeliveries} onChangePendingDeliveries={toggleFilter}/>
        </div>
        <div className="deliveries-list">
            {pagination}
            {content}
            {pagination}
        </div>
      </Fragment>
    );
}

export default DeliveryList;

const DeliveryListFilter = ({intl, onlyPendingDeliveries, onChangePendingDeliveries}) => {
  return (
    <div>
      <ToggleIcon enabled={onlyPendingDeliveries} className="text-primary" style={{fontSize: "1.8rem"}}
        onClick={onChangePendingDeliveries}
        title={intl.formatMessage({id: "deliveries.list.filter"})} />
      <span className="ml-2" style={{verticalAlign:"middle"}}>
        <FormattedMessage id="deliveries.list.filter" />
      </span>
    </div>
  );
};
