import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { logger } from '../../shared/Logger';

export const DocumentStatus = ({status}) => {

  let type = "";
  switch (status) {
    case "IN_PROCESS":
    case "UPLOADED":
    case "VALIDATED":
      type = "in-progress";
      break;
    case "CONVERTED":
      type = "ready";
      break;
    case "ERROR":
      type = "error";
      break;
    case "PRINTED":
      type = "printed";
      break;
    default:
      logger.warn("Invalid status: {}", status);
      break;
  }

  let icon = (
    <i className={`fas fa-circle circle-${type}`}></i>
  );

  if (type === "in-progress") {
    icon = (
      <i className={`fas fa-circle-notch fa-spin circle-${type}`}></i>
    );
  }

  return (
    <div data-test="document-status">
      {icon}
      <span className="ml-1">
        <FormattedMessage id={"documents.state." + status} />
      </span>
    </div>
  );

};

DocumentStatus.propTypes = {
  status: PropTypes.oneOf(['IN_PROCESS', 'CONVERTED', 'ERROR', 'UPLOADED', 'PRINTED', 'VALIDATED']),
}
