import React from 'react';
import PropTypes from 'prop-types';

import icon from '../../images/google.svg';

export const GoogleIcon = (props) => {
  
  return (
    <img src={icon}
      alt={props.alt}
      title={props.title}
      style={{"width": props.width, "height": props.height}}
      className={props.className}/>
  );
};

GoogleIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

GoogleIcon.defaultProps = {
  width: "18px",
  height: "18px",
  alt: "Google icon"
};