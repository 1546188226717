import React, { useState, useEffect } from 'react';
import { useGlobal } from '../../contexts/GlobalContext';
import Select, { components } from 'react-select';
import './DocumentSelect.css';
import {DOC_VALID_STATES_FOR_AI, DOC_STATES_IN_PROCESS} from '../../shared/utils';
import chroma from 'chroma-js';
import { FormattedMessage } from 'react-intl';
import { useMixpanel } from '../../contexts/Mixpanel';
import { AI_USER_CLICK_SELECT_DOCUMENT } from '../../shared/EventsNames';
import { DocumentUploadButton } from '../../Components/Documents';
import { Tooltip, useMediaQuery, useTheme, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { fetchDocuments } from '../../store/actions';

const customStyles = (open) => ({
  control: (styles) => ({
    ...styles,
    appearance: 'none',
    border: 0,
    outline: 0,
    font: 'inherit',
    fontSize: '1.4rem',
    width: '100%',
    /* top | right | bottom | left */
    padding: '0.6rem 1rem 0.6rem 1rem',
    margin: '1rem 0rem 0rem 0rem',
    color: 'white',
    borderRadius: '0.9em',
    boxShadow: "0 0 1em 0 #00b4ff",
    zIndex: 1,
    cursor: 'pointer',
    '&::-ms-expand': {
      display: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      boxShadow: "0 0 1em 0 #ff33e9 !important",
    }
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const selected = chroma('#00b4ff')
    const option = chroma('#00b4ff')
    return {
    ...styles,
    height: '100%',
    width: '100%',
    color: 'inherit',
    cursor: 'pointer',
    fontSize: "1.2rem",
    borderRadius: '0.4em',
    display: "inherit",
    backgroundColor: isDisabled ? "white" : isSelected ? selected.alpha(0.6).css() : isFocused ? option.alpha(0.6).css() : undefined,
    color: isDisabled ? "var(--darkblue-light-color)" : "var(--darkblue-color)",
    cursor: isDisabled ? 'not-allowed' : 'pointer',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled ? isSelected ? selected.alpha(0.6).css() : option.alpha(0.6).css() : undefined,
    },
  }},
  menu: (styles) => ({
    ...styles,
    zIndex: "99998",
    borderRadius: '0.9em',
    height: open ? "fit-content" : "0px",
    width: open ? "fit-content" : "0px",
    minWidth: "100%",
    opacity: open ? 1 : 0,
    transition: "all 0.5s ease-in-out",
    visibility: open ? "visible" : "hidden"
  })
})

export const DocumentSelect = ({handleSelected}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(null);
  const mixpanel = useMixpanel();
  const { userDocs } = useGlobal();
  const [dropdown, setDropdown] = useState(false)
  const [placeholder, setPlaceholder] = useState("choose")
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();
  const [processingDocuments, setProcessingDocuments] = useState([]);

  const dispatcher = useDispatch();

  useEffect(() => {
    if (userDocs.length === 0) {
      setDropdown(false)
      setPlaceholder("upload")
      return;
    }
    else {
      setDropdown(true)
      setPlaceholder("choose")
    }

    let processing = false;


    userDocs.forEach(doc => {
      if (processing) {
        return
      }
      if (DOC_STATES_IN_PROCESS.includes(doc.status)) {
        processing = true
      }
      setLoading(processing)
      if (processing) {
        setSelected(null)
        setPlaceholder('processing') 
      }
    })
  }, [userDocs]);


  useEffect(() => {
    handleSelected(selected)
  }, [selected])


  const handleDocumentSelect = (selectedOption) => {
    setSelected(selectedOption);
  };

  const handleSelectClick = () => {
    setOpen(!open)
    mixpanel.track(AI_USER_CLICK_SELECT_DOCUMENT)
  }

const IndicatorsContainer = ({ children, ...props }) => {
    if (!dropdown) {
      return null;
    }
    else {
      return (
        <components.IndicatorsContainer {...props}>
          {children}
        </components.IndicatorsContainer>
      );
    }
  }

const CustomOption = (props) => {
  const {
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data,
  } = props;
  let tooltipMsg = null;
  if (DOC_STATES_IN_PROCESS.includes(data.status)) {
    tooltipMsg = "documents.state.IN_PROCESS"
  }
  else if (data.numberOfPages !== undefined && data.numberOfPages !== null && data.numberOfPages < 5) {
    tooltipMsg = "ai.minimumPages"
  }
  if (data.status === "ERROR") {
    tooltipMsg = "ai.document.upload.error"
  }
  tooltipMsg = tooltipMsg ? intl.formatMessage({id: tooltipMsg}) : "";

  return (
    <div
      ref={innerRef}
      style={{...getStyles('option', props), minWidth: "100%"}}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      ) + " d-flex flex-row"}
      {...innerProps}
    >
    <Tooltip title={tooltipMsg} PopperProps={{style:{zIndex:99999}}} placement={'right-start'}>
      <div className="d-flex align-items-center" style={{whiteSpace: "nowrap"}} >
        <div style={{width: "fit-content"}}>{data.name}</div> 
        {data.status === "ERROR" && <i className={`fas fa-circle circle-error mx-1`}></i>}
        {DOC_STATES_IN_PROCESS.includes(data.status) && <i className={`fas fa-circle-notch fa-spin circle-in-progress mx-2`} style={{color: "var(--darkblue-color)"}}></i>}
      </div>
    </Tooltip>
    <Tooltip title={tooltipMsg} PopperProps={{style:{zIndex:99999}}} placement={'left-start'}>
      <div style={{width: "100%"}}/>
    </Tooltip>
    </div>
  );
};

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      📄 {children}
    </components.Control>
  );

  return (
      <Grid container spacing={4} justifyContent={"flex-end"} alignItems={"baseline"}>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Select
            isDisabled={userDocs.length === 0}
            styles={customStyles(open, userDocs.length)}      
            onChange={handleDocumentSelect}
            options={userDocs}
            value={selected}
            getOptionLabel={d => d.name}
            getOptionValue={d => d.id}
            isMulti={false}
            onFocus={() => setOpen(userDocs.length > 0)}
            placeholder={<FormattedMessage id={`ai.document.select.placeholder.${placeholder}`}/>}
            isLoading={loading && selected === null}
            components={{Control, Option: CustomOption, IndicatorsContainer}}
            hideSelectedOptions={true}
            isOptionDisabled={d => !DOC_VALID_STATES_FOR_AI.includes(d.status) || d.numberOfPages < 5 }
          >
          </Select>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={3} className={sm ? "px-3" : ""}>
          <DocumentUploadButton className={`${sm ? "btn-block" : ""}`} style={{fontSize: "1.4rem"}} />
        </Grid>
      </Grid>
  );
};
