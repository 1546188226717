import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';

export const GlobalError = ({error, extraContent, defaultMessage}) => {

  if (error === undefined || error === null || error === false) {
    return null;
  }

  if (error.errorKey) {
    return (
      <Alert color="danger">
        <FormattedMessage id={error.errorKey} values={error.errorParams} defaultMessage={defaultMessage}/>
        {extraContent}
      </Alert>
    );
  }

  return (
    <Alert color="danger">
      {error.errorCode}: {error.errorMessage}
    </Alert>
  );
};
