export const ADS_FETCH = "ADS_FETCH";
export const ADS_FETCH_SUCCESS = "ADS_FETCH_SUCCESS";
export const ADS_FETCH_ERROR = "ADS_FETCH_ERROR";
export const ADS_INCREASE_VIEW = "ADS_INCREASE_VIEW";
export const ADS_CLICKED = "ADS_CLICKED";

export const ADDRESSES_FETCH = "ADDRESSES_FETCH";
export const ADDRESSES_FETCH_SUCCESS = "ADDRESSES_FETCH_SUCCESS";
export const ADDRESSES_FETCH_ERROR = "ADDRESSES_FETCH_ERROR";

export const ADDRESS_FETCH = "ADDRESS_FETCH";
export const ADDRESS_FETCH_SUCCESS = "ADDRESS_FETCH_SUCCESS";
export const ADDRESS_FETCH_ERROR = "ADDRESS_FETCH_ERROR";

export const ADDRESS_SAVE = "ADDRESS_SAVE";
export const ADDRESS_SAVE_SUCCESS = "ADDRESS_SAVE_SUCCESS";
export const ADDRESS_SAVE_ERROR = "ADDRESS_SAVE_ERROR";

export const ADDRESS_DELETE = "ADDRESS_DELETE";
export const ADDRESS_DELETE_SUCCESS = "ADDRESS_DELETE_SUCCESS";
export const ADDRESS_DELETE_ERROR = "ADDRESS_DELETE_ERROR";

export const ADDRESS_FORM_TOGGLE = "ADDRESS_FORM_TOGGLE";

export const CITIES_FETCH = "CITIES_FETCH";
export const CITIES_FETCH_SUCCESS = "CITIES_FETCH_SUCCESS";
export const CITIES_FETCH_ERROR = "CITIES_FETCH_ERROR";

export const CONSENT_ASK = "CONSENT_ASK";
export const CONSENT_TOGGLE = "CONSENT_TOGGLE";
export const CONSENT_UPDATE = "CONSENT_UPDATE";
export const CONSENT_PARTNER_EMAILS_TOGGLE = "CONSENT_PARTNER_EMAILS_TOGGLE";
export const CONSENT_PARTNER_EMAILS_ERROR = "CONSENT_PARTNER_EMAILS_ERROR";
export const CONSENT_LEADERBOARD_TOGGLE = "CONSENT_LEADERBOARD_TOGGLE";
export const CONSENT_LEADERBOARD_ERROR = "CONSENT_LEADERBOARD_ERROR";

export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_ERROR = 'DOCUMENT_DELETE_ERROR';

export const DOCUMENTS_FETCH = "DOCUMENTS_FETCH";
export const DOCUMENTS_FETCH_SUCCESS = "DOCUMENTS_FETCH_SUCCESS";
export const DOCUMENTS_FETCH_ERROR = "DOCUMENTS_FETCH_ERROR";

export const DOCUMENT_RELOAD = 'DOCUMENT_RELOAD';
export const DOCUMENT_RELOAD_SUCCESS = 'DOCUMENT_RELOAD_SUCCESS';
export const DOCUMENT_RELOAD_ERROR = 'DOCUMENT_RELOAD_ERROR';

export const DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD';
export const DOCUMENT_UPLOAD_PROGRESS = 'DOCUMENT_UPLOAD_PROGRESS';
export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENT_UPLOAD_ERROR = 'DOCUMENT_UPLOAD_ERROR';

export const DOCUMENT_TOGGLE = "DOCUMENT_TOGGLE";
export const DOCUMENT_TOGGLE_CONFIRM_DELETE = 'DOCUMENT_TOGGLE_CONFIRM_DELETE';

export const DOCUMENT_DETAIL_TOGGLE = "DOCUMENT_DETAIL_TOGGLE";

export const DOCUMENT_REPORT_PROBLEM_TOGGLE = "DOCUMENT_REPORT_PROBLEM_TOGGLE";

export const DELIVERY_REQUEST_SELECT_ADDRESS = "DELIVERY_REQUEST_SELECT_ADDRESS";
export const DELIVERY_REQUEST_SHOW_ADDRESS = "DELIVERY_REQUEST_SHOW_ADDRESS";
export const DELIVERY_REQUEST_SHOW_PAYMENT_SELECTOR = "DELIVERY_REQUEST_SHOW_PAYMENT_SELECTOR";
export const DELIVERY_REQUEST_SELECT_PAYMENT = "DELIVERY_REQUEST_SELECT_PAYMENT";
export const DELIVERY_REQUEST = "DELIVERY_REQUEST";
export const DELIVERY_REQUEST_SUCCESS = "DELIVERY_REQUEST_SUCCESS";
export const DELIVERY_REQUEST_ERROR = "DELIVERY_REQUEST_ERROR";
export const DELIVERY_REQUEST_CLEAN = "DELIVERY_REQUEST_CLEAN";

export const DELIVERY_LOCKED_TOGGLE = "DELIVERY_LOCKED_TOGGLE";
export const DELIVERY_TYPE_SELECT = "DELIVERY_TYPE_SELECT";

export const FEATURES_ENABLED_FETCH = "FEATURES_ENABLED_FETCH";
export const FEATURES_ENABLED_FETCH_SUCCESS = "FEATURES_ENABLED_FETCH_SUCCESS";

export const LOCALE_SET = "LOCALE_SET";

export const LOGIN = "LOGIN";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SWITCH = "LOGIN_SWITCH";

export const LOGIN_AUTO = "LOGIN_AUTO";
export const LOGIN_AUTO_ERROR = "LOGIN_AUTO_ERROR";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const LOG_IN_TOGGLE = "LOG_IN_TOGGLE";

export const METADATA_FETCH = "METADATA_FETCH";
export const METADATA_FETCH_SUCCESS = "METADATA_FETCH_SUCCESS";

export const PROFILE_FETCH = "PROFILE_FETCH";
export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const PROFILE_SAVE_SECTION = "PROFILE_SAVE_SECTION";
export const PROFILE_SAVE_SECTION_SUCCESS = "PROFILE_SAVE_SECTION_SUCCESS";
export const PROFILE_SAVE_SECTION_ERROR = "PROFILE_SAVE_SECTION_ERROR";
export const PROFILE_TOGGLE_MODAL = "PROFILE_TOGGLE_MODAL";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILE_FETCH_UNIVERSITIES = "PROFILE_FETCH_UNIVERSITIES";
export const PROFILE_FETCH_UNIVERSITIES_SUCCESS = "PROFILE_FETCH_UNIVERSITIES_SUCCESS";
export const PROFILE_FETCH_UNIVERSITIES_ERROR = "PROFILE_FETCH_UNIVERSITIES_ERROR";
export const PROFILE_FETCH_UNIVERSITY = "PROFILE_FETCH_UNIVERSITY";
export const PROFILE_FETCH_UNIVERSITY_SUCCESS = "PROFILE_FETCH_UNIVERSITY_SUCCESS";
export const PROFILE_FETCH_UNIVERSITY_ERROR = "PROFILE_FETCH_UNIVERSITY_ERROR";

export const LEADERBOARD_FETCH = "LEADERBOARD_FETCH";
export const LEADERBOARD_FETCH_SUCCESS = "LEADERBOARD_FETCH_SUCCESS";
export const LEADERBOARD_FETCH_ERROR = "LEADERBOARD_FETCH_ERROR";

export const LOCATIONS_FETCH = "LOCATIONS_FETCH";
export const LOCATIONS_FETCH_SUCCESS = "LOCATIONS_FETCH_SUCCESS";
export const LOCATIONS_FETCH_ERROR = "LOCATIONS_FETCH_ERROR";

export const SHARED_DOCUMENT_COPY = "SHARED_DOCUMENT_COPY";
export const SHARED_DOCUMENT_COPY_SUCCESS = "SHARED_DOCUMENT_COPY_SUCCESS";
export const SHARED_DOCUMENT_COPY_ERROR = "SHARED_DOCUMENT_COPY_ERROR";
export const SHARED_DOCUMENT_FETCH = "SHARED_DOCUMENT_FETCH";
export const SHARED_DOCUMENT_FETCH_SUCCESS = "SHARED_DOCUMENT_FETCH_SUCCESS";
export const SHARED_DOCUMENT_FETCH_ERROR = "SHARED_DOCUMENT_FETCH_ERROR";
export const SHARED_DOCUMENT_SUBMIT = "SHARED_DOCUMENT_SUBMIT";
export const SHARED_DOCUMENT_SUBMIT_SUCCESS = "SHARED_DOCUMENT_SUBMIT_SUCCESS";
export const SHARED_DOCUMENT_SUBMIT_ERROR = "SHARED_DOCUMENT_SUBMIT_ERROR";
export const SHARED_DOCUMENT_TOGGLE = "SHARED_DOCUMENT_TOGGLE";
export const SHARED_DOCUMENT_TOGGLE_PREVIEW = "SHARED_DOCUMENT_TOGGLE_PREVIEW";

export const SECTIONS_FETCH = "SECTIONS_FETCH";
export const SECTIONS_FETCH_SUCCESS = "SECTIONS_FETCH_SUCCESS";
export const SECTIONS_FETCH_ERROR = "SECTIONS_FETCH_ERROR";
export const SECTIONS_NEXT = "SECTIONS_NEXT";

export const USER_FETCH = "USER_FETCH";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";

export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";

export const USER_FORGOT_PASSWORD_TOGGLE = "USER_FORGOT_PASSWORD_TOGGLE";
export const USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_ERROR = "USER_FORGOT_PASSWORD_ERROR";
export const USER_FORGOT_PASSWORD_CLEAR = "USER_FORGOT_PASSWORD_CLEAR";

export const USER_CREATE_ACCOUNT_TOGGLE = "USER_CREATE_ACCOUNT_TOGGLE";
export const USER_CREATE_ACCOUNT = "USER_CREATE_ACCOUNT";
export const USER_CREATE_ACCOUNT_SUCCESS = "USER_CREATE_ACCOUNT_SUCCESS";
export const USER_CREATE_ACCOUNT_ERROR = "USER_CREATE_ACCOUNT_ERROR";
export const USER_CREATE_ACCOUNT_CLEAR = "USER_CREATE_ACCOUNT_CLEAR";

export const USER_INVITE_FRIENDS = "USER_INVITE_FRIENDS";
export const USER_INVITE_FRIENDS_SUCCESS = "USER_INVITE_FRIENDS_SUCCESS";
export const USER_INVITE_FRIENDS_ERROR = "USER_INVITE_FRIENDS_ERROR";

export const USER_COPY_LINK = "USER_COPY_LINK";
export const USER_ACTIVATE = "USER_ACTIVATE";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";

export const USER_NPS_TOGGLE = "USER_NPS_TOGGLE";
export const USER_NPS_RATING = "USER_NPS_RATING";
export const USER_NPS_SUBMIT = "USER_NPS_SUBMIT";
export const USER_NPS_SUBMITTED = "USER_NPS_SUBMITTED";
export const USER_NPS_ASK = "USER_NPS_ASK";
export const USER_NPS_NEXT = "USER_NPS_NEXT";

export const USER_STATS = "USER_STATS";
export const USER_STATS_SUCCESS = "USER_STATS_SUCCESS";
export const USER_STATS_ERROR = "USER_STATS_ERROR";

export const USER_GET_STARTED = "USER_GET_STARTED";
export const USER_GET_STARTED_SUCCESS = "USER_GET_STARTED_SUCCESS";
export const USER_GET_STARTED_ERROR = "USER_GET_STARTED_ERROR";

export const USER_GET_AD_CLICK_COUNTER = "USER_GET_AD_CLICK_COUNTER";
export const USER_GET_AD_CLICK_COUNTER_SUCCESS = "USER_GET_AD_CLICK_COUNTER_SUCCESS";
export const USER_GET_AD_CLICK_COUNTER_ERROR = "USER_GET_AD_CLICK_COUNTER_ERROR";

export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT";
export const WEBSOCKET_DISCONNECT = "WEBSOCKET_DISCONNECT";
