import React from "react";

export const CheckIcon = ({className, style, fill = true, ...props}) => {

  const newStyle = {...style, fontVariationSettings: `'FILL' ${fill ? 1 : 0}`}

  return (
    <span className={`material-symbols-outlined align-middle${className ? ` ${className}`:""}`} style={newStyle} {...props}>check</span>
  );
}
