import api from '../Api';
import { getToken } from '../shared/utils';

class AdsService {

    /**
     * Obtain ads for logged user.
     * @param {string} token 
     */
    static async fetch(token) {
        return api.get("/v1/user/ads", {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }

    /**
     * Obtain small ads for logged user.
     * @param {string} token 
     */
    static async fetchSmallImages(token) {
        return api.get("/v1/user/ads?type=TYPE_WEB_IMAGE_SMALL", {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }


    /**
     * Increase the amount of view for specific ad.
     * @param {string} token 
     * @param {number} adId 
     */
    static async addView(token, adId) {
        return api.patch(`/v1/user/ads/${adId}`, {}, {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }

    /**
     * Increase the amount of clicks for specific ad.
     * @param {string} token 
     * @param {number} adId 
     */
    static async addClick(token, adId) {
        return api.patch(`/v1/user/ads/${adId}/clicked`, {}, {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }
}

export default AdsService;
