import React, { useEffect, useState } from 'react';
import GlobalMessage from './GlobalMessage';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import  LocalStorageSerivce  from '../../Services/LocalStorageSerivce'; 

const BackOnlineMessage = () => {
  const storageKey = "backOnlineMessage"
  const backOnline = useSelector(state => state.system.system_back_online)  
  const [show, setShow] = useState(false); 

  useEffect(() => {
    if (backOnline === true) {
      if (!LocalStorageSerivce.get(storageKey)) {
        setShow(true)
      }
    }
    else if (backOnline === false) {
      LocalStorageSerivce.remove(storageKey)
    }
  }, [backOnline])

  const handleClose = () => {
    LocalStorageSerivce.setWithExpiry(storageKey, "true",  24 * 60 * 60 * 1000) // 1 day
  }

    return (
    <>
      {show &&
        <GlobalMessage onClose={handleClose} type="accent">
          <FormattedMessage id="message.global.backOnline" />
        </GlobalMessage>
      }
    </>
    );

}


export default BackOnlineMessage;


