import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import SharedDocumentForm from './SharedDocumentForm';

import { submit } from 'redux-form';
import { sharedDocumentModalToggle, sharedDocumentSubmit, fetchUniversities, fetchUniversity } from '../../store/actions';

class SharedDocumentModal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {
    this.props.fetchUniversities();
    this.props.fetchUniversity();
  }

  handleToggle(e) {
    e.preventDefault();

    if (this.props.isSubmitting === true) {
        return;
    }

    this.props.toggle();
  }

  handleSubmit(e) {
    e.preventDefault();
    
    if (this.props.isSubmitting === true) {
        return;
    }

    this.props.submit("sharedDocumentForm");
  }

  onSubmitForm(model) {
    this.props.onSubmit(model, this.props.intl);
  }

  render() {
    const { isOpen, isSubmitting, universities } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.handleToggle} data-test="modal-to-share-document">
        <ModalHeader toggle={this.handleToggle}>
          <FormattedMessage id="sharedDocuments.modal.title" />
        </ModalHeader>
        <ModalBody>

            <SharedDocumentForm
              isSubmitting={isSubmitting}
              onSubmit={this.onSubmitForm}
              intl={this.props.intl} 
              universities={universities} />

        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={this.handleSubmit} disabled={isSubmitting} data-test="btn-share-document">
                <FormattedMessage id="sharedDocuments.modal.btn.share" />
                {isSubmitting?<Spinner size="sm" className="ml-2"/>:null}
            </Button>
            <Button color="secondary" onClick={this.handleToggle} disabled={isSubmitting}>
              <FormattedMessage id="btn.cancel" />
            </Button>
        </ModalFooter>
      </Modal>
    );
  }

}

SharedDocumentModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

SharedDocumentModal.defaultProps = {
    isOpen: false,
    isSubmitting: false
}

function mapStateToProps(state) {
  return {
    isOpen: state.sharedDocuments.modal.isOpen,
    isSubmitting: state.sharedDocuments.modal.isSubmitting,
    universities: state.profile.universities
  };
}

const mapDispatchToProps = {
  toggle: sharedDocumentModalToggle,
  onSubmit: sharedDocumentSubmit,
  submit,
  fetchUniversities,
  fetchUniversity
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SharedDocumentModal));
