import api from '../ApiForTutor';
import { getToken } from '../../shared/utils';

class ConfigurationService {

    static async fetchConfig(key) {
      return api.get(`/metadata/${key}`, {headers: {'X-AUTH-TOKEN': getToken()}});
    }


    
}

export default ConfigurationService;

export const SPENDING_LIMIT_REACHED = "SPENDING_LIMIT_REACHED";
export const ADSENSE_ENABLED = "ADSENSE_ENABLED";
