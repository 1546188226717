import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';
import Leaflet from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import { Map, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import LocationFilter from './LocationFilter'

import './Locations.css'
import { LocationMarkerItem } from './LocationMarkerItem';

import locationSvg from '../../images/map-location.svg';
import { LocationService } from '../../Services';
import { logger } from '../../shared/Logger';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PrintAtCopyShop } from '../Documents/PrintAtCopyShop';
import { InfoIcon } from '../Icons';

Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'
Leaflet.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

const markerIcon = new Leaflet.Icon({
    iconUrl: locationSvg,
    iconSize: [32, 52],
    iconAnchor: [10, 52]
});

// Brussels
const defaultPosition = [50.859017, 4.343561];
const defaultZoom = 8;
const cityZoom = 11;

// FIXME Hard filtering of CS with problems to use desktop app.
const hardLocationFilter = [
  
];

const Locations = ({title}) => {

  const [search, setSearch] = useState(false);
  const [position, setPosition] = useState(defaultPosition);
  const [zoom, setZoom] = useState(defaultZoom);
  const [locations, setLocations] = useState([]);
  const desktopAppIsEnabled = useSelector(state => state.system.system_enable_desktop_app);

  const map = useRef();

  const intl = useIntl();
  const dispatcher = useDispatch();

  useEffect(() => {
    
    LocationService.fetchLocations().then(response => {
      setLocations(response.data);
    }).catch(err => {
      logger.error("Error loading locations", err);
    });

  }, []);

    
  /**
  * Handle city filtering to do zoom to the selected city.
  */
  const handleFilter = (city) => {
    setSearch(true);

    if (city === undefined || city === null) {
      changePosition(defaultPosition[0], defaultPosition[1], defaultZoom);
    } else {
      changePosition(city.latitude, city.longitude, city.zoom?city.zoom:cityZoom);
    }
  }

  /**
  * Change the position of the map to do zoom to the selected coordinates.
  */
  const changePosition = (latitude, longitude, zoom) => {
  
    if (zoom === undefined) {
      zoom = defaultZoom;
    }
    setPosition([latitude, longitude]);
    setZoom(zoom);
  }


  if (desktopAppIsEnabled !== true) {
    return null;
  }

  const gestureHandlingOptions = {
      text: {
          touch: intl.formatMessage({id: "locations.touch"}),
          scroll: intl.formatMessage({id: "locations.scroll"}),
          scrollMac: intl.formatMessage({id: "locations.scrollMac"})
      },
      duration: 1000
  };

  if (map.current) {

    var mac = false;

    if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
        mac = true;
    }

    var scrollContent = gestureHandlingOptions.text.scroll;
    if (mac) {
        scrollContent = gestureHandlingOptions.text.scrollMac;
    }

    if (map.current.container) {
      map.current.container.setAttribute(
        "data-gesture-handling-touch-content",
        gestureHandlingOptions.text.touch
      );

      map.current.container.setAttribute(
        "data-gesture-handling-scroll-content",
        scrollContent
      );
    }
    
  }

  return (
    <React.Fragment>
      <div id="our_locations" className='pb-2 border-bottom mb-3'>
        <div className='d-flex align-items-center'><h4 className='mb-md-0 mb-1 mr-1'>{title}</h4><Info /></div>
      </div>
      <div className='d-flex flex-column align-items-start flex-md-row justify-content-between pb-2 mb-3'>
        <LocationFilter onFilter={handleFilter}/>
      </div>
      <div className='locationMap'>
        <Map id={"map"} ref={map} center={position} zoom={zoom} zoomControl={false} style={{"height": "100%", "minHeight": "400px"}} gestureHandling={true} gestureHandlingOptions={gestureHandlingOptions} >
          <ZoomControl position="topright" />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          />
          {locations.filter(l => !hardLocationFilter.includes(l.id)).filter(l => l.latitude !== undefined && l.latitude !== null).map(l =>
            <Marker key={"locationMarker" + l.id} position={[l.latitude, l.longitude]} icon={markerIcon}>
              <Popup maxWidth={300}>
                <LocationMarkerItem item={l} />
              </Popup>
            </Marker>
          )}

        </Map>
      </div>
    </React.Fragment>
  );

}

export default Locations;

const Info = (props) => {

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  }

  return (
    <Fragment>
      <InfoIcon className="c-pointer" onClick={toggle} />
      <Modal toggle={toggle} isOpen={open} className='copyshop-info-modal'>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id="documents.printForFree.title" /> - <FormattedMessage id="documents.printForFree.cs.title" />
        </ModalHeader>
        <ModalBody>
          <PrintAtCopyShop />
        </ModalBody>
      </Modal>
    </Fragment>
  );

}
