import React from 'react';
import PropTypes from 'prop-types';
import SharedDocumentItem from './SharedDocumentItem';
import { sharedDocumentShape } from '../../Shapes';
import ListPagination from '../Commons/ListPagination';

const SharedDocumentList = ({ total, items, page, pageSize, lang, intl, onCopyClick, onPageChange, disableSharing, onPreviewClick }) => {

        const content = items.map(item => 
            <SharedDocumentItem key={`sharedDocument-${item.id}`} item={item} lang={lang}
                intl={intl} onCopyClick={onCopyClick} disableSharing={disableSharing}
                onPreviewClick={onPreviewClick} />
        );

        let pagination = <ListPagination page={page} totalItems={total} pageSize={pageSize} onPageChange={onPageChange} />;

        return (
            <div className="sharedDocument-list">
                {pagination}
                {content}
                {pagination}
            </div>
        );
}

SharedDocumentList.propTypes = {
    items: PropTypes.arrayOf(sharedDocumentShape).isRequired,
    total: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    onPreviewClick: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    disableSharing: PropTypes.bool
}

SharedDocumentList.defaultProps = {
    pageSize: 10,
    page: 0,
    total: 0,
    disableSharing: false
}

export default SharedDocumentList;