import api from '../ApiForTutor';
import apiTopics from '../ApiForTopics';
import { getToken } from '../../shared/utils';

class FlashcardService {

    static async fetchAll() {
        return api.get("/flashcards", {headers: {'X-AUTH-TOKEN': getToken()}});
    }

    static async fetch(key) {
      return api.get(`/flashcards/${key}`, {headers: {'X-AUTH-TOKEN': getToken()}});
    }

    static async delete(key) {
      return api.delete(`/flashcards/${key}`, {headers: {'X-AUTH-TOKEN': getToken()}});
    }

    static async create(data) {

      let topics = [];
      let language = null;
      let tokens = null;

      try {
        const responseForTopics = await apiTopics.get(`/documents/${data.selectedDoc.hash}`, {headers: {'X-AUTH-TOKEN': getToken()}});
        topics = responseForTopics.data.topics;
        language = responseForTopics.data.language;
        tokens = responseForTopics.data.tokens;
      } catch(error) {
        return Promise.reject(error);
      }

      // FIXME This field does not exist, for now we can calculate it
      let sourceKey;
      if (data.selectedDoc.url) {
        sourceKey = new URL(data.selectedDoc.url).pathname.substring(1).replace(".pdf", ".source.pdf");
      } else if (!data.selectedDoc.previewUrl.endsWith(".source.pdf")) {
        sourceKey = new URL(data.selectedDoc.previewUrl).pathname.substring(1).replace(".pdf", ".source.pdf");
      } else {
        sourceKey = data.selectedDoc.previewUrl;
      }

      const start = data.pageRange[0];
      const end = data.pageRange[1];
      const pageRange = [];
      
      for(let i = start;i<=end;i++) {
        pageRange.push(i);
      }

      const postData = {
        "numberOfFlashcards": data.qNumber,
        "pageRange": pageRange,
        "startPage": start,
        "endPage": end,
        "topics": topics,
        "language": language,
        "tokens": tokens,
        "document": {
          "id": data.selectedDoc.hash,
          "name": data.selectedDoc.name,
          "sourceKey": sourceKey 
        },
        "name": data.name 
      }

      return api.post("/flashcards", postData, {headers: {'X-AUTH-TOKEN': getToken()}});
  }
}

export default FlashcardService;
