import React from "react";
import { useGlobal } from "../../contexts/GlobalContext";
import { useIntl } from "react-intl";
import { Error } from "../Messages/Error";

const LIMIT_PERCENTAGE = 0.99;

const DailyLimiErrorMessage = () => {

  const { globalLimitCounter } = useGlobal();
  const intl = useIntl();

  if (globalLimitCounter?.currentDailyValue >= (globalLimitCounter?.dailyLimit * LIMIT_PERCENTAGE)) {
    return <Error errorMessage={intl.formatMessage({id: "errors.api.code.33"})} />;
  }

  return null;
}

export default DailyLimiErrorMessage;