import * as actionTypes from './types';

export const fetchCities = () => {
  return {
    type: actionTypes.CITIES_FETCH
  };
};

export const fetchCitiesSuccess = (cities) => {
  return {
    type: actionTypes.CITIES_FETCH_SUCCESS,
    cities: cities
  };
};

export const fetchCitiesError = (error) => {
  return {
    type: actionTypes.CITIES_FETCH_ERROR,
    error: error
  };
};

export const fetchLocations = (city) => {

  return {
    type: actionTypes.LOCATIONS_FETCH,
    city: city
  };
};

export const fetchLocationsSuccess = (locations) => {
  return {
    type: actionTypes.LOCATIONS_FETCH_SUCCESS,
    locations: locations
  };
};

export const fetchLocationsError = (error) => {
  return {
    type: actionTypes.LOCATIONS_FETCH_ERROR,
    error: error
  };
};
