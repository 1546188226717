import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';

export const AddressListEmpty = () => {

  return (
    <div className="row mt-2" data-test="msg-address-list-empty">
        <div className="col-md-12">
            <Alert color="info" className="text-center">
                <FormattedMessage id="address.list.empty.title"/>
            </Alert>
        </div>
    </div>
  );

}
