import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';

export const StatsLoading = () => {

  return (
    <div className="text-center">
        <Spinner color="primary" style={{width: "4rem", height: "4rem"}}/>
        <div>
            <FormattedMessage id="stats.loading" />
        </div>
    </div>
  );

};
