import React from "react";

import { Route } from 'react-router-dom';
import { languages } from "./config";

/**
 * Component loader to load components according to marketing campaigns. Sample below:
 * 
 * marketingLoader(CreateAccountPage, [{campaigns: ["x", "x1", "x2"], component: LandingPageForSoMeCampaign}]);
 * 
 * @param {Component} defaultComponent Component to be loaded by default 
 * @param {Array} campaignsMap Array with the information about campaigns.
 */
export const marketingLoader = (defaultComponent, campaignsMap) => {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  
  let comp = null;

  if (params.has("utm_campaign")) {

    const campaign = params.get("utm_campaign");

    campaignsMap.forEach(item => {

      if (item.campaigns && Array.isArray(item.campaigns) && item.campaigns.includes(campaign)) {
        comp = item.component;
       return;
      }

    });
  }

  return comp ? comp : defaultComponent;
}

export const marketingLanguage = (defaultLang) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  
  let lang = null;

  if (params.has("utm_content")) {
    const content = params.get("utm_content");

    const contentData = content.split("_");

    if (contentData.length > 1) {
      lang = contentData[contentData.length - 1].toLowerCase();
    }
  }

  return lang && languages.includes(lang) ? lang : defaultLang;
}