import React from "react";
import { FormattedMessage } from "react-intl";
import { Label, Input, FormGroup } from "reactstrap"

export const FeedbackField = ({question, type, onChange}) => {

  return (
    <FormGroup className="mb-2">
      <Label for={question}><FormattedMessage id={question}/></Label>
      <Input id={question} name={question} type={type} onChange={onChange} maxLength={1000}/>
    </FormGroup>
  );

}
