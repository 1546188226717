import React from 'react';
import { connect } from "react-redux";
import { localeSet } from "../../store/actions";
import LanguageLink from './LanguageLink';
import './LanguageSelector.css';
import { languages } from '../../config';

class LanguageSelector extends React.Component {

  render () {

    const { onChangeLocale, activeLang } = this.props;

    return (

      <ul className="language-selector list-inline mb-0">

        {languages.map(l => {

            const isActive = activeLang === l;

            return (
              <li key={l} className="list-inline-item">
                <LanguageLink lang={l} text={l.toUpperCase()} active={isActive} onClick={onChangeLocale} />
              </li>
            );
        })}
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeLang: state.locale.lang
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onChangeLocale: lang => {
      dispatch(localeSet(lang))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
