import * as actionTypes from './types';

export const adsFetch = () => {
  return {
    type: actionTypes.ADS_FETCH
  };
};

export const adsFetchSuccess = (ads) => {
  return {
    type: actionTypes.ADS_FETCH_SUCCESS,
    ads: ads
  };
};

export const adsFetchError = (error) => {
  return {
    type: actionTypes.ADS_FETCH_ERROR,
    error: error
  };
};

export const adsIncreaseView = (id) => {
  return {
    type: actionTypes.ADS_INCREASE_VIEW,
    id: id
  }
};

export const adsClicked = (id) => {
  return {
      type: actionTypes.ADS_CLICKED,
      id: id
  };
};
