import { takeEvery, all } from "redux-saga/effects";

import * as actionTypes from "../actions/types";

import {
  adsFetchSaga,
  adsIncreaseViewSaga,
  adsClickedSaga
} from './ads';

import {
  documentsFetchSaga,
  documentDeleteSaga,
  documentUploadSaga,
  deliveryRequestSaga
} from "./document";

import {
  userFetchSaga,
  userUpdateSaga,
  userForgotPasswordSaga,
  userCreateAccountSaga,
  userInviteFriendsSaga,
  userCopyLinkSaga,
  userActivateSaga,
  userResetPasswordSaga,
  npsSubmitSaga,
  npsAskSaga,
  changeLanguageSaga,
  userStatsSaga,
  userGetAdClickCounterSaga
} from "./user";

import {
  locationFetchCitiesSaga,
  locationFetchLocationsSaga
} from "./location";

import {
  logInSaga,
  logoutSaga,
  logInFacebookSaga,
  directLoginSaga,
  logInAsAnotherUserSaga
} from "./security";

import {
  addressesFetchSaga,
  addressFetchSaga,
  addressSaveSaga,
  addressDeleteSaga
} from "./address";

import {
  saveSectionSaga,
  fetchProfileSaga,
  profileUpdateSaga,
  fetchUniversitiesSaga,
  fetchUniversitySaga
} from "./profile";

import {
  metadataFetchSaga,
  featuresEnabledSaga
} from "./system";

import {
  onWebSocketMessageSaga
} from "./global";

import {
  consentUpdateSaga,
  consentAskSaga,
  consentPartnerEmailsToggleSaga,
  consentLeaderboardToggleSaga
} from './consent';

import {
  leaderboardSagas
} from './leaderboard';

import {
  sharedDocumentSubmitSaga,
  sharedDocumentFetchSaga,
  sharedDocumentCopySaga
} from './sharedDocuments';

export function* watchDocument() {
    yield all([
        takeEvery(actionTypes.DOCUMENTS_FETCH, documentsFetchSaga),
        takeEvery(actionTypes.DOCUMENT_DELETE, documentDeleteSaga),
        takeEvery(actionTypes.DOCUMENT_UPLOAD, documentUploadSaga),
        takeEvery(actionTypes.DELIVERY_REQUEST, deliveryRequestSaga)
    ]);
}

export function* watchUser() {
    yield all([
        takeEvery(actionTypes.USER_FETCH, userFetchSaga),
        takeEvery(actionTypes.USER_UPDATE, userUpdateSaga),
        takeEvery(actionTypes.USER_FORGOT_PASSWORD, userForgotPasswordSaga),
        takeEvery(actionTypes.USER_CREATE_ACCOUNT, userCreateAccountSaga),
        takeEvery(actionTypes.USER_INVITE_FRIENDS, userInviteFriendsSaga),
        takeEvery(actionTypes.USER_COPY_LINK, userCopyLinkSaga),
        takeEvery(actionTypes.USER_ACTIVATE, userActivateSaga),
        takeEvery(actionTypes.USER_RESET_PASSWORD, userResetPasswordSaga),
        takeEvery(actionTypes.USER_NPS_SUBMIT, npsSubmitSaga),
        takeEvery(actionTypes.USER_NPS_ASK, npsAskSaga),
        takeEvery(actionTypes.LOCALE_SET, changeLanguageSaga),
        takeEvery(actionTypes.USER_STATS, userStatsSaga),
        takeEvery(actionTypes.USER_GET_AD_CLICK_COUNTER, userGetAdClickCounterSaga),
    ]);
}

export function* watchLocation() {
    yield all([
        takeEvery(actionTypes.CITIES_FETCH, locationFetchCitiesSaga),
        takeEvery(actionTypes.LOCATIONS_FETCH, locationFetchLocationsSaga)
    ]);
}

export function* watchSecurity() {
    yield all([
        takeEvery(actionTypes.LOGIN, logInSaga),
        takeEvery(actionTypes.LOGOUT, logoutSaga),
        takeEvery(actionTypes.LOGIN_FACEBOOK, logInFacebookSaga),
        takeEvery(actionTypes.LOGIN_AUTO, directLoginSaga),
        takeEvery(actionTypes.LOGIN_SWITCH, logInAsAnotherUserSaga)
    ]);
}

export function* watchAddress() {
    yield all([
        takeEvery(actionTypes.ADDRESSES_FETCH, addressesFetchSaga),
        takeEvery(actionTypes.ADDRESS_FETCH, addressFetchSaga),
        takeEvery(actionTypes.ADDRESS_SAVE, addressSaveSaga),
        takeEvery(actionTypes.ADDRESS_DELETE, addressDeleteSaga)
    ]);
}

export function* watchProfile() {
    yield all([
        takeEvery(actionTypes.PROFILE_SAVE_SECTION, saveSectionSaga),
        takeEvery(actionTypes.PROFILE_FETCH, fetchProfileSaga),
        takeEvery(actionTypes.PROFILE_UPDATE, profileUpdateSaga),
        takeEvery(actionTypes.PROFILE_FETCH_UNIVERSITIES, fetchUniversitiesSaga),
        takeEvery(actionTypes.PROFILE_FETCH_UNIVERSITY, fetchUniversitySaga),
    ]);
}

export function* watchAds() {
    yield all([
        takeEvery(actionTypes.ADS_FETCH, adsFetchSaga),
        takeEvery(actionTypes.ADS_INCREASE_VIEW, adsIncreaseViewSaga),
        takeEvery(actionTypes.ADS_CLICKED, adsClickedSaga)
    ]);
}

export function* watchSystem() {
    yield all([
        takeEvery(actionTypes.METADATA_FETCH, metadataFetchSaga),
        takeEvery(actionTypes.FEATURES_ENABLED_FETCH, featuresEnabledSaga),
    ]);
}

export function* watchGlobal() {

  yield all([
    takeEvery("WEBSOCKET:MESSAGE", onWebSocketMessageSaga)
  ]);
}

export function* watchConsent() {

  yield all([
    takeEvery(actionTypes.CONSENT_UPDATE, consentUpdateSaga),
    takeEvery(actionTypes.CONSENT_ASK, consentAskSaga),
    takeEvery(actionTypes.CONSENT_PARTNER_EMAILS_TOGGLE, consentPartnerEmailsToggleSaga),
    takeEvery(actionTypes.CONSENT_LEADERBOARD_TOGGLE, consentLeaderboardToggleSaga),
  ]);
}

export function* watchLeaderboard() {

  yield all([
    takeEvery(actionTypes.LEADERBOARD_FETCH, leaderboardSagas)
  ]);
}

export function* watchSharedDocuments() {

  yield all([
    takeEvery(actionTypes.SHARED_DOCUMENT_SUBMIT, sharedDocumentSubmitSaga),
    takeEvery(actionTypes.SHARED_DOCUMENT_FETCH, sharedDocumentFetchSaga),
    takeEvery(actionTypes.SHARED_DOCUMENT_COPY, sharedDocumentCopySaga)
  ]);
}
