import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { ConfirmationIcon, WarningIcon } from '../Components/Icons';
import { PrivateTemplate } from '../Templates';

const PaymentResultPage = (props) => {

  const location = useLocation();

  let content = null;
  
  if (location.pathname === "/payment/success") {
    content = (
      <Alert color="success" className="text-center" data-test="msg-delivery-request-success">
        <ConfirmationIcon />
        <div className="mt-2">
          <FormattedMessage id="deliveries.request.payment.message.success" />
        </div>
      </Alert>
    );
    
  } else {
    content = (
      <Alert color="danger" className="text-center" data-test="msg-delivery-request-cancel">
          <WarningIcon />
          <div className="mt-2">
            <FormattedMessage id="deliveries.request.payment.message.cancel" />
          </div>
      </Alert>
    );
  }

  return (
    <PrivateTemplate>
      <div className="container-fluid">
          <div className="row">
            <div className="col-12">
            { content }
            </div>
          </div>
          <div className="row">
          <div className="col-12">
            <div className="float-right">
              <Link className="btn btn-sm mr-3 float-right" role="button" to="/">
                  <FormattedMessage id="btn.back" />
              </Link>
            </div>
          </div>
          </div>
      </div>
    </PrivateTemplate>
  );
}

export default PaymentResultPage;
