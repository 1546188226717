import { updateObject } from '../../shared/utils';

import * as actionTypes from '../actions/types';

const initialState = {
  data: {
      prints: {
        GLOBAL: [],
        SCHOOL: []
      },
      friends: {
        GLOBAL: [],
        SCHOOL: []
      }
  },
  loading: false
}

const leaderboardFetch = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined
  })
};

const leaderboardFetchSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    data: action.data
  })
};

const leaderboardFetchError = (state, action) => {
  return updateObject(state, {
    loading: false
  })
};

const clearData = (state, action) => {
  return updateObject(state, {
    data: undefined
  });
}

/**
* Redux reducer for leaderboard actions
*/
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS: return clearData(state, action);

    case actionTypes.LEADERBOARD_FETCH: 
        return leaderboardFetch(state, action);
    case actionTypes.LEADERBOARD_FETCH_SUCCESS:
            return leaderboardFetchSuccess(state, action);
    case actionTypes.LEADERBOARD_FETCH_ERROR:
            return leaderboardFetchError(state, action);
    default:
      return state;
  }
};
