import * as actionTypes from './types';

export const nextSection = () => {
  return {
    type: actionTypes.SECTIONS_NEXT
  }
};

export const toggleProfile = () => {
  return {
    type: actionTypes.PROFILE_TOGGLE_MODAL
  }
};

export const profileFetch = () => {
  return {
    type: actionTypes.PROFILE_FETCH
  };
};

export const profileFetchSuccess = (sections, currentChoices) => {
  return {
    type: actionTypes.PROFILE_FETCH_SUCCESS,
    sections: sections,
    currentChoices: currentChoices
  };
};

export const saveProfile = (sectionId, fieldsValues, finalStep) => {

  return {
    type: actionTypes.PROFILE_SAVE_SECTION,
    sectionId: sectionId,
    choices: fieldsValues,
    finalStep: finalStep
  }

};

export const saveProfileSuccess = () => {
  return {
    type: actionTypes.PROFILE_SAVE_SECTION_SUCCESS,
  }
};

export const saveProfileError = (error) => {
  return {
    type: actionTypes.PROFILE_SAVE_SECTION_ERROR,
    error: error
  }
};

export const profileUpdate = () => {
  return {
    type: actionTypes.PROFILE_UPDATE
  }
}

export const fetchUniversities = () => {
  return {
    type: actionTypes.PROFILE_FETCH_UNIVERSITIES
  };
}

export const fetchUniversitiesSuccess = (list) => {
  return {
    type: actionTypes.PROFILE_FETCH_UNIVERSITIES_SUCCESS,
    list: list
  };
}

export const fetchUniversitiesError = () => {
  return {
    type: actionTypes.PROFILE_FETCH_UNIVERSITIES_ERROR
  };
}

export const fetchUniversity = () => {
  return {
    type: actionTypes.PROFILE_FETCH_UNIVERSITY
  };
}

export const fetchUniversitySuccess = (university) => {
  return {
    type: actionTypes.PROFILE_FETCH_UNIVERSITY_SUCCESS,
    university: university
  };
}

export const fetchUniversityError = () => {
  return {
    type: actionTypes.PROFILE_FETCH_UNIVERSITY_ERROR
  };
}
