import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { deliveryStatuses } from '../../Shapes/DeliveryShape';

export const DeliveryStatus = ({status}) => {

  let statusClass = null;

  switch (status) {
    case "DELIVERED":
      statusClass = "text-success";
      break;
    case "MISSING":
    case "ERROR":
    case "PAYMENT_ERROR":
    case "PAYMENT_TIMEOUT":
      statusClass = "text-danger";
      break;
    default:

  }

  return (
    <span className={statusClass}>
      <FormattedMessage id={"deliveries.status." + status} />
    </span>
  );

}

DeliveryStatus.propTypes = {
  status: PropTypes.oneOf(deliveryStatuses),
}
