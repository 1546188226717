import moment from "moment";

export const COMPETITION_TYPE_FRIENDS = "FRIENDS";
export const COMPETITION_TYPE_PAGES = "PAGES";

/*

Important: First matching competition will be loaded, avoid to overlap dates for competitions, we will match by dates with "between" for today.
 
FIXME Change start date date

*/
export const competition_config = [
  {
    "key": "2023_november_friends",
    "type": COMPETITION_TYPE_FRIENDS,
    "start_date": moment("01/11/2023", "DD/MM/YYYY"), 
    "end_date": moment("30/11/2023", "DD/MM/YYYY"),
    "info_link": "https://www.boardx.be/plus/berria-student-weeks"
  },
  {
    "key": "2024_february_friends",
    "type": COMPETITION_TYPE_FRIENDS,
    "start_date": moment("01/02/2024", "DD/MM/YYYY"), 
    "end_date": moment("29/02/2024", "DD/MM/YYYY"),
    "info_link": "https://www.boardx.be/plus/berria-student-weeks",
  },
  {
    "key": "2024_april_friends",
    "type": COMPETITION_TYPE_FRIENDS,
    "start_date": moment("01/04/2024", "DD/MM/YYYY"), 
    "end_date": moment("30/04/2024", "DD/MM/YYYY"),
    "info_link": "https://www.boardx.be/plus/berria-student-weeks",
    "notification": true
  }
];

export const getCurrentCompetition = () => {
  const now = moment();
  return competition_config.find(c => now.isBetween(c.start_date, c.end_date, 'day', "[]"));
}