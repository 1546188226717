import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

const AddressDeleteConfirm = ({isOpen, toggle, onDelete, isSubmitting}) => {

  const handleDelete = (e) => {

    if (isSubmitting) {
      return;
    }

    toggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={handleDelete} data-test="modal-confirm-addresses-delete">
      <ModalHeader className="no-border p-2 pl-3 pt-3" toggle={handleDelete}>
        <FormattedMessage id="confirm.title" />
      </ModalHeader>
      <ModalBody className="p-2 pl-3">
        <FormattedMessage id="address.delete.confirm" />
      </ModalBody>
      <ModalFooter className="no-border p-2">
        <Button color="danger" onClick={onDelete} disabled={isSubmitting} data-test="modal-confirm-addresses-delete-ok">
          <FormattedMessage id="confirm.btn.ok" />
          {isSubmitting?<Spinner size="sm" className="ml-2"/>:null}
        </Button>
        &nbsp;
        <Button color="secondary" onClick={toggle} disabled={isSubmitting}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );

};

AddressDeleteConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

export default AddressDeleteConfirm;
