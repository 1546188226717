import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { InfoIcon } from '../Icons';

const LeaderboardInfo = ({ className, intl, reward }) => {
  
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  return (
    <React.Fragment>
      <InfoIcon width="24px" height="24px" style={{cursor: "pointer"}}
          onClick={toggle} title={intl.formatMessage({id: "leaderboard.consent.moreInfo"})} className={className} />

      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle} tag="h4">
          <FormattedMessage id="leaderboard.consent.title" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id="leaderboard.consent.description" values={{breakLine: (<div className='mt-1' />)}} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default injectIntl(LeaderboardInfo);