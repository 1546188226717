import React from 'react';
import PropTypes from 'prop-types';

import WarningSvg from '../../images/warning.svg';

export const WarningIcon = (props) => {
  return (
    <img src={WarningSvg}
      alt={props.alt}
      title={props.title}
      style={{"width": props.width, "height": props.height}}
      className={props.className}/>
  );
};

WarningIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

WarningIcon.defaultProps = {
  width: "64px",
  height: "64px",
  alt: "Warning icon"
};
