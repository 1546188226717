import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { isReadyForDelivery } from '../../shared/utils';
import PrintForFree from './PrintForFree';
import { useGlobal } from '../../contexts/GlobalContext';
import { AiButton } from '../../AiTutor/Ai/AiButton';
import { Experiment, Variant } from '@marvelapp/react-ab-test';

const DocumentListButtons = (props) => {

    const docs = useSelector(state => state.documents.list);
    const selected = useSelector(state => state.documents.selected);
    const freeLimit = useSelector((state) => state.system.credit_policy_user_print_limit);
    const user = useSelector((state) => state.user.user);
    const [open, setOpen] = useState(false);

    const { features, userCanPrint, hasDocuments, globalLimitCounter } = useGlobal();
    const deliveryServiceFeatureEnabled = features.DELIVERY_SERVICE;

    const togglePrintForFree = () => {
      setOpen(!open);
    }

    const hasSelected = selected.documents.length !== 0;
    const isDeliveryDisabled = deliveryServiceFeatureEnabled === false 
                              || isReadyForDelivery(docs, selected.documents) === false
                              || (user?.printsForAcademicYear + selected.pages + selected.ads) > freeLimit
                              || user?.printCredits <= (selected.pages)
                              || !userCanPrint
                              || (selected?.documents.length > 0 && (globalLimitCounter?.currentDailyValue + selected?.pages + selected?.ads) > globalLimitCounter?.dailyLimit);


    const withoutAi =
        <div>
          <div className="d-flex justify-content-md-end align-items-sm-strech justify-content-lg-start flex-column flex-md-row mb-3">
            <button data-test="btn-delivery-request" className="btn btn-primary mr-2"
              onClick={togglePrintForFree} disabled={!hasSelected || isDeliveryDisabled}>
              <FormattedMessage id="documents.btn.order" />
            </button>
          </div>
          <PrintForFree isOpen={open} toggle={togglePrintForFree} />
        </div>

    const withAi =
        <div>
          <div className="d-flex align-items-center justify-content-between flex-md-row-reverse flex-sm-row mb-3">
            <button data-test="btn-delivery-request" className="btn btn-primary mr-2"
              onClick={togglePrintForFree} disabled={!hasSelected || isDeliveryDisabled}>
              <FormattedMessage id="documents.btn.order" />
            </button>
          { hasDocuments ? 
            <AiButton location={'document-list'} className={'mr-2'}/>
            : null
          }
          </div>
          <PrintForFree isOpen={open} toggle={togglePrintForFree} />
        </div>



    return (
      <Experiment name='AI Call to Action'>
        <Variant name='document-list'>
          {withAi}
        </Variant>
        <Variant name='toolbar'>
          {withoutAi}
        </Variant>
        <Variant name='document-list-item'>
          {withoutAi}
        </Variant>
      </Experiment>
    );
}

export default DocumentListButtons;
