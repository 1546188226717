import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';

export const LocationMarkerItem = ({item}) => {

  let routeDestination = item.address.replaceAll(" ", "+");

  if (routeDestination.endsWith(",")) {
    routeDestination = routeDestination.substr(0, routeDestination.length - 1);
  }

  if (item.postalCode) {
    routeDestination += ",+" + item.postalCode
  }

  routeDestination += ",+" + item.cityName;

  return (
    <Fragment>
      <div>
        <div>
          <h4>{item.name}</h4>
        </div>
        <div>
          {item.address}
        </div>
        <div>
          {item.postalCode?`${item.postalCode} `:null}{item.cityName?item.cityName:null}
        </div>
        <div className='mt-1'>
          <a className='' href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(routeDestination)}`} target='_blank' rel="noopener noreferrer">
            <FormattedMessage id="locations.route" />
          </a>
        </div>
        <LocationOpeningHours item={item} />
      </div>
    </Fragment>
  );

};

const LocationOpeningHours = ({item}) => {

  if (item?.businessDays === null || item?.businessDays === undefined || !Array.isArray(item?.businessDays)) {
    return null;
  }

  const days = item.businessDays.map((day, index) => <Day key={index} index={index} dayData={day} />);

  return (
    <div className="pt-2">
      <FormattedMessage id="locations.openingHours" />:
      <div className="pt-2">
        {days}
      </div>
    </div>
  );

}

const Day = ({index, dayData}) => {

  let hours = null, splitHours = null;
  if (dayData?.open === true) {
    hours = (
      <Fragment>
        <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.from}</Moment>
        &nbsp;<FormattedMessage id="locations.openingHours.text.to" />&nbsp;
        <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.to}</Moment>
      </Fragment>
    );
  }

  if (dayData?.split === true) {
    splitHours = (
      <Fragment>
        &nbsp;<FormattedMessage id="locations.openingHours.text.and" />&nbsp;
        <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.fromSplit}</Moment>
        &nbsp;<FormattedMessage id="locations.openingHours.text.to" />&nbsp;
        <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.toSplit}</Moment>
      </Fragment>
    );
  }

  return (
    <div className={splitHours ? '' : 'd-flex'}>
      <div>
        <strong className="text-capitalize"><FormattedMessage id={`days.${index}`} />{ !splitHours ? <Fragment>&nbsp;</Fragment> : null}</strong>
      </div>
      <div>
        {!hours && !splitHours ? <Fragment><FormattedMessage id="locations.openingHours.text.closed" /></Fragment> : null }
        {hours}
        {splitHours}
      </div>
    </div>
  )
};