import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Fade } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { leaderboardShape } from '../../Shapes';
import LeaderboardItem from './LeaderboardItem';
import LeaderboardHeader from './LeaderboardHeader';

import './Leaderboard.css';
import { LeaderboardInfo } from '../others';
import { logger } from '../../shared/Logger';

const Leaderboard = ({loading, data, global, sorted}) => {

  const [headerCollapsed, setHeaderCollapsed] = useState(false);
  const [type, setType] = useState("PRINTS");
  const [showPrints, setShowPrints] = useState(false);
  const [showFriends, setShowFriends] = useState(false);

  useEffect(() => {
    setShowFriends(type === "FRIENDS");
    setShowPrints(type === "PRINTS");
  }, [type]);

  const onLeaderboardFriendsClick = (e) => {
    e?.preventDefault();

    if (loading === true) {
      return;
    }

    setType("FRIENDS");
  }
    
  const onLeaderboardPrintsClick = (e) => {
    e?.preventDefault();

    if (loading === true) {
        return;
    }

    setType("PRINTS");
  }

  const onHeaderTitleClick = (e) => {
    e?.preventDefault();

    if (loading === true) {
        return;
    }

    setHeaderCollapsed(!headerCollapsed);
  }

  const renderContent = (list) => {

    if (list === undefined || list === null) {
      return (
        <FormattedMessage id="leaderboard.empty" />
      );
    }

    let contentClassName = "";

    if (list.length > 5) {
      contentClassName = "overflow-auto"
    }

    const items = list.map((item, index) => <LeaderboardItem key={"leaderboard-item-" + index} index={index} name={item.name} counter={item.counter} /> );

    return (
      <div className={contentClassName}>
        {items}
      </div>
    );
  }

  const renderData = () => {

    let friends = data.friends.GLOBAL;
    let prints = data.prints.GLOBAL;
    
    if (global === false) {
        friends = data.friends.SCHOOL;
        prints = data.prints.SCHOOL;
    }

    return (
      <React.Fragment>
        <Fade in={showFriends} timeout={175} className={showFriends === false?"d-none":null}>
          {renderContent(friends)}
        </Fade>
        <Fade in={showPrints} timeout={175} className={showPrints === false?"d-none":null}>
          {renderContent(prints)}
        </Fade>
      </React.Fragment>
    );
  }

  if (data === undefined || data === null) {
      return null;
  }

  let currentData = null;
  switch (type) {
    case "PRINTS":
      currentData = data.prints;
      break;
    case "FRIENDS":
      currentData = data.friends;
      break;
    default:
      logger.warn("Invalid type definition. Type: " + type);
  }

  let list = currentData.GLOBAL;
  if (global === false) {
    list = currentData.SCHOOL;
  }

  if (sorted !== true) {
    list.sort((a, b) => {
      if (a.counter > b.counter) {
          return -1;
      }
      if (a.counter < b.counter) {
          return 1;
      }
      // a must be equal to b
      return 0;
    });
  }

  return (
      <div className="leaderboard card zc-card">
          <div className="card-body p-md-2">
              <h4 className="leaderboard-header d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <FormattedMessage id="leaderboard.title"/>
                  <span className='ml-1'>
                    <LeaderboardInfo />
                  </span>
                </div>
                <a href="#toggleLeaderboardHeader" onClick={onHeaderTitleClick}>
                  <i className={`fas ${!headerCollapsed?"fa-minus":"fa-plus"}`}></i>
                </a>
              </h4>
              <Collapse isOpen={!headerCollapsed}>
                  <div className="leaderboard-header">
                      <LeaderboardHeader activeType={type}
                          onClickFriends={onLeaderboardFriendsClick}
                          onClickPrints={onLeaderboardPrintsClick}
                          />
                  </div>
                  {renderData()}
              </Collapse>
          </div>
      </div>
  );
}

Leaderboard.defaultProps = {
    sorted: true
}

Leaderboard.propTypes = {
    data: PropTypes.shape({
        prints: PropTypes.shape({
            GLOBAL: PropTypes.arrayOf(leaderboardShape),
            SCHOOL: PropTypes.arrayOf(leaderboardShape)
        }).isRequired,
        friends: PropTypes.shape({
            GLOBAL: PropTypes.arrayOf(leaderboardShape),
            SCHOOL: PropTypes.arrayOf(leaderboardShape)
        }).isRequired
    }).isRequired,
    sorted: PropTypes.bool,
    global: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired
}

export default Leaderboard;
