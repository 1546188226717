import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import CustomSpinner from "../../Components/Commons/Spinner";

import './CompetitionLeaderboard.css';
import { Card, CardBody, CardFooter } from "reactstrap";
import { useSelector } from "react-redux";
import { LeaderboardService } from "../../Services";
import { globalErrorHandling } from "../../shared/utils";
import { COMPETITION_TYPE_FRIENDS } from "../competitions_config";
import { LeaderboardConsentButton } from "../../Components/Consent";
import { InfoIcon } from "../../Components/Icons";
import { Redirect } from 'react-router-dom';
import { logger } from "../../shared/Logger";
import { NUMBER_OF_USERS } from "../../Services/LeaderboardService";

const mapItemToList = (item, index) => {
  return {"position": index + 1, "text": item.name, "value": item.counter}
};

const CompetitionLeaderboard = ({competitionKey, competitionType}) => {

  const user = useSelector(state => state.user.user);
  const consent = useSelector(state => state.consent.leaderboard);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState();
  const [userPosition, setUserPosition] = useState();
  const [navigate, setNavigate] = useState(false);

  useEffect(() => {

    if (user) {

      if (consent !== true) {
        setList(undefined);
        setLoading(false);
        return;
      }

      LeaderboardService.fetch()
        .then(response => {

          let newList = [];

          switch(competitionType) {
            case COMPETITION_TYPE_FRIENDS:

              if (response.data.friends.GLOBAL) {
                newList = response.data.friends.GLOBAL.map(mapItemToList);
              } 

              break;
            case COMPETITION_TYPE_FRIENDS:
              if (response.data.prints.GLOBAL) {
                newList = response.data.prints.GLOBAL.map(mapItemToList);
              }
              break;
            default:
              logger.warn("Competition type is not supported.");
              break;
          }

          setList(newList);

          const userIndex = newList.findIndex(item => item.text === user.username);
          if (userIndex !== -1 && userIndex > (NUMBER_OF_USERS - 1)) {
            setUserPosition(newList[userIndex]);
          } else if (userIndex === -1) {
            setUserPosition({"position": "...", "text": user.username, "value": 0});
          }

        }).catch(error => {
          const errorObj = globalErrorHandling(error);
        }).finally(() => {
          setLoading(false);
        });

    }

  }, [user, consent]);

  if (navigate === true) {
    return (
      <Redirect to="/competition" />
    );
  }

  let content = null;
  let footer = null;

  if (loading === true) {
    content = (
      <div className="text-center">
        <CustomSpinner messageKey="competitions.loading" />
      </div>
    );
  } else if (!list) {
    content = (
      <div>
        <LeaderboardConsentButton showInfo={false} textKey="competitions.leaderboard.disabled"/>
      </div>
    );
  } else if (list.length == 0) {
    content = (
      <div className="text-center">
        <FormattedMessage id="competitions.leaderboard.noData" values={{
          breakLine: (<div className='mt-1' />),
          b: (text) => (<strong>{text}</strong>),
        }} />
      </div>
    );
  } else {

    let contentClassName = "";

    if (list.length > 5) {
      contentClassName = "overflow-auto"
    }

    const items = list.map((item, index) => <CompetitionLeaderboardItem key={index} position={item.position} text={item.text} value={item.value} /> );

    content = (
      <div className={contentClassName}>
        {items}
      </div>
    );

    if (userPosition) {
      footer = (
        <CardFooter>
          <UserLeaderboardItem position={userPosition.position} text={userPosition.text} value={userPosition.value} />
        </CardFooter>
      );
    }
  }

  const handleInfoClick = (e) => {
    e?.preventDefault();
    setNavigate(true);
  }

  return (
    <div className="competition-leaderboard mt-3">
      <Card>
        <CardBody className="p-3">
          <div className="d-flex align-items-center mb-2">
            <h4 className="flex-fill mb-0">
              <FormattedMessage id={`competitions.leaderboard.title`} />
            </h4>
            <InfoIcon onClick={handleInfoClick} className="ml-2 c-pointer" />
          </div>
          <div className="mb-3">
            <FormattedMessage id={`competitions.leaderboard.${competitionType}`} defaultMessage="Define competition leaderboard text for each language" />
          </div>
          {content}
        </CardBody>
        {footer}
      </Card>
    </div>
  );
}

export default CompetitionLeaderboard;

const CompetitionLeaderboardItem = ({position, text, value}) => {

  return (
    <div className="d-flex leaderboard-item">
      <div className="position">
        {position}
      </div>
      <div className="flex-fill text">
        {text}
      </div>
      <div className="value">
        {value}
      </div>
    </div>
  );

}


const UserLeaderboardItem = ({position, text, value}) => {

  return (
    <div className="d-flex leaderboard-user-item">
      <div className="flex-fill text">
        {text}
      </div>
      <div className="value">
        {value}
      </div>
    </div>
  );

}