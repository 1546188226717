import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Leaderboard from './Leaderboard';
import LeaderboardDisabled from './LeaderboardDisabled';
import {
    leaderboardFetch
} from '../../store/actions';

const LeaderboardWrapper = ({ props }) => {

  const data = useSelector(state => state.leaderboard.data);
  const loading = useSelector(state => state.leaderboard.loading);
  const enabled = useSelector(state => state.system.system_enable_leaderboard);
  const consent = useSelector(state => state.consent.leaderboard);

  const dispather = useDispatch();

  useEffect(() => {

    dispather(leaderboardFetch());

  }, []);

  if (enabled !== true) {
      return null;
  }

  if (consent !== true) {
    return (
      <div className="mt-3 mb-3">
        <LeaderboardDisabled />
      </div>
    );
  }

  return (
  <React.Fragment>
    <div className="mt-3 mb-3">
      <Leaderboard data={data} global={true} loading={loading} />
    </div>
  </React.Fragment>
  );

}

export default LeaderboardWrapper;