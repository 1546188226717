import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { calendarStrings } from '../../shared/utils';
import { DeliveryStatus } from './DeliveryStatus';
import './DeliveryItem.css'

const DeliveryItem = ({ item }) => {

    const intl = useIntl();
    const lang = intl.locale;

    let addressInfo = null;

    if (item.metadata) {
      addressInfo = <DeliveryItemMetadata metadata={JSON.parse(item.metadata)}/>
    }

    const totalPages = item.documents.map(d => d.pages).reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    return (
        <div className="card mb-3">
          <div className="card-header p-2">

            <div className='d-flex flex-sm-row flex-column justify-content-between'>
              <div className=''>
                <div className='d-flex flex-wrap'>
                  <span className='mr-1 flex-fill'><b><FormattedMessage id="deliveries.field.id" /></b>:</span>
                  {item.id}
                </div>
              </div>
              <div className=''>
                <div className='d-flex flex-wrap'>
                  <span className='mr-1 flex-fill'><b><FormattedMessage id="deliveries.field.created" /></b>:</span>
                  <Moment utc local parse="DD/MM/YYYY" format="LL" calendar={calendarStrings} locale={lang}>{item.created}</Moment>
                </div>
              </div>
              <div className=''>
                <div className='d-flex flex-wrap'>
                  <span className='mr-1 flex-fill'><b><FormattedMessage id="deliveries.field.status" /></b>:</span>
                  <DeliveryStatus status={item.status} />
                </div>
              </div>
                <div className='d-flex flex-wrap'>
                  <span className='mr-1 flex-fill'><b><FormattedMessage id="deliveries.request.overview.documents.total" /></b>:</span>
                  <FormattedMessage id="global.words.pages" values={{pages: totalPages}}/>
                </div>
                <div className='d-flex flex-wrap'>
                  <span className='mr-1 flex-fill'><b><FormattedMessage id="deliveries.request.generic.price" /></b>:</span>
                  {item.premium === true? `${intl.formatNumber(item.amount / 100)} €`:<FormattedMessage id="global.words.free"/>}
              </div>
            </div>
            
          </div>
          <div className="card-body p-2">
            <div className='row'>
             <div className='col-12 col-md-4 mb-3 mb-md-0'>
                {addressInfo}
              </div>
              <div className='col-12 col-md-8 mt-2 mt-md-0'>
                <ul className="delivery-docs mb-0">
                {item.documents.map((doc, index) =>
                  <li key={index}>
                    <div className='d-flex flex-column flex-md-row align-items-top'>
                      <div className='delivery-doc-name text-break'>{doc.name}</div>
                      <div className='ml-2 text-muted'>
                        <FormattedMessage id="global.words.pages" values={{pages: doc.pages}}/>
                        <span className='ml-1 mr-1'>+</span>
                        <FormattedMessage id="global.words.fullPageAds" values={{ads: doc.ads}}/>
                      </div>
                    </div>
                  </li>
                )}
                </ul>
              </div>
            </div>
          </div>
        </div>
    );

};

export default DeliveryItem;

const DeliveryItemMetadata = ({metadata}) => {

  return (
    <div className="d-flex flex-wrap">
      <div className='font-weight-bold mr-2'>
        <FormattedMessage id="deliveries.addressInfo" />
      </div>
      <div>
        <div className='text-break'>{metadata.first_name} {metadata.last_name}</div>
        <div className='text-break'>{metadata.address}</div>
        <div className='text-break'>{metadata.postal_code} {metadata.city}</div>
      </div>
    </div>
  );
}
