import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';

const DocumentListEmpty = () => {

    return (
        <div data-test="msg-empty-document-list" className="row mt-2">
            <div className="col-md-12">
                <Alert color="success" className="text-center">
                    <FormattedMessage id="documents.emptyList" />
                </Alert>
            </div>
        </div>
    );
}

export default DocumentListEmpty;
