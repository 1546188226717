import React from "react";
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Card, CardContent, useMediaQuery, useTheme, Chip } from "@material-ui/core";
import { ArrowDownward, ArrowForward } from "@material-ui/icons";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { AI_FLASHCARDS, AI_QUIZ } from "../Ai/Containers/AiTutorPage";
import { QuizPage } from "../Quiz/Containers/QuizPage";
import { AiContentTable } from "../Table/AiContentTable";
import { FeedbackForm } from "../Components/FeedbackForm";
import { FlashcardList } from "../Flashcard/FlashcardList";
import "./AiTutorLandingPage.css";
import { PrivateTemplate } from "../../Templates";
import AiTutorContentPage from "./AiTutorContentPage";
import { useMixpanel } from "../../contexts/Mixpanel";
import { AI_NEW_CONTENT_CLICK } from "../../shared/EventsNames";
import "../Ai/AiTutor.css";

const AiTutorLandingPage = (props) => {

  let { path, url } = useRouteMatch();

  return (
    <PrivateTemplate>
      <Switch>
        <Route path={`${path}/quiz`} exact>
          <AiContentTable type={AI_QUIZ} />
        </Route>
        <Route path={`${path}/quiz/feedback`} exact>
          <FeedbackForm type={AI_QUIZ} number={4} />
        </Route>
        <Route path={`${path}/quiz/:key`} >
          <QuizPage />
        </Route>
        <Route path={`${path}/flashcards/feedback`} exact>
          <FeedbackForm type={AI_FLASHCARDS} number={4} />
        </Route>
        <Route path={`${path}/flashcards`} exact>
          <AiContentTable type={AI_FLASHCARDS} />
        </Route>
        <Route path={`${path}/flashcards/:key`}>
          <FlashcardList />
        </Route>
        <Route path={`${path}/flashcards-generator`} exact>
          <AiTutorContentPage type={AI_FLASHCARDS} />
        </Route>
        <Route path={`${path}/quiz-generator`} exact>
          <AiTutorContentPage type={AI_QUIZ} />
        </Route>
        <Route exact path={path}>
          <LandingPage />
        </Route>
      </Switch>
    </PrivateTemplate>
  );
}

export default AiTutorLandingPage;

const LandingPage = () => {

  const mixpanel = useMixpanel();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));


  const handleFlashcardClick = () => {
    history.push(`/ai/flashcards-generator`, {previous: location.pathname});
    trackNewClickEvent(AI_FLASHCARDS);
  }

  const handleQuizzClick = () => {
    history.push(`/ai/quiz-generator`, {previous: location.pathname});
    trackNewClickEvent(AI_QUIZ);
  }

  const trackNewClickEvent = (type) => {
    mixpanel.track(AI_NEW_CONTENT_CLICK, {"ai_landing_page": true, "ai_content_type": type});
  }

  return (
    <div className="d-flex flex-column mx-auto ai-container" style={{gap: "1.5rem"}}>

      <h1 className="text-center mb-0">
        <FormattedMessage id="ai.landingPage.title" defaultMessage="Zerocopy - AI Tutor" />
        <span>&nbsp;</span>
        <Chip label="BETA" color="secondary" style={{marginTop: "-4px"}}/>
      </h1>

      <div className="text-center fs-6">
        <FormattedMessage id="ai.landingPage.info" defaultMessage="Preparing yourself for exams? Do you want to improve your knowledge? Our AI Tutor will help you with it. Transform your documents into flashcards or quizzes to test yourself. Simply upload a PDF, Word or PowerPoint document and our AI Tutor will be able to help you within minutes." />
      </div>

      <div className={`d-flex ${md ? "align-items-stretch justify-content-center" : "flex-column align-items-center"} ai-steps-container`} style={{gap: "20px"}}>
        <StepCard
          headerKey="ai.landingPage.header.one" headerDefaultValue="Step 1: Upload"
          contentKey="ai.landingPage.content.one" contentDefaultValue="Upload your document to easily convert your study materials into flashcards or quizzes." />

        <StepArrowIcon />

        <StepCard
          headerKey="ai.landingPage.header.two" headerDefaultValue="Step 2: Generate"
          contentKey="ai.landingPage.content.two" contentDefaultValue="Choose what kind of content do you want to generate: flashcards or quizzes." />

        <StepArrowIcon />

        <StepCard
          headerKey="ai.landingPage.header.three" headerDefaultValue="Step 3: Test yourself"
          contentKey="ai.landingPage.content.three" contentDefaultValue="Test yourself using the content generated by the AI Tutor." />

      </div>

      <h3 className="text-center fs-5 mt-1 mb-0">
        <FormattedMessage id="ai.landingPage.languages" defaultMessage="English, French, Dutch, Flemmish, Spanish, German and Italian supported" />
      </h3>

      <h3 className="text-center fs-5 mt-3 mb-0">
        <FormattedMessage id="ai.landingPage.action" defaultMessage="How do you want to test yourself?" />
      </h3>

      <div className="d-flex flex-column flex-md-row align-items-center justify-content-center" style={ md ? null : {gap: "1rem"}}>
        <Button color="secondary" size="lg" block={!md} style={ md ? {minWidth: "200px"} : null } onClick={handleFlashcardClick}>
          <FormattedMessage id="ai.landingPage." defaultMessage="Flashcards" />
        </Button>
        <div className="ml-3 mr-3"></div>

        <Button color="secondary" size="lg" block={!md} style={ md ? {minWidth: "200px"} : null } onClick={handleQuizzClick}>
          <FormattedMessage id="ai.landingPage." defaultMessage="Quiz" />
        </Button>
      </div>

    </div>
  );
}

const StepCard = ({headerKey, contentKey, headerDefaultValue, contentDefaultValue}) => {

  return (
    <Card className="ai-step-card bg-primary disabled" elevation={5}>
      <CardContent>
        <h2 className="text-center fs-4">
          <FormattedMessage id={headerKey} defaultMessage={headerDefaultValue} />
        </h2>
        <p className="text-center fs-6">
          <FormattedMessage id={contentKey} defaultMessage={contentDefaultValue} />
        </p>
      </CardContent>
    </Card>
  );
}

const StepArrowIcon = () => {

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  if (md) {
    return (
      <ArrowForward className="align-self-center" style={{ color: 'var(--primary-color)', fontSize: "65" }} />
    );
  }

  return (
    <ArrowDownward style={{ color: 'var(--primary-color)', fontSize: "65" }} />
  );
}
