import React, { useEffect, useState } from "react";
import { Option } from "./Option";
import { Typography } from "@material-ui/core";
import './Question.css'


export const Question = ({ num, options, correctAnswer, questionText, handleAnswer, summary = false, page }) => {
  const [selectedOption, setSelectedOption] = useState("") 
  const [correct, setCorrect] = useState(false)

  useEffect(() => {
    if (summary) {
      setCorrect(options.findIndex(opt => opt.valid && opt.selected) != -1)
    }
  }, [options])

  const handleClick = (answer) => {
    setSelectedOption(answer)
    options.forEach(opt => opt.text === answer ? opt.selected = true : opt.selected = false)
    handleAnswer(answer === correctAnswer)
  }

  const summaryStyle = () =>  {
    return {
      borderRadius: "15px", 
      borderLeft: `solid 1rem ${correct ? "var(--green-color)" : "var(--tangerine-dark-color)"}`, 
      backgroundColor: "var(--darkblue-lightest-color)",
    }
  }

  

  return (
    <section id={`question-${num}`} style={summary ? summaryStyle() : null} className={summary ? "p-3" : ""}>
      <div className="d-flex align-items-start mb-1">
        <h3 className="text-dark font-weight-bold">{num}. {questionText}</h3>
        { summary &&
          <Typography color="textPrimary" variant="h5" className='pageNum'>
            p.{page}
          </Typography>
        }
      </div>


      {options.map((opt, i) => (
        <Option
          key={i}
          index={i}
          value={opt.text}
          selected={opt.text === selectedOption || opt.selected}
          handleClick={handleClick}
          correctAnswer={correctAnswer}
          summary={summary}
        />
      ))}
    </section>
  );
}

