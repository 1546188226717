const initialState = {
  contactEmail: "support@zerocopy.be",
  wsConnected: false
};

const reducer = ( state = initialState, action ) => {
  switch(action.type) {
    case "WEBSOCKET:CONNECT": return {...state, wsConnected: true};
    case "WEBSOCKET:DISCONNECT": return {...state, wsConnected: false};
    default: return state;
  }
};

export default reducer;
