import React, { useEffect, useRef, useState } from "react"
import Async from 'react-select/async';
import { FormGroup, FormText, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { GlobalError } from "../../Components/Messages/GlobalError";

const buildAddress = (intl, address) => {

  if (!address?.id) {
    return null;
  }

  let box = "";

  if (address.boxNumber !== null && address.boxNumber !== undefined && address.boxNumber !== "") {
    box = ", " + intl.formatMessage({id: "address.item.boxNumber"}, {boxNumber: address.boxNumber});
  }

  return {
    streetName: address.street,
    houseNumber: address.streetNumber,
    boxNumber: address.boxNumber,
    postalCode: address.postalCode,
    municipalityName: address.city,
    string: address.street + " " + address.streetNumber + box + " - " + address.postalCode + " " + address.city
  }
};

const AddressAutocomplete = ({onSelect, previousSelection}) => {

  const intl = useIntl();
  const [selected, setSelected] = useState(buildAddress(intl, previousSelection));
  const [error, setError] = useState();

  const selectRef = useRef();

  useEffect(() => {

    if (onSelect && selected) {
      const address = {
        street: selected.streetName,
        streetNumber: selected.houseNumber,
        boxNumber: selected.boxNumber,
        postalCode: selected.postalCode,
        city: selected.municipalityName
      };
      onSelect(address);
      if (!selected.houseNumber) {
        setError("adddress.message.noNumber");
      } else {
        setError(null);
      }
    }

  }, [selected, onSelect]);

  useEffect(() => {

    const select = selectRef.current?.select;
    const inputRef = select?.select?.inputRef;

    if (inputRef ) {

      let newValue = previousSelection.street + " " + previousSelection.streetNumber + " - " + previousSelection.postalCode + " " + previousSelection.city; 

      select.state.inputValue = newValue;
      inputRef.value = newValue;
    }

  }, [previousSelection]);

  const loadOptions = (inputValue, callback) => {

    if (!inputValue || inputValue.length < 5) {
      return Promise.resolve([]);
    }

    fetch("https://webservices-pub.bpost.be/ws/ExternalMailingAddressProofingCSREST_v1/address/autocomplete?" + new URLSearchParams({
      q: inputValue
    })).then(response => response.json()).then(response => {
      callback(response.response.topSuggestions.map(item => item.address));
    })
    
  };

  const handleOnSelect = (selection) => {
    setSelected(selection);
  };

  const handleFocus = () => {
    const select = selectRef.current?.select;
    const inputRef = select?.select?.inputRef;

    if (inputRef && select.state.value) {

      select.state.inputValue = select.state.value.string;
      inputRef.value = select.state.value.string;

      if (!select.state.value.houseNumber) {
        const newPosition = select.state.value.streetName.length + 1;
        inputRef.setSelectionRange(newPosition, newPosition);
      }
      
    }
  }

  let content = (
      <FormGroup>
        <Label for="selectAddress"><FormattedMessage id="address.selector" /></Label>
        <Async
          value={selected}
          ref={selectRef}
          inputId="selectAddress"
          data-test="address-autocomplete"
          autoFocus={true}
          cacheOptions={true}
          loadOptions={loadOptions}
          getOptionValue={(option) => option}
          getOptionLabel={(option) => option.string }
          onChange={handleOnSelect}
          placeholder={intl.formatMessage({id: "address.selector.placeholder", defaultMessage: "rue des bouchers 3, 1000 Bruxelles"})}
          noOptionsMessage={() => intl.formatMessage({id: "address.selector.noOption", defaultMessage: "Search your address typing at least 5 characters"})}
          loadingMessage={() => intl.formatMessage({id: "address.selector.loading", defaultMessage: "Loading matching addresses"})} 
          onFocus={handleFocus} 
          blurInputOnSelect={true}
          isClearable={true}
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }} />
        <FormText>
          <FormattedMessage id="address.select.info" />
        </FormText>
      </FormGroup>
    );

  let errorData = null;

  if (error) {
    errorData = (
      <GlobalError error={{errorKey: error}} />
    );
  }

  return (
    <div>
      {errorData}
      {content}
    </div>
  );
  
}

export default AddressAutocomplete;
