import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from "react-helmet";

import './CompetitionPage.css';
import { PrivateTemplate } from '../../Templates';
import InviteFriendsCopyLink from '../../Components/InviteFriends/InviteFriendsCopyLink';
import CustomSpinner from '../../Components/Commons/Spinner';
import { COMPETITION_TYPE_FRIENDS } from '../competitions_config';
import { calendarStrings } from '../../shared/utils';
import Moment from 'react-moment';
import { useCompetition } from '../CompetitionContext';

const CompetitionPage = (props) => {

  const intl = useIntl();

  const { competition, loading } = useCompetition();

  let title = intl.formatMessage({id: "competitions.title"});
  let content = null;

  if (loading) {
    content = (
      <CustomSpinner messageKey="competitions.loading" />
    );
  } else if (!competition) {
    content = (
      <div>
        <FormattedMessage id="competitions.noAtThisMoment" />
      </div>
    );
  } else {

    let langForImg = intl.locale;

    if (competition.image_by_lang !== true || langForImg === "es") {
      langForImg = "en";
    }

    if (competition.type === COMPETITION_TYPE_FRIENDS) {

      content = (
        <div>
          <div>
            
            <div className='float-none float-md-left mr-0 mr-md-2'>
              <CoverWithFallback src={`https://zerocopy.ams3.digitaloceanspaces.com/images/competitions/${competition.key}_${langForImg}.png`} />
            </div>
            
            <div className='mt-2 mt-md-0'>
              <h2><FormattedMessage id={`competitions.${competition.key}.title`} defaultMessage="Define competition title for each language" /></h2>
              <div>
                <span><FormattedMessage id="competitions.startDate" /></span>&nbsp;
                <Moment parse="DD/MM/YYYY" format="DD/MM/YYYY" calendar={calendarStrings} locale={intl.locale}>{competition.start_date}</Moment>
              </div>
              <div>
                <span><FormattedMessage id="competitions.endDate" /></span>&nbsp;
                <Moment parse="DD/MM/YYYY" format="DD/MM/YYYY" calendar={calendarStrings} locale={intl.locale}>{competition.end_date}</Moment>
              </div>
              <h3 className='mt-3'>
                <FormattedMessage id="competitions.description" />
              </h3>
              <div>
                <FormattedMessage id={`competitions.${competition.key}.description`} defaultMessage="Define competition description for each language" 
                  values={{
                    breakLine: (<div className='mt-1' />),
                    b: (text) => (<strong>{text}</strong>),
                    info: (text) => (<a href={competition.info_link} target="_blank" rel="noopener noreferrer">{text}</a>),
                  }}/>
              </div>
            </div>
            
          </div>

          <h4 className='mt-3'>
            <FormattedMessage id="competitions.explanation" />
          </h4>
          <div>
            <FormattedMessage id={`competitions.explanation.${competition.type}`} defaultMessage="Define competition explanation for each language" 
              values={{
                breakLine: (<div className='mt-1' />),
                b: (text) => (<strong>{text}</strong>),
              }} />
          </div>
          <div className="mt-3 font-weight-bold font-italic small">
            <i className="fas fa-exclamation-triangle mr-2"></i>
            <FormattedMessage id={`competitions.explanation.${competition.type}.important`} defaultMessage="Define competition important text for each language"
              values={{
                breakLine: (<div className='mt-1' />),
                b: (text) => (<strong>{text}</strong>),
              }} />
          </div>
          <div className="mt-1 font-weight-bold font-italic small">
            <i className="fas fa-exclamation-triangle mr-2"></i>
            <FormattedMessage id={`competitions.explanation.${competition.type}.disclaimer`} defaultMessage="Define competition disclaimer for each language"
              values={{
                breakLine: (<div className='mt-1' />),
                b: (text) => (<strong>{text}</strong>),
              }} />
          </div>
          <div className="mt-2" style={{maxWidth: "400px"}}>
            <InviteFriendsCopyLink title={intl.formatMessage({id: "competitions.inviteFriendsLink"})}/>
          </div>
          
        </div>
      );

    }

  }

  return (
    <PrivateTemplate>
      <Helmet>
          <title>Zerocopy - {title}</title>
      </Helmet>

      <div className="container-fluid page-in competition-page">

        <div className="row">
          <div className="col-12">
            {content}
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
}

export default CompetitionPage;

export const CoverWithFallback = ({ src, size = 300 }) => {

  const [showAlt, setShowAlt] = useState(false);

  useEffect(() => {
    setShowAlt(false);
  }, [src]);

  const onImageError = () => {
    setShowAlt(true);
  }

  if (showAlt) {
    return (
      <svg className="bd-placeholder-img rounded" width={size} height={size} xmlns="http://www.w3.org/2000/svg" role="img" aria-label={`Placeholder: ${size}x${size}`} preserveAspectRatio="xMidYMid slice" focusable="false">
        <title>Placeholder</title>
        <rect width="100%" height="100%" fill="#6c757d"></rect>
        <text x="50%" y="50%" fill="#dee2e6" dy=".3em">{size}x{size}</text>
      </svg>
    );
  }

  return (
    <img src={src} onError={onImageError} width={size} height={size} className='rounded' alt='' />
  )
}
