import React, { Fragment } from 'react';

import AddressAutocomplete from '../../Address/Components/AddressAutocomplete';
import { useForm } from 'react-hook-form';
import { Field } from '../Form/CustomFields';
import { useIntl } from 'react-intl';
import { Col, Container, Form, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

const AddressForm = ({onSubmit}) => {

  const intl = useIntl();
  const address = useSelector(state => state.address.item);

  const { control, register, reset, handleSubmit, setValue, setFocus, setError, formState: { submitting, errors } } = useForm({
    mode: "onSubmit",
    defaultValues: address
  });

  const onSelectAddress = (selectedAddress) => {
    setValue("street", selectedAddress.street);
    setValue("streetNumber", selectedAddress.streetNumber);
    setValue("boxNumber", selectedAddress.boxNumber);
    setValue("postalCode", selectedAddress.postalCode);
    setValue("city", selectedAddress.city);
  };

  return (
    <Fragment>
       <Container fluid>
          <Row>
            <Col>
              <AddressAutocomplete onSelect={onSelectAddress} previousSelection={address} />
            </Col>
          </Row>
      </Container>

      <Form id="address-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <input type='hidden' {...register("street", {required: true, maxLength: 120})} />
        <input type='hidden' {...register("streetNumber", {required: true, maxLength: 16})} />
        <input type='hidden' {...register("boxNumber", {maxLength: 15})} />
        <input type='hidden' {...register("postalCode", {required: true, maxLength: 10})} />
        <input type='hidden' {...register("city", {required: true, maxLength: 64})} />

        <Container fluid>
          <Row>
            <Col>
              <Field field="name" data-test="address-name-input" labelKey="address.field.name" placeholder={intl.formatMessage({id: "address.field.name.placeholder"})}
                options={{ required: true, maxLength: 120 }}
                errors={errors} register={register} disabled={submitting} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field field="surname" data-test="address-surname-input" labelKey="address.field.surname" placeholder={intl.formatMessage({id: "address.field.surname.placeholder"})}
                options={{ required: true, maxLength: 120 }}
                errors={errors} register={register} disabled={submitting} />
            </Col>
          </Row>
        </Container>
        
      </Form>
    </Fragment>
  );

}

export default AddressForm;
