import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';

export const DeliveryListEmpty = () => {
  
  return (
    <div className="row mt-2">
        <div className="col-md-12">
            <Alert color="info" className="text-center">
              <div className="mb-3">
                  <i className="far fa-frown" style={{fontSize: "3rem"}}></i>
              </div>
              <FormattedMessage id="deliveries.list.empty"/>
            </Alert>
        </div>
    </div>
  );

}
