import { createTheme } from '@material-ui/core/styles';

export const MuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    type: "light",
    text: {
      primary: `#516b85`,
      secondary: `#a7b4c1`,
    }
  },
});
