import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDropzone from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_BYTES, allowedMimeTypes } from '../../shared/utils';
import './DocumentUpload.css';
import image from '../../images/documents.svg';
import { error as errorNotification } from 'react-notification-system-redux';
import { GlobalError } from '../Messages/GlobalError';
import { metadataFetch, fetchDocuments } from '../../store/actions';
import { useMixpanel } from '../../contexts/Mixpanel';
import { DOCUMENTS_UPLOAD_SELECTION, FAILED_UPLOAD } from '../../shared/EventsNames';
import { useGlobal } from '../../contexts/GlobalContext';
import ProfileButton from '../Profile/ProfileButton';
import { DocumentService } from '../../Services';
import { logger } from '../../shared/Logger';
import { Container, Box, Grid, Paper, Typography } from '@material-ui/core';
import { AiIcon, UploadIcon } from '../Icons'

const DocumentUpload = () => {

  const enabled = useSelector(state => state.system.system_enable_upload);
  const docs = useSelector(state => state.documents.list);
  const freeLimit = useSelector((state) => state.system.credit_policy_user_print_limit);
  const user = useSelector((state) => state.user.user);
  const profileReward = useSelector((state) => state.system.credit_policy_level_5);
  const [uploading, setUploading] = useState(false);

  const intl = useIntl();
  const dispatcher = useDispatch();

  const mixpanel = useMixpanel();
  const { userCanUpload, userCanPrint, askProfile, maxUploads, hasDocuments } = useGlobal();

  const handleCheckUploadClick = (e) => {

    if (e !== undefined) {
      e.preventDefault();
    }

    dispatcher(metadataFetch("system_enable_upload", "boolean"));
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    processUploadFiles(dispatcher, intl, mixpanel, acceptedFiles, rejectedFiles, setUploading, maxUploads);
  }


    if (enabled === false) {

      const refreshBtn = (
        <a href="#check-upload" className="pl-2 alert-link"
          onClick={handleCheckUploadClick}
          title={intl.formatMessage({id: "btn.checkAgain"})}>
          <i className="fas fa-sync-alt"></i>
        </a>
      );

      return (
          <GlobalError error={{errorKey: "documents.upload.message.disabled"}} extraContent={refreshBtn} />
      );
    } else if (user?.printsForAcademicYear >= freeLimit) {
      return (
        <GlobalError error={{errorKey: "errors.api.code.212"}} />
      );
    } else if (!userCanUpload && userCanPrint) {
      return (
        <GlobalError error={{errorKey: "documents.upload.message.almostLocked", errorParams:{
          breakLine: (<div className='mt-1' />),
          b: (text) => (<strong>{text}</strong>)
        }}} />
      );
    } else if (!userCanPrint && !userCanUpload) {
      return (
        <GlobalError error={{errorKey: "documents.upload.message.locked", errorParams:{
          breakLine: (<div className='mt-1' />),
          b: (text) => (<strong>{text}</strong>)
        }}} />
      );
    } else if (askProfile === true) {
      const extraContent = (
        <div className='mt-2'>
          <ProfileButton />
        </div>
      );
      return (
        <GlobalError error={{errorKey: "profile.msg.required", errorParams: {reward: profileReward}}} extraContent={extraContent} />
      );
    }

    const dropZone = (
        <React.Fragment>
          <ReactDropzone
              onDrop={onDrop}
              multiple
              accept={allowedMimeTypes}
              maxSize={MAX_FILE_SIZE_BYTES}
              maxFiles={maxUploads}
              disabled={uploading || user?.printsForAcademicYear >= freeLimit || !userCanUpload}>

              {({getRootProps, getInputProps}) => (
                <div data-test="document-upload" {...getRootProps({className: 'Dropzone c-pointer mt-2 p-2'})}>

                  <input {...getInputProps()} aria-label={intl.formatMessage({id: 'documents.upload.button'})}/>
                  <div className="text-center mt-4">
                    <UploadIcon alt={intl.formatMessage({id: 'documents.upload.button'})} role="button" style={{"fontSize":"7em",}}/>
                    <br />
                    <button id="btnUploadDocuments" className="btn btn-accent btn-lg" disabled={uploading || user?.printsForAcademicYear > freeLimit}>
                        { uploading ? <Spinner size="sm" className="mr-1" /> : null }
                        <FormattedMessage id="documents.upload.button" />
                    </button>
                  </div>

                  <div className="text-center mt-2">
                  <FormattedMessage id="documents.upload.ai.small" values={{
                    highlight: text => <span className="ai-text">{text}</span>,
                    icon: <AiIcon/>, 
                  }}/>
                  </div>

                  <div className="text-center mt-2 mx-2 mx-md-0 text-secondary small mb-4">
                    <div className="text-secondary">
                      <span className='font-weight-bold'><FormattedMessage id="documents.upload.allowedTypes.1" /></span>
                      <FormattedMessage id="documents.upload.allowedTypes.2" />
                    </div>
                    <div>
                      <span className='font-weight-bold'><FormattedMessage id="documents.upload.limit.1" /></span>
                      <FormattedMessage id="documents.upload.limit.2" values={{amount: maxUploads }} />
                    </div>
                  </div>
                </div>
              )}
              
              
          </ReactDropzone>

          
        </React.Fragment>
      );

    const aiTutorIcon = () => {
        return ( 
          <span className="ai-text">
            <FormattedMessage id='ai.tutor' 
            values={{
              icon: <AiIcon height='38px' width='38px'/>,
              }}/>
          </span>
        );
      }

  
    
    
    
    return (
        <React.Fragment>
          <Container className='upload-container'>
            <Box>
            {false ? 
              <Grid container spacing={2} className={"mb-2"}>
                <Grid item xs={12} className="pb-0">
                  <Paper elevation={0} className='text-center'>
                    <Typography color='textPrimary' className='mb-0 fs-4 font-weight-bold'>
                      <FormattedMessage id="documents.upload.ai.big" values={{
                        b: (text) => (<strong>{text}</strong>),
                        icon: aiTutorIcon(), 
                      }}/>
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} className='p-0 mb-1'>
                  <Paper elevation={0} className='text-muted text-center fs-5' style={{"textAlign":"center"}}>
                    <Typography color='textSecondary'>
                      <span><FormattedMessage id="documents.upload.ai.flashcards" values={{
                        icon: <AiIcon className={"filter-muted"}/>, 
                      }}/> </span>
                      <span><FormattedMessage id="documents.upload.ai.quiz" values={{
                        icon: <AiIcon className={"filter-muted"}/>, 
                      }}/> </span>
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
                : null }
              {dropZone}
              <div className='d-flex justify-content-end'>
                <a target="_blank" href="https://www.youtube.com/watch?v=4WYkBFrRtQY" rel="noopener noreferrer">
                  <FormattedMessage id="documents.upload.makePdf" />
                </a>
              </div>
            </Box>
          </Container>
        </React.Fragment>
    );

}

export default DocumentUpload;

export const processUploadFiles = (dispatcher, intl, mixpanel, acceptedFiles, rejectedFiles, setUploading, maxNumFiles) => {

  if (setUploading) {
    setUploading(true);
  }

  if (acceptedFiles?.length > 0) {

    DocumentService.validateFiles(acceptedFiles).then(invalidDocs => {

      let filesToUpload = acceptedFiles;
      if (invalidDocs.length !== 0) {
        filesToUpload = acceptedFiles.filter(file => !invalidDocs.includes(file));
        processInvalidFiles(mixpanel, dispatcher, invalidDocs);
      }
    
      mixpanel.track(DOCUMENTS_UPLOAD_SELECTION, {accepted: filesToUpload.length, rejected: rejectedFiles.length + invalidDocs.length});
    
      if (filesToUpload.length !== 0) {
        DocumentService.uploadDocuments(filesToUpload).then(response => {
          dispatcher(fetchDocuments());
        }).catch(error => {
          notifyDefaultError(intl, dispatcher, error, mixpanel);
        }).finally(() => {
          if (setUploading) {
            setUploading(false);
          }
        });
      }

    }).catch(error => {
      if (setUploading) {
        setUploading(false);
      }
      notifyDefaultError(intl, dispatcher, error, mixpanel);
    });

  } else {
    setUploading(false);
  }
  
  
  processRejectedFiles(mixpanel, dispatcher, intl, rejectedFiles, maxNumFiles)

}

const notifyDefaultError = (intl, dispatcher, error, mixpanel) => {
  mixpanel.track(FAILED_UPLOAD, {"Type": "Unexpected"});
  dispatcher(errorNotification({
    message: intl.formatMessage({ id: "documents.upload.message.error"}),
    position: 'tc',
    autoDismiss: 0,
  }));

  logger.error("Unexpected error uploading documents", error);

};

const processRejectedFiles = (mixpanel, dispatcher, intl, rejectedFiles, maxNumFiles) => {
  

  if (rejectedFiles.length !== 0) {

    // Check for too-many-files error
    const tooManyFiles = rejectedFiles.find(x => x.errors.map(e => e.code).includes("too-many-files"));

    if (tooManyFiles) {

      mixpanel.track(FAILED_UPLOAD, {"Type": "Too many files"});

      dispatcher(errorNotification({
          message: intl.formatMessage({ id: "documents.upload.message.error.tooManyFiles"}, {maximum: maxNumFiles}),
          position: 'tc',
          autoDismiss: 0,
        }));

      return;
    }

    rejectedFiles.forEach(({file, errors}) => {

      const errorCodes = errors.map(e => e.code);

      let errorType = "Other";

      let errorText = "documents.upload.message.error";
      let errorParams = {};

      if (errorCodes.includes("file-too-large")) {
        errorText = "documents.upload.message.error.size";
        errorParams["maximum"] = MAX_FILE_SIZE;
        errorType = "Too big";
      } else if (errorCodes.includes("file-invalid-type")) {
        errorText = "documents.upload.message.error.type";
        errorType = "File not supported";
      }

      mixpanel.track(FAILED_UPLOAD, {"Type": errorType});

      dispatcher(errorNotification({
          message: intl.formatMessage({ id: errorText}, errorParams),
          position: 'tc',
          autoDismiss: 0,
        }));

    });
  }

}

const processInvalidFiles = (mixpanel, dispatcher, invalidDocs) => {

  invalidDocs.forEach(file => {
  
    mixpanel.track(FAILED_UPLOAD, {type: "File not supported"});

    dispatcher(errorNotification({
        message: intl.formatMessage({ id: "documents.upload.message.error.type"}),
        position: 'tc',
        autoDismiss: 0,
      }));

  })

}
