import React from "react"
import { BancontactIcon, MasterCardIcon, VisaBlueIcon } from "../Icons";

const DeliveryPaymentMethods = (props) => {

  return (
    <div className="d-flex">
      <BancontactIcon />
      <MasterCardIcon />
      <VisaBlueIcon />
    </div>
  );

}

export default DeliveryPaymentMethods;