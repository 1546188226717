import { put, select } from 'redux-saga/effects';
import { globalErrorHandling } from '../../shared/utils';
import { getToken } from './selectors';
import { LeaderboardService } from '../../Services';
import * as actions from '../actions';

export function* leaderboardSagas () {
  try {
    const token = yield select(getToken);
    const response = yield LeaderboardService.fetch(token);
    yield put(actions.leaderboardFetchSuccess(response.data));
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.leaderboardFetchError(errorObj));
  }
}