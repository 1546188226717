import * as actionTypes from './types';

export const consentToggle = () => {
  return {
    type: actionTypes.CONSENT_TOGGLE
  };
};

export const consentAsk = () => {
  return {
    type: actionTypes.CONSENT_ASK
  };
};

export const consentUpdate = (accept) => {
  return {
    type: actionTypes.CONSENT_UPDATE,
    accept: accept
  };
};

export const consentPartnerEmailsToggle = (intl) => {
  return {
    type: actionTypes.CONSENT_PARTNER_EMAILS_TOGGLE,
    intl: intl
  };
}

export const consentPartnerEmailsError = () => {
  return {
    type: actionTypes.CONSENT_PARTNER_EMAILS_ERROR
  };
}

export const consentLeaderboardToggle = (intl) => {
  return {
    type: actionTypes.CONSENT_LEADERBOARD_TOGGLE,
    intl: intl
  };
}

export const consentLeaderboardError = () => {
  return {
    type: actionTypes.CONSENT_LEADERBOARD_ERROR
  };
}
