import React from 'react';
import PropTypes from 'prop-types';

export default class LanguageLink extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();

        const { lang, onClick, active } = this.props;
        
        if (!active) {
            onClick(lang);
        }
        
    }

    render() {

        const { active, text, lang } = this.props;

        return (
            <a href={`#Language${lang}`} className={active?"zerocopy-link active":"zerocopy-link"} onClick={this.handleClick}>{text}</a>
        );
    }

}

LanguageLink.propTypes = {
    active: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}
  