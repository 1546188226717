import React from 'react';
import { FormattedMessage } from 'react-intl';

const input = ({
  input, id, label, placeholder, className, type, maxLength, autoComplete, inputRef, disabled,
  meta: { touched, error } }) => {

    if (touched && error && className !== "") {
        className += " is-invalid";
    }

  return (
    <div className="form-group">
      {label !== undefined?<label className="font-weight-bold" htmlFor={id}>{label}</label>:null}
      <input {...input}
        id={id}
        className={className}
        placeholder={placeholder}
        type={type}
        autoComplete={autoComplete}
        maxLength={maxLength}
        ref={inputRef}
        disabled={disabled}
      />
      {touched && (error && <div className="text-danger"><FormattedMessage id={error.id} values={error.values} /></div>)}
    </div>
  );
}

export default input;
