import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ConsentService from "../../Services/ConsentService";
import { CONSENT_TERMS_AND_CONDITIONS } from "../../shared/utils";

import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { useDispatch } from "react-redux";

import { globalErrorHandling } from "../../shared/utils";
import { fetchUser, logout } from "../../store/actions";

const TermsAndConditionsUpdate = () => {

  const [open, setOpen] = useState(true);
  const [submitting, setSubtmitting] = useState(false);

  const dispatcher = useDispatch();
  const intl = useIntl();

  const onClick = (e) => {
    e.preventDefault();

    setSubtmitting(true);

    ConsentService.grant(CONSENT_TERMS_AND_CONDITIONS)
    .then((response) => {

      dispatcher(successNotification({
        message: intl.formatMessage({ id: "termsAndConditions.msg.success"}),
        position: 'tc',
        autoDismiss: 5,
      }));

      setOpen(false);

      dispatcher(fetchUser());

    })
    .catch(error => {

      const errorObj = globalErrorHandling(error);

      let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
      if (msg === errorObj.errorKey) {
        msg = intl.formatMessage({ id: "termsAndConditions.msg.error"});
      }
      
      dispatcher(errorNotification({
        message: msg,
        position: 'tc',
        autoDismiss: 0,
      }));

    }).finally(() => {
      setSubtmitting(false);
    });

  }

  const onCancel = (e) => {
    e.preventDefault();
    dispatcher(logout());
  }

  return (
    <Modal isOpen={open} zIndex="9999">
      <ModalHeader>
        <FormattedMessage id="termsAndConditions.title" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="termsAndConditions.content" values={{
           breakLine: (<div className='mt-1' />),
           a: (text) => (<a href="https://zerocopy.be/terms-and-conditions" target="_blank" rel="noopener noreferrer">{text}</a>),
           note: (text) => (<div className="font-italic small">{text}</div>)
        }}/>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClick} disabled={submitting} color="primary">
          <FormattedMessage id="termsAndConditions.buttons.grant" />
        </Button>

        <Button onClick={onCancel} disabled={submitting } color="danger" outline>
          <FormattedMessage id="termsAndConditions.buttons.decline" />
        </Button>
      </ModalFooter>
    </Modal>
  );

}

export default TermsAndConditionsUpdate;