import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoIcon, ToggleIcon } from '../Icons';
import ConsentService from '../../Services/ConsentService';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { globalErrorHandling } from '../../shared/utils';
import { error as errorNotification } from 'react-notification-system-redux';

const consentType = "MAIL_REMINDERS";

const MailRemindersConsentButton = ({showInfo = true, textKey}) => {
    
  const user = useSelector(state => state.user.user);
  const enabledFeature = useSelector(state => state.system.system_enable_mail_reminders);

  const [enabled, setEnabled] = useState(false);
  
  const intl = useIntl();
  const dispatcher = useDispatch();

  useEffect(() => {

    if (user) {
      setEnabled(user.consents.includes(consentType));
    }

  }, [user]);

  const handleToggle = () => {
      
    const handleError = (error) => {
      const errorObj = globalErrorHandling(error);

      let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
      if (msg === errorObj.errorKey) {
        msg = intl.formatMessage({ id: "mailReminders.consent.message.error"});
      }
      
      dispatcher(errorNotification({
        message: msg,
        position: 'tc',
        autoDismiss: 0,
      }));
    }

    if (enabled !== true) {
      ConsentService.grant(consentType)
        .then(response => {
          setEnabled(true);
        })
        .catch(handleError);
    } else {
      ConsentService.remove(consentType)
        .then(response => {
          setEnabled(false);
        })
        .catch(handleError);
    }
  }

  if (enabledFeature !== true) {
      return null;
  }

  let titleKey = "mailReminders.consent.title";
  if (textKey) {
    titleKey = textKey;
  }

  return (
      <div className='d-flex align-items-center'>

        <ToggleIcon enabled={enabled} className="text-primary" style={{fontSize: "1.3rem"}}
              onClick={handleToggle}
              title={intl.formatMessage({id: titleKey})}
          />
              
          <span className="ml-2 mr-1">
              <FormattedMessage id={titleKey} />
          </span>

          { showInfo === true ? <MailRemindersInfo /> : null }
          
      </div>
  );
}
  
export default MailRemindersConsentButton;

const MailRemindersInfo = ({ className }) => {
  
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  return (
    <React.Fragment>
      <InfoIcon width="24px" height="24px" style={{cursor: "pointer"}}
          onClick={toggle} title={intl.formatMessage({id: "mailReminders.consent.buttons.moreInfo"})} className={className} />

      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle} tag="h4">
          <FormattedMessage id="mailReminders.consent.title" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id="mailReminders.consent.info" values={{breakLine: (<div className='mt-1' />)}} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}