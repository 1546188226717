import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card, Slider, Collapse, Grid, Typography, Tooltip} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ViewCarouselRoundedIcon from '@material-ui/icons/ViewCarouselRounded';
import IconButton from '@material-ui/core/IconButton';
import { AiButton } from './AiButton';
import './AiCard.css'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useMixpanel } from '../../contexts/Mixpanel';
import { AI_USER_CHANGE_NUMBER, AI_USER_CHANGE_PAGES } from '../../shared/EventsNames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { error as errorNotification } from 'react-notification-system-redux';
import { useIntl } from 'react-intl';
import { AI_FLASHCARDS, AI_QUIZ } from './Containers/AiTutorPage';
import { ClearOutlined, CreateRounded, QuestionAnswerRounded } from '@material-ui/icons';
import { QuizOptions } from './QuizOptions';
import { Input, FormFeedback, FormGroup, Label, InputGroup, Button } from 'reactstrap';
import { change } from 'redux-form';
import { EyeIcon } from '../../Components/Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0.9rem',
    boxShadow: "0 0 1em 0 var(--ai-secondary-color)",
    animation: "fadeIn 0.4s ease-in-out",
    minWidth: "100%"
  },
  title: {
    fontSize: '1.5rem',
  },
  titleDisabled: {
    fontSize: '1.5rem',
    color: 'grey',
  },
  settings: {
    borderRadius: '0.9rem',
    boxShadow: "0 0 1em 0 grey",
  },
  expand: {
    transform: 'rotate(0deg)',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(90deg)',
  },
}));

const getDefaultGenerationName = (type) => {
  return `${type.substring(0, 1).toUpperCase()}${type.substring(1)} ${new Date().toDateString()}`;
}


export const AiCard = ({doc, type, maxPages, changeQNumber, changePageRange, generate, showPreview, preview, changeQuestionTypes, changeName}) => {
  const mixpanel = useMixpanel();
  const classes = useStyles();
  const [qNumber, setQNumber] = useState(20);
  const [qNumInvalid, setQNumInvalid] = useState(false);
  const [qNumInvalidMsg, setQNumInvalidMsg] = useState("");
  const [maxQuestions, setMaxQuestions] = useState(50)
  const [pageRange, setPageRange] = useState([1, maxPages]);
  const [pageRangeInput, setPageRangeInput] = useState(`1-${maxPages}`);
  const [pageRangeInvalid, setPageRangeInvalid] = useState(false);
  const [pageRangeInvalidMsg, setPageRangeInvalidMsg] = useState("");
  const dispatcher = useDispatch();
  const intl = useIntl();
  const [generationName, setGenerationName] = useState("")
  const nameRef = useRef(null);

  useEffect(() => {
    setGenerationName(getDefaultGenerationName(type))
  }, [type])

  useEffect(() => {
    setPageRange([1, maxPages])
    setMaxQuestions(Math.min(50, (pageRange[1] - (pageRange[0] - 1) * 4)))
    setPageRangeInput(`1-${maxPages}`)
  }, [maxPages])

  useEffect(() => {
    if (doc) {
      setPageRange([1, doc.numberOfPages])
      setMaxQuestions(Math.min(50, doc.numberOfPages * 4))
      setPageRangeInput(`1-${doc.numberOfPages}`)
    }
  }, [doc])

  useEffect(() => {
    if (generationName != undefined && generationName != null && generationName != "") {
      changeName(generationName)
    }
  }, [generationName])

  const handleNoDocumentError = () => {
    dispatcher(errorNotification({
      message: intl.formatMessage({ id: "ai.document.select.missing"}),
      position: 'tc',
      autoDismiss: 0,
    }));
  }

  const handleButtonClick = () => {
    if (doc) {
      generate()
    }
    else {
      handleNoDocumentError()
    }
  }

  const handlePageRangeChange = (e) => {
    setPageRangeInput(e.target.value)
  }

  const handlePageRangeBlur = () => {
    const pattern = /^\s*\d+\s*-\s*\d+\s*$/;
    const input = pageRangeInput
    if (pattern.test(input)) {
      const newRange = input.split("-").map(number => number.trim())
      mixpanel.track(AI_USER_CHANGE_PAGES, {type: type, numPages: maxPages, newRange: newRange})
      validatePageRange(newRange)
    }
    else {
      setPageRangeInvalidMsg(<FormattedMessage id={"ai.option.error.pageRangeFormat"}/>)
      setPageRangeInvalid(true)
    }
  }

  const validatePageRange = (newRange) => {
    if (Number(newRange[0]) > Number(newRange[1])) {
      setPageRangeInvalidMsg(<FormattedMessage id={"ai.option.error.startPage"}/>)
      setPageRangeInvalid(true)
    }
    else if (newRange[1] - (newRange[0] - 1) < 5) {
      setPageRangeInvalidMsg(<FormattedMessage id={"ai.option.error.minPages"}/>)
      setPageRangeInvalid(true)
    } 
    else if (newRange[1] > maxPages) {
      setPageRangeInvalidMsg(<FormattedMessage id={"ai.option.error.maxPages"} values={{maxPages: maxPages}}/>)
      setPageRangeInvalid(true)
    }
    else {
      setPageRangeInvalid(false)
      setPageRange(newRange)
      changePageRange(newRange)
    }
  }

  const handleQNumberChange = (value) => {
    mixpanel.track(AI_USER_CHANGE_NUMBER, {type: type, numPages: maxPages, newNumber: value})
    if (value < 20) {
      setQNumInvalidMsg(<FormattedMessage id={`ai.option.error.minQuestion.${type}`}/>)
      setQNumInvalid(true)
    }
    else if (value > 50) {
      setQNumInvalidMsg(<FormattedMessage id={`ai.option.error.maxQuestions.${type}`}/>)
      setQNumInvalid(true)
    }
    else if (value > (pageRange[1] - pageRange[0]) * 4) {
      setQNumInvalidMsg(<FormattedMessage id={`ai.option.error.questionsPerPage.${type}`}/>)
      setQNumInvalid(true)
    }
    else {
      setQNumInvalid(false)
    }
    setQNumber(value)
    changeQNumber(value)
  }

  const handleQuestionTypeChange = (questions) => {
    changeQuestionTypes(questions)
  }

  useEffect(() => {
    setMaxQuestions(Math.min(50, (pageRange[1] - pageRange[0] !== 0 ? pageRange[1] - ( pageRange[0] - 1 ) : 1) * 4))
  }, [pageRange])

  useEffect(() => {
    if (qNumber > maxQuestions) {
      handleQNumberChange(maxQuestions)
    }
  }, [maxQuestions])

  const toggleShowPreview = (e) => {
    e.preventDefault()
    if (doc) {
      showPreview()
    }
    else {
      handleNoDocumentError()
    }
  }

  const handleNameChange = (value) => {
    if (value != null && value != undefined) {
      setGenerationName(value)
    }
  }

  const handleNameBlur = () => {
    if (generationName == "") {
      setGenerationName(getDefaultGenerationName(type))
    }
  }

  const handleNameClear = () => {
    setGenerationName("")
    nameRef.current.focus()
  }
  
  const content = 
        <CardContent className={"p-3"}>
          <Grid container className='mt-2 d-flex justify-content-between' spacing={0}>
            {type === AI_QUIZ && 
            <Grid container spacing={0} >
              <Grid item xs={12}>
                <QuizOptions onChange={handleQuestionTypeChange}/>
              </Grid>
            </Grid>
            }
            <Grid container spacing={3}>
              <Grid item xs={12} md={preview ? 12 : 6} lg={preview ? 12 : 6} >
                <Typography 
                  color='textPrimary'
                  id="pages-slider"
                  variant='h6'
                >
                  <FormattedMessage id={"ai.table.header.name"}/>
                </Typography>
                <InputGroup>
                  <Input 
                    innerRef={nameRef} 
                    disabled={!doc} 
                    value={generationName} 
                    onChange={e => handleNameChange(e.target.value)} 
                    onBlur={handleNameBlur}
                    />
                  <Button 
                    style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} 
                    onClick={() => handleNameClear()}
                    disabled={!doc} 
                  >
                    <ClearOutlined/>
                  </Button>
                </InputGroup>
              </Grid>
              <Grid item xs={12} md={preview ? 12 : 3} lg={preview ? 12 : 3} >
                <Typography 
                  color='textPrimary'
                  id="pages-slider"
                  variant='h6'
                >
                  <FormattedMessage id={`ai.${type}.option.number.label`}/>
                </Typography>
                <Input
                  required
                  onChange={(e) => handleQNumberChange(e.target.value)}
                  value={qNumber}
                  invalid={qNumInvalid}
                  type={"text"}
                  disabled={!doc}
                />
                <FormFeedback>
                  {qNumInvalidMsg}
                </FormFeedback>
              </Grid>
              <Grid item xs={12} md={preview ? 12 : 3} lg={preview ? 12 : 3} >
                <Typography 
                  color='textPrimary'
                  id="pages-slider"
                  variant='h6'
                >
                  <FormattedMessage id={`ai.${type}.option.pages.label`}/>
                </Typography>
                <InputGroup>
                  <Input
                    required
                    pattern="\s*\d+\s*-\s*\d+\s*"
                    onChange={handlePageRangeChange}
                    onBlur={handlePageRangeBlur}
                    value={pageRangeInput}
                    invalid={pageRangeInvalid}
                    type={"text"}
                    disabled={!doc}
                  />
                  <FormFeedback>
                    {pageRangeInvalidMsg}
                  </FormFeedback>
                  <Tooltip title={"See document"}>
                    <Button 
                      style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} 
                      disabled={!doc} 
                      onClick={toggleShowPreview}
                    >
                      <EyeIcon/>   
                    </Button>
                  </Tooltip>
                </InputGroup>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

  return (
    <Card variant='outlined' 
      elevation={3} 
      className={ classes.root } 
    >
      {content}
      <CardContent className='d-flex align-items-sm-strech align-items-md-center justify-content-center flex-column'>
        <AiButton disabled={!doc || (pageRangeInvalid || qNumInvalid)} onClick={handleButtonClick} secondary={false} text={'generate'} />
      </CardContent>
    </Card>
  );
}
