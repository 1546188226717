import R7Insight from 'r7insight_js';

let loggerInitialized = false;

export const logger = {

  init: (token) => {

    R7Insight.init({
      token: token,
      region: 'eu',
      catchall: true
    });

    loggerInitialized = true;
  },

  log: (text, ...params) => {
    if (loggerInitialized) {
      R7Insight.log(text, ...params);
    } else {
      console.log(text, ...params);
    }
  },
  info: (text, ...params) => {
    if (loggerInitialized) {
      R7Insight.info(text, ...params);
    } else {
      console.log(text, ...params);
    }
  },
  error: (text, ...params) => {
    if (loggerInitialized) {
      R7Insight.error(text, ...params);
    } else {
      console.log(text, ...params);
    }
  },
  warn: (text, ...params) => {
    if (loggerInitialized) {
      R7Insight.warn(text, ...params);
    } else {
      console.log(text, ...params);
    }
  },
}