import React, { useEffect, useState } from "react";
import { Question } from "./Question";
import { Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import './QuizSummary.css'
import { AI_QUIZ } from "../Ai/Containers/AiTutorPage";
import { AiNps } from "../Components/AiNps";
import { FormattedMessage } from "react-intl";
import { useHistory, useRouteMatch } from "react-router-dom";
import Slider from "./NetflixSlider";
import { moveToTop, scrollTo } from "../../shared/utils";
import { Button } from "reactstrap";

const scoreProgress = makeStyles({
  bottom: {
    color: "var(--tangerine-dark-color)",
  },
  top: {
    color: "var(--green-color)",
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
});

function CircularProgressWithLabel(props) {

  const classes = scoreProgress();

  return (
    <Box position="relative" display="inline-flex" className={props.className}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={"8rem"}
        thickness={6}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={"8rem"}
        thickness={6}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" component="div" style={{color: `${props.textColor} !important`}}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const QuizSummary = ({quiz}) => {
  const [score, setScore] = useState(0)
  const [correctCount, setCorrectCount] = useState(0)
  const [incorrectCount, setIncorrectCount] = useState(0)
  const [unansweredCount, setUnansweredCount] = useState(0)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  let { path, url } = useRouteMatch();
  const indxColor =
    score >= 80 ? "var(--green-color)" : score >= 60 ? "var(--green-dark-color)" : "var(--tangerine-dark-color)";
  const history = useHistory();

  useEffect(() => {
    window.onscroll = function() {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                setShowScrollToTop(true)
            } else {
                setShowScrollToTop(false)
            }
        };
  }, [])

  useEffect(() => {
    let tmpCorrectCount = 0;
    let tmpIncorrectCount = 0;
    let tmpUnansweredCount = 0;

    quiz.content.forEach(q => {
      if (q.answers.findIndex(ans => ans.selected) != -1) {
        if (q.answers.findIndex(ans => ans.selected && ans.valid ) != -1) {
          tmpCorrectCount++
        }
        else {
          tmpIncorrectCount++
        }
      }
      else {
        tmpUnansweredCount++
      }
    })
    setCorrectCount(tmpCorrectCount)
    setIncorrectCount(tmpIncorrectCount)
    setUnansweredCount(tmpUnansweredCount)
    setScore(tmpCorrectCount / quiz.content.length * 100)

    history.push(url + "/summary", {"replace": true});

  }, [quiz])

  const handleQuestionChange = (index) => {
    scrollTo(`question-${index}`) 
  } 

  return (
    <>
      <div className="d-flex justify-content-center flex-column mb-3">
        <div className="d-flex justify-content-between align-items-center quiz-container p-3 flex-column flex-sm-row">
          <div className="d-flex justify-content-center m-1">
            <CircularProgressWithLabel value={score} textColor={indxColor} />
          </div>

          <div className="font-weight-medium d-flex flex-column align-items-end m-1 p-1" >
            <p className="d-flex justify-content-between mb-2" style={{width: "12.5rem", fontSize: "2rem", color: "var(--green-color)"}}>
              <FormattedMessage id="ai.quiz.summary.correct" />: <span className="score correct">{correctCount}</span>
            </p>
            <p className="d-flex justify-content-between mb-0" style={{width: "12.5rem", fontSize: "2rem", color: "var(--tangerine-dark-color)"}}>
              <FormattedMessage id="ai.quiz.summary.incorrect" />: <span className="score incorrect">{incorrectCount + unansweredCount}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="summary-container">
        <Grid container spacing={2} className="d-flex justify-content-center"> 
          <Grid item xs={12}> 
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Slider onChange={handleQuestionChange} isSummary={true} data={quiz.content.map((q, index) => ( { num: index, correct: q.answers.findIndex(ans => ans.valid && ans.selected) != -1 }))}/>
              </Grid>
            {quiz.content.map((question, index) => (
              <Grid key={index} item xs={12} >
                <Question
                key={index}
                num={index + 1}
                handleAnswer={() => {}}
                options={question.answers}
                correctAnswer={question.answers.filter(ans => ans.valid === true)[0].text}
                questionText={question.question}
                summary={true}
                page={question.pageNumber}
                />
              </Grid>
            ))}
            </Grid>
            <Grid item xs={12}>
              { showScrollToTop && <Button id='scrollToTopBtn' onClick={() => moveToTop()}>
                <FormattedMessage id={'btn.moveToTop'}/> 
              </Button> }
            </Grid>
          </Grid>
        </Grid>
      </div>
    <AiNps type={AI_QUIZ}/>
    </>
  );
}

