import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DEFAULT_OPTIONS_MUTATION_OBSERVABLE = {
    attributes: true,
    childList: true,
    subtree: true
};

export function useMutationObservable(ref, callback, options = DEFAULT_OPTIONS_MUTATION_OBSERVABLE) {
  
  useEffect(() => {
   
    if (ref?.current) {

      const observer = new MutationObserver(callback)
      observer.observe(ref.current, options)

      return () => { observer.disconnect() }

    }

   
  }, [callback, options]);


}