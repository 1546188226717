import * as actionTypes from './types';

export const fetchAddresses = () => {
  return {
    type: actionTypes.ADDRESSES_FETCH
  }
};

export const fetchAddressesSuccess = (addresses) => {
  return {
    type: actionTypes.ADDRESSES_FETCH_SUCCESS,
    list: addresses
  }
};

export const fetchAddressesError = (error) => {
  return {
    type: actionTypes.ADDRESSES_FETCH_ERROR,
    error: error
  }
};

export const fetchAddress = (id) => {
  return {
    type: actionTypes.ADDRESS_FETCH,
    id: id
  }
};

export const fetchAddressSuccess = (address) => {
  return {
    type: actionTypes.ADDRESS_FETCH_SUCCESS,
    item: address
  }
};

export const fetchAddressError = (error) => {
  return {
    type: actionTypes.ADDRESS_FETCH_ERROR,
    error: error
  }
};

export const saveAddress = (item, isDeliveryRequest) => {
  return {
    type: actionTypes.ADDRESS_SAVE,
    item: item,
    isDeliveryRequest: isDeliveryRequest === true?true:false
  };
};

export const saveAddressSuccess = () => {
  return {
    type: actionTypes.ADDRESS_SAVE_SUCCESS
  };
};

export const saveAddressError = (error) => {
  return {
    type: actionTypes.ADDRESS_SAVE_ERROR,
    error: error
  };
};

export const addressDelete = (id) => {
  return {
    type: actionTypes.ADDRESS_DELETE,
    id: id
  };
};

export const addressDeleteSuccess = () => {
  return {
    type: actionTypes.ADDRESS_DELETE_SUCCESS
  };
};

export const addressDeleteError = (error) => {
  return {
    type: actionTypes.ADDRESS_DELETE_ERROR,
    error: error
  };
};

export const addressFormToggle = (isNew) => {
  return {
    type: actionTypes.ADDRESS_FORM_TOGGLE,
    isNew: isNew === true?true:false
  };
};
