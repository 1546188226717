import api from '../Api';
import { getToken } from '../shared/utils';

class AuthService {

  /**
   * Log in using email
   * @param {string} username Username or email
   * @param {string} password User password
   */
  static async auth(username, password) {
    return api.post("/v1/login", {"username": username, "password": password});
  }

  /**
   * Log in using Google
   * @param {object} data Access data information
   */
  static async authWithGoole(data) {
    return api.post("/v2/auth/google", data);
  }

  /**
   * Log in using Facebook
   * @param {object} data Access data information
   */
  static async authWithFacebook(data) {
    return api.post("/v2/auth/facebook", data);
  }

  /**
   * Logout
   */
  static async logout() {
    return api.post("/v1/logout", {}, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

}

export default AuthService;