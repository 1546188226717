import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from "react-helmet";

import './Account.css'
import AddressList from './AddressList';
import Stats from '../Components/Stats/Stats';
import { PrivateTemplate } from '../Templates';
import Settings from '../Components/Account/Settings';
import MyProfile from '../Components/Account/MyProfile';

const Account = (props) => {

  const intl = useIntl();

  return (
    <PrivateTemplate>
      <Helmet>
          <title>Zerocopy - {intl.formatMessage({id: "account.general.title"})}</title>
      </Helmet>
    
      <div className="container-fluid">

        <div className="page-in">
          
          <div className='row' >
            <div className='col-12 col-md-6'>
              <MyProfile />
            </div>

            <div className='col-12 col-md-6'>
                <AddressList className="mb-3" />
                <Stats className="mb-3" />
                <Settings />
            </div>
            
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );

}


export default Account;
