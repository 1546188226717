import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { defaultMaxLength } from '../../shared/validations';
import Checkbox from '../../hoc/Checkbox';
import TextArea from '../../hoc/TextArea';

const NpsForm = (props) => {

  const { handleSubmit, intl, low } = props;

  if (low === false) {
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Field id="chkAddToTestGroup" name="addToTestGroup" className="form-check-input"
            component={Checkbox}
            label={intl.formatMessage({id: "nps.fields.addToTestGroup"})}
          />
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">

    <div className="mb-2"><FormattedMessage id="nps.concerns" /></div>
    <Field id="chkNoPaperOrInk" name="noPaperOrInk" className="form-check-input"
        component={Checkbox}
        label={intl.formatMessage({id: "nps.fields.noPaperOrInk"})}
      />
    <Field id="chkLostCredits" name="lostCredits" className="form-check-input"
        component={Checkbox}
        label={intl.formatMessage({id: "nps.fields.lostCredits"})}
      />
    <Field id="chkNotCredits" name="notCredits" className="form-check-input"
        component={Checkbox}
        label={intl.formatMessage({id: "nps.fields.notCredits"})}
      />
    <Field id="chkTechnicalIssues" name="technicalIssues" className="form-check-input"
      component={Checkbox}
      label={intl.formatMessage({id: "nps.fields.technicalIssues"})}
      />
    <Field id="chkNotEditable" name="notEditable" className="form-check-input"
      component={Checkbox}
      label={intl.formatMessage({id: "nps.fields.notEditable"})}
      />
    <Field id="chkOthers" name="others" className="form-check-input"
      component={Checkbox}
      label={intl.formatMessage({id: "nps.fields.others"})}
      />
      <Field id="txtRemarks" name="remarks" className="form-control" containerClassName="mt-3"
        component={TextArea}
        label={intl.formatMessage({id: "nps.fields.remarks"})}
        validate={[ defaultMaxLength ]}
        />
    </form>
  );

}

NpsForm.propTypes = {
    intl: PropTypes.object.isRequired,
    low: PropTypes.bool.isRequired
};

NpsForm.defaultProps = {
  low: true
};

let InitializeFromStateForm = reduxForm({
  form: 'npsForm'
})(NpsForm);

export default InitializeFromStateForm;
