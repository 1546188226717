import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';
import { consentLeaderboardToggle } from '../../store/actions';
import { LeaderboardInfo } from '../others';
import { ToggleIcon } from '../Icons';

const LeaderboardConsentButton = ({showInfo, textKey}) => {
    
  const enabled = useSelector(state => state.consent.leaderboard);
  const enabledFeature = useSelector(state => state.system.system_enable_leaderboard);
  const dispatcher = useDispatch();
  const intl = useIntl();

  const handleToggle = () => {
      dispatcher(consentLeaderboardToggle(intl));
  }

  if (enabledFeature !== true) {
      return null;
  }

  let titleKey = "leaderboard.consent.title";
  if (textKey) {
    titleKey = textKey;
  }

  return (
      <div className='d-flex align-items-center'>

        <ToggleIcon enabled={enabled} className="text-primary" style={{fontSize: "1.3rem"}}
              onClick={handleToggle}
              title={intl.formatMessage({id: titleKey})}
          />
              
          <span className="ml-2 mr-1">
              <FormattedMessage id={titleKey} />
          </span>
          
          { showInfo === true ? <LeaderboardInfo />: null }
      </div>
  );
}

LeaderboardConsentButton.defaultProps = {
    enabled: true,
    enabledFeature: true,
    showInfo: true
}

  
export default LeaderboardConsentButton;