import React, { Fragment } from "react";
import CompetitionLeaderboard from "./CompetitionLeaderboard";
import { LeaderboardWrapper } from "../../Components/Gamification";
import { useCompetition } from "../CompetitionContext";
import { CompetitionNotification } from "./CompetitionNotification";

const CompetitionLeaderboardWrapper = (props) => {

  const { competition } = useCompetition();

  if (competition) {
    return (
      <Fragment>
        <CompetitionLeaderboard competitionKey={competition.key} competitionType={competition.type} />
        <CompetitionNotification />
      </Fragment>
    );
  } else {
    return (
      <LeaderboardWrapper className="mb-3" />
    );
  }
}

export default CompetitionLeaderboardWrapper;