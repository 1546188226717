import React, { createContext, useContext, useEffect, useState } from "react";
import { getCurrentCompetition } from "./competitions_config";

const context = createContext(null);

export const CompetitionProvider = ({children}) => {

  const [competition, setCompetition] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const current = getCurrentCompetition();
    setCompetition(current);
    setLoading(false);
  }, []);
  
  const { Provider } = context;

  return (
    <Provider value={{
      competition: competition, loading: loading
    }}>
      {children}
    </Provider>
  );
}

export const useCompetition = () => useContext(context);