import * as actionTypes from '../actions/types';
import {updateObject} from '../../shared/utils';

const initialLocationsState = {
  cities: [],
  locations: [],
  loading: false,
  error: false
};

const citiesFetchSuccess = (state, action) => {
  return updateObject( state, {
    cities: action.cities
  });
};

const citiesFetchError = (state, action) => {
  return updateObject(state, {
    cities: [],
    error: action.error
  })
};

const locationFetchSuccess = (state, action) => {
  return updateObject(state, {
    locations: action.locations
  });
};

const locationFetchError = (state, action) => {
  return updateObject(state, {
    locations: [],
    error: action.error
  });
};

/**
* Redux reducer for location actions.
*/
export default function (state = initialLocationsState, action) {
  switch (action.type) {
    case actionTypes.CITIES_FETCH_SUCCESS: return citiesFetchSuccess(state, action);
    case actionTypes.CITIES_FETCH_ERROR: return citiesFetchError(state, action);
    case actionTypes.LOCATIONS_FETCH_SUCCESS: return locationFetchSuccess(state, action);
    case actionTypes.LOCATIONS_FETCH_ERROR: return locationFetchError(state, action);
    default: return state;
  }
};
