import React from 'react';
import Notifications from 'react-notification-system-redux';
import ConsentModal from '../Components/Consent/ConsentModal';
import LanguageSelector from '../Components/Commons/LanguageSelector';
import { Row, Col, Container } from 'reactstrap';
import { LogoGreen} from '../Components/Icons';

import "./PublicTemplate.css";
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

export const PublicTemplate = (props) => {

  const notifications = useSelector(state => state.notifications);

  return (
    <React.Fragment>
      <Notifications
        notifications={notifications}
        style={false}
      />

      <div className="content app" style={{height: "100%"}}>
        <aside className="sidebar d-none d-md-flex align-items-center ">
          <div className="sidebar-text-container">
            <FormattedMessage id="login.info.title" tagName="h1"/>
            <FormattedMessage id="login.info.description" tagName="h2"/>
          </div>
        </aside>
        <main className="d-flex align-items-center">
            <Container fluid className="page-in">
              
              <Row>
                  <Col xs="12" md={{size: 7, offset: 3}} xl={{size: 6, offset: 3}}>
                      <div className="text-center"><LogoGreen width="300" /></div>
                  </Col>
              </Row>

              <Row className="mt-3">
                  <Col xs="12" md={{size: 7, offset: 3}} xl={{size: 6, offset: 3}}>
                    
                    {props.children}

                    <div className="text-center mt-5">
                      <LanguageSelector />
                    </div>
                  </Col>
              </Row>
            </Container>
        </main>
      </div>

      <ConsentModal />
    </React.Fragment>
  );

};
