import api from '../Api';

import { getToken } from '../shared/utils';

class ConsentService {

    /**
     * Grant consent
     * @param {string} consentType Type of consent
     */
    static async grant(consentType) {
        return api.patch(`/v1/user/consent/grant/${consentType}`, {}, {headers: {'X-AUTH-TOKEN': getToken()}});
    }

    /**
     * Remove consent
     * @param {string} consentType 
     */
    static async remove(consentType) {
      return api.delete(`/v1/user/consent/grant/${consentType}`, {headers: {'X-AUTH-TOKEN': getToken()}});
  }
}

export default ConsentService;