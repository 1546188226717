import React, { Fragment, useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, FormGroup, Input, InputGroup, Label, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { DEFAULT_MAX_LENGTH } from "../../shared/validations";

const ChangePasswordForm = ({intl, apply, validate = true, isSubmitting = false}) => {

    const { register, clearErrors, handleSubmit, setError, formState: { errors } } = useForm({
        mode: "onSubmit"
    });

    const { ref: oldPasswordRef, ...registerOldPassowrd } = register("oldPassword", { required: true, maxLength: DEFAULT_MAX_LENGTH });
    const { ref: newPasswordRef, ...registerNewPassword } = register("newPassword", { required: true });
    const { ref: newPasswordRepeatRef, ...registerNewPasswordRepeat } = register("newPasswordRepeat", { required: true, maxLength: DEFAULT_MAX_LENGTH });

    const handleOnBlur = (e) => {

      if (errors.oldPassword && errors.oldPassword.type === "required" && e.currentTarget.value === "") {
        clearErrors("oldPassword");
      }

      if (errors.newPassword && errors.newPassword.type === "required" && e.currentTarget.value === "") {
        clearErrors("newPassword");
      }

      if (errors.newPasswordRepeat && errors.newPasswordRepeat.type === "required" && e.currentTarget.value === "") {
        clearErrors("newPasswordRepeat");
      }
    }

    const handleForm = (data) => {

        let error = false;

        // Custom validations here
        if (!error && data.newPassword !== data.newPasswordRepeat) {
          error = true;
          setError(
            "newPassword",
            {
                "type": "manual",
                "message": intl.formatMessage({"id": "changePassword.msg.password_are_not_equal"})
            }
          );
          if (newPasswordRef.current) {
            newPasswordRef.current.focus();
          }
        }

        if (!error && data.oldPassword === data.newPassword) {
          error = true;
          setError(
            "newPassword",
            {
                "type": "manual",
                "message": intl.formatMessage({"id": "changePassword.msg.password_are_equals_to_old"})
            }
          );
          if (newPasswordRef.current) {
            newPasswordRef.current.focus();
          }
        }

        if (!error) {
          apply(data);
        }
        
    };

    return (
      <Form onSubmit={handleSubmit(handleForm)}>
        <button type="submit" style={{display: "none"}}></button>
          
          <FormGroup>
            <Label for="old-password">
              <FormattedMessage id="changePassword.field.oldPassword" />
            </Label>
            <PasswordInput id="old-password" data-test="field-old-password" name="oldPassword" placeholder={intl.formatMessage({id: "changePassword.field.oldPassword"})}
                className="form-control form-control-sm d-inline"
                invalid={errors.oldPassword ? true : false}
                onBlur={handleOnBlur}
                innerRef={oldPasswordRef}
                {...registerOldPassowrd} />
            <InputErrors fieldName="oldPassword" errors={errors} />
          </FormGroup>

          <FormGroup>
            <Label for="old-password">
              <FormattedMessage id="changePassword.field.password" />
            </Label>
            <PasswordInput id="new-password" data-test="field-new-password" name="newPassword" placeholder={intl.formatMessage({id: "changePassword.field.password"})}
                className="form-control form-control-sm d-inline"
                invalid={errors.newPassword ? true : false}
                onBlur={handleOnBlur}
                innerRef={newPasswordRef}
                {...registerNewPassword} />
            <InputErrors fieldName="newPassword" errors={errors} />

          </FormGroup>

          <FormGroup>
            <Label for="old-password">
              <FormattedMessage id="changePassword.field.repeatPassword" />
            </Label>
            <PasswordInput id="new-password-repeat" data-test="field-new-password-repeat" name="newPasswordRepeat" placeholder={intl.formatMessage({id: "changePassword.field.repeatPassword"})}
                className="form-control form-control-sm d-inline"
                invalid={errors.newPasswordRepeat ? true : false}
                onBlur={handleOnBlur}
                innerRef={newPasswordRepeatRef}
                {...registerNewPasswordRepeat} />
            <InputErrors fieldName="newPasswordRepeat" errors={errors} />
          </FormGroup>

          <div className="d-flex flex-row-reverse">
            <button
              className="btn btn-primary btn-sm ml-1"
              type="submit"
              disabled={isSubmitting}
              style={{marginTop: "0px"}}
              title={intl.formatMessage({id: "changePassword.title"})}
              data-test="submit-change-password">
              {isSubmitting?<Spinner size="sm" className="ml-2" />:null}<FormattedMessage id="changePassword.btn" />
            </button>
          </div>
          
      </Form>
    );

};

export default injectIntl(ChangePasswordForm);

const PasswordInput = (props) => {

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const icon = passwordShown ? "fa fa-eye": "fa fa-eye-slash";

  return (
    <InputGroup>
      <Input type={passwordShown ? "text" : "password"} {...props} />
      <div className="input-group-append">
        <span className="input-group-text"
            style={{"cursor": "pointer"}}
            onClick={togglePasswordVisiblity}>
            <i className={icon} aria-hidden="true"></i>
        </span>
      </div>
    </InputGroup>
  );
}

const InputErrors = ({fieldName, errors}) => {

  return (
    <Fragment>
      {errors[fieldName] && errors[fieldName].type === "required" && <p className="text-danger small mb-0"><FormattedMessage id="validations.field.required" /></p>}
      {errors[fieldName] && errors[fieldName].type === "maxLength" && <p className="text-danger small mb-0"><FormattedMessage id="validations.field.maxLength" values={{length: DEFAULT_MAX_LENGTH}} /></p>}
      {errors[fieldName] && errors[fieldName].type === "manual" && <p className="text-danger small mb-0">{errors[fieldName].message}</p>}
    </Fragment>
  );
}