import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';
import TextArea from '../../hoc/TextArea';
import { required, defaultMaxLength, isEmailList } from '../../shared/validations';
import { InviteFriendMailLinkTitle } from './';

const InviteFriendsByEmailForm = (props) => {

  const { handleSubmit, submitting, freeCapReached } = props;

  return (
    <form className="row no-gutters" onSubmit={handleSubmit} autoComplete="off" data-test="form-invite-friends-by-email">
      <div className="col-12">
        <Field id="txtFriends" name="friends" className={'form-control'}
            component={TextArea}
            label={<InviteFriendMailLinkTitle freeCapReached={freeCapReached} />}
            placeholder="example@example.com, example2@example.com"
            validate={[ required, defaultMaxLength, isEmailList ]}
            disabled={submitting}
          />
      </div>
      <div className="col-12 mt-1">
        <button className="btn btn-secondary" disabled={submitting} data-test="btn-invite-friends-by-email">
          <FormattedMessage id="inviteFriends.mailLink.button.send" />
          {submitting?<Spinner size="sm" className="ml-2"/>:null}
        </button>
      </div>
    </form>
  );

}

InviteFriendsByEmailForm.propTypes = {
    freeCapReached: PropTypes.bool.isRequired
};

let InitializeFromStateForm = reduxForm({
  form: 'inviteFriendsByEmailForm',
  touchOnBlur: false
})(InviteFriendsByEmailForm);

export default InitializeFromStateForm;
