import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InfoIcon } from '../Icons';

const PartnerEmailInfo = ({ className, intl, reward, showLink }) => {
  
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  return (
    <React.Fragment>
      <InfoIcon width="24px" height="24px" style={{cursor: "pointer"}}
          onClick={toggle} title={intl.formatMessage({id: "partnerEmails.moreInfo"})} className={className} />

      <Modal isOpen={isOpen} toggle={toggle} centered>
          <ModalHeader className="no-border p-2 pl-3" toggle={toggle} tag="h4">
              <FormattedMessage id="partnerEmails.title" />
          </ModalHeader>
          <ModalBody className="no-border p-2 pl-3">
              <FormattedMessage id="partnerEmails.description" values={{credits: reward, breakLine: (
                  <div className='mt-1' />
              )}} />
              {showLink === true ? 
                <Fragment>
                  <div className='mt-1'>
                    <FormattedMessage id="partnerEmails.title"/>
                    &nbsp;
                    <Link to="/user/account"><FormattedMessage id="global.words.here" /></Link>.
                  </div>
                </Fragment>: null }
          </ModalBody >
          <ModalFooter className="no-border p-2 pl-3"/>
      </Modal>
    </React.Fragment>
  );
}

export default injectIntl(PartnerEmailInfo);
