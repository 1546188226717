import React from 'react';
import { FormattedMessage } from "react-intl";
import { FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { InputErrors } from "../Commons/InputErrors";

export const Field = ({field, labelKey, labelClassName, labelHidden = false, register, errors, options, type = "text", children, ...props}) => {

  const { ref, ...registerField } = register(field, options);

  return (
    <FormGroup>
      <Label for={field} className={labelHidden === true ? "d-none": labelClassName}><FormattedMessage id={labelKey} /></Label>
      <Input id={field} name={field} type={type} innerRef={ref} invalid={errors[field] ? true : false} {...registerField} {...props}>{children}</Input>
      <InputErrors fieldName={field} errors={errors} />
    </FormGroup>
  );
  
};

export const InlineField = ({field, labelKey, labelClassName, labelHidden = false, register, errors, options, type = "text", children, ...props}) => {

  const { ref, ...registerField } = register(field, options);

  return (
    <div>
      <Label for={field} className={labelHidden === true ? "d-none": labelClassName}><FormattedMessage id={labelKey} /></Label>
      <Input id={field} name={field} type={type} innerRef={ref} invalid={errors[field]} {...registerField} {...props}>{children}</Input>
      <InputErrors fieldName={field} errors={errors} />
    </div>
  );
  
};

export const AddonField = ({field, labelKey, labelHidden = false, register, errors, options, iconClassName, onIconClick, type = "text", children, ...props}) => {

  const { ref: refField, ...registerField } = register(field, options);

  return (
    <FormGroup>
      <Label for={field} className={labelHidden === true ? "d-none": null}><FormattedMessage id={labelKey} /></Label>
      <InputGroup>
        <Input id={field} name={field} type={type} innerRef={refField} invalid={errors[field]} {...registerField} {...props}>{children}</Input>
        <InputGroupText role="button" onClick={onIconClick}><i className={iconClassName}></i></InputGroupText>
      </InputGroup>
      <InputErrors fieldName={field} errors={errors} />
    </FormGroup>
  );
}