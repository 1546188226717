import { emitter } from "@marvelapp/react-ab-test";

export const configureExperiments = (mixpanel) => {

  emitter.defineVariants('AI Call to Action', ['toolbar', 'document-list', 'document-list-item']);

  // Called when the experiment is displayed to the user.
  emitter.addPlayListener(function(experimentName, variantName) {
      mixpanel.track('$experiment_started', {
          "Experiment name": experimentName,
          "Variant name": variantName,
        });
  });

  // Called when a 'win' is emitted, in this case by emitter.emitWin('My Example')
  emitter.addWinListener(function(experimentName, variantName) {
    mixpanel.track('$experiment_win', {
        "Experiment name": experimentName,
        "Variant name": variantName,
    });
  });

};
