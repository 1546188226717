import { put } from 'redux-saga/effects';

import * as actions from '../actions';

export function* onWebSocketMessageSaga(action) {

  const { channel, message } = action.payload;

  switch (channel) {
    case "/user/documents/update":
      yield put(actions.reloadDocument(message.documentId, message.newStatus, message.extraData));
      break;
    case "/user/updatesWithOperations":
      switch (message.operation) {
        case "adClick":
            if (message.reward === true) {
              yield put(actions.fetchUser());
              yield put(actions.userGetAdClickCounterFetch());
            } else if (message.counter === true) {
              yield put(actions.userGetAdClickCounterFetch());
            }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
}
