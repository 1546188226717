import { updateObject } from '../../shared/utils';
import * as actionTypes from '../actions/types';

const emptyAddress = {
  name: "",
  surname: "",
  street: "",
  streetNumber: "",
  boxNumber: "",
  postalCode: "",
  city: ""
};

const initialAddressState = {
  list: [],
  loading: false,
  error: undefined,
  errorModal: undefined,
  fieldsWithProblems: [],
  addressSaved: false,
  item: emptyAddress,
  openAddressForm: false,
  isNew: false,
  submitting: false,
};

const addressesFetch = (state, action) => {
  return updateObject(state, {
    loading: true
  });
}

const addressFetchSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    item: action.item
  });
}

const addressDelete = (state, action) => {
  return updateObject(state, {
    isDeleteSubmitting: true
  });
};

const addressDeleteSuccess = (state, action) => {
  return updateObject(state, {

  });
};

const addressDeleteError = (state, action) => {
  return updateObject(state, {
    error: action.error
  });
};

const addressFormToggle = (state, action) => {

  if (action.isNew === true) {
    return updateObject(state, {
      openAddressForm: !state.openAddressForm,
      item: emptyAddress,
      isNew: true,
      errorModal: undefined,
      fieldsWithProblems: []
    });
  }

  return updateObject(state, {
    openAddressForm: !state.openAddressForm,
    isNew: false,
    errorModal: undefined,
    fieldsWithProblems: []
  });
};

const addressSaveError = (state, action) => {
  return updateObject(state, {
    submitting: false,
    errorModal: action.error,
    fieldsWithProblems: action.error.fields ? action.error.fields : []
  });
};

const clearData = (state, action) => {
  return updateObject(state, {
    list: [],
    item: emptyAddress,
  });
}

/**
* Redux reducer for addresses actions.
*/
export default function (state = initialAddressState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS: return clearData(state, action);

    case actionTypes.ADDRESSES_FETCH: return addressesFetch(state, action);
    case actionTypes.ADDRESSES_FETCH_SUCCESS: return {...state, loading: false, list: action.list};
    case actionTypes.ADDRESSES_FETCH_ERROR:
    case actionTypes.ADDRESS_FETCH_ERROR: return {...state, loading: false, error: action.error};
    case actionTypes.ADDRESS_FETCH_SUCCESS: return addressFetchSuccess(state, action);
    case actionTypes.ADDRESS_SAVE: return {...state, submitting: true};
    case actionTypes.ADDRESS_SAVE_ERROR: return addressSaveError(state, action);
    case actionTypes.ADDRESS_SAVE_SUCCESS: return {...state, submitting: false, isNew: false, addressSaved: true};
    case actionTypes.ADDRESS_DELETE: return addressDelete(state, action);
    case actionTypes.ADDRESS_DELETE_SUCCESS: return addressDeleteSuccess(state, action);
    case actionTypes.ADDRESS_DELETE_ERROR: return addressDeleteError(state, action);
    case actionTypes.ADDRESS_FORM_TOGGLE: return addressFormToggle(state, action);
    default: return state;
  }
}
