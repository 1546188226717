import React from 'react';
import PropTypes from 'prop-types';

import ConfirmationSvg from '../../images/confirmation.svg';
import ConfirmationSvgSoMe from '../../images/confirmation_soMe.svg';

export const ConfirmationIcon = (props) => {
  return (
    <img src={props.soMe ? ConfirmationSvgSoMe : ConfirmationSvg}
      alt={props.alt}
      title={props.title}
      style={{"width": props.width, "height": props.height}}
      className={props.className}/>
  );
};

ConfirmationIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

ConfirmationIcon.defaultProps = {
  width: "64px",
  height: "64px",
  alt: "Confirmation icon"
};
