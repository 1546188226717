import React from 'react';
import GlobalMessage from './GlobalMessage';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

class FreeOfflineMessage extends React.PureComponent {

  render() {

    if (this.props.freeOffline !== true || this.props.backOnline === true) {
      return null;
    }

    return (
      <GlobalMessage type="accent">
        <FormattedMessage id="message.global.freeOffline" />
      </GlobalMessage>
    );
  }
}

const mapStateToProps = state => {
    return {
        lang: state.locale.lang,
        freeOffline: state.system.system_free_offline,
        backOnline: state.system.system_back_online
    };
};

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeOfflineMessage);
