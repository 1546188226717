import { put, select, getContext, delay, call } from 'redux-saga/effects';
import api from '../../Api';
import copy from 'copy-to-clipboard';
import { globalErrorHandling, getRefIdentifier, clearRefIdentifier, API_SOURCE } from '../../shared/utils';
import { getToken } from './selectors';
import { INVITE_FRIENDS } from '../../shared/EventsNames';
import { reset, startSubmit, stopSubmit } from 'redux-form';
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';

import * as actions from '../actions';
import { logoutSaga } from './security';
import { logger } from '../../shared/Logger';

export function* userFetchSaga() {
  try {
    const token = yield select(getToken);
    const response = yield api.get("/v1/user", {headers: {'X-AUTH-TOKEN': token}});
    yield put(actions.fetchUserSuccess(response.data));

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.fetchUserError(errorObj));

    if (errorObj.errorCode === -2) {
        yield put(actions.logoutSuccess());
    }
  }
}

export function* userUpdateSaga(action) {

  const request = {
  	"username": action.username,
  	"email": action.email,
  	"fullname": action.fullname
  };

  try {
    yield put(startSubmit("accountForm"));
    const token = yield select(getToken);
    yield api.post("/v1/user", request, {headers: {'X-AUTH-TOKEN': token}});
    yield put(actions.userUpdateSuccess());
    yield put(actions.fetchUser());

    yield put(successNotification({
      message: action.intl.formatMessage({ id: "account.general.message.success"}),
      position: 'tc',
      autoDismiss: 0,
    }));

  } catch(error) {
    const errorObj = globalErrorHandling(error, "account.general.message.error");
    yield put(actions.userUpdateError(errorObj));

    yield put(errorNotification({
      message: action.intl.formatMessage({ id: errorObj.errorKey}),
      position: 'tc',
      autoDismiss: 0,
    }));
  } finally {
    yield put(stopSubmit("accountForm"));
  }
}

export function* userForgotPasswordSaga(action) {

  try {
    yield api.post("/v1/forgot-password", {email: action.email});
    yield put(actions.userForgotPasswordSuccess());
    yield put(successNotification({
      message: action.intl.formatMessage({ id: "forgotPassword.message.success"}),
      position: 'tc',
      autoDismiss: 0,
    }));
  } catch(error) {

    const errorObj = globalErrorHandling(error, "forgotPassword.message.error");
    yield put(actions.userForgotPasswordError(errorObj));
  }
}

export function* userCreateAccountSaga(action) {

  const language = yield select(state => {
    return state.locale.lang;
  });

  const mixpanel = yield getContext("mixpanel");

  let request = {
    source: API_SOURCE,
    agreePrivacyPolicy: true,
    email: action.email,
    password: action.password,
    passwordRepeat: action.passwordRepeat,
    language: language,
    analyticsId: mixpanel.get_distinct_id()
  };

  const ref_id = getRefIdentifier();
  if (ref_id !== undefined) {
    request.refId = ref_id;
  }

  try {
    yield api.post("/v1/registration", request);
    yield put(actions.userCreateAccountSuccess());
    if (action.notify) {
      yield put(successNotification({
        message: action.intl.formatMessage({ id: "createAccount.message.success"}),
        position: 'tc',
        autoDismiss: 0,
      }));
    }
    clearRefIdentifier();
  } catch(error) {

    const errorObj = globalErrorHandling(error, "createAccount.message.error");
    yield put(actions.userCreateAccountError(errorObj));
  }
}

export function* userInviteFriendsSaga(action) {

  try {
    yield put(startSubmit("inviteFriendsByEmailForm"));
    const token = yield select(getToken);
    yield api.post("/v1/user/invite", {friends: action.friends}, {headers: {'X-AUTH-TOKEN': token}});

    const mixpanel = yield getContext("mixpanel");
    mixpanel.track(INVITE_FRIENDS, {"Type": "Email"});

    yield put(actions.userInviteFriendsSuccess());
    yield put(reset("inviteFriendsByEmailForm"));
    yield put(successNotification({
      message: action.intl.formatMessage({ id: "inviteFriends.mailLink.message.success"}),
      position: 'tc',
      autoDismiss: 10,
    }));
  } catch(error) {

    const errorObj = globalErrorHandling(error, "inviteFriends.mailLink.message.error");
    yield put(actions.userInviteFriendsError(errorObj));
  } finally {
    yield put(stopSubmit("inviteFriendsByEmailForm"));
  }

}

export function* userCopyLinkSaga(action) {

  copy(action.link);
  const mixpanel = yield getContext("mixpanel");
  mixpanel.track(INVITE_FRIENDS, {"Type": "CopyLink"});
  yield put(successNotification({
    message: action.intl.formatMessage({ id: "inviteFriends.shareLink.message.success"}),
    position: 'tc',
    autoDismiss: 0,
  }));
}

export function* userActivateSaga(action) {
  try {
    const response = yield api.get("/v1/user/activate/" + action.activationKey);

    if (response.data) {

      if (response.data.email) {
        yield put(successNotification({
          message: action.intl.formatMessage({ id: "message.global.accountActivated"}, {email: response.data.email}),
          position: 'tc',
          autoDismiss: 10,
        }));
      }

      if (response.data.token) {

        let token = yield select(getToken);

        if (token) {
          yield call(logoutSaga);
          yield delay(1000);
        }
        
        yield put(actions.directLogIn(response.data.token));
      }
      
    }
    

  } catch(error) {
    // FIXME Ignore error for now
    logger.error(error);
  }
}

export function* userResetPasswordSaga(action) {

  try {
    const response = yield api.get("/v1/user/reset-password/" + action.resetPasswordKey);

    if (response.data && response.data.email) {
      yield put(successNotification({
        message: action.intl.formatMessage({ id: "message.global.newPasswordActivated"}, {email: response.data.email}),
        position: 'tc',
        autoDismiss: 0,
      }));
    }
  } catch(error) {
    // FIXME Ignore error for now
    logger.error(error);
  }

}

export function* npsSubmitSaga(action) {

  try {

    const request = {
      rating: action.rating,
      ...action.model
    };

    const token = yield select(getToken);
    yield api.post("/v1/user/nps", request, {headers: {'X-AUTH-TOKEN': token}});
    yield put(actions.npsToggle());

    yield put(successNotification({
      message: action.intl.formatMessage({ id: "nps.message.success"}),
      position: 'tc',
      autoDismiss: 0,
    }));

  } catch(error) {

    const errorObj = globalErrorHandling(error, "nps.message.error");

    yield put(errorNotification({
      message: action.intl.formatMessage({ id: errorObj.errorKey}),
      position: 'tc',
      autoDismiss: 0,
    }));
  } finally {
    yield put(actions.npsSubmitted());
  }
}

export function* npsAskSaga() {

  try {
    const token = yield select(getToken);
    const response = yield api.get("/v1/user/nps/ask", {headers: {'X-AUTH-TOKEN': token}});

    if (response.data.ask === true) {
      yield put(actions.npsToggle());
    }
  } catch(error) {
    logger.error(error);
  }
}

export function* changeLanguageSaga(action) {

  try {

    const token = yield select(getToken);
    const currentUser = yield select(state => {
      return state.user.user;
    });

    if (currentUser === undefined) {
      return;
    }

    yield api.patch("/v1/user/language", {"language": action.lang}, {headers: {'X-AUTH-TOKEN': token}})
  } catch(error) {
    logger.error(error);
  }
}

export function* userStatsSaga() {

  try {
    const token = yield select(getToken);
    const response = yield api.get("/v1/user/stats", {headers: {'X-AUTH-TOKEN': token}});

    yield put(actions.userStatsSuccess(response.data));

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.userStatsError(errorObj));
  }
}

export function* userGetAdClickCounterSaga(action) {

  try {
    const token = yield select(getToken);
    const response = yield api.get("/v1/counter/ad-clicks", {headers: {'X-AUTH-TOKEN': token}});

    yield put(actions.userGetAdClickCounterSuccess(response.data));

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.userGetAdClickCounterError(errorObj));

    if (action.intl !== undefined) {
      yield put(errorNotification({
        message: action.intl.formatMessage({ id: "adClickCounter.error"}),
        position: 'tc',
        autoDismiss: 0,
      }));
    }
  }
}
