import { put, select } from 'redux-saga/effects';
import { globalErrorHandling } from '../../shared/utils';
import { getToken } from './selectors';
import { AdsService } from '../../Services';
import * as actions from '../actions';

export function* adsFetchSaga () {

  try {
    const token = yield select(getToken);
    const response = yield AdsService.fetch(token);
    yield put(actions.adsFetchSuccess(response.data));

    // Increase number of view for first ad
    if (response.data.length !== 0) {
      yield put(actions.adsIncreaseView(response.data[0].id));
    }

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.adsFetchError(errorObj));
  }
}

export function* adsIncreaseViewSaga(action) {

  if (action.id) {
    try {
      const token = yield select(getToken);
      yield AdsService.addView(token, action.id);
    } catch(error) {
      // Just ignore
    }
  }
}

export function* adsClickedSaga(action) {

  if (action.id) {
    try {
      const token = yield select(getToken);
      yield AdsService.addClick(token, action.id);
    } catch(error) {
      // Just ignore
    }
  }
}
