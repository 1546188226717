import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { sharedDocumentsFetch, sharedDocumentsCopy, sharedDocumentsTogglePreview, fetchUniversities, fetchUniversity } from '../store/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SharedDocumentList, SharedDocumentFilter } from '../Components/SharedDocuments';
import { Spinner, Modal, ModalBody, Alert } from 'reactstrap';
import { isOutOfViewport } from '../shared/utils';
import { DocumentPreview } from '../Components/Documents/DocumentPreview';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { PrivateTemplate } from '../Templates';

class SharedDocumentsPage extends React.PureComponent {

    constructor(props) {
        super(props);

        this.handleOnCopyClick = this.handleOnCopyClick.bind(this);
        this.handleOnFilterSubmit = this.handleOnFilterSubmit.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
        this.handleOnPreviewClick = this.handleOnPreviewClick.bind(this);
    }

    componentDidMount() {
        this.props.fetch();
        this.props.fetchUniversity();
        this.props.fetchUniversities();
        
    }

    handleOnCopyClick(item) {
        this.props.copy(item.id, this.props.intl);
    }

    handleOnPreviewClick(item) {
        this.props.previewToggle(item.previewUrl)
    }

    handleOnFilterSubmit(model) {
        this.props.fetch();
    }

    handleOnPageChange(e, pageToLoad) {
        this.props.fetch(pageToLoad);
        const comp = ReactDOM.findDOMNode(this);
        if (isOutOfViewport(comp).top === true) {
            window.scrollTo(0, comp.getBoundingClientRect().offsetTop)
        }
    }

    render() {
        
        const { total, list, currentPage, pageSize, loading, sharing, copying, preview, sharingEnabled } = this.props;

        if (sharingEnabled !== true) {
            return (
                <Redirect to="/" />
            );
        }

        let content = null;

        if (loading === true) {
            content = (
                <div className="text-center">
                    <div>
                        <Spinner color="primary" style={{width: "4rem", height: "4rem"}}/>
                    </div>
                    <div>
                        <FormattedMessage id="sharedDocuments.loading" />
                    </div>
                </div>
            );
        } else if (total === 0) {
            content = (
                <Alert color="info" className="text-center">
                    <div className="mb-3">
                        <i className="far fa-frown" style={{fontSize: "3rem"}}></i>
                    </div>
                    <FormattedMessage id="sharedDocuments.empty" />
                </Alert>
            );
        } else {
            content = (
                <React.Fragment>
                    <SharedDocumentList total={total} items={list} page={currentPage} pageSize={pageSize}
                        lang={this.props.lang} intl={this.props.intl}
                        onCopyClick={this.handleOnCopyClick}
                        onPageChange={this.handleOnPageChange}
                        disableSharing={sharing}
                        onPreviewClick={this.handleOnPreviewClick}/>
                    { preview.url ? <DocumentPreview url={preview.url} isOpen={preview.isOpen} toggle={this.props.previewToggle} /> : null }
                    <Modal isOpen={copying} centered={true}>
                        <ModalBody className="text-center">
                            <Spinner size="md" color="primary" />
                            <div>
                                <FormattedMessage id="sharedDocuments.item.message.copying" />
                            </div>
                        </ModalBody>
                    </Modal>
                </React.Fragment>
            );
        }

        return (
            <PrivateTemplate>
                <Helmet>
                    <title>Zerocopy - {this.props.intl.formatMessage({id: "sharedDocuments.toolbar.title"})}</title>
                </Helmet>
                <div className="container-fluid page-in">
                    <div className='row'>
                      <div className='col'>
                        <h4><FormattedMessage id="sharedDocuments.toolbar.title" /></h4>
                      </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <SharedDocumentFilter
                                onSubmit={this.handleOnFilterSubmit}
                                intl={this.props.intl}
                                universities={this.props.universities} 
                                isSubmitting={loading}
                                />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <h6><FormattedMessage id="sharedDocuments.title" /></h6>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <div className="">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </PrivateTemplate>
        );
    }
}

function mapStateToProps(state) {
  return {
    lang: state.locale.lang,
    list: state.sharedDocuments.list,
    total: state.sharedDocuments.total,
    currentPage: state.sharedDocuments.currentPage,
    pageSize: state.sharedDocuments.pageSize,
    loading: state.sharedDocuments.loading,
    sharing: state.sharedDocuments.sharing,
    copying: state.sharedDocuments.copying,
    preview: state.sharedDocuments.preview,
    universities: state.profile.universities,
    sharingEnabled: state.system.system_enable_shared_documents
  };
}

const mapDispatchToProps = { 
    fetch: sharedDocumentsFetch,
    copy: sharedDocumentsCopy,
    previewToggle: sharedDocumentsTogglePreview,
    fetchUniversities,
    fetchUniversity
 }

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SharedDocumentsPage));
