import React from "react";
import { CheckCircle, ErrorRounded, PlayCircleFilled } from "@material-ui/icons";
import Moment from "react-moment";
import { TrashIcon } from "../../Components/Icons/TrashIcon";
import { IconButton } from "@material-ui/core";
import { AI_FLASHCARDS, AI_QUIZ } from "../Ai/Containers/AiTutorPage";



export function getColumns(type, lang) {
  switch (type) {
    case AI_FLASHCARDS:
      return FlashcardColumns(lang)
    case AI_QUIZ:
      return QuizColumns(lang)
  }
}

function generatePageString(aiContent) {
   return aiContent.startPage && aiContent.endPage ? `${aiContent.startPage}-${aiContent.endPage}` : "-"
}

function generateStatusIcon(status) {
  if (status === "GENERATED") {
   return <CheckCircle style={{color: "var(--green-dark-color)"}}/>  
  }
  else if (status === "ERROR") {
    return <ErrorRounded style={{color: "var(--tangerine-dark-color)"}}/>
  }
  else if (status === "GENERATING") {
    return <i className={`fas fa-circle-notch fa-spin circle-in-progress mx-2`} style={{color: "var(--darkblue-color)"}}></i>
  }
}

const calendarStrings = {
    lastDay : '[Yesterday at] HH:mm',
    sameDay : '[Today at] HH:mm',
    nextDay : 'DD/MM/YYYY HH:mm',
    lastWeek : 'DD/MM/YYYY HH:mm',
    sameElse : 'DD/MM/YYYY HH:mm'
};

const FlashcardColumns = (lang) => {
  return [
    {
      headerKey: "ai.table.header.name",
      align: 'left',
      className: "name-cell",
      rowClassName: "name-cell",
      selector: aiContent => aiContent.name ? aiContent.name : "Flashcards" 
    },
    {
      headerKey: "flashcards.table.header.number",
      align: 'center',
      rowAlign: 'center',
      className: "number small-cell",
      rowClassName: "small-cell",
      selector: aiContent => aiContent.number
    },
    {
      headerKey: "ai.table.header.pages",
      align: 'center',
      rowAlign: "center",
      className: "small-cell",
      rowClassName: "small-cell",
      selector: aiContent => generatePageString(aiContent) 
    },
    {
      headerKey: "ai.table.header.status",
      align: 'center',
      className: "small-cell",
      rowClassName: "small-cell",
      rowAlign: 'center',
      selector: aiContent => generateStatusIcon(aiContent.status)
    },
    {
      headerKey: "ai.table.header.created",
      align: 'left',
      className: "created date-cell",
      rowClassName: "date-cell",
      selector: aiContent =>  <Moment utc local format="HH:mm LL" calendar={calendarStrings} locale={lang}>{aiContent.created}</Moment> 
    },
    {
      headerKey: null,
      align: 'left',
      className: "action-header",
      style: {
        width: "20px", 
        padding: "0px"
      },
      selector: (aiContent, onSelect, onDelete) => 
            <IconButton style={{padding: "5px"}}  onClick={(e) => {
              onDelete(aiContent);
              e?.stopPropagation();
              }}>
                <TrashIcon/>
            </IconButton>
    },
  ];
}


const QuizColumns = (lang) => {  
 return [
    {
      headerKey: "ai.table.header.name",
      align: 'left',
      className: "name-cell",
      rowClassName: "name-cell",
      selector: aiContent => aiContent.name ? aiContent.name : "Flashcards" 
    },
    {
      headerKey: "quiz.table.header.number",
      align: 'left',
      className: "number small-cell",
      rowClassName: "small-cell",
      selector: aiContent => aiContent.number
    },
    {
      headerKey: "ai.table.header.pages",
      align: 'left',
      className: "small-cell",
      rowClassName: "small-cell",
      selector: aiContent => aiContent.startPage && aiContent.endPage ? `${aiContent.startPage}-${aiContent.endPage}` : "-" 
    },
    {
      headerKey: "ai.table.header.status",
      align: 'center',
      className: "small-cell",
      rowClassName: "small-cell",
      rowAlign: 'center',
      selector: aiContent => generateStatusIcon(aiContent.status)
    },
    {
      headerKey: "ai.table.header.created",
      align: 'left',
      className: "created date-cell",
      rowClassName: "date-cell",
      selector: aiContent =>  <Moment utc local format="HH:mm LL" calendar={calendarStrings} locale={lang}>{aiContent.created}</Moment> 
    },
    {
      headerKey: null,
      align: 'left',
      className: "action-header",
      style: {
        width: "20px", 
        padding: "0px"
      },
      selector: (aiContent, onDelete) => 
            <IconButton style={{padding: "5px"}}  onClick={(e) => {
              onDelete(aiContent);
              e?.stopPropagation();
              }}>
                <TrashIcon/>
            </IconButton>
    },
  ];
}
