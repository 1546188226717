import React from 'react';
import PropTypes from 'prop-types';


export const BulletIcon = (props) => {
  return (
    <div className={props.className} style={{ "width": props.width, "height": props.height, color: `var(${props.backgroundColor})`, backgroundColor: `var(${props.color})`, 
      borderRadius: "50%", display: "flex", alignContent: "center", justifyContent: "center", fontSize: "1rem", fontFamily: "sans-serif"}}>
      {props.text}
    </div>
  );
};

BulletIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string
};

BulletIcon.defaultProps = {
  width: "25px",
  height: "25px",
  alt: "First Bullet Point"
};
