import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useIntl } from 'react-intl';
import './LocationFilter.css';
import { LocationService } from '../../Services';
import { logger } from '../../shared/Logger';

const LocationFilter = ({onFilter}) => {

  const [selected, setSelected] = useState(-1);
  const [cities, setCities] = useState([]);

  const intl = useIntl();

  useEffect(() => {

    if (selected === -1) {
      LocationService.fetchCities().then(response => {
        setCities(response.data);
      }).catch(err => {
        logger.error("Error loading cities", err);
        setCities();
      });
    }

  }, [selected]);

  const loadOptions = (inputValue, callback) => {

    LocationService.fetchCities(inputValue).then(response => {
      setCities(response.data);
      callback(response.data);
    }).catch(err => {
      logger.error("Error loading cities", err);
      callback([]);
    });
  }

  const handleOnSelect = (selected) => {

    let newOption = selected;

    if (selected === undefined || selected === null) {
      newOption = {id: -1};
    }

    if (selected !== newOption.id) {
        setSelected(newOption.id);
    }

    const city = cities.find(c => c.id === newOption.id);

    onFilter(city);
  }

  return (
      <div className="LocationFilter">
          <AsyncSelect
            blurInputOnSelect={true}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions={cities}
            closeMenuOnSelect={true}
            openMenuOnFocus={true}
            openMenuOnClick={false}
            isClearable
            getOptionValue={(option) => (`${option.id}`)}
            getOptionLabel={(option) => (`${option.name}`)}
            placeholder={intl.formatMessage({id: 'locations.filter.field'})}
            noOptionsMessage={() => (intl.formatMessage({id: 'locations.filter.empty'}))}
            onChange={handleOnSelect}
            styles={{
            menu: styles => ({ ...styles, zIndex: '99999' }),
            valueContainer: styles => ({ ...styles, cursor: 'text'})
            }}
        />
      </div>
  );
}

export default LocationFilter;
