import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from '../Icons';

import './GlobaMessage.css';

class GlobalMessage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      show: true
    };

    this.handleClose = this.handleClose.bind(this);

  }

  handleClose(e) {
    e.preventDefault();

    this.setState({show: false});

    const { onClose } = this.props

    if (onClose) {
      onClose()
    }
  }

  render() {

    if (this.state.show === false) {
      return null;
    }

    const { type } = this.props;

    return (
      <div className={"globalMessage p-2 d-flex align-items-center justify-content-between " + type}>
        {this.props.children}
        <div className="float-right">
          <a href="#close" onClick={this.handleClose} className="globalMessageClose">
            <CloseIcon width="20px" heigth="20px" />
          </a>
        </div>
      </div>
    );
  }
}

GlobalMessage.propTypes = {
  onClose: PropTypes.func,
  closeText: PropTypes.string,
  type: PropTypes.oneOf(['default', 'primary', 'secondary', 'accent']),
}

GlobalMessage.defaultProps = {
  type: "default"
}

export default GlobalMessage;
