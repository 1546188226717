import React, { useEffect } from 'react';
import { Field } from './CustomFields';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import { DEFAULT_MAX_LENGTH } from '../../shared/validations';
import { EMAIL_PATTERN } from '../../shared/utils';

const ForgotPasswordForm = ({id, disabled = false, onSubmit}) => {

  const intl = useIntl();

  const { register, handleSubmit, setFocus, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)} data-test="form-forgot-password">
      <button type="submit" style={{display: "none"}} disabled={submitting}></button>

      <Field field="email" labelHidden={true} labelKey="forgotPassword.field.email" disabled={disabled} placeholder={intl.formatMessage({id: "forgotPassword.field.email.placeholder"})}
        options={{ required: true, maxLength: DEFAULT_MAX_LENGTH, pattern: {value: EMAIL_PATTERN, message: "email"} }}
        errors={errors} register={register} />

    </Form>
  );

 
}

export default ForgotPasswordForm;
