import React, { useEffect, useState } from "react";
import { SingleQuestion } from "./SingleQuestion";
import { Box, Grid, LinearProgress, Typography, makeStyles } from "@material-ui/core";
import { ArrowBack, ArrowForward, Close, DoneAll } from "@material-ui/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";
import { SubmitConfirmation } from "./SubmitConfirmation";
import Slider from './NetflixSlider'

export const QuestionList = ({questions, handleFinish}) => {
  const [answered, setAnswered] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionContent, setQuestionContent] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useHistory();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    const temp = []
    questions.forEach((q, i) => {
      temp.push(<SingleQuestion key={i} index={i} total={questions.length} question={q} onAnswer={handleAnswer}/>)
    })
    setQuestionContent(temp)
  }, [ questions ])

  useEffect(() => {
      history.push(url + "/" + currentQuestion, {"replace": true});
  }, [ currentQuestion ])

  const previousQuestion = () => {
    setCurrentQuestion(currentQuestion - 1)
  }

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1)
  }

  const submit = () => {
    setShowConfirmation(!showConfirmation)
  }

  const changeQuestion = (index) => {
    setCurrentQuestion(index)
  }

  const finish = () => {
      history.push(url + "/summary", {"replace": true});
      handleFinish()
  }

  const handleAnswer = (index) => {
    let temp = answered
    temp.push(index)
    setAnswered(temp)
  }

  return(
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Slider currentIndex={currentQuestion} onChange={changeQuestion} answered={answered} data={questions.map((_, index) => ( { num : index}))}/>
        </Grid>
        <Grid item xs={12}>
          {questionContent[currentQuestion]}
        </Grid>
          <div className={ `d-flex ${currentQuestion == 0 ? "justify-content-end" : "justify-content-between"} align-items-between flex-column-reverse flex-sm-row` } style={{width: "100%"}}>      
            {currentQuestion > 0 &&
              <Button onClick={previousQuestion} type="button" className="mt-4">
                <ArrowBack/>  Previous Question
              </Button>
            }
            {currentQuestion < questions.length - 1 && 
              <Button onClick={nextQuestion} type="button" className="mt-4">
                Next Question <ArrowForward/>
              </Button>
            }
            {currentQuestion == questions.length - 1 &&
              <Button onClick={submit} type="button" className="mt-4">
                Submit <DoneAll/>
              </Button>
            }
          </div>
        <SubmitConfirmation open={showConfirmation} handleClose={submit} handleConfirm={finish}/>
      </Grid>
  );


}

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  bar1Determinate: {
    borderRadius: "1rem",
    animation: "$determinate1 .3s linear forwards"
  },
  "@keyframes determinate1": {
    "0%": {
      left: "-35%",
      right: "100%"
    },
    "100%": {
      left: "0%",
      right: "0%"
    }
  }
});

const LinearProgressWithLabel = ({onClick, label, ...rest}) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={25} mr={1} className={"cursor-pointer"}>
        <Close onClick={onClick}/>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress 
          classes={{
            bar1Determinate: classes.bar1Determinate,
          }}
          variant="determinate" {...rest} style={{height: "1rem", borderRadius: "1rem"}} />
      </Box>
      <Box minWidth={35} mt={1}>
        <Typography variant="h5" color="textPrimary" className="mr-2">{label}</Typography>
      </Box>
    </Box>
  );
}
