import * as actionTypes from './types';

export const leaderboardFetch = () => {
    return {
        type: actionTypes.LEADERBOARD_FETCH
    };
};

export const leaderboardFetchSuccess = (data) => {
    return {
        type: actionTypes.LEADERBOARD_FETCH_SUCCESS,
        data: data
    };
};

export const leaderboardFetchError = (error) => {
    return {
        type: actionTypes.LEADERBOARD_FETCH_ERROR,
        error: error
    };
};