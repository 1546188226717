import api from '../Api';
import { getToken } from '../shared/utils';

class SharedDocumentsService {

    /**
     * Share document with the community.
     * @param {string} token Security token.
     * @param {object} data Object with document id to share and new name for document.
     */
    static async share(token, data) {
        return api.put("/v1/shared-documents", data, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Obtain the list of shared documents for specific page.
     * @param {string} token Security token. 
     * @param {number} page Page to obtain.
     * @param {number} pageSize Page size.
     * @param {object} filter Object with filter fields.
     */
    static async list(token, page, pageSize, filter) {
        return api.post("/v1/shared-documents", {...filter, page: page, pageSize: pageSize}, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Copy document to user account.
     * @param {string} token Security token.
     * @param {number} id Shared document identifier.
     */
    static async copy(token, id) {
        return api.post(`/v1/shared-documents/${id}/copy`, {}, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Delete shared document.
     * @param {number} id Shared document identifier.
     */
    static async delete(id) {
      return api.delete(`/v1/shared-documents/${id}`, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

}

export default SharedDocumentsService;