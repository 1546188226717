import React from 'react';
import PropTypes from 'prop-types';

import SparklesIconSvg from '../../images/sparkles.svg';

export const AiIcon = (props) => {
  return (
    <img src={SparklesIconSvg}
    alt={"AI Tutor"}
    title={props.title}
    style={{...props.style, "width": props.width, "height": props.height}}
    className={`ai-icon${props.className !== undefined ? " " + props.className: ""}`}/>
  );
};

AiIcon.propTypes = {
  style: PropTypes.object,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

AiIcon.defaultProps = {
  width: "20px",
  height: "20",
  alt: "AI icon"
};
