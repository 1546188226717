import React, { useState }  from "react";
import { Question } from "./Question";

export const SingleQuestion = ({question, index, onAnswer}) => {

  const handleClick = (correct) => {
    question['correct'] = correct
    onAnswer(index) 
  }

  return (
    <>
      <Question
        num={index + 1}
        handleAnswer={handleClick}
        options={question.answers}
        correctAnswer={question.answers.filter(ans => ans.valid)[0].text}
        questionText={question.question}
        page={question.pageNumber}
      />
    </>
  );
}

