import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import { AddonField, Field } from './CustomFields';
import { DEFAULT_MAX_LENGTH } from '../../shared/validations';
import { EMAIL_PATTERN } from '../../shared/utils';

const CreateAccountForm = ({id, onSubmit}) => {
  const [showPass, setShowPass] = useState(false);
  const { control, register, reset, handleSubmit, setValue, setFocus, setError, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });

  const intl = useIntl();

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const togglePass = () => {
    setShowPass(!showPass);
  };

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)} data-test="form-create-account">
      <button type="submit" style={{display: "none"}} disabled={submitting}></button>

      <Field field="email" labelKey="createAccount.field.email" placeholder={intl.formatMessage({id: "createAccount.field.email"})}
        options={{ required: true, maxLength: DEFAULT_MAX_LENGTH, pattern: {value: EMAIL_PATTERN, message: "email"} }}
        errors={errors} register={register} disabled={submitting} />
      <AddonField field="password" type={showPass ? "text" : "password"} labelKey="createAccount.field.password" 
        placeholder={intl.formatMessage({id: "createAccount.field.password"})}
        options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register}
        iconClassName={showPass ? "fa fa-eye": "far fa-eye-slash"} onIconClick={togglePass} disabled={submitting}/>
      <AddonField field="passwordRepeat" type={showPass ? "text" : "password"} labelKey="createAccount.field.password" 
        placeholder={intl.formatMessage({id: "createAccount.field.repeatPassword.placeholder"})}
        options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register}
        iconClassName={showPass ? "fa fa-eye": "far fa-eye-slash"} onIconClick={togglePass} disabled={submitting}/>
    </Form>
  );
}

export default CreateAccountForm;
