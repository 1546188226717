import React from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

export const EnchancedSelect = props => {
  
    const { input, id, label, disabled, placeholder, className, options, getOptionValue, getOptionLabel, isClearable, isSearchable, meta: { touched, error }} = props;

    let innerClassName = "";
    if (touched && error) {
        innerClassName += "is-invalid";
    }



    return (
        <div className={`form-group${className !== undefined ? " " + className: ""}`}>
            {label !== undefined?<label className="font-weight-bold" htmlFor={id}>{label}</label>:null}
            <Select 
                {...input} 
                onChange={value => input.onChange(value)} 
                onBlur={() => input.onBlur(input.value)} 
                placeholder={placeholder}
                options={options}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={disabled}
                className={innerClassName}
                classNamePrefix="react-select"
            />
            {touched && (error && <div className="text-danger"><FormattedMessage id={error.id} values={error.values} /></div>)}
        </div>
    );
}

export default EnchancedSelect;