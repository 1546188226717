import api from '../ApiForTutor';
import { getToken } from '../../shared/utils';

class FeedbackService {


    static async hasGivenFeedback(service) {
      return api.get(`/feedback/${service}`, {headers: {'X-AUTH-TOKEN': getToken()}});
    }

    static async postFeedback(service, data, consent) {

      const output = {
        service: service,
        consent: consent,
        questions: []
      };

      for (const key in data) {
       key.startsWith(`${service}.feedback.question.`)
        output.questions.push({
          key: key,
          value: data[key]
        });
      }

      return api.post(`/feedback`, output, {headers: {'X-AUTH-TOKEN': getToken()}});
    }

}

export default FeedbackService;
