import PropTypes from 'prop-types';
import {fieldShape} from './FieldShape';

export const sectionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  labelEn: PropTypes.string.isRequired,
  labelNl: PropTypes.string.isRequired,
  labelFr: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(fieldShape).isRequired
});
