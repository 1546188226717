import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NpsForm } from './Form';

import { submit } from 'redux-form';
import { npsToggle, npsSubmit, npsUpdateRating, npsNextStep } from '../store/actions';

import StarRatingComponent from 'react-star-rating-component';

import './Nps.css';

class Nps extends React.Component {

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onStarClick = this.onStarClick.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmit(e) {

    if (e !== undefined) {
      e.preventDefault();
    }

    const { lowRating, highRating, secondStep } = this.props;

    if (!secondStep && (lowRating || highRating)) {
      this.props.npsNextStep();
    } else {
      if (lowRating || highRating) {
          this.props.submit("npsForm");
      } else {
        this.onSubmitForm({});
      }
    }
  }

  onStarClick(newValue, prevValue, name) {

    this.props.npsUpdateRating(newValue);
  }

  onSubmitForm(model) {

    this.props.npsSubmit(model, this.props.rating, this.props.intl);
  }

  render () {

    const { isOpen, rating, lowRating, highRating, secondStep, npsToggle, submitting, msgId = "nps.question" } = this.props;

    let content = (
      <div>
        <div className="mb-2">
          <FormattedMessage id={msgId} />
        </div>
        <StarRatingComponent
          className="ml-5"
          name="npsRating"
          value={rating}
          starCount={10}
          onStarClick={this.onStarClick}
          renderStarIcon={() => <i className="fas fa-star"></i>}/>
        <div className="rating-likely clearfix pb-2">
          <div className="float-left p-1 bg-danger rounded"><FormattedMessage id="nps.not_likely" /></div>
          <div className="float-right p-1 bg-success rounded"><FormattedMessage id="nps.likely" /></div>
        </div>
      </div>
    );

    let extra = null;

    if (secondStep) {

      content = null;

      if (lowRating || highRating) {
        extra = (
          <NpsForm intl={this.props.intl} onSubmit={this.onSubmitForm} low={lowRating} />
        );
      }
    }



    return (
      <Modal isOpen={isOpen} toggle={npsToggle} id="npsModal">
        <ModalHeader toggle={npsToggle} tag="h4">
          <FormattedMessage id="nps.title" />
        </ModalHeader>
        <ModalBody>
          {content}
          {extra}
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary btn-md" onClick={this.onSubmit} disabled={submitting}>
            <FormattedMessage id="btn.submit" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }

}

Nps.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    locale: state.locale.lang,
    isOpen: state.user.nps.open,
    rating: state.user.nps.rating,
    lowRating: state.user.nps.lowRating,
    highRating: state.user.nps.highRating,
    secondStep: state.user.nps.secondStep,
    submitting: state.user.nps.submitting
  };
}

const mapDispatchToProps = { npsToggle, npsSubmit, npsUpdateRating, npsNextStep, submit }

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Nps));
