

export const redesignFreshdeskWidgetLauncher = async () => {

  const container = await waitFor("#freshworks-container", document);
  const frame = await waitFor('#launcher-frame', document);
  const frameHead = frame.contentDocument.getElementsByTagName("head")[0];

  container.style.zIndex = 1000;
  frame.style.zIndex = 1000;

  const new_style_element = document.createElement("style");
  new_style_element.textContent = ".launcher-button, .launcher-button:hover, .launcher-button:active { background: none; background-color: #8CC448 !important; transition: none !important;animation: none !important;}"

  frameHead.appendChild(new_style_element);

  const button = await waitFor(".launcher-button", frame.contentDocument);
  
  button.addEventListener("click", (e) => {
    redesignFreshdeskWidget();
  });

  await redesignFreshdeskWidget();

}

async function redesignFreshdeskWidget() {
  const frameWidget = await waitFor("#widget-frame", document);
  const frameWidgetHead = frameWidget.contentDocument.getElementsByTagName("head")[0];

  const new_style_element_2 = document.createElement("style");
  new_style_element_2.textContent = ".kbfwkc {background: linear-gradient(214.85deg, #8cc448 0%, #67912f 100%) 0% 0% / contain !important;}";

  frameWidgetHead.appendChild(new_style_element_2);
}

function waitFor(selector, docToSearch) {
  return new Promise(resolve => {
      if (docToSearch.querySelector(selector)) {
          return resolve(docToSearch.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
          if (docToSearch.querySelector(selector)) {
              resolve(docToSearch.querySelector(selector));
              observer.disconnect();
          }
      });

      observer.observe(docToSearch.body, {
          childList: true,
          subtree: true
      });
  });
}

export async function hideFreshworksWidget() {
    const container = await waitFor("#freshworks-container", document);
    container.style.display = "none"
}

export async function showFreshworksWidget() {
    const container = await waitFor("#freshworks-container", document);
    container.style.display = ""
}
