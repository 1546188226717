import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Button, Col, Form, Input, Label, Row, Spinner } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DEFAULT_MAX_LENGTH } from '../../shared/validations';
import { userUpdate } from '../../store/actions';
import { useForm } from 'react-hook-form';
import { Field, InlineField } from './CustomFields';
import { EditIcon } from './../Icons';
import { InputErrors } from '../Commons/InputErrors';
import { EMAIL_PATTERN } from '../../shared/utils';

const AccountForm = ({user, isOpen}) => {

  const [ edit, setEdit ] = useState(false);

  const { register, handleSubmit, setFocus, formState: { submitting, errors } } = useForm({
    mode: "onSubmit",
    defaultValues: {username: user.username, email: user.email}
  });

  const intl = useIntl();
  const dispatcher = useDispatch();

  useEffect(() => {

    setEdit(false);

  }, [user]);

  const handleEdit = (model) => {

    dispatcher(
      userUpdate(
        model.username,
        model.email,
        user.fullName,
        intl
      )
    );
  }

  const toggletEdit = () => {
    setEdit(!edit);
    isOpen(edit);
  }

  if (!edit) {

    return (
        <div>
            <div className='d-flex justify-content-between'>
              <div>
                <div>
                  <span className='font-weight-bold'>
                    <FormattedMessage id="account.general.field.username" />:
                  </span> {user?.username}
                </div>
                <div>
                  <span className='font-weight-bold'>
                    <FormattedMessage id="account.general.field.email" />:
                  </span> {user?.email}
                </div>
              </div>
              <div>
                <EditIcon onClick={toggletEdit} role="button" className="c-pointer" />
              </div>
            </div>
        </div>
    );
  }

  const { ref: refUsername, ...registerFieldUsername } = register("username", { required: true, maxLength: DEFAULT_MAX_LENGTH });
  const { ref: refEmail, ...registerFieldEmail } = register("email", { required: true, maxLength: DEFAULT_MAX_LENGTH, pattern: {value: EMAIL_PATTERN, message: "email"} });

  return (
    <Form onSubmit={handleSubmit(handleEdit)} autoComplete="off">

      <div className='d-flex justify-content-between flex-column'>

        <div className='flex-fill mr-2'>
          <div className='form-group'>
            <Label for="username" className="font-weight-bold mb-0 "><FormattedMessage id="account.general.field.username" /></Label>&nbsp;
            <Input className="d-block mt-0 d-sm-inline-block w-80 w-md-auto" id="username" name="username" type="text" innerRef={refUsername} {...registerFieldUsername}
              placeholder={intl.formatMessage({id: "account.general.field.username"})} disabled={submitting} invalid={errors["username"] ? true : false}/>
            <InputErrors fieldName="username" errors={errors} />
          </div>
          <div className='mt-2 form-group'>
            <Label for="email" className="font-weight-bold mb-0"><FormattedMessage id="account.general.field.email" /></Label>&nbsp;
            <Input className="d-block mt-0 d-sm-inline-block w-80 w-md-auto" id="email" name="email" type="email" innerRef={refEmail} {...registerFieldEmail}
              placeholder={intl.formatMessage({id: "account.general.field.email"})} disabled={submitting} invalid={errors["email"] ? true : false}/>
            <InputErrors fieldName="email" errors={errors} />
          </div>
        </div>

          <div className='d-flex justify-content-start flex-row mt-2 mt-md-0 mb-2'>
            <div>
              <Button className="mr-1" color="primary" size="sm" disabled={submitting} block>
                {!submitting ? <FormattedMessage id="btn.save" /> : null }
                {submitting?<Spinner size="sm" className="ml-2"/>:null}
              </Button>
            </div>
            <div>
              <Button className="ml-1" onClick={toggletEdit} outline size="sm" block>
                <FormattedMessage id="btn.cancel" />
              </Button>
            </div>
          </div>

      </div>

    </Form>
  );

}

export default AccountForm;
