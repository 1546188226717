import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';

let client;

const processMessage = (messageBody) => {
  try {
    return JSON.parse(messageBody);
  } catch(e) {
    return messageBody;
  }
}


const middleware = store => next => action => {
  switch (action.type) {
    // User request to connect
    case 'WEBSOCKET:CONNECT':

      if (client !== undefined) {
        client.deactivate();
      }

      // action.payload.email
      client = new Client({
        brokerURL: action.payload.url,
        connectHeaders: {email: action.payload.email},
        webSocketFactory: function () {
          // Note that the URL is different from the WebSocket URL
          return new SockJS(action.payload.url);
        },
        onConnect: () => {

          action.payload.channels.forEach(channel => {
            client.subscribe(channel, message => {
              store.dispatch({type: "WEBSOCKET:MESSAGE", payload: {channel: channel, message: processMessage(message.body)}});
            });
          });
        },
      });
    
      client.activate();

      break;

    // User request to disconnect
    case 'WEBSOCKET:DISCONNECT':
      if (client !== undefined) {
        client.deactivate();
      }

      break;

    default: // We don't really need the default but ...
      break;
  };

  return next(action);
};

export default middleware;
