import React, { Fragment } from 'react';
import { DocumentItem, DocumentListEmpty, DocumentListLoading, DocumentListTitle } from './';
import { useDispatch, useSelector } from 'react-redux';
import DocumentListButtons from './DocumentListButtons';
import { fetchDocuments } from '../../store/actions';
import { FormattedMessage } from 'react-intl';

import './DocumentList.css';

const DocumentList = (props) => {

    const docs = useSelector(state => state.documents.list);
    const error = useSelector(state => state.documents.error);
    const loading = useSelector(state => state.documents.loading);

    if (loading) {
      return <DocumentListLoading />
    }

    if (docs.length === 0) {
      return (error ? <DocumentLoadingError /> : <DocumentListEmpty />);
    }

    return (
      <Fragment>
        <DocumentListTitle />
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="document-list">
              <table className="table" data-test="table-documents">
                <tbody>
                  {docs.map(d =>
                    <DocumentItem
                      key={d.id}
                      doc={d}
                      />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <DocumentListButtons />
      </Fragment>
    );
}

export default DocumentList;

const DocumentLoadingError = (props) => {

  const dispatcher = useDispatch();

  const onClickRefresh = (e) => {
    e.preventDefault();
    dispatcher(fetchDocuments());
  }

  return (
    <div>
      <div><FormattedMessage id="documents.error.loading" /></div>
      <button className="btn btn-sm btn-secondary" onClick={onClickRefresh}>
        <FormattedMessage id="btn.refresh" />
      </button>
    </div>
  );
}