import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';

const DocumentListLoading = () => {

    return (
        <div className="container-fluid">
            <div className="row mb-3">
                <div className="col">
                    <div className="text-center">
                        <Spinner color="primary"/>
                        <div>
                            <FormattedMessage id="documents.loading" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentListLoading;