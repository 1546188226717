import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const ListPagination = ({page, totalItems, pageSize, onPageChange}) => {

  const maxPages = Math.ceil(totalItems/pageSize) - 1;

  let pagination = null;

  if (maxPages > 0) {

    const currentPage = page + 1;
    var start = currentPage - 2, end = currentPage + 2;

    if(end > maxPages){
        start -= (end - maxPages);
        end = maxPages;
    }

    if(start <= 0){
        end += ((start - 1) * -1);
        start = 1;
    }

    end = end > maxPages ? maxPages : end;

    const pageNumbers = [];
    for (let i = start - 1; i <= end - 1;i++) {
      pageNumbers.push(
        (<PaginationItem active={i === page} key={i}>
          <PaginationLink onClick={e => onPageChange(e, i)}>
          {i + 1}
          </PaginationLink>
      </PaginationItem>)
      );
    }

    pagination = (
        <Pagination>
            <PaginationItem disabled={page === 0}>
                <PaginationLink first onClick={e => {onPageChange(e, 0)}} />
            </PaginationItem>
            <PaginationItem disabled={page === 0}>
                <PaginationLink previous onClick={e => {onPageChange(e, page - 1)}} />
            </PaginationItem>
            {pageNumbers}
            <PaginationItem disabled={maxPages -1 === page}>
                <PaginationLink next onClick={e => {onPageChange(e, page + 1)}} />
            </PaginationItem>
            <PaginationItem disabled={maxPages - 1 === page}>
                <PaginationLink last onClick={e => {onPageChange(e, maxPages - 1)}} />
            </PaginationItem>
        </Pagination>
    );
  }

  return pagination;
}

export default ListPagination;