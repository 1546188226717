import React, { useState, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Container, Row, Col, Button, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { DeliveryDiscountCodeService } from '../../Services';
import { globalErrorHandling } from "../../shared/utils";

const DeliveryDiscount = ({intl, apply, validate = true}) => {

    const [discountCode, setDiscountCode] = useState();
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        mode: "onSubmit"
    });
    const { ref: promoCodeRef, ...registerPromoCode } = register("promoCode", { required: true });

    const handleAdd = (data) => {

        const process = () => {
            apply(data);
            setDiscountCode(data.promoCode);
        }

        if (validate === false) {
            process();
            return;
        }

        DeliveryDiscountCodeService.validateCode(data.promoCode)
            .then(response => {
                process();
            })
            .catch(error => {
                const errorObj = globalErrorHandling(error);

                setError(
                    "promoCode",
                    {
                        "type": "manual",
                        "message": intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams)
                    }
                );
            });
        
    };

    const handleRemove = (e) => {
        e.preventDefault();
        apply({promoCode: undefined});
        setDiscountCode(undefined);
    };

    if (discountCode !== undefined) {
        return (
            <Container fluid className="p-0">
                <Row noGutters>
                    <Col>
                        <span className="small">
                            <FormattedMessage id="deliveries.request.discount.applied" values={{"discountCode": discountCode}}/>
                        </span>
                        &nbsp;
                        <a href="#remove-discount" className="small text-underline text-secondary-dark" onClick={handleRemove}>
                            <FormattedMessage id="deliveries.request.discount.btn.remove" />
                        </a>
                    </Col>
                </Row>
            </Container>
        );
    } 

    return (
      <form onSubmit={handleSubmit(handleAdd)}>
        <div className="d-flex flex-column flex-sm-row">
          <div className="flex-grow-1">
            <Input id="deliveryPromoCode" data-test="delivery-request-promo-code" type="text" name="promoCode"
              placeholder={intl.formatMessage({id: "deliveries.request.discount.add.placeholder"})}
              className="form-control-xs"
              invalid={errors.promoCode ? true : false}
              {...registerPromoCode}
              innerRef={promoCodeRef}/>
          </div>
          <div className="mt-2 mt-sm-0 pl-sm-2">
            <Button type="submit" data-test='delivery-promocode-button' block className="btn-xs align-top">
                <FormattedMessage id="deliveries.request.discount.btn.apply" />
            </Button>
          </div>
        </div>
          {errors?.promoCode ? <div>
            {errors.promoCode.type === "required" && <p className="text-danger small mb-0"><FormattedMessage id="validations.field.required" /></p>}
            {errors.promoCode.type === "manual" && <p className="text-danger small mb-0">{errors.promoCode.message}</p>}
          </div> : null }
      </form>
    );

};

export default injectIntl(DeliveryDiscount);
