import PropTypes from 'prop-types';

export const deliveryFinalStatuses = ['DELIVERED', 'MISSING', 'ERROR', 'PAYMENT_CANCELED', 'PAYMENT_TIMEOUT', 'PAYMENT_ERROR'];

export const deliveryStatuses = ['REQUESTED', 'WAITING_FOR_PRINTING', 'PRINTING', 'IN_WAY', 'DELIVERED', 'MISSING', 'ERROR', 'PAYMENT_PENDING', 'PAYMENT_CANCELED', 'PAYMENT_TIMEOUT', 'PAYMENT_ERROR', 'GENERATING_PACKAGE'];

const deliveryDocumentShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  pages: PropTypes.number.isRequired,
  ads: PropTypes.number.isRequired
});

export const deliveryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
  status: PropTypes.oneOf(deliveryStatuses),
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  documents: PropTypes.arrayOf(deliveryDocumentShape),
  premium: PropTypes.bool.isRequired,
  amount: PropTypes.number
});
