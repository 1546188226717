import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';
import { AD_BLOCK_DETECTED } from '../shared/EventsNames';
import { useMixpanel } from '../contexts/Mixpanel';
import { PrivateTemplate } from '../Templates';

export const AdBlock = (props) => {

    const mixpanel = useMixpanel();

    mixpanel.track(AD_BLOCK_DETECTED);

    return (
      <PrivateTemplate>
        <div className="container-fluid mt-3">
          <Alert color="warning">
            <FormattedMessage id="message.global.adBlock" />
          </Alert>
        </div>
      </PrivateTemplate>
    );
};
