import React from 'react';
import cx from 'classnames';
import './Item.css'

const Item = ({ onSelect, currentIndex, elementRef, index, flexAmount, first: isFirst, last: isLast, isAnswered, isCorrect, isSummary }) => {
      const isActive = currentIndex === index;

      return (
        <div
          ref={elementRef}
          onClick={() => onSelect(index)}
          className={cx('item', {
            'item--active': isActive,
            'item--answered': isAnswered && !isActive,
            'item--correct' : isCorrect && isSummary,
            'item--incorrect' : !isCorrect && isSummary,
          }) + ` ${isFirst ? "first" : (isLast ? "last" : "")}`}
          style={{flex: `0 0 ${flexAmount}%`}}
        >
          {index + 1}
        </div>
      );
}

export default Item;
