import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Form } from "reactstrap";
import { AddonField, Field } from "../../Components/Form/CustomFields";
import { useForm } from "react-hook-form";
import { DEFAULT_MAX_LENGTH } from "../../shared/validations";
import { useDispatch, useSelector } from "react-redux";
import { directLogIn, logInFacebook, userCreateAccount } from "../../store/actions";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useGoogleLogin } from "@react-oauth/google";
import AuthService from "../../Services/AuthService";
import {error as errorNotification } from 'react-notification-system-redux';
import { useMixpanel } from "../../contexts/Mixpanel";
import { EMAIL_PATTERN, clearRefIdentifier, getRefIdentifier, globalErrorHandling, isFacebookApp, isTiktokApp, setFacebookLogIn, setGoogleLogin, setToken } from "../../shared/utils";
import { getLanguage } from "../../store/sagas/selectors";
import { GlobalError } from "../../Components/Messages/GlobalError";
import { logger } from "../../shared/Logger";

const CreateAccountForm = ({ hideButtonsOnSmall = false }) => {

  const [showPass, setShowPass] = useState(false);
  const [localLoading, setLoading] = useState(false);
  const [googleData, setGoogleData] = useState();
  const [social, setSocial] = useState();
  const [auth, setAuth] = useState();

  const loading = useSelector(state => state.user.createAccount.loading);
  const error = useSelector(state => state.user.createAccount.error);
  const language = useSelector(getLanguage);

  const { control, register, reset, handleSubmit, setValue, setFocus, setError, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });

  const intl = useIntl();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  useEffect(() => {

    if (googleData) {
    
      setLoading(true);

      const request = {
        "token": googleData.access_token,
        "language": language,
        "analyticsId": mixpanel.get_distinct_id(),
        "newAccount": true
      };

      const ref_id = getRefIdentifier();
      if (ref_id !== undefined) {
        request.refId = ref_id;
      }

      AuthService.authWithGoole(request).then(response => {
        setSocial("Google");
        setAuth(response.data);
        clearRefIdentifier();
      }).catch(err => {

        const errorObj = globalErrorHandling(err);

        let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
        if (msg === errorObj.errorKey) {
          msg = intl.formatMessage({ id: "createAccount.msg.error"});
        }
        
        dispatch(errorNotification({
          message: msg,
          position: 'tc',
          autoDismiss: 0,
        }));

      }).finally(() => {
        setLoading(false);
      })
      }

  }, [googleData]);

  useEffect(() => {

    if (auth) {
      setToken(auth.token);
      dispatch(directLogIn(auth.token));
    }

  }, [auth]);

  useEffect(() => {

    if (social) {
      if (social === "Google") {
        setGoogleLogin();
      } else if (social === "Facebook") {
        setFacebookLogIn();
      }
      
    }

  }, [social]);

  const togglePass = () => {
    setShowPass(!showPass);
  };

  const handleFacebookLogin = (fbData) => {

    if (fbData.accessToken !== undefined) {
      dispatch(logInFacebook(fbData.accessToken, intl));
    }

  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => setGoogleData(response),
    onError: (error) => logger.error('Login Failed:', error)
  });

  const handleEmailLogin = (model) => {
    dispatch(userCreateAccount(model.email, model.password, model.password, intl, false));
  }

  const handleOnFocus = (event) => {
    logger.info("onFoucs event:")
    logger.info(event)
    try {
      event.currentTarget.scrollIntoView({behavior: 'smooth'});
    } 
    catch (error) {
      logger.error("Could not scroll into view.", error)
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleEmailLogin)}>

      { error ? <GlobalError error={error} /> : null }

      <div>
        <Field field="email" labelHidden={true} onFocus={handleOnFocus} labelKey="createAccount.field.email" placeholder={intl.formatMessage({id: "createAccount.field.email"})}
          options={{ required: true, maxLength: DEFAULT_MAX_LENGTH, pattern: {value: EMAIL_PATTERN, message: "email"} }}
          errors={errors} register={register} disabled={localLoading || loading || submitting} />
      </div>
      <div>
        <AddonField field="password" onFocus={handleOnFocus} type={showPass ? "text" : "password"} labelHidden={true} labelKey="createAccount.field.password" 
          placeholder={intl.formatMessage({id: "createAccount.field.password"})}
          options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register}
          iconClassName={showPass ? "fa fa-eye": "far fa-eye-slash"} onIconClick={togglePass} disabled={localLoading || loading || submitting}/>
      </div>
      <div className="very-small policy-text">
        <FormattedMessage id="createAccount.field.policy" values={{
            privacy: (text) => (<a href="https://zerocopy.be/privacy/" target="_blank" rel="noopener noreferrer">{text}</a>),
            terms: (text) => (<a href="https://zerocopy.be/terms-and-conditions" target="_blank" rel="noopener noreferrer">{text}</a>)
          }} />
      </div>
      <div className="">

        <Button color="primary" disabled={localLoading || loading || submitting} className="px-5 mt-3">
          <FormattedMessage id="createAccount.button" />
        </Button>

        <span className={`text-nowrap${isTiktokApp() || isFacebookApp() ? " d-none" : ""}`}>
          <Button id="btnCreateAccountGoogle" color="secondary" onClick={handleGoogleLogin} className="ml-2 mr-2 mt-3"
            disabled={localLoading || loading || submitting}>
            <i className="fab fa-google"></i>
          </Button>

          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID}
            fields="name,email"
            callback={handleFacebookLogin}
            render={renderProps => {
              return (
                <Button id="btnCreateAccountFacebook" color="secondary" className="mt-3" onClick={renderProps.onClick}
                  disabled={localLoading || loading || submitting}>
                    <i className="fab fa-facebook-f"></i>
                </Button>
                );
              }}
            />
          </span>
      </div>
    </Form>
  );
}

export default CreateAccountForm;
