import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { nextSection } from '../../store/actions';

import Section from './Section'

import './SectionList.css'

class SectionList extends React.Component {

  render () {

    const { error, loading, submitting, list, currentIndex, opened, rewardForFilledProfile, onSave, lang } = this.props;

    let content;

    if (loading) {
      content = (
        <div><FormattedMessage id="profile.sections.loading" /></div>
      );
    } else if (error) {
      content = (
        <div><FormattedMessage id="profile.sections.error" /></div>
      );
    } else if (!list.length) {
      content = (
        <div><FormattedMessage id="profile.filled" /></div>
      );
    } else if (!opened) {
      return null;
    } else {
      content = (
        list.map((s, index) =>
          <Section section={s} lang={lang} index={index} currentIndex={currentIndex} totalSections={list.length} key={s.id} reward={rewardForFilledProfile} onSave={onSave} submitting={submitting} />
        )
      );
    }

    return (
      <Modal isOpen={opened} data-test="modal-fill-profile" keyboard={false}>
        <ModalHeader>
          <FormattedMessage id="profile.title" />
        </ModalHeader>
        <ModalBody>
            {content}
        </ModalBody>
      </Modal>
    );
  }

}

SectionList.propTypes = {
  opened: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    lang: state.locale.lang,
    list: state.profile.list,
    rewardForFilledProfile: state.system.credit_policy_level_5
  };
}

const mapDispatchToProps = { nextSection }

export default connect(mapStateToProps, mapDispatchToProps)(SectionList);
