import React, { Fragment, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import ChangePasswordForm from "./ChangePasswordForm";
import { UserService } from "../../Services";
import { getToken, globalErrorHandling } from "../../shared/utils";

const ChangePasswordModal = ({intl, showButton}) => {

  // State management
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dispatcher = useDispatch();

  const onSubmit = (model) => {
    
    setIsSubmitting(true);

    UserService.changePassword(getToken(), model)
      .then(response => {

        setIsOpen(false);
        setIsSubmitting(false);

        dispatcher(successNotification({
          message: intl.formatMessage({ id: "changePassword.msg.password_changed"}),
          position: 'tc',
          autoDismiss: 0,
        }));
      })
      .catch(error => {
          
          const errorObj = globalErrorHandling(error);

          setIsSubmitting(false);

          let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
          if (msg === errorObj.errorKey) {
            msg = intl.formatMessage({ id: "changePassword.msg.error"});
          }
          
          dispatcher(errorNotification({
            message: msg,
            position: 'tc',
            autoDismiss: 0,
          }));
      });
  };

  const toggle = (e) => {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  return (
    <Fragment>

      { showButton ? 
        <Button color="link" className="p-0 border-0" onClick={toggle} data-test="change-password-button">
          <FormattedMessage id="changePassword.title" />
        </Button>
      : null }

      <Modal isOpen={isOpen} toggle={toggle} data-test="modal-change-password" id="changePasswordModal">
        <ModalHeader toggle={toggle}>
          <FormattedMessage id="changePassword.title" />
        </ModalHeader>
        <ModalBody>
          <ChangePasswordForm apply={onSubmit} isSubmitting={isSubmitting} />
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default injectIntl(ChangePasswordModal);
