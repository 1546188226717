import FlashcardService from './FlashcardService'
import QuestionService from './QuestionService'
import { AI_FLASHCARDS, AI_QUIZ } from '../Ai/Containers/AiTutorPage';

class NpsSerivce {

    static async show(type) {
        const filter = (response) => { response.data.findIndex(d => d.status === "GENERATED") != -1 && Math.random() >= 0.95 }

        if (type === AI_FLASHCARDS) {
            return FlashcardService.fetchAll()
                .then(filter)
        } else if (type === AI_QUIZ) {
            return QuestionService.fetchAll()
                .then(filter)
        }
    }


}

export default NpsSerivce
