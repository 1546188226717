import React, { useEffect } from 'react';
import { createContext, useContext } from 'react';

const context = createContext(null);

export const TestProvider = ({mixpanel, children}) => {


  useEffect(() => {


  }, []);


  const { Provider } = context;

  return (
    <Provider value={{

    }}>
      {children}
    </Provider>
  );
};

export const useTest = () => useContext(context);
