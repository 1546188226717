import PropTypes from 'prop-types';

export const documentShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  uploadDate: PropTypes.string.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  numberOfFullPageAds: PropTypes.number.isRequired,
  previewUrl: PropTypes.string,
});
