import { LOCALE_SET } from "../actions/types";
import { hasLocalStorage } from '../../shared/utils';
import messages from '../../i18n/messages';

export default function locale(state = { lang: "en", messages: messages["en"] }, action = {}) {
  switch (action.type) {
    case LOCALE_SET:
      if (hasLocalStorage()) {
          localStorage.setItem("lang", action.lang);
      }
      return {...state, lang: action.lang, messages: messages[action.lang]};
    default:
      return state;
  }
}
