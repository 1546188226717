import React, {useEffect} from'react';
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, FormattedNumber} from'react-intl';
import {StatsLoading} from'.';
import {userStatsFetch} from'../../store/actions';

import'./Stats.css';
import InviteFriendsButton from'../InviteFriends/InviteFriendsButton';

const Stats = ({className, ...props}) => {

  const stats = useSelector((state) => state.user.stats);

  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher(userStatsFetch());
  }, []);

  return (
    <div className={`stats${className ? ` ${className}` : ""}`}>

      <div className='d-flex align-items-center justify-content-between pb-2 border-bottom mb-2'>
        <h4 data-test="stats-title" className='mb-0'><FormattedMessage id="stats.title"/></h4>
        <InviteFriendsButton/>
      </div>

      {stats.loading === true ? <div className="row ">
        <div className="col-md-12">
          <StatsLoading/>
        </div>
      </div> : <StatsContent stats={stats}/>}
    </div>
  );
}

export default Stats;

const StatsContent = ({stats}) => {

  return (
    <div data-test='upload-stats' className='d-flex justify-items-between flex-sm-row flex-column w-100'>
      <div className='d-flex flex-sm-column flex-fill stats-data text-center align-items-center mb-2'>
          <span className="stats-icon mb-2 mr-3 text-primary material-symbols-outlined">cloud_upload</span>
            <div className='d-flex flex-column align-items-center'>
              <div className='d-flex flex-sm-column flex-row'>
                <div className='mr-2' data-test='upload-value'>
                  <b><FormattedNumber value={stats.uploads}/></b>
                </div>
                <div className='flex-sm-grow-1'>
                  <FormattedMessage id="stats.uploads.title"/>
                </div>
              </div>
            </div>
        </div>

        <div className='d-flex flex-sm-column flex-fill stats-data text-center align-items-center mb-2' data-test='print-stats'>
          <span className="stats-icon mb-2 mr-3 text-primary material-symbols-outlined">print</span>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-sm-column flex-row'>
              <div className='mr-2' data-test='prints-value'>
                <b><FormattedNumber value={stats.prints}/></b>
              </div>
              <div className='flex-sm-grow-1'>
                <FormattedMessage id="stats.prints.title"/>
              </div>
            </div>
            <div className='d-flex flex-sm-column flex-row mt-1' data-test='pages-value'>
              <div className='mr-2'>
                <b><FormattedNumber value={stats.pagesPrinted}/></b>
              </div>
              <div className='flex-sm-grow-1'>
                <FormattedMessage id="stats.pages_printed.title"/>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-sm-column flex-fill stats-data text-center align-items-center mb-2' data-test='friends-stats'>
          <span className="stats-icon mb-2 mr-3 text-primary material-symbols-outlined">group_add</span>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-sm-column flex-row'>
              <div className='mr-2' data-test='activated-value'>
                <b><FormattedNumber value={stats.friendsActivated}/></b>
              </div>
              <div className='flex-sm-grow-1' data-test='registered-value'>
                <FormattedMessage id="stats.friends.registered"/>
              </div>
            </div>
            <div className='d-flex flex-sm-column flex-row mt-1' data-test='printing-value'>
              <div className='mr-2'>
                <b><FormattedNumber value={stats.friendsWithPrints}/></b>
              </div>
              <div className='flex-sm-grow-1'>
                <FormattedMessage id="stats.friends.printing"/>
              </div>  
            </div>  
          </div>
        </div>

    </div>
  );
};
