import React, { useEffect } from 'react';
import { useState } from 'react';
import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import AdsService from '../Services/AdsService';
import { globalErrorHandling } from '../shared/utils';
import { logger } from '../shared/Logger';
import ConfigurationService, { ADSENSE_ENABLED } from '../AiTutor/Services/ConfigurationService';
import { ConfirmationIcon } from '../Components/Icons';

const context = createContext(null);

export const AdsProvider = ({children}) => {

  const [currentBillboardAdIndex, setCurrentBillboardAdIndex] = useState(0);
  const [billboardAds, setBillboardAds] = useState([]);
  const [currentSmallAdIndex, setCurrentSmallAdIndex] = useState(0);
  const [smallImageAds, setSmallImageAds] = useState([]);
  const default_timeout_seconds = useSelector(state => state.system.system_ads_default_timeout)  
  const [useAdSense, setUseAdSense] = useState(false)

  useEffect(() => {
    loadContext();
  }, []);

  const loadContext = () => {
    loadSmallAds();
    loadBillboardAds();
    ConfigurationService.fetchConfig(ADSENSE_ENABLED)
      .then(response => {
        setUseAdSense(response.data === "true" || response.data === true);
      }).catch(error => {
        logger.error("Error fetching adsense enabled" + config, error);
      });
  }

  const loadSmallAds = () => {
    AdsService.fetchSmallImages()
      .then(response => {
        setSmallImageAds(response.data);
      })
      .catch(error => {
        logger.error("Error fetching small ads:" + error);
    });
  }

  const loadBillboardAds = () => {
    AdsService.fetch()
      .then(response => {
        setBillboardAds(response.data);
      })
      .catch(error => {
        logger.error("Error fetching billboard ads:" + error);
    });
  }

  const changeBillboardAd = (positive) => {
    if (positive && currentBillboardAdIndex < billboardAds.length -1) { 
      setCurrentBillboardAdIndex(currentBillboardAdIndex + 1);
    } 
    else if (!positive && currentBillboardAdIndex > 0) {
      setCurrentBillboardAdIndex(currentBillboardAdIndex - 1);
    }
    else {
      loadBillboardAds();
      setCurrentBillboardAdIndex(0);
    }
  }

  const changeSmallAd = (positive) => {
    if (positive && currentSmallAdIndex < smallImageAds.length -1) { 
      setCurrentSmallAdIndex(currentSmallAdIndex + 1);
    } 
    else if (!positive && currentSmallAdIndex > 0) {
      setCurrentSmallAdIndex(currentSmallAdIndex - 1);
    }
    else {
      loadSmallAds();
      setCurrentSmallAdIndex(0);
    }
  }

  const { Provider } = context;

  return (
    <Provider value={{
      billboardAds : billboardAds,
      currentBillboardAdIndex: currentBillboardAdIndex,
      changeBillboardAd : changeBillboardAd,
      smallAds: smallImageAds,
      default_timeout_seconds: default_timeout_seconds,
      changeSmallAd: changeSmallAd,
      currentSmallAdIndex: currentSmallAdIndex,
      adSenseEnabled: useAdSense,
    }}>
      {children}
    </Provider>
  );
};

export const useAds = () => useContext(context);
