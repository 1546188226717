import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Spinner } from 'reactstrap';
import { AddressItem, AddressModalForm, AddressListEmpty} from '../Components/Address';
import { GlobalError } from '../Components/Messages/GlobalError';

import { fetchAddresses, addressFormToggle, fetchAddress, saveAddress } from '../store/actions';

import './AddressList.css';

const AddressList = ({className, ...props}) => {

  const addresses = useSelector((state) => state.address.list);
  const loading = useSelector((state) => state.address.loading);
  const error = useSelector((state) => state.address.error);
  const isNew = useSelector((state) => state.address.isNew);
  const openAddressForm = useSelector((state) => state.address.openAddressForm);

  const dispatcher = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatcher(fetchAddresses());
  }, []);

  const handleNewAddressClick = (e) => {

    if (e !== undefined) {
      e.preventDefault();
    }

    dispatcher(addressFormToggle(true));
  }

  const handleOnSubmit = (model) => {

    dispatcher(saveAddress(model));
  }

  const toggle = () => {
    dispatcher(addressFormToggle());
  }

  const onEdit = (id) => {
    dispatcher(fetchAddress(id));
  }

  let content;

  if (loading === true) {
    content = (
      <div className="text-center">
        <Spinner color="primary" style={{width: "4rem", height: "4rem"}}/>
        <div>
          <FormattedMessage id="address.list.loading" />
        </div>
      </div>
    );
  } else if (addresses.length === 0) {
    content = (
      <AddressListEmpty />
    );
  } else {
    content = (
      <div className="address-list page-in row" data-test="list-address">
        {addresses.map(address =>
          <div className='col-12 col-md-6 mb-2' key={address.id}>
            <AddressItem
              item={address}
              onEdit={onEdit}
              />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`address-list-container${className ? ` ${className}`:""}`}>
      <div className='d-flex align-items-center justify-content-between pb-2 border-bottom mb-3'>
        <h4 className='mb-0'><FormattedMessage id="address.list.title" /></h4>
        <Button color="secondary" size="sm" onClick={handleNewAddressClick} data-test="link-to-create-address">
          <FormattedMessage id="address.list.btn.add"/>
        </Button>
      </div>
      <div>
        <GlobalError error={error}/>
        {content}
        <AddressModalForm
          isOpen={openAddressForm}
          toggle={toggle}
          onSubmit={handleOnSubmit}
          isNew={isNew}
        />
      </div>
    </div>
  );

}

export default AddressList;
