import React, { useEffect } from "react";
import Notifications from "react-notification-system-redux";
import { useSelector } from "react-redux";

import './LandingPageForSoMeCampaign.css';
import { LogoNewGreen } from "../components/LogoNewGreen";

import img_landing from '../images/landing_page_v3.png';
import { Col, Container, Row } from "reactstrap";
import CreateAccountForm from "../components/CreateAccountForm";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LanguageSelector from "../components/LanguageSelector";
import { Redirect, useLocation } from 'react-router-dom';
import { logger } from "../../shared/Logger";
import { AccountCreatedMessage } from "../../Components/Account/AccountCreatedMessage";

const LandingPageForSoMeCampaign = (props) => {

  const notifications = useSelector(state => state.notifications);
  const loggedIn = useSelector(state => state.security.loggedIn);
  const successful = useSelector(state => state.user.createAccount.successful)
  const { search } = useLocation();


  useEffect(() => {
    logger.info("User agent from the browser", navigator.userAgent);
  }, []);

  if (loggedIn === true) {
    return (
      <Redirect to={{
        pathname: "/documents",
        search: search
      }} />
    );
  }

  let contents =
      <>
        <div className="subTitle">
            <FormattedMessage id="soMeCampaign.subTitle" />
          </div>
          <div className="mt-md-5 mt-3">
            <CreateAccountForm />
          </div>
          <div className="mx-3 mt-md-0 mt-2">
            <FormattedMessage id="createAccount.youHaveAccount"/>&nbsp;
            <Link to="/login" className="highlight">
                <FormattedMessage id="createAccount.button.back" />
            </Link>
          </div>
      </>

  if (successful) {
   contents = 
    <>
        <AccountCreatedMessage soMeCampaign={true} className={"mt-5"}/>
    </>
  }

  return (
    <React.Fragment>
      <Notifications
        notifications={notifications}
        style={false}
      />

      <Container fluid className="soMeCampaign" style={{backgroundImage: `url(${img_landing})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', height: "94%"}}>
        <Row className="d-none d-md-flex">
          <Col>
            <div className="d-flex justify-content-between p-4">
              <div>
                <LogoNewGreen width="250" />
              </div>
              <div>
                <LanguageSelector />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" xl="5">
            <div className="mx-1 mx-md-0 ml-md-5 mt-1 mt-md-5">
              <div className="title">
                <FormattedMessage id="soMeCampaign.title" values={{
                  highlight: text => <span className="highlight">{text}</span>
                }}/>
              </div>
              {contents}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="d-flex d-md-none soMeCampaign justify-content-end p-1 mr-2">
          <LanguageSelector />
      </div>
    </React.Fragment>
  );
}

export default LandingPageForSoMeCampaign;
