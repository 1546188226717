import api from '../Api';
import { getToken } from '../shared/utils';

export const NUMBER_OF_USERS = 15;

class LeaderboardService {

    /**
     * Obtain leaderboard for logged user.
     * @param {string} token 
     */
    static async fetch(token) {
       return api.get(`/v1/leaderboard?numberOfUsers=${NUMBER_OF_USERS}`, {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }
    
}

export default LeaderboardService;