import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { consentPartnerEmailsToggle } from '../../store/actions';
import { PartnerEmailInfo } from '../others';
import { ToggleIcon } from '../Icons';
import { useDispatch, useSelector } from 'react-redux';

const PartnerEmailButton = ({ reward }) => {
    
  const enabled = useSelector(state => state.consent.partnerEmails);
  const enabledFeature = useSelector(state => state.system.system_enable_partner_emails);
  const dispatcher = useDispatch();
  const intl = useIntl();

  const handleToggle = () => {
    dispatcher(consentPartnerEmailsToggle(intl));
  }

  if (enabledFeature !== true) {
      return null;
  }

  return (
    <div className='d-flex align-items-center'>

      <ToggleIcon enabled={enabled} className="text-primary" style={{fontSize: "1.3rem"}}
          onClick={handleToggle}
          title={intl.formatMessage({id: "partnerEmails.title"})}
      />
          
      <span className="ml-2 mr-1">
          <FormattedMessage id="partnerEmails.title" />
      </span>

      <PartnerEmailInfo reward={reward} showLink={false} />
      
    </div>
  );
}

PartnerEmailButton.propTypes = {
    reward: PropTypes.number.isRequired
};

export default PartnerEmailButton;