import * as actionTypes from './types';

export const metadataFetch = (key, valueType) => {
  return {
    type: actionTypes.METADATA_FETCH,
    key: key,
    valueType: valueType
  }
};

export const metadataFetchSuccess = (data, valueType) => {

  let value;

  switch (valueType) {
    case "boolean":
      value = data.value === "true";
      break;
    case "integer":
      value = parseInt(data.value);
      break;
    default:
      value = data.value
      break;
  }

  return {
    type: actionTypes.METADATA_FETCH_SUCCESS,
    key: data.key,
    value: value
  }
};


export const featuresEnabledFetch = (features) => {
  return {
    type: actionTypes.FEATURES_ENABLED_FETCH,
    features: features,
  }
};

export const featuresEnabledFetchSuccess = (featuresResult) => {

  return {
    type: actionTypes.FEATURES_ENABLED_FETCH_SUCCESS,
    result: featuresResult
  }
};
