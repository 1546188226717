import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { localeSet } from "../../store/actions";
import { languages } from "../../config";

const LanguageSelector = (props) => {

  const lang = useSelector(state => state.locale.lang);

  const [open, setOpen] = useState(false);

  const dispatcher = useDispatch();

  const toggle = () => setOpen((prevState) => !prevState);

  const onChange = (newLang) => {
    if (lang === newLang) {
      return;
    }

    dispatcher(localeSet(newLang));
  }

  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle caret>{lang.toUpperCase()}</DropdownToggle>
      <DropdownMenu>
        {languages.map((l, index) => <DropdownItem active={lang === l} key={index} onClick={e => onChange(l)}>{l.toUpperCase()}</DropdownItem>)}
      </DropdownMenu>
    </Dropdown>
  );

}

export default LanguageSelector;