import * as actionTypes from '../actions/types';
import {updateObject} from '../../shared/utils';


const emptySelected = {
  documents: [],
  pages: 0,
  ads: 0
};

const initialState = {
  list: [],
  uploading: false,
  loading: false,
  error: false,
  selected: emptySelected,
  isDeleteSubmitting: false,
  openDetail: false,
  deliveryRequest: {
    selectedAddress: undefined,
    showSummary: true,
    showAddressSelector: false,
    success: false,
    error: false,
    errorData: undefined,
    estimatedDate: undefined,
    submitting: false,
    premium: false,
    type: "WITH_ADS",
    deliveries: -1,
    payment: "BANCONTACT",
    discountCode: undefined
  },
  deliveryLocked: {
    isOpen: false
  },
};


const fetchDocuments = ( state, action ) => {
  return updateObject(state, {
    loading: true
  });
};

const fetchDocumentsSuccess = ( state, action ) => {
  return updateObject(state, {
    loading: false,
    list: action.list
  });
};

const fetchDocumentsError = ( state, action ) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const toggleDocument = (state, action) => {
  const index = state.selected.documents.indexOf(action.documentId);
  const newSelected = state.selected.documents.slice();
  let pages = state.selected.pages;
  let ads = state.selected.ads;
  if (index >= 0) {
    newSelected.splice(index, 1);
    pages -= action.pages;
    ads -= action.ads;
  } else {
    newSelected.push(action.documentId);
    pages += action.pages;
    ads += action.ads;
  }

  return updateObject( state, {
    selected: {
      documents: newSelected,
      pages: pages,
      ads: ads
    }
  });
};

const deleteDocument = (state, action) => {
    return updateObject(state, {
      isDeleteSubmitting: true
    });
};

const deleteDocumentSuccess = (state, action) => {

    const newList = state.list.filter(d => !action.documentIds.includes(d.id))

    return updateObject( state, {
        list: newList,
        isDeleteSubmitting: false,
        selected: emptySelected
    })
};

const deleteDocumentError = (state, action) => {
    return updateObject( state, {
        error: action.error,
        isDeleteSubmitting: false
    });
};

const uploadDocument = (state, action) => {
    return updateObject( state, {
        uploading: true
    });
};

const uploadDocumentProgress = (state, action) => {
    return updateObject( state, {
        progress: action.progress
    });
};

const uploadDocumentSuccess = (state, action) => {
    return updateObject( state, {
        uploading: false,
    });
};

const uploadDocumentError = (state, action) => {
    return updateObject( state, {
        uploading: false,
    });
};

const reloadDocument = (state, action) => {

    let errors;

    if (action.extraData?.errors) {
      errors = action.extraData.errors;
    }

    let pagesInfo = false;
    if (action.extraData?.pages) {
      pagesInfo = true;
    }

    return { 
      ...state, 
      list: state.list.map((item, i) => {
        if (item.id === action.documentId) {
          if (errors) {
            return {...item, status: action.newStatus, errors: errors, withProblems: true};
          } else if (pagesInfo) {
            return {...item, status: action.newStatus, numberOfPages: action.extraData.pages, numberOfFullPageAds: action.extraData.fullPageAds, previewUrl: action.extraData.previewUrl, hash: action.extraData.hash};
          }
           else {
            return {...item, status: action.newStatus};
          }
        }

        return item;
        
      })
   }

    return state;
};

const toggleConfirmDelete = (state, action) => {
  return state;
};

const deliveryRequestShowAddress = ( state, action ) => {
  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest, showSummary: false, showAddressSelector: true}
  });
};

const deliveryRequestSelectAddress = ( state, action ) => {
  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest, selectedAddress: action.address}
  });
};

const deliveryRequestShowPaymentSelector = ( state, action ) => {
  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest, showAddressSelector: false}
  });
};

const deliveryRequestSelectPayment = ( state, action ) => {
  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest, payment: action.payment}
  });
};

const deliveryRequest = ( state, action) => {
  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest,
      submitting: true
    }
  });
}

const deliveryRequestSuccess = ( state, action) => {
  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest,
      success: true,
      error: false,
      estimatedDate: action.estimatedDate,
      submitting: false,
      errorData: undefined
    },
    selected: emptySelected
  });
}

const deliveryRequestError = ( state, action) => {
  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest,
      success: false,
      error: true,
      submitting: false,
      errorData: action.error
    }
  });
}

const deliveryRequestClean = ( state, action ) => {

  return updateObject(state, {
    deliveryRequest: {...state.deliveryRequest,
      selectedAddress: undefined,
      type: "WITH_ADS",
      success: false,
      error: false,
      errorData: undefined,
      estimatedDate: undefined
    }
  });
}

const clearData = (state, action) => {
  return updateObject(state, {
    list: [],
    selected: emptySelected
  });
}

const deliveryLockedToggle = (state, action) => {
  return updateObject(state, {
    deliveryLocked: {
      ...state.deliveryLocked, isOpen: !state.deliveryLocked.isOpen
    }
  });
}

const reducer = ( state = initialState, action ) => {
  switch(action.type) {
    case actionTypes.LOGOUT_SUCCESS: return clearData(state, action);

    case actionTypes.DOCUMENTS_FETCH: return fetchDocuments(state, action);
    case actionTypes.DOCUMENTS_FETCH_SUCCESS: return fetchDocumentsSuccess(state, action);
    case actionTypes.DOCUMENTS_FETCH_ERROR: return fetchDocumentsError(state, action);
    case actionTypes.DOCUMENT_TOGGLE: return toggleDocument(state, action);
    case actionTypes.DOCUMENT_DELETE: return deleteDocument(state, action);
    case actionTypes.DOCUMENT_DELETE_SUCCESS: return deleteDocumentSuccess(state, action);
    case actionTypes.DOCUMENT_DELETE_ERROR: return deleteDocumentError(state, action);
    case actionTypes.DOCUMENT_UPLOAD: return uploadDocument( state, action );
    case actionTypes.DOCUMENT_UPLOAD_PROGRESS: return uploadDocumentProgress( state, action );
    case actionTypes.DOCUMENT_UPLOAD_SUCCESS: return uploadDocumentSuccess( state, action );
    case actionTypes.DOCUMENT_UPLOAD_ERROR: return uploadDocumentError( state, action );
    case actionTypes.DOCUMENT_RELOAD: return reloadDocument( state, action );
    case actionTypes.DOCUMENT_TOGGLE_CONFIRM_DELETE: return toggleConfirmDelete(state, action);

    case actionTypes.DELIVERY_REQUEST_SELECT_ADDRESS: return deliveryRequestSelectAddress(state, action);
    case actionTypes.DELIVERY_REQUEST_SHOW_ADDRESS: return deliveryRequestShowAddress(state, action);
    case actionTypes.DELIVERY_REQUEST_SHOW_PAYMENT_SELECTOR: return deliveryRequestShowPaymentSelector(state, action);
    case actionTypes.DELIVERY_REQUEST_SELECT_PAYMENT: return deliveryRequestSelectPayment(state, action);
    case actionTypes.DELIVERY_REQUEST: return deliveryRequest(state, action);
    case actionTypes.DELIVERY_REQUEST_SUCCESS: return deliveryRequestSuccess(state, action);
    case actionTypes.DELIVERY_REQUEST_ERROR: return deliveryRequestError(state, action);
    case actionTypes.DELIVERY_REQUEST_CLEAN: return deliveryRequestClean(state, action);

    case actionTypes.DELIVERY_LOCKED_TOGGLE: return deliveryLockedToggle(state, action);

    default: return state;
  }
};

export default reducer;
