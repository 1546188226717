import { put, select } from 'redux-saga/effects';
import { globalErrorHandling } from '../../shared/utils';
import { getToken } from './selectors';
import * as actions from '../actions';
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { SharedDocumentsService } from '../../Services';

export function* sharedDocumentSubmitSaga(action) {

  try {
    const sharedDocsEnabled = yield select(state => state.system.system_enable_shared_documents);

    if (!sharedDocsEnabled) {

      yield put(actions.sharedDocumentSubmitError());

      yield put(errorNotification({
        message: action.intl.formatMessage({ id: "sharedDocuments.disabled"}),
        position: 'tc',
        autoDismiss: 0,
      }));

      return;
    }

    const token = yield select(getToken);
    
    const response = yield SharedDocumentsService.share(token, {
      documentId: action.data.id,
      name: action.data.name,
      description: action.data.description,
      category: action.data.category.value,
      university: action.data.university.id
    });

    yield put(actions.sharedDocumentSubmitSuccess());
    yield put(actions.sharedDocumentModalToggle());
    yield put(actions.fetchDocuments());
    yield put(successNotification({
      message: action.intl.formatMessage({ id: "sharedDocuments.modal.message.success"}),
      position: 'tc',
      autoDismiss: 0,
    }));

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.sharedDocumentSubmitError(errorObj));

    yield put(errorNotification({
      message: action.intl.formatMessage({ id: "sharedDocuments.modal.message.error"}),
      position: 'tc',
      autoDismiss: 0,
    }));
  }
}

export function* sharedDocumentFetchSaga(action) {

  try {
    const token = yield select(getToken);
    const page = yield select(state => state.sharedDocuments.currentPage);
    const pageSize = yield select(state => state.sharedDocuments.pageSize);
    const filterData = yield select(state => state.form.sharedDocumentFilterForm.values);
     
    let request = {};
    
    if (filterData !== undefined) {

      request.filter = filterData.filter;

      if (filterData.category) {
        request.category = filterData.category.value;
      }

      if (filterData.university) {
        request.university = filterData.university.id;
      }
    }

    const response = yield SharedDocumentsService.list(token, page, pageSize, request);

    yield put(actions.sharedDocumentsFetchSuccess(response.data));
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.sharedDocumentsFetchError(errorObj));
  }
}

export function* sharedDocumentCopySaga(action) {

  try {
    const token = yield select(getToken);
    
    const response = yield SharedDocumentsService.copy(token, action.id);

    yield put(actions.sharedDocumentsCopySuccess(response.data));
    yield put(actions.fetchDocuments());

    const currentPage = yield select(state => state.sharedDocuments.currentPage);
    yield put(actions.sharedDocumentsFetch(currentPage));

    yield put(successNotification({
      message: action.intl.formatMessage({ id: "sharedDocuments.item.message.copySuccess"}),
      position: 'tc',
      autoDismiss: 0,
    }));
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.sharedDocumentsCopyError(errorObj));

    yield put(errorNotification({
      message: action.intl.formatMessage({ id: "sharedDocuments.item.message.copyError"}),
      position: 'tc',
      autoDismiss: 0,
    }));
  }
}
