import api from '../Api';
import { getToken } from '../shared/utils';

class DeliveryService {

 /**
     * Obtain the list of deliveries for specific page.
     * @param {string} token Security token. 
     * @param {number} page Page to obtain.
     * @param {number} pageSize Page size.
     * @param {object} onlyPendingDeliveries If we need to show only pending deliveries
     */
  static async list(token, page, pageSize, onlyPendingDeliveries) {
    return api.get("/v1/deliveries", {params: {onlyPendingDeliveries: onlyPendingDeliveries, page: page, pageSize: pageSize}, headers: {'X-AUTH-TOKEN': token}});
  }


    /**
     * Obtain specific delivery.
     * @param {string} token 
     * @param {number} deliveryId 
     */
    static async fetch(token, deliveryId) {
        return api.get(`/v1/deliveries/${deliveryId}`, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Obtain all the deliveries.
     * @param {string} token 
     */
    static async fetchAll(token) {
        return api.get("/v1/deliveries", {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Delete specific delivery.
     * @param {string} token 
     * @param {number} deliveryId 
     */
    static async delete(token, deliveryId) {
        return api.delete(`/v1/deliveries/${deliveryId}`, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Change status of specific delivery to "missing".
     * @param {string} token 
     * @param {number} deliveryId 
     */
    static async moveToMissing(token, deliveryId) {
        return api.post(`/v1/deliveries/${deliveryId}/missing`, {}, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Change status of specific delivery to "delivered".
     * @param {string} token 
     * @param {number} deliveryId 
     */
    static async moveToDelivered(token, deliveryId) {
        return api.post(`/v1/deliveries/${deliveryId}/delivered`, {}, {headers: {'X-AUTH-TOKEN': token}});
    }

    static async request(request) {
      return api.post("/v2/delivery/request", request, {headers: {'X-AUTH-TOKEN': getToken()}})
    }

    static async getPricing(documents) {
      return api.post("/v2/deliveries/pricing ", {documents: documents}, {headers: {'X-AUTH-TOKEN': getToken()}})
    }

    static async checkFreePostalcode(addressId) {
      return api.get(`/v2/deliveries/free_postal_code/${addressId}`, {headers: {'X-AUTH-TOKEN': getToken()}})
    }
}

export default DeliveryService;