export const websocketConnection = (email) => {
  return {
    type: "WEBSOCKET:CONNECT",
    payload: {
      url: process.env.REACT_APP_EVENT_SERVER_URL + "/stomp?t=" + btoa(email),
      channels: WS_CHANNELS
    }
  }
}

export const websocketDiscconnect = () => {
  return {
    type: "WEBSOCKET:DISCONNECT"
  }
}

const WS_CHANNELS = [
  "/user/documents/update",
  "/user/updatesWithOperations"
];
