import React from 'react';
import { FormattedMessage } from 'react-intl';

const input = ({
  input, id, label, placeholder, className, inputRef, disabled,
  meta: { touched, error } }) => {

    if (touched && error && className !== "") {
        className += " is-invalid";
    }

  return (
    <div className="form-check">

      <input {...input}
        id={id}
        className={className}
        placeholder={placeholder}
        type="checkbox"
        ref={inputRef}
        disabled={disabled}
      />
      <label className="form-check-label font-weight-bold" htmlFor={id}>{label}</label>
      {touched && (error && <div className="text-danger"><FormattedMessage id={error.id} values={error.values} /></div>)}
    </div>
  );
}

export default input;
