import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { directLogIn } from '../store/actions';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import UserService from '../Services/UserService';
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import AuthService from '../Services/AuthService';
import { getToken, setToken } from '../shared/utils';
import { logger } from '../shared/Logger';

const Activate = (props) => {

  const intl = useIntl();
  const dispatcher = useDispatch();
  const { activationKey } = useParams();
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {

    UserService.activate(activationKey).then(response => {

      dispatcher(successNotification({
        message: intl.formatMessage({ id: "message.global.accountActivated"}, {email: response.data.email}),
        position: 'tc',
        autoDismiss: 10,
      }));

      if (response.data.token) {

        const commonSteps = () => {
          setToken(response.data.token);
          dispatcher(directLogIn(response.data.token));
          setRedirect(true);
        };

        if (getToken()) {
         AuthService.logout().then(response => {
          commonSteps();
         });
        } else {
          commonSteps();
        }
      }

    }).catch(error => {
      logger.error(error);
      setError(true);
    });

  }, []);

  return (
    <div className='d-flex align-items-center h-100 justify-content-center'>
       <Spinner /> 
      { error || redirect ? <Redirect to="/" /> : null}
    </div>
  );
}

export default Activate;
