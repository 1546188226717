import { DEFAULT_PAGE_SIZE, updateObject } from '../../shared/utils';
import * as actionTypes from '../actions/types';

const initialState = {
  total: 0,
  list: [],
  currentPage: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  loading: false,
  sharing: false,
  copying: false,
  modal: {
      isOpen: false,
      isSubmitting: false,
      documentId: undefined,
      name: undefined,
      description: undefined,
      category: undefined,
  },
  preview: {
    isOpen: false,
    url: undefined
  }
};

const clearData = (state, action) => {
  return updateObject(state, initialState);
}

const toggleModal = (state, action) => {
    return updateObject(state, {
        modal: { ...state.modal, 
          isOpen: !state.modal.isOpen,
          documentId: action.id,
          name: action.name,
          description: action.description,
          category: action.category
        }
    });
}

const submit = (state, action) => {
    return updateObject(state, {
        modal: { ...state.modal, isSubmitting: true}
    });
}

const submitted = (state, action) => {
    return updateObject(state, {
        modal: { ...state.modal, isSubmitting: false}
    });
}

const fetch = (state, action) => {
  return updateObject(state, {
    currentPage: action.page,
    loading: true
  });
}

const fetchSuccess = (state, action) => {
  return updateObject(state, {
    total: action.page.total,
    list: action.page.data,
    loading: false
  });
}

const fetchError= (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const copy = (state, action) => {
  return updateObject(state, {
    copying: true
  });
}

const copyFinished = (state, action) => {
  return updateObject(state, {
    copying: false
  });
}

const togglePreview = (state, action) => {
  return updateObject(state, {
      preview: { ...state.preview, isOpen: !state.preview.isOpen, url: action.url}
  });
}

/**
* Redux reducer for shared documents actions.
*/
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS: return clearData(state, action);
    case actionTypes.SHARED_DOCUMENT_TOGGLE: return toggleModal(state, action);
    case actionTypes.SHARED_DOCUMENT_SUBMIT: return submit(state, action);
    case actionTypes.SHARED_DOCUMENT_SUBMIT_ERROR:
    case actionTypes.SHARED_DOCUMENT_SUBMIT_SUCCESS: return submitted(state, action);
    case actionTypes.SHARED_DOCUMENT_FETCH: return fetch(state, action);
    case actionTypes.SHARED_DOCUMENT_FETCH_SUCCESS: return fetchSuccess(state, action);
    case actionTypes.SHARED_DOCUMENT_FETCH_ERROR: return fetchError(state, action);
    case actionTypes.SHARED_DOCUMENT_COPY: return copy(state, action);
    case actionTypes.SHARED_DOCUMENT_COPY_ERROR: 
    case actionTypes.SHARED_DOCUMENT_COPY_SUCCESS: return copyFinished(state, action);
    case actionTypes.SHARED_DOCUMENT_TOGGLE_PREVIEW: return togglePreview(state, action);
    default: return state;
  }
}
