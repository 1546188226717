import React, {Fragment} from "react";
import { injectIntl } from "react-intl";

const AddressItemSimple = ({intl, address, separator = "-"}) => {

    if (address === undefined || address === null) {
        return null;
    }

    let box = "";

    if (address.boxNumber !== null && address.boxNumber !== undefined && address.boxNumber !== "") {
      box = address.boxNumber
      if (typeof "".padStart === "function") {
        box = ("" + box).padStart(4, "0");
      }

      box = ", " + intl.formatMessage({id: "address.item.boxNumber"}, {boxNumber: box});
    }
    const space = (<Fragment>&nbsp;</Fragment>);

    let finalSeparator = separator;
    if (typeof separator === "string") {
      finalSeparator = (<Fragment>{space}{separator}{space}</Fragment>)
    }

    return (
        <Fragment>
            <span>{address.name} {address.surname}</span>
            {finalSeparator}
            <span>{address.streetNumber} {address.street}{box}</span>
            {finalSeparator}
            <span>{address.postalCode} {address.city}</span>
        </Fragment>
    );
};

export default injectIntl(AddressItemSimple);