import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Alert, Spinner } from 'reactstrap';
import { saveProfile } from '../../store/actions';
import { sectionShape } from '../../Shapes';
import Field from './Field'

class Section extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      fieldsValues: {},
      fieldError: {},
    };

    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.validateFields = this.validateFields.bind(this);
  }

  handleNextClick(e) {
    e.preventDefault();

    if (this.validateFields()) {

      const finalStep = this.isFinalStep();

      if (this.props.onSave) {
        this.props.onSave(this.props.section.id, this.state.fieldsValues, finalStep);
      } else {
        this.props.saveProfile(this.props.section.id, this.state.fieldsValues, finalStep);
      }
    }
  }

  componentDidMount() {

    if (this.props.previousChoices !== undefined) {

      const prevChoicesForSection = this.props.previousChoices[this.props.section.id];

      if (prevChoicesForSection !== undefined) {

        const newValues = {};

        Object.keys(prevChoicesForSection).forEach(fieldId => {

          newValues[fieldId] = [];

          prevChoicesForSection[fieldId].forEach(choice => {
            newValues[fieldId].push(choice.id);
          });

        });

        this.setState({
          fieldsValues: newValues,
        });
      }

    }
  }

  validateFields() {
    const { intl, section, lang } = this.props;
    const filledFields = this.state.fieldsValues;
    const { fields } = section;
    const fieldErrors = {}; // Object to store field-specific errors

    // Check all fields for errors
    fields.forEach(field => {
        const selectedChoices = filledFields[field.id] || [];

        const belowMinChoices = selectedChoices.length < field.minChoices;
        const aboveMaxChoices = selectedChoices.length > field.maxChoices;

        let label;
        if (field.hasOwnProperty(`label${lang}`)) {
          label = field[`label${lang}`];
        } else {
          label = field.labelEn;
        }

        // Record errors for each field
        if (belowMinChoices) {
            fieldErrors[field.id] = intl.formatMessage({"id": "profile.section.error.min"}, {min: field.minChoices, "label": label});
        } else if (aboveMaxChoices) {
            fieldErrors[field.id] = intl.formatMessage({"id": "profile.section.error.max"}, {max: field.maxChoices, "label": label});
        }
    });

    // Check if there are any errors
    if (Object.keys(fieldErrors).length > 0) {
        this.setState({ fieldError: fieldErrors }); // Store field-specific errors
        return false;
    }

    // Clear errors if validation passes
    this.setState({ fieldError: {} });
    return true;
}


  isFinalStep() {
    return this.props.index === this.props.totalSections - 1;
  }

  handleFieldChange = (newFieldValue, fieldId) => {

    if (( typeof newFieldValue === "string" && newFieldValue === "")
          || (Array.isArray(newFieldValue) && newFieldValue.length === 0)
          || newFieldValue === undefined || newFieldValue === null) {
      delete this.state.fieldsValues[fieldId];
    } else {
      this.setState(prevState => ({
          fieldsValues: {
              ...prevState.fieldsValues,
              [fieldId]: newFieldValue.length?newFieldValue.map(v => v.id):[newFieldValue.id]
          }
      }));
    }
  }

  render () {

    const { section, index, currentIndex, totalSections, previousChoices, submitting, lang, reward } = this.props;
    const { fieldError  } = this.state;

    const active = index === currentIndex;
    const finalStep = this.isFinalStep();

    if (!active) {
      return null;
    }

    let defaultValues = [];
    if (previousChoices !== undefined && previousChoices[section.id] !== undefined) {
      defaultValues = previousChoices[section.id];
    }

    let sectionTitle;

    if (section.hasOwnProperty(`label${lang}`)) {
      sectionTitle = section[`label${lang}`];
    } else {
      sectionTitle = section.labelEn;
    }

    let errorComponent = null;
    const errorFields = Object.keys(fieldError);
    if (errorFields.length > 0) {

      let errors;
      if (errorFields.length === 1) {
        errors = (
          <div>{fieldError[errorFields[0]]}</div>
        );
      } else {
        const errorItems = Object.entries(fieldError).map(([key, value], index) => {
          return <li key={index}>{value}</li>
        })
        errors = (
          <ul>
            {errorItems}
          </ul>
        );
      }

      

      errorComponent = (
        <Alert color='danger'>
          {errors}
        </Alert>
      );
    }


    return (
      <form>
        <h4>{sectionTitle}</h4>
        {errorComponent}
        {section.fields.map(f =>
          <Field field={f} key={f.id} onChangeHandler={this.handleFieldChange} defaultValues={defaultValues[f.id]} disabled={submitting} />
        )}
        <div className="modal-footer">
          <div className="w-100">
            <FormattedMessage id="profile.section.step" values={{current: index + 1, total: totalSections}} />
          </div>
          <button type="button" className="btn btn-sm btn-primary" onClick={this.handleNextClick} disabled={submitting} data-test={`btn-fill-profile-${finalStep ? "finish": "next"}`}>
              <FormattedMessage id={`profile.section.btn.${finalStep ? "finish": "next"}`} />
              {submitting?<Spinner size="sm" className="ml-2"/>:null}
            </button>
        </div>
      </form>
    );
  }

}

Section.propTypes = {
  index: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  totalSections: PropTypes.number.isRequired,
  section: sectionShape.isRequired,
  reward: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    previousChoices: state.profile.previousChoices,
  };
}

const mapDispatchToProps = { saveProfile }

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Section));
