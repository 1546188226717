import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { fetchUser } from "../../store/actions";
import UserService from "../../Services/UserService";
import { useDispatch } from "react-redux";
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { CheckIcon, CircleIcon } from "../Icons";
import { globalErrorHandling } from "../../shared/utils";
import { logger } from "../../shared/Logger";

const PromoCodeForm = ({styles}) => {

    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const intl = useIntl();
    const dispatcher = useDispatch();

    const { register, clearErrors, handleSubmit, setError, setValue, formState: { submitting, errors } } = useForm({
        mode: "onSubmit"
    });

    useEffect(() => {
    
      if (!showSuccess) {
        setValue("promoCode", "");
      }
  
    }, [showSuccess]);

    const handleOnBlur = (e) => {

      if (errors.promoCode && errors.promoCode.type === "required" && e.currentTarget.value === "") {
        clearErrors("promoCode");
      }
    }

    const { ref: promoCodeRef, ...registerPromoCode } = register("promoCode", { required: true });

    const handleAdd = (data) => {

      setLoading(true);

      UserService.applyPromoCode(data.promoCode).then(response => {

        dispatcher(fetchUser());
        dispatcher(successNotification({
          message: intl.formatMessage({ id: "promo_code.message.success"}),
          position: 'tc',
          autoDismiss: 10,
        }));

        setShowSuccess(true);

        setTimeout(() => setShowSuccess(false), 15000);

      }).catch(error => {
        
        const errorObj = globalErrorHandling(error, "promo_code.message.error");

        logger.error(error, errorObj);

        dispatcher(errorNotification({
          message: intl.formatMessage({ id: errorObj.errorKey}),
          position: 'tc',
          autoDismiss: 0,
        }));

      }).finally(() => {
        setLoading(false);
      });

    };

    return (
      <form onSubmit={handleSubmit(handleAdd)}>
        <button type="submit" style={{display: "none"}}></button>
        <div className='d-flex align-items-center justify-content-between w-100' style={styles}>
          
          <div className='mr-auto d-flex align-items-center'>
              <label className='m-0'><FormattedMessage id="promo_code.title" /></label>
          </div>

          <div className="d-flex align-items-center">
            <div style={{maxWidth: "100px"}}>
              <Input id="promoCode" data-test="field-promo-code" type="text" name="promoCode" placeholder={intl.formatMessage({id: "promo_code.field.code"})}
                  className="form-control form-control-sm d-inline"
                  disabled={showSuccess}
                  invalid={errors.promoCode ? true : false}
                  onBlur={handleOnBlur}
                  innerRef={promoCodeRef}
                  {...registerPromoCode}
                  />
                  {errors.promoCode && errors.promoCode.type === "required" && <p className="text-danger small mb-0"><FormattedMessage id="validations.field.required" /></p>}
                  {errors.promoCode && errors.promoCode.type === "manual" && <p className="text-danger small mb-0">{errors.promoCode.message}</p>}
            </div>
            { showSuccess ? <CircleIcon checked={false} className="ml-1 text-success font-weight-bold" /> :
              <button
                className="btn btn-secondary btn-sm ml-1 px-1"
                type="submit"
                disabled={submitting}
                title={intl.formatMessage({id: "promo_code.apply_button"})}>
                {loading?<Spinner size="sm" className="ml-2" />:<CheckIcon />}
              </button>
            }
            </div>
        </div>
      </form>
    );

};

export default PromoCodeForm;
