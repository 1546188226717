import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ConsentCookie, cookieShape } from './ConsentCookie';

export const ConsentCookies = ({list}) => {

  return (

    <table className="table-responsive table-striped table-bordered table-sm">
      <thead>
        <tr>
          <th><FormattedMessage id="consent.cookiePolicy.cookie.field.domain" /></th>
          <th><FormattedMessage id="consent.cookiePolicy.cookie.field.name" /></th>
          <th><FormattedMessage id="consent.cookiePolicy.cookie.field.expire" /></th>
          <th><FormattedMessage id="consent.cookiePolicy.cookie.field.description" /></th>
          <th><FormattedMessage id="consent.cookiePolicy.cookie.field.more" /></th>
        </tr>
      </thead>
      <tbody>
      {list.map((cookie, index) => {
        return (
          <ConsentCookie key={index} cookie={cookie} />
        );
      })}
      </tbody>
    </table>
  );
}

ConsentCookies.propTypes = {
  list: PropTypes.arrayOf(cookieShape).isRequired
}
