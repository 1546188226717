import * as actionTypes from '../actions/types';
import { updateObject } from '../../shared/utils';

const initialUserState = {
  loading: false,
  error: null,
  user: undefined,
  forgotPassword: {
    open: false,
    loading: false,
    error: undefined,
    redirectToLogin: false
  },
  createAccount: {
    open: false,
    loading: false,
    error: undefined,
    redirectToLogin: false,
    successful: false
  },
  nps: {
    open: false,
    rating: undefined,
    lowRating: false,
    highRating: false,
    submitting: false,
    secondStep: false
  },
  stats: {
    prints: 0,
    pagesPrinted: 0,
    friendsRegistered: 0,
    friendsActivated: 0,
    friendsWithPrints: 0,
    creditsPerFriend: 0,
    creditsPerActivatedFriend: 0,
    creditsPerFriendWithPrints: 0,
    uploads: 0,
    loading: false
  },
  adClickCounter: {
    reward: 25,
    reachedForThisWeek: false,
    maxClicks: 5,
    currentClicks: 0
  }
};

const fetchUser = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const fetchUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: action.user
  });
};

const fetchUserError = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const userUpdate = (state, action) => {
  return updateObject(state, {
    loading: true
  });
}

const userUpdateSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const userUpdateError = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
}

const userForgotPassword = (state, action) => {
  return updateObject(state, {
    forgotPassword: {...state.forgotPassword, loading: true}
  });
}

const userForgotPasswordSuccess = (state, action) => {
  return updateObject(state, {
    forgotPassword: {...state.forgotPassword, loading: false, open: false, redirectToLogin: true}
  });
}

const userForgotPasswordError = (state, action) => {
  return updateObject(state, {
    forgotPassword: {...state.forgotPassword, loading: false, error: action.error }
  });
}

const userForgotPasswordClear = (state, action) => {
  return updateObject(state, {
    forgotPassword: {...state.forgotPassword, loading: false, error: undefined, redirectToLogin: false }
  });
}

const userForgotPasswordToggle = (state, action) => {
  return updateObject(state, {
    forgotPassword: {...state.forgotPassword, open: !state.forgotPassword.open, error: undefined }
  });
}

const userCreateAccount = (state, action) => {
  return updateObject(state, {
    createAccount: {...state.createAccount, loading: true, successful: false }
  });
}

const userCreateAccountSuccess = (state, action) => {
  return updateObject(state, {
    createAccount: {...state.createAccount, loading: false, open: false, redirectToLogin: true, successful: true }
  });
}

const userCreateAccountError = (state, action) => {
  return updateObject(state, {
    createAccount: {...state.createAccount, loading: false, error: action.error, successful: false }
  });
}

const userCreateAccountClear = (state, action) => {
  return updateObject(state, {
    createAccount: {...state.createAccount, loading: false, error: undefined, redirectToLogin: false }
  });
}

const userCreateAccountToggle = (state, action) => {
  return updateObject(state, {
    createAccount: {...state.createAccount, open: !state.createAccount.open, error: undefined }
  });
}

const clearData = (state, action) => {
  return updateObject(state, {
    user: undefined
  });
}

const npsToggle = (state, action) => {

  return updateObject(state, {
    nps: {...state.nps, open: !state.nps.open, rating: undefined, lowRating: false, highRating: false, secondStep: false}
  })
}

const npsRating = (state, action) => {

  return updateObject(state, {
    nps: { ...state.nps, rating: action.rating, lowRating: action.rating <= 6, highRating: action.rating > 8 }
  })
}

const npsNextStep = (state, action) => {
  return updateObject(state, {
    nps: { ...state.nps, secondStep: true }
  })
}

const npsSubmit = (state, action) => {

  return updateObject(state, {
    nps: { ...state.nps, submitting: true }
  });
}

const npsSubmitted = (state, action) => {

  return updateObject(state, {
    nps: { ...state.nps, submitting: false }
  });
}

const statsInit = (state, action) => {
  return updateObject(state, {
    stats: {...state.stats,
      loading: true
    }
  });
}

const statsUpdate = (state, action) => {
  return updateObject(state, {
    stats: { ...state.stats,
      prints: action.data['PRINTS'],
      pagesPrinted: action.data['PAGES_PRINTED'],
      friendsRegistered: action.data['FRIENDS_REGISTERED'],
      friendsActivated: action.data['FRIENDS_ACTIVATED'],
      friendsWithPrints: action.data['FRIENDS_WITH_PRINTS'],

      creditsPerFriend: action.data['CREDITS_PER_REGISTERED_FRIEND'],
      creditsPerActivatedFriend: action.data['CREDITS_PER_ACTIVATED_FRIEND'],
      creditsPerFriendWithPrints: action.data['CREDITS_PER_FRIENDS_WITH_PRINTS'],

      creditsPerFriends: action.data['CREDITS_PER_FRIENDS'],
      creditsPerLevel: action.data['CREDITS_PER_LEVEL'],

      uploads: action.data['UPLOADS'],

      loading: false
    }
  });
}

const statsError = (state, action) => {
  return updateObject(state, {
    stats: {...state.stats,
      loading: false
    }
  });
}

const getAdClickCounterSuccess = (state, action) => {
  return updateObject(state, {
    adClickCounter: {...state.adClickCounter,
      reward: action.data.reward,
      reachedForThisWeek: action.data.reachedForThisWeek,
      maxClicks: action.data.maxClicks,
      currentClicks: action.data.currentClicks
    }
  });
}
const deleteAccountToggle = (state, action) => {
  return updateObject(state, {
    deletingAccountConfirm: true
  });
}

/**
* Redux reducer for user actions.
*/
export default function (state = initialUserState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS: return clearData(state, action);
    case actionTypes.LOGIN_SUCCESS: return fetchUserSuccess(state, action);

    case actionTypes.USER_FETCH: return fetchUser(state, action);
    case actionTypes.USER_FETCH_SUCCESS: return fetchUserSuccess(state, action);
    case actionTypes.USER_FETCH_ERROR: return fetchUserError(state, action);

    case actionTypes.USER_UPDATE: return userUpdate(state, action);
    case actionTypes.USER_UPDATE_SUCCESS: return userUpdateSuccess(state, action);
    case actionTypes.USER_UPDATE_ERROR: return userUpdateError(state, action);

    case actionTypes.USER_FORGOT_PASSWORD: return userForgotPassword(state, action);
    case actionTypes.USER_FORGOT_PASSWORD_SUCCESS: return userForgotPasswordSuccess(state, action);
    case actionTypes.USER_FORGOT_PASSWORD_ERROR: return userForgotPasswordError(state, action);
    case actionTypes.USER_FORGOT_PASSWORD_TOGGLE: return userForgotPasswordToggle(state, action);
    case actionTypes.USER_FORGOT_PASSWORD_CLEAR: return userForgotPasswordClear(state, action);

    case actionTypes.USER_CREATE_ACCOUNT: return userCreateAccount(state, action);
    case actionTypes.USER_CREATE_ACCOUNT_SUCCESS: return userCreateAccountSuccess(state, action);
    case actionTypes.USER_CREATE_ACCOUNT_ERROR: return userCreateAccountError(state, action);
    case actionTypes.USER_CREATE_ACCOUNT_TOGGLE: return userCreateAccountToggle(state, action);
    case actionTypes.USER_CREATE_ACCOUNT_CLEAR: return userCreateAccountClear(state, action);

    case actionTypes.USER_NPS_TOGGLE: return npsToggle(state, action);
    case actionTypes.USER_NPS_RATING: return npsRating(state, action);
    case actionTypes.USER_NPS_SUBMIT: return npsSubmit(state, action);
    case actionTypes.USER_NPS_SUBMITTED: return npsSubmitted(state, action);
    case actionTypes.USER_NPS_NEXT: return npsNextStep(state, action);

    case actionTypes.USER_STATS: return statsInit(state, action);
    case actionTypes.USER_STATS_SUCCESS: return statsUpdate(state, action);
    case actionTypes.USER_STATS_ERROR: return statsError(state, action);

    case actionTypes.USER_GET_AD_CLICK_COUNTER_SUCCESS: return getAdClickCounterSuccess(state, action);

    default: return state;
  }
};
