import { updateObject } from '../../shared/utils';
import * as actionTypes from '../actions/types';

const initialState = {
    system_enable_upload: true,
    system_enable_home_delivery: false,
    system_enable_home_delivery_promo_code: false,
    system_free_offline: false,
    system_enable_partner_emails: false,
    system_enable_leaderboard: false,
    system_enable_ad_clicks: false,
    system_back_online: null,

    features: {
      DOCUMENT_UPLOAD: false,
      DELIVERY_SERVICE: false,
      DELIVERY_PREMIUM_SERVICE: false
    }
};

const metadataFetchSuccess = (state, action) => {
  return updateObject(state, {
    [action.key]: action.value
  });
}

const featuresEnabledFetchSuccess = (state, action) => {

  const result = action.result;

  let features = state.features;

  for(let r of Object.keys(result)) {

    features = {...features, ...{[r]: result[r]}}
  }

  return updateObject(state, {
    features: features
  });
}

/**
* Redux reducer for system actions.
*/
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.METADATA_FETCH_SUCCESS: return metadataFetchSuccess(state, action);
    case actionTypes.FEATURES_ENABLED_FETCH_SUCCESS: return featuresEnabledFetchSuccess(state, action);
    default: return state;
  }
}
