import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { DEFAULT_MAX_LENGTH } from '../../shared/validations';
import { AddonField, Field } from './CustomFields';
import { EMAIL_PATTERN } from '../../shared/utils';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../store/sagas/selectors';

const LoginForm = ({id, onSubmit, disabled}) => {

  const [showPass, setShowPass] = useState(false);
  const lang = useSelector(getLanguage);

  const intl = useIntl();

  const { control, register, reset, handleSubmit, setValue, setFocus, setError, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const togglePass = () => {
    setShowPass(!showPass);
  };
  

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)} data-test="form-login">
      <button type="submit" style={{display: "none"}} disabled={submitting}></button>

      <Field field="email" labelKey="login.email.label" placeholder={intl.formatMessage({id: "login.email.label"})}
        options={{ required: true, maxLength: DEFAULT_MAX_LENGTH, pattern: {value: EMAIL_PATTERN, message: "email"} }}
        errors={errors} register={register} disabled={submitting || disabled} />
      <AddonField field="password" type={showPass ? "text" : "password"} labelKey="login.password.label"  placeholder={intl.formatMessage({id: "login.password.label"})}
        options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register}
        iconClassName={showPass ? "fa fa-eye": "far fa-eye-slash"} onIconClick={togglePass} disabled={submitting || disabled} />

    </Form>
  );
}

export default LoginForm;
