import React from 'react';
import PropTypes from 'prop-types';

export const DocumentIcon = (props) => {

    let icon;
    let color;

    if (props.type) {

        switch (props.type) {
            case "doc":
            case "docx":
                icon = "fa-file-word";
                color = "#2B579A";
                break;
            default:
                icon = "fa-file-pdf";
                color = "#FF0000";
                break;
        }

    }

    return (
        <i className={`far ${icon}`} style={{fontSize: props.size, "color": color}}></i>
    );
};

DocumentIcon.propTypes = {
  size: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["pdf", "doc", "docx"]).isRequired,
};