import * as actionTypes from './types';

export const sharedDocumentModalToggle = (data) => {

  if (data === undefined) {
    return {
      type: actionTypes.SHARED_DOCUMENT_TOGGLE
    };
  }

  return {
    type: actionTypes.SHARED_DOCUMENT_TOGGLE,
    id: data.id,
    name: data.name.replace(/\.[^/.]+$/, "")
  }
};

export const sharedDocumentSubmit = (data, intl) => {
    return {
      type: actionTypes.SHARED_DOCUMENT_SUBMIT,
      data: data,
      intl: intl
    }
};

export const sharedDocumentSubmitSuccess = () => {
    return {
      type: actionTypes.SHARED_DOCUMENT_SUBMIT_SUCCESS
    }
};

export const sharedDocumentSubmitError = () => {
    return {
      type: actionTypes.SHARED_DOCUMENT_SUBMIT_ERROR
    }
};

export const sharedDocumentsFetch = (page = 0) => {
    return {
      type: actionTypes.SHARED_DOCUMENT_FETCH,
      page: page
    }
};

export const sharedDocumentsFetchSuccess = (page) => {
    return {
      type: actionTypes.SHARED_DOCUMENT_FETCH_SUCCESS,
      page: page
    }
};

export const sharedDocumentsFetchError = () => {
    return {
      type: actionTypes.SHARED_DOCUMENT_FETCH_ERROR
    }
};

export const sharedDocumentsCopy = (id, intl) => {
  return {
    type: actionTypes.SHARED_DOCUMENT_COPY,
    id: id,
    intl: intl
  }
};

export const sharedDocumentsCopyError = () => {
  return {
    type: actionTypes.SHARED_DOCUMENT_COPY_ERROR
  }
};

export const sharedDocumentsCopySuccess = () => {
  return {
    type: actionTypes.SHARED_DOCUMENT_COPY_SUCCESS
  }
};

export const sharedDocumentsTogglePreview = (url = "") => {
  return {
    type: actionTypes.SHARED_DOCUMENT_TOGGLE_PREVIEW,
    url: url
  }
};