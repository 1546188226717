import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

export const SubmitConfirmation = ({ open, handleClose, handleConfirm }) => {

  return (
      <Modal data-test="modal-confirm-ai-quiz-submit" isOpen={open} toggle={handleClose} centered={true}>
        <ModalHeader toggle={handleClose}>
          <FormattedMessage id={"ai.quiz.submit.header"}/>
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id={"ai.quiz.submit.body"}/>
        </ModalBody>
        <ModalFooter>
          <Button data-test="modal-confirm-flashcards-delete-ok" color="danger" onClick={() => handleConfirm()} >
            <FormattedMessage id="confirm.btn.ok" />
          </Button>
          &nbsp;
          <Button color="secondary" onClick={handleClose} >
            <FormattedMessage id="btn.cancel" />
          </Button>
        </ModalFooter>
      </Modal>
  );
}
