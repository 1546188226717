import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { deliveryRequestClean, fetchAddresses, fetchDocuments } from "../../store/actions";

import './PrintForFree.css';
import { isNumberOfPagesOverMaximum, scrollTo } from "../../shared/utils";
import { useMixpanel } from "../../contexts/Mixpanel";
import DeliveryRequestAccordion from "../Deliveries/DeliveryRequestAccordion";
import { GlobalError } from "../Messages/GlobalError";
import { ConfirmationIcon, WarningIcon } from "../Icons";
import { DELIVERY_START, DELIVERY_REQUEST_CLOSED } from "../../shared/EventsNames";
import { error } from "react-notification-system-redux";
import { Error } from "../Messages/Error";
import { useGlobal } from "../../contexts/GlobalContext";

import { BulletIcon } from "../Icons";
import { PrintAtCopyShop } from "./PrintAtCopyShop";


const PrintForFree = ({isOpen, toggle}) => {

  const [type, setType] = useState();

  const deliverySubmitting = useSelector((state) => state.documents.deliveryRequest.submitting);
  const deliverySuccess = useSelector((state) => state.documents.deliveryRequest.success);
  const docs = useSelector(state => state.documents.list);
  const selected = useSelector(state => state.documents.selected);
  const enabled = useSelector(state => state.system.system_enable_home_delivery);

  const mixpanel = useMixpanel();
  const dispatcher = useDispatch();

  const { maxPages } = useGlobal();
  const maxPageError = isNumberOfPagesOverMaximum(docs, selected.documents, maxPages);
  const isDeliveryDisabled = !enabled || maxPageError;
  const internalToggle = () => {

    if (deliverySubmitting) {
      return;
    }

    toggle();
  }

  const onCopyShopClick = () => {
    setType("COPYSHOP");
  }

  const onDeliveryClick = () => {
    if (isDeliveryDisabled) {
      return;
    }
    setType("DELIVERY");
  }

  const onCopyShopToggle = () => {
    window.location="/copyshop"
  };

  const onBackClick = () => {
    trackCloseDeliveryRequest();
    setType(undefined);
  }

  useEffect(() => {

    if (type == "DELIVERY") {
      dispatcher(fetchAddresses());
      mixpanel.track(DELIVERY_START);
    }

  }, [type]);

  useEffect(() => {

    if (isOpen) {
      setType(undefined);
    } else if (!isOpen && type === "DELIVERY") {
      dispatcher(fetchDocuments());
      dispatcher(deliveryRequestClean());
    }

  }, [isOpen]);

  let errorContent = null;

  if (enabled === false) {
    errorContent = (
      <GlobalError error={{errorKey: "deliveries.request.message.disabled"}} />
    );
  } else if (maxPageError) {
    errorContent = (
      <GlobalError error={{errorKey: "deliveries.request.message.error.maxPages", errorParams:{max: maxPages}}} />
    );
  }

  const trackCloseDeliveryRequest = () => {
    if (type === 'DELIVERY') {
      mixpanel.track(DELIVERY_REQUEST_CLOSED);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={internalToggle} onClosed={trackCloseDeliveryRequest} className="print-for-free">
      <ModalHeader>
        <FormattedMessage id="documents.printForFree.title" />
        {type === "COPYSHOP" ? (<Fragment> - <FormattedMessage id="documents.printForFree.cs.title" /></Fragment>) : null}
        {type === "DELIVERY" ? (<Fragment> - <FormattedMessage id="documents.printForFree.delivery.title" /></Fragment>) : null}
      </ModalHeader>
      <ModalBody className={type === undefined ? (errorContent ? "pb-3" : "pb-5") : null}>

        { type === undefined ? 
          <div>
            
            <div className='mb-4'>
              <FormattedMessage id="documents.printForFree.choose" />
            </div>
            <div className='d-flex align-items-stretch justify-content-between w-100 text-white'>
              
              <div className="bg-primary rounded p-3 custom-button" onClick={onCopyShopClick} role='button' data-test="copyshop-button">
                <div className='mb-3 title'>
                  <FormattedMessage id="documents.printForFree.cs" />
                </div>
                <div className="body">
                  <FormattedMessage id="documents.printForFree.cs.options" values={{
                    breakLine: (<div className='mt-1' />),
                    ul: (...chunks) => (<ul className='mb-0' style={{paddingInlineStart: "1rem"}}>{chunks}</ul>),
                    li: (text) => (<li>{text}</li>)
                  }} />
                </div>
              </div>

              <div className={`bg-primary rounded p-3 custom-button${isDeliveryDisabled ? " disabled": ""}`} onClick={onDeliveryClick} role='button' data-test="delivery-button">
                <div className='mb-3 title'>
                  <FormattedMessage id="documents.printForFree.delivery" />
                </div>
                <div className="body">
                  <FormattedMessage id="documents.printForFree.delivery.options" values={{
                    breakLine: (<div className='mt-1' />),
                    ul: (...chunks) => (<ul className='mb-0' style={{paddingInlineStart: "1rem"}}>{chunks}</ul>),
                    li: (text) => (<li>{text}</li>)
                  }} />
                </div>
              </div>

            </div>

            {errorContent ? (
              <div className="d-flex align-items-stretch justify-content-around w-100">
                <div style={{width: "40%"}}></div>
                <div className="small mt-2" style={{width: "40%"}}>
                  {errorContent}
                </div>
              </div>
            ) : null }

          </div> : null }

          {type === "COPYSHOP" ? <PrintAtCopyShop /> : null }
          {type === "DELIVERY" ? <PrintWithDelivery /> : null }

      </ModalBody>
      { type !== undefined && !deliverySuccess ? 
        <ModalFooter className={'d-flex justify-content-between'}>
          <Button color="light" onClick={onBackClick} disabled={deliverySubmitting}>
            <FormattedMessage id={type === "DELIVERY" ? "btn.cancel" : "btn.back"} />
          </Button>
          {type === "COPYSHOP" ? 
            <Button color="primary" data-test='copyshop-confirm' onClick={onCopyShopToggle}>
              <FormattedMessage id="documents.printForre.cs.details.btn" />
            </Button>
          : null }
        </ModalFooter>
      : null }
    </Modal>
  );
}

export default PrintForFree;

const PrintWithDelivery = () => {

  const intl = useIntl();
  
  
  const submitting = useSelector((state) => state.documents.deliveryRequest.submitting);
  const success = useSelector((state) => state.documents.deliveryRequest.success);
  const showError = useSelector((state) => state.documents.deliveryRequest.error);
  const error = useSelector((state) => state.documents.deliveryRequest.errorData);

  const {reloadConfig } = useGlobal();

  useEffect(() => {

    if (success === true) {
      reloadConfig();
    }

  }, [success]);

  let content = (
    <DeliveryRequestAccordion />
  );
  
  let errorContent = null;

  if (showError) {

    if (error === undefined || !error.errorCode) {
      errorContent = (
        <div className="text-center">
          <WarningIcon className="pb-3" />
          <div className="pb-2 font-weight-bold">
            <FormattedMessage id="deliveries.request.message.error" />
          </div>
        </div>
      );
    } else {
      errorContent = (
        <GlobalError error={{errorKey: "errors.api.code." + error.errorCode}} defaultMessage={intl.formatMessage({id: "deliveries.request.message.error"})} />
      );
    }

  } else if (submitting === true) {
    content = (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    );
    errorContent = null;
  } else if (success === true) {
    content = (
      <div className="text-center" data-test="msg-success-delivery-request">
        <ConfirmationIcon className="pb-3" />
        <div className="pb-2 font-weight-bold">
          <FormattedMessage id="deliveries.request.message.success" />
        </div>
      </div>
    );
    errorContent = null;
  }

  return (
    <Fragment>
        {errorContent}
        {content}
    </Fragment>
  );
};
