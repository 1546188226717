import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const LeaderboardHeader = ({ activeType, onClickPrints, onClickFriends }) => {

    const activeClasses = "btn btn-secondary btn-sm text-white";

    return (
        <React.Fragment>
            <div className="mb-3">
                <div className="d-inline-block w-50 text-center">
                    <a className={`text-decoration-none ${activeType === "PRINTS"?activeClasses:null}`} href="#leaderboardMorePagesPrinted"
                    onClick={onClickPrints}>
                        <FormattedMessage id="leaderboard.prints" />
                    </a>
                </div>
                <div className="d-inline-block w-50 text-center">
                    <a className={`text-decoration-none ${activeType === "FRIENDS"?activeClasses:null}`} href="#leaderboardMoreFriends"
                    onClick={onClickFriends}>
                        <FormattedMessage id="leaderboard.friends" />
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
}

LeaderboardHeader.propTypes = {
    activeType: PropTypes.oneOf(["PRINTS", "FRIENDS"]),
    onClickPrints: PropTypes.func.isRequired,
    onClickFriends: PropTypes.func.isRequired
}

export default LeaderboardHeader;