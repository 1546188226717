import React, { useEffect, useState } from "react";
import { useMixpanel } from "../../contexts/Mixpanel";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Rating from '@material-ui/lab/Rating';
import { AI_NPS } from "../../shared/EventsNames";
import NpsService from "../Services/NpsService";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import './ApiNps.css'

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3rem',
    borderTop: 'solid 1px var(--darkblue-color)',
    paddingTop: '1rem', 
    marginTop: '0.4rem'
  },
  label: {
    marginBottom: 0,
  }
});

export const AiNps = ({type}) => {
  const mixpanel = useMixpanel();
  const [open, setOpen] = useState(true)
  const [hover, setHover] = useState(-1)
  const classes = useStyles();

  const handleSubmit = (value) => {
    mixpanel.track(AI_NPS, {type: type, value: value})
    setOpen(false)
  }

  return (
      <Modal data-test="modal-ai-nps" isOpen={open} centered={true}>
        <ModalBody>
          <Typography variant="body1">
            <FormattedMessage id={`ai.nps`} values={{service: type}}/>
          </Typography>
          <div className={classes.root}>
            <Box mr={2} mb={1}>
              <FormattedMessage id={"nps.not_likely"}/>
            </Box>
            <Rating
              className={classes.label}
              name="hover-feedback"
              size={"large"}
              precision={0.5}
              onChange={(event, newValue) => {
                handleSubmit(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              style={{marginBottom: 0}}
            />
            <Box ml={2} mb={1}>
              <FormattedMessage id={"nps.likely"}/>
            </Box>
          </div>
        </ModalBody>
      </Modal>
  );

}
