import React, { Fragment, useState, useEffect } from "react";
import {  FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Card, CardHeader, CardBody, Button, Fade, Alert } from "reactstrap";

import { DELIVERY_TYPE_WITH_ADS } from '../../shared/utils';
import { DELIVERY_ADDRESS_SELECTED, DELIVERY_SELECT_ADDRESS } from '../../shared/EventsNames';
import { addressFormToggle, deliveryRequest } from '../../store/actions';

import AddressSelect from '../Address/AddressSelect';
import AddressItem from '../Address/AddressItem';
import AddressItemSimple from '../Address/AddressItemSimple';

import RequestSummary from "./RequestSummary";
import DeliveryDiscount from './DeliveryDiscount';

import { useMixpanel } from '../../contexts/Mixpanel';
import { DeliveryService } from "../../Services";
import Moment from "react-moment";
import DeliveryPaymentMethods from "./DeliveryPaymentMethods";

/**
 * Component to process all delivery request steps with vertical layout like an "accordion".
 * Note: Alternative version can be found at deliveryRequest.js
 */
const DeliveryRequestAccordion = () => {

  const intl = useIntl();
  const lang = intl.locale;

  // Previous data from context
  const deliveries = useSelector((state) => state.documents.deliveryRequest.deliveries);
  const selected = useSelector((state) => state.documents.selected);
  const selectedAddress = useSelector((state) => state.documents.deliveryRequest.selectedAddress);
  const documents = useSelector((state) => state.documents.list.filter(doc => state.documents.selected.documents.includes(doc.id)));
  const addresses = useSelector((state) => state.address.list);
  // Logged user
  const user = useSelector((state) => state.user.user);

  // Internal state
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState();
  const [discountCode, setDiscountCode] = useState();
  const [pricingConfig, setPricingConfig] = useState(undefined);
  const [freePostalCode, setFreePostalCode] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (selectedAddress) {
      setAddress(selectedAddress)
    }
  }, [selectedAddress])
  
  useEffect(() => {

    if (address !== undefined) {
      return;
    }

    let mainAddress = undefined;

    if (addresses.length === 1) {
      mainAddress = addresses[0]
    } else {
      const mainAddresses = addresses.filter((a) => a.main === true);
      if (mainAddresses.length !== 0) {
        mainAddress = mainAddresses[0];
      }
    }

    setAddress(mainAddress);
    
  }, [address, addresses]);

  useEffect(() => {

    DeliveryService.getPricing(selected.documents).then(response => {
      setPricingConfig(response.data);
    }).catch(error => {
      setPricingConfig(null);
    });

  }, []);

  // Event tracker
  const mixpanel = useMixpanel();

  // Action launcher
  const dispatcher = useDispatch();

  if (pricingConfig === null) {
    return (
      <div><FormattedMessage id="deliveries.request.message.error.noConfig" /></div>
    );
  }

  let pricePerPackageWithAds = pricingConfig?.price;
  let total = pricePerPackageWithAds;

  if (freePostalCode === true || discountCode) {
    total = 0;
  }

  let isWithPayment = total !== 0;

  const requestReady = address !== undefined;

  const openOverviewStep = () => {
    setStep(1);
  };

  const openAddressStep = () => {

    setStep(2);

    mixpanel.track(DELIVERY_SELECT_ADDRESS, {type: DELIVERY_TYPE_WITH_ADS});
  };

  const openCheckoutStep = () => {
    if (address === undefined) {
        return;
    }

    setSubmitting(false);

    DeliveryService.checkFreePostalcode(address.id).then(response => {
      setFreePostalCode(response.data.free);
    })

    setStep(3);
  };

  const openFourthStep = () => {
    setStep(4);
  }

  const onAddressSelect = (address) => {
    setAddress(address);
    mixpanel.track(DELIVERY_ADDRESS_SELECTED, {type: DELIVERY_TYPE_WITH_ADS});
  };

  const onNewAddress = () => {
    dispatcher(addressFormToggle(true));
  }

  const onDiscount = (discountData) => {
    setDiscountCode(discountData.promoCode);
  };

  const onRequest = () => {

    if (!submitting) {
      setSubmitting(true);

      const request = {
        addressId: address.id,
        documents: selected.documents,
        type: DELIVERY_TYPE_WITH_ADS,
        paymentType: "NONE",
        discountCode: discountCode
      };

      dispatcher(deliveryRequest(request));
    }
  };

  const renderSelectedAddress = () => {

    if (address === undefined || address === null) {
      return null;
    }

    return (
      <div className="mb-2">
        <AddressItem item={address} allowDelete={false} allowEdit={false} />
      </div>
    );
  };

  let steps = (
    <Fragment>
      <CustomCard index={1} currentIndex={step} headerTextId="deliveries.request.overview.title" toggle={openOverviewStep}>
              
          <RequestSummary documents={documents} docsToShow={5} />

          <div className="mt-2"></div>

          {pricingConfig?.nextFree ? <Alert color="info" className="mt-2 mb-0 p-2 small"><FormattedMessage id="deliveries.request.overview.tip.pages" values={{pages: pricingConfig.nextFree, pages2: pricingConfig.nextFreeMax}} /></Alert> : null }

          <ButtonToolbar onNextClick={openAddressStep} nextDataTest="btn-delivery-request-next-to-address"/>
      </CustomCard>

      <CustomCard index={2} currentIndex={step} headerTextId="deliveries.request.address.title" style={{"overflow": "visible"}} toggle={openAddressStep}>
          {renderSelectedAddress()}
          <AddressSelect addresses={addresses} onSelect={onAddressSelect} onNewAddress={onNewAddress}/>

          {pricingConfig?.estimatedDate ? <Alert color="info" className="mt-2 mb-0 p-2 small"><FormattedMessage id="deliveries.request.address.shippingTime" values={{
            date1: <Moment parse="YYYY-MM-DD" format="ddd DD MMMM" locale={lang}>{pricingConfig.estimatedDate}</Moment>,
            date2: <Moment parse="YYYY-MM-DD" format="ddd DD MMMM" locale={lang} add={{days: 2}}>{pricingConfig.estimatedDate}</Moment>
          }} /></Alert> : null}

          <ButtonToolbar onNextClick={openCheckoutStep} disabled={address === undefined} back={true} onBackClick={openOverviewStep} nextDataTest="btn-delivery-request-next-to-recap"/>
      </CustomCard>
      
      <CustomCard index={3} currentIndex={step} headerTextId="deliveries.request.checkout.title" toggle={openFourthStep} selectable={requestReady}>
            

          <div className="container-fluid border mb-3">

            <div className="row pb-1 pt-1 border-bottom">
                <div className="col font-weight-bold">
                    <FormattedMessage id="deliveries.request.address.title" />
                </div>
            </div>
            <div className="row pb-1 pt-1 border-bottom">
                <div className="col">
                  <AddressItemSimple address={address} separator={<br />}  />
                </div>
            </div>

            {pricingConfig?.estimatedDate ? <div className="row pb-1 pt-1 border-bottom">
                <div className="col-6 font-weight-bold">
                    <FormattedMessage id="deliveries.request.checkout.deliveryTime" />
                </div>
                <div className="col-6 text-right">
                  <Moment parse="YYYY-MM-DD" format="ddd DD.MM" locale={lang}>{pricingConfig.estimatedDate}</Moment> - <Moment parse="YYYY-MM-DD" format="ddd DD.MM" locale={lang} add={{days: 2}}>{pricingConfig.estimatedDate}</Moment>
                </div>
            </div> : null}

            <div className="row pb-1 pt-1">
                <div className="col-6 font-weight-bold">
                    <FormattedMessage id="deliveries.request.overview.documents.credits" />
                </div>
                <div className="col-6 text-right">
                {selected.pages}
                </div>
            </div>
          </div>

          <PriceInformation
            amountOfDeliveries={1} 
            pricePerDelivery={pricePerPackageWithAds}
            pricePerPages={0}
            total={total}
            freePostalCode={freePostalCode}
            discount={discountCode !== undefined ? pricePerPackageWithAds : 0}/>

          { isWithPayment ? <div className="mt-3 py-2 px-3 border small">
            <FormattedMessage id="deliveries.request.paymentMethods" />
            <DeliveryPaymentMethods />
          </div> : null }

          <Fade in={pricePerPackageWithAds !== 0 && !freePostalCode} unmountOnExit={true} baseClassActive="show mt-3">
            <DeliveryDiscount apply={onDiscount} />
          </Fade>

          <ButtonToolbar onNextClick={onRequest}
              textId={isWithPayment?"deliveries.request.btn.pay":"deliveries.request.btn.order"}
              disabled={address === undefined || submitting} back={true} onBackClick={openAddressStep} nextDataTest="btn-delivery-request-order" />
      </CustomCard>
    </Fragment>
  );

  return (
    <div className="accordion">
      <Fade>{steps}</Fade>    
    </div>
  );
};

const ButtonToolbar = ({disabled = false, back = false, onNextClick, onBackClick, textId = "deliveries.request.btn.next", tooltip, nextDataTest, backDataTest}) => {

  let backButton = (
    <Button color="light" onClick={onBackClick} data-test={backDataTest}><FormattedMessage id="btn.back" /></Button>
  );

  if (!back) {
    backButton = null;
  }

  return (
    <div className={`d-flex mt-3 ${back === true?"justify-content-between align-items-end":"justify-content-end"}`}>
      {backButton}
      <Button color="primary" onClick={onNextClick} disabled={disabled} title={tooltip} data-test={nextDataTest}>
        <FormattedMessage id={textId} />
      </Button>
    </div>
  );
};

const CustomCard = ({index, currentIndex, headerTextId, children, style, hidden = false, toggle, selectable = true}) => {

  if (hidden === true) {
    return null;
  }

  const selected = index === currentIndex;

  if (selected || selectable !== true) {
    toggle=null;
  }

  return (
    <Card style={style} className={selected?"card-secondary nohover":null}>
      <CardHeader className={`card-header-sm`} style={selected ? null : {borderBottom: "none"}}>
        <FormattedMessage id={headerTextId} />
      </CardHeader>
      <Collapse isOpen={selected}>
        <CardBody>
          {children}
        </CardBody>
      </Collapse>
    </Card>
  );

};

const PriceInformation = ({amountOfDeliveries, pricePerDelivery, pricePerPages, total, freePostalCode, discount}) => {

  let pageContent = (
    <span><FormattedNumber value={pricePerPages} minimumFractionDigits={2} maximumFractionDigits={2} /> €</span>
  );

  if (pricePerPages === 0) {
    pageContent = (
      <span className="font-weight-bold text-uppercase">
        <FormattedMessage id="global.words.free" />
      </span>
    );
  }


  let deliveryContent = (
    <span>{amountOfDeliveries > 1?`${amountOfDeliveries} x `:""}<FormattedNumber value={pricePerDelivery} minimumFractionDigits={2} maximumFractionDigits={2} /> €</span>
  );

  let freePostageMessage = null

  if (freePostalCode) {
    deliveryContent = <span><FormattedNumber value={0} minimumFractionDigits={2} maximumFractionDigits={2} /></span>
    freePostageMessage =<div className="mt-2 ml-1"><span className={'text-muted small'}><FormattedMessage id="deliveries.request.message.freePostage" /></span></div>
  }

  if (pricePerDelivery === 0) {
      deliveryContent = (
        <span className="font-weight-bold text-uppercase">
          {amountOfDeliveries > 1?`${amountOfDeliveries} x `:""}<FormattedMessage id="global.words.free" />
        </span>
      );
  }
  

  let totalContent = (
      <span><FormattedNumber value={total} minimumFractionDigits={2} maximumFractionDigits={2} /> €</span>
  );

  if (total === 0) {
      totalContent = (
          <span className="font-weight-bold text-uppercase">
              <FormattedMessage id="global.words.free" />
          </span>
      );
  }

  let discountData = null;

  if (discount && discount !== 0) {
      discountData = (
        <div className="row">
            <div className="col-6">
              <FormattedMessage id="deliveries.request.generic.discount" />
            </div>
            <div className="col-6 text-right">
              <span className="text-primary">- <FormattedNumber value={discount} minimumFractionDigits={2} maximumFractionDigits={2} /> €</span>
            </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div className="container-fluid border-left border-right border-bottom">
        <div className="row pb-1 pt-1 border-top">
          <div className="col font-weight-bold">
            <FormattedMessage id="deliveries.request.generic.price" />
          </div>
        </div>
        <div className="row pb-1 pt-1 border-top">
          <div className="col-6">
            <FormattedMessage id="deliveries.request.generic.printing" />
          </div>
          <div className="col-6 text-right">
            {pageContent}
          </div>
        </div>
        <div className="row pb-1 pt-1 border-top">
          <div className="col-6">
            <FormattedMessage id="deliveries.request.generic.shipping" />
          </div>
          <div className="col-6 text-right">
            {deliveryContent}
          </div>
        </div>
        {discountData}
        <div className="row pb-1 pt-1 border-top border-primary">
          <div className="col-6">
            <FormattedMessage id="deliveries.request.generic.total" />
          </div>
          <div className="col-6 text-right">
            {totalContent}
          </div>
        </div>
      </div>
      {freePostageMessage}
    </Fragment>
  );
};

export default DeliveryRequestAccordion;
