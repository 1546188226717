import React from 'react';
import { useIntl } from 'react-intl';
import { DeliveryList } from '../Components/Deliveries';
import { Helmet } from "react-helmet";

import './DeliveriesPage.css';
import { PrivateTemplate } from '../Templates';

const DeliveriesPage = (props) => {

  const intl = useIntl();

  return (
    <PrivateTemplate>
      <Helmet>
          <title>Zerocopy - {intl.formatMessage({id: "deliveries.list.title"})}</title>
      </Helmet>

      <div className="container-fluid page-in">

        <div className="row">
          <div className="col-12">
            <DeliveryList  />
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
}

export default DeliveriesPage;
