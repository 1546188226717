import PropTypes from 'prop-types';

export const addressShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  streetNumber: PropTypes.string.isRequired,
  boxNumber: PropTypes.string,
  postalCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired
});
