import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { Field } from "../Form/CustomFields";
import { DEFAULT_MAX_LENGTH } from "../../shared/validations";
import { DocumentService } from "../../Services";
import { error as errorNotification } from 'react-notification-system-redux';
import { MAX_FILE_SIZE, globalErrorHandling } from "../../shared/utils";
import './DocumentProblem.css';

const genericError = "document.reportProblem.solution.default";

const DocumentProblem = ({isOpen, toggle, doc}) => {

  const [submitting, setSubmitting] = useState(false);
  const [errorReported, setErrorReported] = useState(doc?.withProblems);

  const intl = useIntl();
  const dispatcher = useDispatch();


  useEffect(() => {
    setSubmitting(false);
  }, [isOpen]);

  useEffect(() => {
    doc.withProblems = errorReported;
  }, [errorReported]);

  const submitProblem = (data) => {
    
    setSubmitting(true);

    DocumentService.reportProblem(doc.id, data.problem)
      .then(response => {
        
        setErrorReported(true);

      }).catch(error => {

        const errorObj = globalErrorHandling(error, "document.reportProblem.message.error");

        let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
        if (msg === errorObj.errorKey) {
          msg = intl.formatMessage({ id: "document.reportProblem.message.error"});
        }
        
        dispatcher(errorNotification({
          message: msg,
          position: 'tc',
          autoDismiss: 0,
        }));

      }).finally(() => {
        setSubmitting(false);
      });
    
  };

  const internalToggle = () => {
    
    if (submitting) {
      return;
    }

    toggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={internalToggle}>
      <ModalHeader toggle={internalToggle}>
        <FormattedMessage id="document.reportProblem.title" />
      </ModalHeader>
      <ModalBody className="report-problem">
        {doc?.errors?.map(mapErrors)
          .reduce(removeDuplicatedErrors, [])
          .filter((item, index, array) => { return array.length == 1 || array.length > 1 && item.error !== genericError  })
          .map((item, index) => <ErrorItem key={index} error={item.error} solution={item.solution} params={item.params} />)}
        {doc.type !== "pdf" && (!doc?.errors || doc.errors.length == 0) ? <ErrorItem error="document.reportProblem.solution.wordToPdf" solution="document.reportProblem.solution.wordToPdf.solution" /> : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div className="flex-grow-1">
          {errorReported ?  <ErrorReported /> : <ErrorForm onSubmit={submitProblem} isSubmitting={submitting} /> }
        </div>
      </ModalFooter>
    </Modal>
  );

};

export default DocumentProblem;

const ErrorItem = ({error, solution, params}) => {

  const intl = useIntl();

  let solutionParams = {
    faq: (text) => (<a href="https://zerocopy.be/faq" target="_blank" rel="noopener noreferrer">{text}</a>),
    breakLine: (<div className='mt-1' />),
    b: (text) => (<strong>{text}</strong>),
    li: (item) => (<li>{item}</li>),
    ul: (...chunks) => (<ul className="mb-0">{chunks}</ul>),
    ol: (...chunks) => (<ol className="mb-0">{chunks}</ol>),
    iLove: (text) => (<a target="blank" rel="noopener noreferrer" href="https://www.ilovepdf.com/compress_pdf">{text}</a>),
    smallPdf: (text) => (<a target="blank" rel="noopener noreferrer" href="https://smallpdf.com/compress-pdf">{text}</a>),
    support: (text) => (<a href="mailto:support@zerocopy.be">support@zerocopy.be</a>)
  };

  if (params) {
    solutionParams = {...solutionParams, ...params};
  }

  let errorMsg = intl.formatMessage({id: error});
  let solutionMsg = intl.formatMessage({id: solution}, solutionParams);

  return (
    <div className="error-item">
      <div className="font-weight-bold">
        <FormattedMessage id="document.reportProblem.error" />
      </div>
      <div>
        {errorMsg}
      </div>
      <div className="mt-2 font-weight-bold">
        <FormattedMessage id="document.reportProblem.solution" />
      </div>
      <div>
        {solutionMsg}
      </div>
      <hr />
    </div>
  );
};

const ErrorForm = ({onSubmit, isSubmitting}) => {

  const intl = useIntl();

  const { register, handleSubmit, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex">
        <div className="flex-grow-1 align-self-end mb-n3">
          <Field field="problem" labelKey="document.reportProblem.description" labelClassName="font-weight-bold" type="textarea" placeholder={intl.formatMessage({id: "document.reportProblem.description.placeholder"})}
            options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} disabled={isSubmitting} />
        </div>
        <div className="ml-2 align-self-end">
          <Button color="primary" type="submit" size="sm" disabled={isSubmitting}>
            <FormattedMessage id="document.reportProblem.btn.send" />
            {isSubmitting?<Spinner size="sm" className="ml-2"/>:null}
          </Button>
        </div>
      </div>
    </Form>
  );

};

const ErrorReported = (props) => {

  return (
    <div>
      <div className="font-weight-bold"><FormattedMessage id="document.reportProblem.description" /></div>
      <div className="text-success"><FormattedMessage id="document.reportProblem.message.success" /></div>
    </div>
  );
};

const mapErrors = (error) => {

  let errorKey;
  let solutionKey;
  let params = null;

  switch (error) {
    case "19":
      errorKey = "document.reportProblem.solution.noAds";
      solutionKey = "document.reportProblem.solution.noAds.solution";
      break;
    // Font errors
    case "401":
    case "409":
      errorKey = "document.reportProblem.solution.font";
      solutionKey = "document.reportProblem.solution.font.solution";
      break;
    // Timeout
    case "404":
      errorKey = "document.reportProblem.solution.timeout";
      solutionKey = "document.reportProblem.solution.timeout.solution";
      break;
    // Encrypted
    case "202":
    case "1003":
      errorKey = "document.reportProblem.solution.encrypted";
      solutionKey = "document.reportProblem.solution.encrypted.solution";
      break;
    case "WORD_TO_PDF":
      errorKey = "document.reportProblem.solution.wordToPdf";
      solutionKey = "document.reportProblem.solution.wordToPdf.solution";
      break;
    case "1002":
      errorKey = "document.reportProblem.solution.maxSize";
      solutionKey = "document.reportProblem.solution.maxSize.solution";
      params = {"max": MAX_FILE_SIZE}
      break;
    case "1004":
      errorKey = "document.reportProblem.solution.invalidType";
      solutionKey = "document.reportProblem.solution.invalidType.solution";
      break;
    case "1005":
      errorKey = "document.reportProblem.solution.numberOfPages";
      solutionKey = "document.reportProblem.solution.numberOfPages.solution";
      break;
    // Others
    default:
      errorKey = genericError;
      solutionKey = "document.reportProblem.solution.default.solution";
      break;
  }

  let result = {
    "error": errorKey,
    "solution": solutionKey
  }

  if (params) {
    result["params"] = params;
  }

  return result;
}

const removeDuplicatedErrors = (accumulator, current) => {

  if (!accumulator.find(item => item.error === current.error)) {
    accumulator.push(current);
  }

  return accumulator;
}

