import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import InviteFriendByEmailForm from './InviteFriendsByEmailForm';
import InviteFriendsCopyLink from './InviteFriendsCopyLink';

import './InviteFriends.css';

const InviteFriends = (props) => {

  const user = useSelector(state => state.user.user);
  const rewardFriendRegistered = useSelector(state => state.system.credit_policy_friends_first_level);
  const rewardFriendPrinting = useSelector(state => state.system.credit_policy_friends_first_print);



  if (user === undefined || user === null) {
    return null;
  }


  return (
    <div>
      <div>
        <FormattedMessage id="inviteFriends.info"
          values={{credits1: rewardFriendRegistered, credits2: rewardFriendPrinting}}/>
      </div>

      <div className="mt-3">
        <InviteFriendsCopyLink />
      </div>

      <div className="mt-3">
        <InviteFriendByEmailForm onSubmit={props.onInviteFriends} />
      </div>

    </div>
  );
}

InviteFriends.propTypes = {
  onInviteFriends: PropTypes.func.isRequired,
  onCopyLink: PropTypes.func.isRequired
}

export default InviteFriends;
