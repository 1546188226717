import React from 'react';
import { WarningIcon } from '../Icons';

export const Error = ({errorMessage, extraContent}) => {

  if (errorMessage === undefined || errorMessage === null || errorMessage === false) {
    return null;
  }

  return (
    <div className="alert alert-danger mt-2 mb-1 d-flex flex-row align-items-center">
      <span className="mr-3">
       <WarningIcon width="32px" height="32px" />
      </span>
      <div>
        <strong>{errorMessage}</strong>
        {extraContent}
      </div>
    </div>
  );
};
