import * as actionTypes from '../actions/types';
import { setToken, clearToken, getToken, updateObject } from '../../shared/utils';

const token = getToken();
const isLoggedIn = token !== undefined && token !== null && token !== "";

const securityState = {
  token: undefined,
  loading: false,
  error: false,
  loggedIn: isLoggedIn,
  submitting: false,
  logIn: {
    isOpen: false
  }
};

const login = (state, action) => {
  return updateObject(state, {
    loading: true,
    submitting: true
  });
}

const loginSuccess = (state, action) => {

  setToken(action.token);

  return updateObject(state, {
    token: action.token,
    loading: false,
    loggedIn: true,
    error: undefined,
    submitting: false,
    logIn: {...state.logIn, isOpen: false}
  });
}

const logoutSuccess = (state, action) => {

  clearToken();

  return updateObject(state, {
    token: null,
    loading: false,
    loggedIn: false,
    error: undefined,
    submitting: false,
    logIn: {...state.logIn, isOpen: false}
  });
}

const loginError = (state, action) => {
  return updateObject(state, {
    token: undefined,
    loading: false,
    error: action.error,
    loggedIn: false,
    submitting: false
  });
}

const directLogIn = (state, action) => {
  return updateObject(state, {
    loading: false,
    loggedIn: true,
    error: false,
    token: action.token
  });
}

const directLogInError = (state, action) => {
  document.location.href="/";
}

/**
* Redux reducer for security actions.
*/
export default function (state = securityState, action) {
  switch (action.type) {
    case actionTypes.LOGIN: return login(state, action);
    case actionTypes.LOGIN_ERROR: return loginError(state, action);
    case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
    case actionTypes.LOGIN_AUTO: return directLogIn(state, action);
    case actionTypes.LOGIN_AUTO_ERROR: return directLogInError(state, action);
    case actionTypes.LOGOUT_SUCCESS: return logoutSuccess(state, action);
    case actionTypes.LOG_IN_TOGGLE:
      return {...state, error: false, logIn: {isOpen: !state.logIn.isOpen}}
    default:
      return state;
  }
}
