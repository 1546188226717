import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Spinner } from 'reactstrap';
import { GlobalError } from '../Components/Messages/GlobalError';
import { CreateAccountForm } from '../Components/Form';
import { directLogIn, logInFacebook, userCreateAccount } from '../store/actions';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookIcon, GoogleIcon } from '../Components/Icons';

import { useGoogleLogin } from '@react-oauth/google';

import "./CreateAccountPage.css";
import { PublicTemplate } from '../Templates';
import AuthService from '../Services/AuthService';
import { clearRefIdentifier, getRefIdentifier, globalErrorHandling, HOME_PAGE_PATH, setFacebookLogIn, setGoogleLogin, setToken } from '../shared/utils';

import {error as errorNotification } from 'react-notification-system-redux';
import { getLanguage } from '../store/sagas/selectors';
import { useMixpanel } from '../contexts/Mixpanel';
import { Helmet } from 'react-helmet';
import { logger } from '../shared/Logger';

const CreateAccountPage = (props) => {

  const loggedIn = useSelector(state => state.security.loggedIn);
  const error = useSelector(state => state.user.createAccount.error);
  const loading = useSelector(state => state.user.createAccount.loading);
  const redirectToLogin = useSelector(state => state.user.createAccount.redirectToLogin);
  const language = useSelector(getLanguage);

  const [localLoading, setLoading] = useState(false);
  const [auth, setAuth] = useState();
  const [googleData, setGoogleData] = useState();
  const [social, setSocial] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();
  const mixpanel = useMixpanel();
  const { search } = useLocation();

  useEffect(() => {

    if (googleData) {
    
      setLoading(true);

      const request = {
        "token": googleData.access_token,
        "language": language,
        "analyticsId": mixpanel.get_distinct_id(),
        "newAccount": true
      };

      const ref_id = getRefIdentifier();
      if (ref_id !== undefined) {
        request.refId = ref_id;
      }

      AuthService.authWithGoole(request).then(response => {
        setSocial("Google");
        setAuth(response.data);
        clearRefIdentifier();
      }).catch(err => {
        const errorObj = globalErrorHandling(err);

        let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
        if (msg === errorObj.errorKey) {
          msg = intl.formatMessage({ id: "createAccount.msg.error"});
        }
        
        dispatch(errorNotification({
          message: msg,
          position: 'tc',
          autoDismiss: 0,
        }));
      }).finally(() => {
        setLoading(false);
      })
      }

  }, [googleData]);

  useEffect(() => {

    if (auth) {
      setToken(auth.token);
      dispatch(directLogIn(auth.token));
    }

  }, [auth]);

  useEffect(() => {

    if (social) {
      if (social === "Google") {
        setGoogleLogin();
      } else if (social === "Facebook") {
        setFacebookLogIn();
      }
      
    }

  }, [social]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => setGoogleData(response),
    onError: (error) => logger.error('Login Failed:', error)
  });

  const handleSubmit = (model) => {
    dispatch(userCreateAccount(model.email, model.password, model.passwordRepeat, intl, false));
  }

  const handleFacebookLogin = (fbData) => {

    if (fbData.accessToken !== undefined) {
      dispatch(logInFacebook(fbData.accessToken, intl));
    }

  }

  if (loggedIn === true) {
    return (
      <Redirect to={{
        pathname: HOME_PAGE_PATH,
        search: search
      }} />
    );
  }

  if (redirectToLogin === true) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
      <PublicTemplate>
        <Helmet>
          <title>Zerocopy - {intl.formatMessage({id: "createAccount.title"})}</title>
        </Helmet>

        <div className="create-account-page">
          <GlobalError error={error} />

          <div className="pb-3">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID}
            fields="name,email"
            callback={handleFacebookLogin}
            render={renderProps => {
              return (
                <Button id="btnCreateAccountFacebook" color="primary" block className='pl-4' onClick={renderProps.onClick} disabled={localLoading || loading}>
                    <div className='d-flex align-items-center'>
                      <FacebookIcon height='22px' width='22px' className="mr-2" />
                      <span className='flex-grow-1 text-center'><FormattedMessage id="createAccount.facebook" /></span>
                    </div>
                </Button>
                );
              }}
            />
          </div>

          <div className="pb-3">
            <Button id="btnCreateAccountGoogle" color="primary" block onClick={handleGoogleLogin} className="pl-4" disabled={localLoading || loading}>
              <div className='d-flex align-items-center'>
                <GoogleIcon height='22px' width='22px' />
                <span className='flex-grow-1 text-center'><FormattedMessage id="createAccount.google" /></span>
              </div>
            </Button>
          </div>

          <div className="px-4 py-2 bg-primary rounded-lg">

            <div className="text-white d-flex mb-3">
              <i className="far fa-envelope" />
              <div className="flex-grow-1 text-center">
                <FormattedMessage id="createAccount.email.title" />
              </div>
            </div>

            <CreateAccountForm id="create-account-form" onSubmit={handleSubmit} />

            <div className="very-small policy-text">
              <FormattedMessage id="createAccount.field.policy" values={{
                  privacy: (text) => (<a href="https://zerocopy.be/privacy/" target="_blank" rel="noopener noreferrer">{text}</a>),
                  terms: (text) => (<a href="https://zerocopy.be/terms-and-conditions" target="_blank" rel="noopener noreferrer">{text}</a>)
                }} />
            </div>
            
            <div className='mt-2 d-flex justify-content-center'>
              <button id="btnCreateAccount"
                  className="btn btn-accent pl-4 pr-4 font-weight-bold"
                  disabled={loading || localLoading} form="create-account-form">
                  <FormattedMessage id="createAccount.button" />
                  {loading?<Spinner size="sm" className="ml-2"/>:null}
              </button>
            </div>
          </div>

          <div className="mt-2">
              <FormattedMessage id="createAccount.youHaveAccount"/>&nbsp;
              <Link to="/login" className="accent-link">
                  <FormattedMessage id="createAccount.button.back" />
              </Link>
          </div>

        </div>
              
    </PublicTemplate>
  );
}

export default CreateAccountPage;
