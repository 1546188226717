import React from 'react';
import PropTypes from 'prop-types';

import LogoutSvg from '../../images/log-out.svg';

export const LogoutIcon = (props) => {
  return (
    <img src={LogoutSvg}
    style={{"width": props.width, "height": props.height}}
    {...props} />
  );
};

LogoutIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

LogoutIcon.defaultProps = {
  width: "32px",
  height: "32px",
  alt: "Logout icon"
};