import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const cookieShape = PropTypes.shape({
  domain: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  expire: PropTypes.number.isRequired,
  expireUnit: PropTypes.oneOf(['year', 'day']),
  description: PropTypes.string.required,
  more: PropTypes.string
});

export const ConsentCookie = ({cookie}) => {

  let expire = null;

  switch (cookie.expire) {
    case -1:
      expire = (<FormattedMessage id="consent.cookiePolicy.cookie.field.expire.permanent" />);
      break;
    case 0:
      expire = (<FormattedMessage id="consent.cookiePolicy.cookie.field.expire.logout" />);
      break;
    default:
      expire = cookie.expire + " " + cookie.expireUnit;
      break;
  }

  return (
    <tr key={cookie.name}>
      <td>{cookie.domain}</td>
      <td>{cookie.name}</td>
      <td>{expire}</td>
      <td><FormattedMessage
        id={ `consent.cookiePolicy.cookie.${cookie.name}.description`} defaultMessage={cookie.description} /></td>
      <td>{cookie.more}</td>
    </tr>
  );
}

ConsentCookie.propTypes = {
  cookie: cookieShape.isRequired
}
