import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../images/logo-green.svg';

export const LogoGreen = (props) => {
  return (
    <img src={logo}
      alt={props.alt}
      title={props.title}
      width={props.width}
      height={props.height}
      className={props.className}
    />
  );
};

LogoGreen.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

LogoGreen.defaultProps = {
  alt: "Zerocopy"
};
