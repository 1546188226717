import { useState, useRef, useEffect } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core';


const useSliding = (elementWidth, countElements) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0)
  const [viewed, setViewed] = useState(0);
  const [flexAmount, setFlexAmount] = useState(5);
  const [itemsFit, setItemsFit] = useState(false)
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])


  useEffect(() => {
    getContainerWidth();
  }, [containerRef.current]);

  useEffect(() => {
    getFlexAmount();
  }, [containerWidth])

  useEffect(() => {
    getTotalInViewPort();
  }, [flexAmount])

  function getFlexAmount() {
    let flex;
    if (lg) {
      flex = Math.max(100 / (countElements), 5);
      setFlexAmount(flex)
    }
    else if (md) {
      flex = Math.max(100 / (countElements) , 10);
      setFlexAmount(flex)
    }
    else if (sm) {
      flex = Math.max(100 / (countElements), 20)
      setFlexAmount(flex)
    }
    setItemsFit(countElements * flex < 100)
  }

  const getContainerWidth = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }

  }

  const getTotalInViewPort = () => {
    setTotalInViewport(Math.floor(100 / flexAmount));
  }

  const handleResize = () => {
    getContainerWidth();
  }

  const handlePrev = () => {
    setViewed(viewed - totalInViewport);
    setDistance(distance + containerWidth);
  }

  const handleNext = () => {
    setViewed(viewed + totalInViewport);
    setDistance(distance - containerWidth)
  }

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)`, justiftContent: "center" }
  };

  const hasPrev = distance < 0 && !itemsFit;
  const hasNext = (viewed + totalInViewport) < countElements && !itemsFit;

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext , flexAmount, totalInViewport, viewed};
}

export default useSliding;
