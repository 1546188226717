import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CircleIcon, InfoIcon } from '../Icons';
import { useSelector } from 'react-redux';

import "./MyCredits.css";
import { PartnerEmailInfo, ProfileInfo } from '../others';
import InviteFriendsButton from '../InviteFriends/InviteFriendsButton';
import ProfileButton from '../Profile/ProfileButton';
import { useGlobal } from '../../contexts/GlobalContext';

const MyCredits = ({ className }) => {

  // Previous data from context
  const user = useSelector((state) => state.user.user);
  const sections = useSelector((state) => state.profile.list);
  const profileReward = useSelector((state) => state.system.credit_policy_level_5);
  const friendsPrintingReward = useSelector((state) => state.system.credit_policy_friends_first_print);
  const friendsAddedReward = useSelector((state) => state.system.credit_policy_friends_first_level);
  const adClickCounter = useSelector((state) => state.user.adClickCounter);

  const freeLimitReached = user && user.freeCapReached;
  const filledProfile = sections && sections.length === 0;
  const friendsReward = friendsAddedReward + friendsPrintingReward;

  if (user?.numberOfPrints === 0 || freeLimitReached) {
    return null;
  }

  return (
    <Fragment>
        <div className={["card zc-card my-credits", className].filter(Boolean).join(" ")}>
          <div className="card-body">
            <h4 className='mb-0'>
              <FormattedMessage id="getExtraCredits.title" />
            </h4>
            
            {filledProfile ? null : <div className='d-flex align-items-center justify-content-between w-100 mt-2'>
              <div className='flex-grow-1'><ProfileButton /></div>
              <CreditsText credits={profileReward} />
            </div> }

            <div className='d-flex align-items-center justify-content-between w-100 mt-2'>
              <InviteFriendsButton />
              <CreditsText credits={friendsReward} />
            </div>

            <div className={`d-flex align-items-center justify-content-between w-100 mt-2${adClickCounter.reachedForThisWeek ? ' text-success':''}`}>
              <AdClickCounter />
              <CreditsText credits={adClickCounter.reward} />
            </div>

          </div>
        </div>

    </Fragment>
  );
}

const WeeklyCredits = ({intl, filledProfile, freeLimitReached}) => {

  // Previous data from context
  const adClickCounterEnabled = useSelector((state) => state.system.system_enable_ad_clicks);
  const adClickCounter = useSelector((state) => state.user.adClickCounter);
  const partnerEmailsEnabled = useSelector((state) => state.system.system_enable_partner_emails);
  const partnerEmailsActivated = useSelector((state) => state.consent.partnerEmails);
  const partnerEmailsReward = useSelector((state) => state.system.credit_policy_partner_emails);
  const profileReward = useSelector((state) => state.system.credit_policy_level_5);
  const freeLimit = useSelector((state) => state.system.credit_policy_user_print_limit);

  const { askProfile } = useGlobal();

  let total = 0;

  if (filledProfile === true) {

    if (freeLimitReached) {
     
    } else {
      total += profileReward;
    }
  }

  if (partnerEmailsEnabled === true && partnerEmailsActivated === true) {
    total += partnerEmailsReward;
  }

  if (adClickCounterEnabled === true && adClickCounter.reachedForThisWeek === true) {
    total += adClickCounter.reward;
  }

  return (
    <div className='d-flex flex-column'>
      <h6><FormattedMessage id="mycredits.weekly.title" /></h6>

      <MyCreditsItem
        active={filledProfile}
        disabled={freeLimitReached}
        title={intl.formatMessage({id: "mycredits.weekly.profile"})}
        credits={profileReward}>
        <ProfileInfo reward={profileReward} freeLimitReached={freeLimitReached} limit={freeLimit} className="ml-1"/>

        { askProfile ? null : <ProfileButton /> }

      </MyCreditsItem>

      <MyCreditsItem
        active={partnerEmailsActivated}
        title={intl.formatMessage({id: "mycredits.weekly.partner_emails"})}
        credits={partnerEmailsReward}>
        <PartnerEmailInfo reward={partnerEmailsReward} className="ml-1" showLink={!partnerEmailsActivated} />
      </MyCreditsItem>
      
      <MyCreditsItem
        active={adClickCounter.reachedForThisWeek}
        credits={adClickCounter.reward}>
        <AdClickCounter intl={intl}
          maxClicks={adClickCounter.maxClicks}
          currentClicks={adClickCounter.currentClicks}
          reachedForThisWeek={adClickCounter.reachedForThisWeek}
          reward={adClickCounter.reward} />
      </MyCreditsItem>

      <div className="d-flex text-success">
        <CreditsText credits={total} total={true} />
      </div>

    </div>
  );
}

const MyCreditsItem = ({active, disabled, title, onTitleClick, credits, children}) => {

  let content = null;

  if (title !== undefined && title !== null) {
    content = (
      <span
        className={`ml-1${onTitleClick !== undefined ? ' c-pointer':''}`}
        onClick={onTitleClick}>{title}</span>
    );
  } 

  return (
    <div className={`d-flex align-items-center w-100 my-credits-item${disabled ? " font-italic disabled" : active ? " text-success" : ""}`}>
      <div className='d-flex align-items-center flex-grow-1 flex-wrap'>
        <CircleIcon checked={active} />
        {content}
        {children}
      </div>
     <CreditsText active={active} credits={credits} disabled={disabled} />
    </div>
  );
}

const CreditsText = ({active, disabled, credits, total}) => {

  let className = "text-right my-credits-item-reward";

  if (total === true) {
    className += " ml-auto my-credits-total-reward";
  }

  if (active === false) {
    className += " font-italic";
  }

  return (
    <div className={className} style={ disabled ? {textDecoration: "line-through"} : null }>
      +{credits ? credits : 0 } <FormattedMessage id="global.words.credits" />
    </div>
  );
}

const AdClickCounter = () => {

  const adClickCounter = useSelector((state) => state.user.adClickCounter);

  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const { maxClicks, currentClicks, reward } = adClickCounter;

  const toggle = (e) => {
      setModalOpen(!modalOpen);
      e.preventDefault();
  }

  if (maxClicks === 0) {
      return null;
  }

  let auxCurrentClicks = currentClicks;
  if (maxClicks < currentClicks) {
    auxCurrentClicks = maxClicks;
  }

  let content = (
    <FormattedMessage id="adClickCounter.text" values={{max: maxClicks, current: auxCurrentClicks}} />
  );

  return (
      <div>
          {adClickCounter.reachedForThisWeek ? <CircleIcon checked={true} className="align-sub" /> : null }
          <span className={adClickCounter.reachedForThisWeek ? 'ml-1': ''}>
            <FormattedMessage id="adClickCounter.title" />
          </span>
          <span className='ml-1'>
            {content}
          </span>

          <InfoIcon width="24px" height="24px" style={{cursor: "pointer"}}
                  onClick={toggle} title={intl.formatMessage({id: "adClickCounter.moreInfo"})} className="ml-1 align-sub" />

          <Modal isOpen={modalOpen} toggle={toggle} centered>
              <ModalHeader toggle={toggle} tag="h4">
                  <FormattedMessage id="adClickCounter.title" />
              </ModalHeader>
              <ModalBody>
                  <FormattedMessage id="adClickCounter.description" values={{reward: reward, max: maxClicks, breakLine: (
                    <div className='mt-1' />
                  )}} />
              </ModalBody>
          </Modal>
         
      </div>
  );
}

export default MyCredits;
