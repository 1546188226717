import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

export const DocumentDeleteConfirm = ({isOpen, toggle, onDelete, isSubmitting}) => {

  const handleDelete = (e) => {

    if (isSubmitting) {
      return;
    }

    toggle();
  }


  return (
    <Modal data-test="modal-confirm-documents-delete" isOpen={isOpen} toggle={handleDelete} centered={true}>
      <ModalHeader toggle={handleDelete}>
        <FormattedMessage id="confirm.title" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="documents.delete.confirm" />
      </ModalBody>
      <ModalFooter>
        <Button data-test="modal-confirm-documents-delete-ok" color="danger" onClick={onDelete} disabled={isSubmitting}>
          <FormattedMessage id="confirm.btn.ok" />
          {isSubmitting?<Spinner size="sm" className="ml-2"/>:null}
        </Button>
        &nbsp;
        <Button color="secondary" onClick={toggle} disabled={isSubmitting}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );

};

DocumentDeleteConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}
