import api from '../Api';

class LocationService {

    static async fetchCities(query) {

        return api.get(`/v1/cities${query ? `?query=${query}`: "" }`);
    }

    static async fetchLocations() {

        return api.get("/v1/locations");
    }
}

export default LocationService;