import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LeaderboardConsentButton } from '../Consent';
import { LeaderboardInfo } from '../others';

const LeaderboardDisabled = (props) => {

    return (
      <div className="leaderboard card zc-card">
        <div className="card-body p-md-2">
            <h4 className="leaderboard-header d-flex align-items-center">
              <FormattedMessage id="leaderboard.title"/>
              <span className='fs-6 ml-1'>
                <LeaderboardInfo />
              </span>
            </h4>
            <div>
              <div className='mt-2'>
                <LeaderboardConsentButton showInfo={false} />
              </div>
            </div>
        </div>
    </div>
    );
}

export default LeaderboardDisabled;