import React from 'react';
import { FormattedMessage } from 'react-intl';

const textarea = ({
  input, id, label, placeholder, className, inputRef, containerClassName, disabled,
  meta: { touched, error } }) => {

    if (touched && error && className !== "") {
        className += " is-invalid";
    }
    
    let labelComp = label;

    if (React.isValidElement(label)) {
      labelComp = React.cloneElement(label, {htmlFor: id})
    } else {
      labelComp = (<label className="font-weight-bold" htmlFor={id}>{label}</label>);
    }

  return (
    <div className={"form-group " + containerClassName}>
      {labelComp}
      <textarea {...input}
        id={id}
        className={className}
        placeholder={placeholder}
        ref={inputRef}
        disabled={disabled}
      />
      {touched && (error && <div className="text-danger"><FormattedMessage id={error.id} values={error.values} /></div>)}
    </div>
  );
}

export default textarea;
