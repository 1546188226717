import React, { useEffect, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useAds } from "../../contexts/AdsContext";
import { Adsense } from '@ctrl/react-adsense';
import { useMutationObservable } from "../../hooks/CustomHooks";

const AdSenseWrapper = ({children, adSlot, parentStyle, layout = '', layoutKey = '', format = 'auto', responsive = 'false', className, smallAd = false}) => {
  const { adSenseEnabled } = useAds();
  const [enabled, setEnabled] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setEnabled(adSenseEnabled);
  }, [adSenseEnabled]);

  const location = useLocation();

  const matchedLocation = matchPath(location.pathname, {
    "path": "/ai",
    "exact": false
  });

  useMutationObservable(ref, mutations => {
    
    if (mutations?.length > 0) {

      mutations.forEach(record => {
        
        if (record?.type === "attributes" && record?.attributeName === "data-ad-status") {

          const newValue = record.target.getAttribute(record.attributeName)

          if (newValue === "unfilled") {
            setEnabled(false);
          }

        }

      });

    }

  }, {attributes: true, childlist: true, subtree: true, attributeFilter: ["data-ad-status"]});


  if (enabled && (matchedLocation || smallAd)) {
    return (
      <div ref={ref} className="adsense-container" style={parentStyle}>
        <Adsense key={location.pathname + '-' + adSlot}
              client="ca-pub-2750583835814404"
              slot={adSlot}
              className={className}
              layout={layout}
              layoutKey={layoutKey}
              format={format}
              responsive={responsive}
            />
      </div>
    );
  } else {
    return children;
  }

}

export default AdSenseWrapper;
