import { updateObject } from '../../shared/utils';

import * as actionTypes from '../actions/types';

const initialState = {
  list: [],
  loading: false
}

const adsFetch = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: undefined
  })
};

const adsFetchSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    list: action.ads
  })
};

const adsFetchError = (state, action) => {
  return updateObject(state, {
    loading: false,
    list: [],
    error: action.error
  })
};

const clearData = (state, action) => {
  return updateObject(state, {
    list: []
  });
}

/**
* Redux reducer for ads actions.
*/
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS: return clearData(state, action);

    case actionTypes.ADS_FETCH: return adsFetch(state, action);
    case actionTypes.ADS_FETCH_SUCCESS: return adsFetchSuccess(state, action);
    case actionTypes.ADS_FETCH_ERROR: return adsFetchError(state, action);
    default:
      return state;
  }
};
