import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, ModalBody } from "reactstrap";
import { CoverWithFallback } from "../Containers/CompetitionPage";
import { useCompetition } from "../CompetitionContext";
import { COMPETITION_TYPE_FRIENDS } from "../competitions_config";
import InviteFriendsCopyLink from "../../Components/InviteFriends/InviteFriendsCopyLink";

export const CompetitionNotification = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const { competition } = useCompetition();
  const notificationKey = competition.key + ".notification";

  useEffect(() => {

    if (competition?.notification === true) {
      setIsOpen(localStorage.getItem(notificationKey) === null);
    }

  }, [competition]);

  if (!competition) {
    return null;
  }

  const toggle = () => {

    if (isOpen) {
      localStorage.setItem(notificationKey, true);
    }

    setIsOpen(!isOpen);
  }

  let langForImg = intl.locale;

  if (competition.image_by_lang !== true || langForImg === "es") {
    langForImg = "en";
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered data-test="competition-message">
      <ModalBody className="small">
        <div className="d-md-flex align-items-center justify-content-between">
          <div className="mb-2 mb-md-0 text-center">
            <CoverWithFallback size="200" src={`https://zerocopy.ams3.digitaloceanspaces.com/images/competitions/${competition.key}_${langForImg}.png`} />
          </div>
          <div className="ml-0 ml-md-2">
            <div className="text-center mb-2 fs-5">
              <FormattedMessage id={`competitions.${competition.key}.notification.title`} defaultMessage="Define competition notification title"
                values={{
                  breakLine: (<div className='mt-1' />),
                  b: (text) => (<strong>{text}</strong>),
                }} />
            </div>
            <div className="text-center mb-4">
              <FormattedMessage id={`competitions.${competition.key}.notification.description`} defaultMessage="Define competition notification description"
                values={{
                  breakLine: (<div className='mt-1' />),
                  b: (text) => (<strong>{text}</strong>),
                }} />
            </div>
            <div>
              <FormattedMessage id={`competitions.${competition.key}.notification.steps`} defaultMessage="Define competition notification steps"
                values={{
                  breakLine: (<div className='mt-1' />),
                  b: (text) => (<strong>{text}</strong>),
                  li: (item) => (<li>{item}</li>),
                  ul: (...chunks) => (<ul className="mb-0">{chunks}</ul>),
                  ol: (...chunks) => (<ol className="mb-0">{chunks}</ol>),
                }} />
            </div>
          </div>
        </div>
        {competition.type !== COMPETITION_TYPE_FRIENDS ? null : 
          <div className="mt-2">
            <InviteFriendsCopyLink sm={true} />
          </div> }
      </ModalBody>
    </Modal>
  );
}
