import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Select from 'react-select';
import { fieldShape } from '../../Shapes';

import './Field.css'
import { injectIntl } from 'react-intl';

class Field extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      values: props.defaultValues === undefined?[]:props.defaultValues,
      maxChoicesError: false,
      minChoicesError: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (selectedOptions) {
    const maxChoices = this.props.field.maxChoices;
    const minChoices = this.props.field.minChoices;

    if (selectedOptions !== null && selectedOptions.length > maxChoices) {
      this.setState({maxChoicesError: true});
    } 
    else if (selectedOptions !== null && selectedOptions.length < minChoices) {
      this.setState({minChoicesError: true});
    } else {

      this.setState({values: selectedOptions, maxChoicesError: false});

      this.props.onChangeHandler(selectedOptions, this.props.field.id);
    }
  }

  render () {

    const value = this.state.values;
    const maxChoicesError = this.state.maxChoicesError;
    const field = this.props.field;
    const multiple = field.maxChoices > 1;
    const showError = this.state.maxChoicesError || this.state.minChoicesError;
    const minChoicesError = this.state.minChoicesError;

    let errorText = "";

    if (maxChoicesError) {
      errorText = this.props.intl.formatMessage({id: "validations.maxChooices"}, {maxChoices: field.maxChoices});
    }
    else if (minChoicesError) {
      errorText = this.props.intl.formatMessage({id: "validations.minChooices"}, {min: field.minChoices});
    }

    const lang = this.props.locale.lang[0].toUpperCase() + this.props.locale.lang.substring(1);
    let label;
    let options;

    /* FIXME This must be removed in the future when we have ready the advanced sorting configuration */
    if ("Date of birth" === field.labelEn) {
      label = field.labelEn;
      options = field.choices.sort((a, b) => b.labelEn.localeCompare(a.labelEn));
    } else {
      if (field.hasOwnProperty(`label${lang}`)) {
        label = field[`label${lang}`];
        options = field.choices.sort((a, b) => a[`label${lang}`].localeCompare(b[`label${lang}`]));
      } else {
        label = field.labelEn;
        options = field.choices.sort((a, b) => a.labelEn.localeCompare(b.labelEn));
      }
    }


    return (
      <div className="form-group" data-test="profile-field">
        <label >{label}{ + field.minChoices > 0 ? ' *' : ''}</label>
        <Select
          options={options}
          onChange={this.handleChange}
          closeMenuOnSelect={true}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.hasOwnProperty(`label${lang}`) ? option[`label${lang}`] : option.labelEn }
          value={value}
          isMulti={multiple}
          isClearable
          isSearchable={true}
          isDisabled={this.props.disabled}
        />
        <div className={showError?"":"d-none"}>
          <small className="text-danger">{errorText}</small>
        </div>
      </div>
    );
  }

}

Field.defaultValues = {
  disabled: false
}
Field.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  field: fieldShape.isRequired,
  defaultValues: PropTypes.array,
  disabled: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    "locale": state.locale
  };
}

const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Field));
