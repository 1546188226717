import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './DocumentPreview.css';

export const DocumentPreview = ({url, isOpen, toggle, isModal = true, style = {} }) => {

  const iframe = 
        <iframe className="doc-preview" title="Doc preview" frameBorder="0" style={style}
          src={`/viewer.html?file=${encodeURIComponent(url)}`} />

  return (
    <>
    { isModal ? 
    <Modal id="previewModal" isOpen={isOpen} toggle={toggle} size="xl" style={{"paddingTop": "2rem"}}>
      <ModalHeader className='d-inline d-md-none' toggle={toggle} />
      <ModalBody>
          {iframe}
      </ModalBody>
    </Modal>
    : 
      <div>{iframe}</div> 
    } 
    </>
  );
};

DocumentPreview.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}
