import React from 'react';
import { FormattedMessage } from 'react-intl';
import { numberOfDaysToDelete } from '../../shared/utils';

const DocumentListTitle = (props) => {

  return (
    <div className="row document-list-tilte">
      <div className="col">
        <div className='border-bottom'>
          <h4 className="title d-inline-block mr-2">
            <FormattedMessage id="documents.title" defaultMessage="Documents" />
          </h4>
          <span className="subtitle text-muted d-block d-lg-inline-block">
            <FormattedMessage id="documents.subtitle" values={{days: numberOfDaysToDelete}}/>
          </span>
        </div>
      </div>
    </div>
  );

};

export default DocumentListTitle;
