import * as actionTypes from './types';

export const fetchDocuments = () => {
  return {
    type: actionTypes.DOCUMENTS_FETCH
  }
};

export const fetchDocumentsSuccess = (list) => {
  return {
    type: actionTypes.DOCUMENTS_FETCH_SUCCESS,
    list: list
  }
};

export const fetchDocumentsError = (error) => {
  return {
    type: actionTypes.DOCUMENTS_FETCH_ERROR,
    error: error
  }
};

export const toggleDocument = (documentId, pages, ads) => {
    return {
        type: actionTypes.DOCUMENT_TOGGLE,
        documentId: documentId,
        pages: pages,
        ads: ads
    };
};

export const deleteDocument = (documentIds, intl) => {
    return {
        type: actionTypes.DOCUMENT_DELETE,
        documentIds: documentIds,
        intl: intl
    };
};

export const deleteDocumentSuccess = (documentIds) => {
    return {
        type: actionTypes.DOCUMENT_DELETE_SUCCESS,
        documentIds: documentIds
    };
};

export const deleteDocumentError = error => {
    return {
        type: actionTypes.DOCUMENT_DELETE_ERROR,
        error: error
    };
};

export const uploadDocumentSuccess = () => {
    return {
        type: actionTypes.DOCUMENT_UPLOAD_SUCCESS,
        uploading: false
    };
};

export const uploadDocument = (file, intl) => {
    return {
        type: actionTypes.DOCUMENT_UPLOAD,
        file: file,
        uploading: true,
        intl: intl
    };
};

export const uploadProgress = progress => {
    return {
        type: actionTypes.DOCUMENT_UPLOAD_PROGRESS,
        progress: progress
    };
};

export const uploadDocumentError = error => {
    return {
        type: actionTypes.DOCUMENT_UPLOAD_ERROR,
        error: error,
        uploading: false
    };
};

export const reloadDocument = (document_id, newStatus, extraData) => {
    return {
        type: actionTypes.DOCUMENT_RELOAD,
        documentId: document_id,
        newStatus: newStatus,
        extraData: extraData
    };
};

export const toggleConfirmDelete = () => {

  return {
    type: actionTypes.DOCUMENT_TOGGLE_CONFIRM_DELETE,
  };
};

export const deliveryRequestSelectAddress = (address) => {
  return {
    type: actionTypes.DELIVERY_REQUEST_SELECT_ADDRESS,
    address: address
  }
};

export const deliveryRequestShowAddress = () => {
  return {
    type: actionTypes.DELIVERY_REQUEST_SHOW_ADDRESS
  }
};

export const deliveryRequestShowPaymentSelector = () => {
  return {
    type: actionTypes.DELIVERY_REQUEST_SHOW_PAYMENT_SELECTOR
  }
};

export const deliveryRequestSelectPayment = (selected) => {
  return {
    type: actionTypes.DELIVERY_REQUEST_SELECT_PAYMENT,
    payment: selected
  }
};

export const deliveryRequest = (request) => {
  return {
    type: actionTypes.DELIVERY_REQUEST,
    request: request
  }
};

export const deliveryRequestSuccess = (data) => {
  return {
    type: actionTypes.DELIVERY_REQUEST_SUCCESS,
    estimatedDate: data.estimatedDate
  }
};

export const deliveryRequestError = (error) => {
  return {
    type: actionTypes.DELIVERY_REQUEST_ERROR,
    error: error
  }
};

export const deliveryRequestClean = () => {
  return {
    type: actionTypes.DELIVERY_REQUEST_CLEAN
  };
}

export const deliveryLockedToggle = () => {
  return {
    type: actionTypes.DELIVERY_LOCKED_TOGGLE
  };
}
