import { put, call, getContext, select } from 'redux-saga/effects';
import api from '../../Api';
import { error as errorNotification } from 'react-notification-system-redux';
import { getToken as getTokenFromCookie, setToken, globalErrorHandling, setFacebookLogIn, getRefIdentifier, clearRefIdentifier, clearFacebookIfNecessary, clearGoogleIfNecessary, ANALYTICS_PROP_VIA } from '../../shared/utils';
import { getToken, getLanguage, getWsConnected } from './selectors';
import { LOGIN_SUBMIT, LOGOUT } from '../../shared/EventsNames';
import UserService from '../../Services/UserService';

import * as actions from '../actions';

export function* logInSaga(action) {
  try {
    const response = yield api.post("/v1/login", {"username": action.username, "password": action.password});
    const mixpanel = yield getContext("mixpanel");
    mixpanel.identify(response.data.user.id);
    mixpanel.track(LOGIN_SUBMIT);
    yield put(actions.logInSuccess(response.data));

    yield initWebsocket(response.data.user.email);
    
    yield afterLogin(action.intl);

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.logInError(errorObj));
  }
}

export function* logoutSaga() {

  try {
    const token = yield select(getToken);
    
    yield api.post("/v1/logout", {}, {headers: {'X-AUTH-TOKEN': token}});
    
    const mixpanel = yield getContext("mixpanel");
    mixpanel.track(LOGOUT);
    mixpanel.reset();
    mixpanel.register({
      Via: ANALYTICS_PROP_VIA,
      app_version: process.env.REACT_APP_VERSION
    });

    yield put(actions.logoutSuccess());
    clearFacebookIfNecessary();
    clearGoogleIfNecessary();
    yield put(actions.websocketDiscconnect());

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.logoutError(errorObj));
  }
}

export function* logInFacebookSaga(action) {
  try {
    const language = yield select(getLanguage);

    const mixpanel = yield getContext("mixpanel");

    const request = {
      "token": action.token,
      "language": language,
      "analyticsId": mixpanel.get_distinct_id()
    };

    const ref_id = getRefIdentifier();
    if (ref_id !== undefined) {
      request.refId = ref_id;
    }

    const response = yield api.post("/v1/facebook_login", request);
    
    if (response.data.isNew !== true) {
      mixpanel.identify(response.data.user.id);
    }
    
    mixpanel.track(LOGIN_SUBMIT);
    yield put(actions.logInSuccess(response.data, "facebook"));
    setFacebookLogIn();
    clearRefIdentifier();

    yield initWebsocket(response.data.user.email);
    
    yield afterLogin(action.intl);

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.logInError(errorObj));
  }
}

export function* directLoginSaga() {

  try {
    const token = yield select(getToken);
    const response = yield call(UserService.fetch, token);

    // Identify user for Mixpanel
    const mixpanel = yield getContext("mixpanel");
    mixpanel.identify(response.data.id);

    yield initWebsocket(response.data.email);

    yield afterLogin();
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    if (errorObj.errorCode === -2) {
      yield put(actions.directLogInError(errorObj));
    }
  }
}

export function* logInAsAnotherUserSaga(action) {

  try {
    const token = getTokenFromCookie();
    const response = yield api.post("/v1/switch", {user: action.nextUser}, {headers: {'X-AUTH-TOKEN': token}});

    if (response.data.token) {
      yield call(actions.logout);
      setToken(response.data.token);
    }

    window.location = window.location.origin;

  } catch(error) {

    if (error.response && error.response.status && error.response.status === 401) {

      yield put(errorNotification({
        message: action.intl.formatMessage({ id: "message.global.switch.unauthorized"}),
        position: 'tc',
        autoDismiss: 0
      }));

    } else {

      yield put(errorNotification({
        message: action.intl.formatMessage({ id: "message.global.switch.error"}),
        position: 'tc',
        autoDismiss: 0
      }));

    }

  }
}

/**
 * Common steps after login exeuction.
 */
function* afterLogin(intl) {

    yield put(actions.metadataFetch([
      "system_enable_upload",
      "system_enable_home_delivery",
      "system_enable_home_delivery_promo_code",
      "system_friends_reward",
      "credit_policy_user_print_limit",
      "credit_policy_level_5",
      "credit_policy_friends_maximum",
      "credit_policy_friends_first_level",
      "credit_policy_friends_first_print",
      "credit_policy_partner_emails",
      "credit_policy_ad_clicks",
      "necessary_clicks_for_ad_clicks",
      "system_ads_default_timeout",
      "system_free_offline",
      "system_back_online",
      "system_enable_partner_emails",
      "system_enable_leaderboard",
      "system_enable_ad_clicks",
      "system_enable_shared_documents",
      "system_enable_desktop_app",
      "company_name",
      "credit_policy_leaderboard_top1",
      "credit_policy_leaderboard_top2",
      "credit_policy_leaderboard_top3",
      "credit_policy_semester_bonus",
      "credit_policy_study_period_bonus",
      "restrict_global_prints",
      "system_enable_mail_reminders",
      "system_restrict_consecutive_uploads",
      "system_max_consecutive_uploads"
    ], [
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "integer",
      "integer",
      "integer",
      "integer",
      "integer",
      "integer",
      "integer",
      "integer",
      "integer",
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "string",
      "integer",
      "integer",
      "integer",
      "integer",
      "integer",
      "boolean",
      "boolean",
      "boolean",
      "integer"
    ]));
    yield put(actions.fetchUser()); // Obtain user data
    yield put(actions.npsAsk()); // Check if we need to ask about NPS
    yield put(actions.profileUpdate()); // Update analytics properties
    yield put(actions.profileFetch()); // Obtain information about user profile
    yield put(actions.adsFetch()); // Load ads for user
    yield put(actions.fetchDocuments()); // Load user documents
    yield put(actions.userGetAdClickCounterFetch(intl)); // Load counter information.
}

function* initWebsocket(email) {

  const wsConnected = yield select(getWsConnected);

  if (wsConnected) {
    yield put(actions.websocketDiscconnect());
  }

  yield put(actions.websocketConnection(email));

}
