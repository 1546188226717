import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Button, Label, Input, FormGroup } from "reactstrap";
import { FeedbackField } from "./FeedbackField";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FeedbackService from "../Services/FeedbackService";
import { useDispatch } from "react-redux";
import { logger } from "../../shared/Logger";
import { useMixpanel } from "../../contexts/Mixpanel";
import { AI_USER_SUMBIT_FEEDBACK } from "../../shared/EventsNames";
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { useIntl } from "react-intl";


export const FeedbackForm = ({ type, number }) => {
  const intl = useIntl();
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [consent, setConsent] = useState(false);
  const history = useHistory();
  const dispatcher = useDispatch();
  const [error, setError] = useState();
  const mixpanel = useMixpanel();

  useEffect(() => {
    setQuestions([...Array(number).keys()].map(num => `${type}.feedback.question.${num + 1}`));
  }, [type, number]);


  const handleChange = (question, value) => {
    setResponses(prevResponses => ({
      ...prevResponses,
      [question]: value,
    }));
  };

  const handleConsentChange = () => {
    setConsent(!consent)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false)
    mixpanel.track(AI_USER_SUMBIT_FEEDBACK, {service: type, feedback: responses})
    if (validateResponses())  {
    FeedbackService.postFeedback(type, responses, consent)
      .then(() => {
        dispatcher(successNotification({
          message: intl.formatMessage({ id: "feedback.result.success"}),
          position: 'tc',
          autoDismiss: 0,
        }));
        history.push(`/ai/${type}`)
      }).catch(error => {
        logger.error("Error ", error)
        dispatcher(errorNotification({
          message: intl.formatMessage({ id: "feedback.result.failure"}),
          position: 'tc',
          autoDismiss: 0,
        }));
      })
    }
    else {
      setError(true)
    }
  };

  const validateResponses = () => {
    return Object.keys(responses).length !== 0
  }


  return (
    <div className="container-fluid" style={{ width: "70%" }}>
      <div className="d-flex align-items-top">
        <Typography variant="h6" className="mb-2">
          <FormattedMessage id={`${type}.feedback.introduction`} />
        </Typography>
      </div>
      <Form onSubmit={handleSubmit}>
        {questions.map(q => (
          <FeedbackField
            key={q}
            question={q}
            type={"textarea"}
            onChange={(e) => handleChange(q, e.target.value)}
          />
        ))}
        <FormGroup className="d-flex pl-4 align-items-top">
          <Label check for="contact-future"><FormattedMessage id="feedback.contact.consent"/></Label>
          <Input type="checkbox" name="future-contact-consent" checked={consent} onChange={handleConsentChange}/>
        </FormGroup>
        { error && <div className="error mb-1" style={{color: "red"}}><FormattedMessage id="feedback.validation.error"/></div> }
        <Button className="btn btn-primary mt-1" type="submit">Send Feedback</Button>
      </Form>
    </div>
  );
};
