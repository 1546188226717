// Definition of each event name as constant to be used in any place on the application.

export const AD_BLOCK_DETECTED = "AdBlock detected";

export const ADDRESS_VALIDATION_ERROR = "Address validation error";
export const ADDRESS_VALIDATION_ERROR_DETAIL = "Address validation error detail";

export const DOCUMENTS_UPLOAD_SELECTION = "Documents selection for upload"
export const DOCUMENT_DELETED = "Document deleted";
export const DOCUMENT_PREVIEW_OPENED = "Document preview opened";
export const FAILED_UPLOAD = "Failed upload";

export const REF_VISIT = "Referred visit";
export const FRIEND_OPEN_LINK = "Friend open link";

export const INVITE_FRIENDS_CLICK = "Invite friends click";
export const INVITE_FRIENDS = "Invite friends";

export const LOGIN_SUBMIT = "Login submit";
export const LOGOUT = "Logout";

export const PAGE_VIEW = "Page view";

export const DELIVERY_START = "Delivery request - Start";
export const DELIVERY_SELECT_ADDRESS = "Delivery request - Select address";
export const DELIVERY_ADDRESS_SELECTED = "Delivery request - Address selected";
export const DELIVERY_SELECT_PAYMENT = "Delivery request - Select payment";
export const DELIVERY_PAYMENT_SELECTED = "Delivery request - Payment selected";
export const DELIVERY_REQUEST_CLOSED = "Delivery request - Closed";
export const DELIVERY_ORDER = "Delivery request - Order";

export const PRINT_FOR_FREE_CLICK = "Print for free - Click";

export const USER_DELETE_CLICK = "USER_DELETE_CLICK";
export const USER_DELETE_CONFIRMATION = "USER_DELETE_CONFIRMATION";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";
export const USER_DELETED = "USER_DELETED";

export const AI_USER_CLICK_AI_BUTTON = "AI - user click button";
export const AI_USER_CLICK_FLASHCARD_MENU = "AI - user click flashcard menu";
export const AI_USER_GENERATE = "AI - user generate";
export const AI_USER_GENERATE_ERROR = "AI - user generate error";
export const AI_USER_CLICK_SELECT_DOCUMENT = "AI - user click document select";
export const AI_USER_SELECT_DOCUMENT = "AI - user select document";
export const AI_USER_OPEN_CONFIGURATION = "AI - user open configuration";
export const AI_USER_CHANGE_NUMBER = "AI - user change number";
export const AI_USER_CHANGE_PAGES = "AI - user change pages";
export const AI_USER_PREVIEW_DOCUMENT = "AI - user preview document";
export const AI_USER_MOVE_FLASHCARD = "AI - user change flashcard";
export const AI_USER_FLIP_FLASHCARD = "AI - user flip flashcard";
export const AI_USER_DELETE_FLASHCARD = "AI - user delete flashcards";
export const AI_USER_REACH_HALFWAY_FLASHCARD = "AI - user reach halfway flashcard";
export const AI_USER_FINISH_FLASHCARD = "AI - user finish flashcards";
export const AI_USER_SELECT_FLASHCARD = "AI - user select flashcards from list";
export const AI_USER_WAIT_LOADING_END = "AI - user waits for loading to end";
export const AI_GENERATION_ERROR = "AI - generation error";
export const AI_NEW_CONTENT_CLICK = "AI - New content click";

export const AI_USER_CLICK_QUIZ_MENU = "AI - user click quiz menu";
export const AI_USER_SELECT_QUIZ = "AI - user select quiz from list";
export const AI_USER_SUMBIT_FEEDBACK = "AI - user sumbit feedback";

export const AI_NPS = "AI - user nps";
