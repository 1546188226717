import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AddressDeleteConfirm from './AddressDeleteConfirm';

import './AddressItem.css'
import { addressDelete } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { EditIcon, TrashIcon } from '../Icons';

const AddressItem = ({onEdit, item, allowDelete, allowEdit, intl}) => {

  // State management
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dispatcher = useDispatch();

  const toggle = (e) => {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  const handleOnEdit = (e) => {
    e.preventDefault();
    onEdit(item.id)
  }

  const handleOnDelete = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    dispatcher(addressDelete(item.id));
    setIsOpen(false);
  }

  const renderButtons = () => {

    let editButton = (
      <EditIcon onClick={handleOnEdit} data-test="btn-edit-address" className="c-pointer fs-5" role="button" 
        title={intl.formatMessage({id: "address.item.btn.edit"})} />
    );

    if (allowEdit !== true) {
      editButton = null;
    }

    if (allowDelete === true) {

      return (
        <React.Fragment>
          <div className='d-flex align-items-center'>
            {editButton}
            <TrashIcon onClick={toggle} data-test="btn-delete-address" className="ml-2 c-pointer fs-5" role="button"
              title={intl.formatMessage({id: "address.item.btn.delete"})} />
          </div>

          <AddressDeleteConfirm
            isOpen={isOpen}
            toggle={toggle}
            onDelete={handleOnDelete}
            isSubmitting={isSubmitting}
            />
        </React.Fragment>
      );
    }

    return editButton;
  }

  if (!item) {
    return null;
  }


  let box = "";

  if (item.boxNumber !== null && item.boxNumber !== undefined && item.boxNumber !== "") {
    box = item.boxNumber
    if (typeof "".padStart === "function") {
      box = ("" + box).padStart(4, "0");
    }

    box = ", " + intl.formatMessage({id: "address.item.boxNumber"}, {boxNumber: box});
  }

  return (
    <div className="card zc-card mb-2 address-item h-100">
      <div className="card-body px-3 pt-3 pb-0">
        <p><span data-test="address-item-name">{item.name}</span> <span data-test="address-item-surname">{item.surname}</span></p>
        <p><span data-test="address-item-street-number">{item.streetNumber}</span> <span data-test="address-item-street">{item.street}</span><span data-test="address-item-box-number">{box}</span></p>
        <p><span data-test="address-item-postal-code">{item.postalCode}</span> <span data-test="address-item-city">{item.city}</span></p>
      </div>
      <div className='card-footer p-1 pl-3'>
        <div className='d-flex align-items-center justify-content-end'>
          {renderButtons()}
        </div>
      </div>
    </div>
  );

}

AddressItem.propTypes = {
  item: PropTypes.object.isRequired,
  onEdit: function(props, propName, componentName) {
    if (props.allowEdit === true && props[propName] === undefined) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. Validation failed.'
      );
    }
  },
  allowDelete: PropTypes.bool.isRequired,
  allowEdit: PropTypes.bool.isRequired
};

AddressItem.defaultProps = {
  allowDelete: true,
  allowEdit: true
};

export default injectIntl(AddressItem);
