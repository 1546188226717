import React from "react"
import { FormattedMessage } from "react-intl";
import { BulletIcon } from "../Icons";

import CopyShopImage from '../../images/copyshop.png';

export const PrintAtCopyShop = (props) => {

  return (
    <div className="d-flex justify-content-center flex-md-row flex-column">
      <div>
        <img src={CopyShopImage} className="copyshop-img mb-2 mb-md-0" />
      </div>
      <div className={"pl-3 col copy-shop-instructions"}>
        <FormattedMessage id="documents.printForre.cs.top"/>
        <div className="row mt-3 mb-2">
          <div className="col-1">
            <BulletIcon text={"1"} color={"--accent-color"} backgroundColor={"--secondary-color"} />
          </div>
          <div className="col-10">
            <FormattedMessage id="documents.printForre.cs.one"/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-1">
            <BulletIcon text={"2"} color={"--accent-color"} backgroundColor={"--secondary-color"} />
          </div>
          <div className="col-10">
            <FormattedMessage id="documents.printForre.cs.two"/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-1">
            <BulletIcon text={"3"} color={"--accent-color"} backgroundColor={"--secondary-color"} />
          </div>
          <div className="col-10">
            <FormattedMessage id="documents.printForre.cs.three"/>
          </div>
        </div>
        <div className={"row mt-5 pl-3"}>
          <FormattedMessage id="documents.printForre.cs.go" />
        </div>
      </div>
    </div>
  );
}