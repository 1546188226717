import React, { useState } from 'react';
import {  useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';
import { ForgotPasswordForm } from '../Components/Form';
import { Redirect, Link, useLocation } from 'react-router-dom';

import "./ForgotPasswordPage.css";
import UserService from '../Services/UserService';
import { globalErrorHandling } from '../shared/utils';
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { Spinner } from 'reactstrap';
import { PublicTemplate } from '../Templates';
import { Helmet } from 'react-helmet';

const ForgotPasswordPage = (props) => {

  const [submitting, setSubtmitting] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const loggedIn = useSelector(state => state.security.loggedIn);

  const dispatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();

  const handleSubmit = (model) => {

    setSubtmitting(true);

    UserService.forgotPassword(model.email).then(response => {

      dispatch(successNotification({
        message: intl.formatMessage({ id: "forgotPassword.message.success"}),
        position: 'tc',
        autoDismiss: 5,
      }));

      setRedirectToLogin(true);

    }).catch(err => {

      const errorObj = globalErrorHandling(err);

      let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
      if (msg === errorObj.errorKey) {
        msg = intl.formatMessage({ id: "forgotPassword.message.error"});
      }
      
      dispatch(errorNotification({
        message: msg,
        position: 'tc',
        autoDismiss: 0,
      }));

    }).finally(() => {
      setSubtmitting(false);
    })
  }

  if (loggedIn === true || redirectToLogin === true) {
    return (
      <Redirect to={{
        pathname: "/documents",
        search: search
      }} />
    );
  }

  return (
    <PublicTemplate>
      <Helmet>
        <title>Zerocopy - {intl.formatMessage({id: "forgotPassword.title"})}</title>
      </Helmet>

        <div className='forgot-password-page'>
          <div className='mb-2'>
            <FormattedMessage id="forgotPassword.description" />
          </div>
          <ForgotPasswordForm
            id="forgot-password-form"
            onSubmit={handleSubmit}
            disabled={submitting}
            />
          <button className="btn btn-primary btn-block"
            disabled={submitting} form="forgot-password-form">
            <FormattedMessage id="forgotPassword.button" />
            {submitting?<Spinner size="sm" className="ml-2"/>:null}
          </button>

          <div className="mt-2">
            <Link to="/login" className="accent-link">
                <FormattedMessage id="forgotPassword.button.back" />
            </Link>
          </div>
        </div>

    </PublicTemplate>
  );
}

export default ForgotPasswordPage;
