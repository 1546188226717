import axios from 'axios';
import { logoutSuccess } from './store/actions/security';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      "Accept": "application/json",
      "Client": "react-application"
    }
  },
  post: {
    'Content-Type': 'application/json'
  },
  put: {
    'Content-Type': 'application/json'
  }
});

api.defaults.timeoutErrorMessage='RequestTimeout';

export default api;

export const setupInterceptors = (store) => {

  const { dispatch } = store;

  api.interceptors.response.use((response) => response, (error) => {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 401) {
      dispatch(logoutSuccess());
    }
    throw error;
  });
}
