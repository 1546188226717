import { getContext, put, select } from 'redux-saga/effects';
import { globalErrorHandling } from '../../shared/utils';
import { getToken } from './selectors';
import { AddressService } from '../../Services';
import * as actions from '../actions';
import { ADDRESS_VALIDATION_ERROR, ADDRESS_VALIDATION_ERROR_DETAIL } from '../../shared/EventsNames';

export function* addressesFetchSaga() {
  try {
    const token = yield select(getToken);
    const response = yield AddressService.fetchAll(token);
    yield put(actions.fetchAddressesSuccess(response.data));
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.fetchAddressesError(errorObj));
  }
}

export function* addressFetchSaga(action) {
  try {
    const token = yield select(getToken);
    const response = yield AddressService.fetch(token, action.id);
    yield put(actions.fetchAddressSuccess(response.data));
    yield put(actions.addressFormToggle());
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.fetchAddressError(errorObj));
  }
}

export function* addressSaveSaga(action) {

  const item = action.item;
  const token = yield select(getToken);

  try {
    const response = yield AddressService.save(token, item);
    yield put(actions.saveAddressSuccess(response.data));
    yield put(actions.fetchAddresses());
    yield put(actions.addressFormToggle());

    if (action.isDeliveryRequest === true) {
      yield put(actions.deliveryRequestSelectAddress(response.data))
    }
  } catch(error) {
    const errorObj = globalErrorHandling(error);

    if (errorObj.errorCode === 208 && error.response.data.fields && error.response.data.fields.length > 0) {
      errorObj.fields = error.response.data.fields;

      const mixpanel = yield getContext("mixpanel");
      mixpanel.track(ADDRESS_VALIDATION_ERROR);
      errorObj.fields.forEach(field => mixpanel.track(ADDRESS_VALIDATION_ERROR_DETAIL, {field: field.name, type: field.type}));
    }

    yield put(actions.saveAddressError(errorObj));
  }
}

export function* addressDeleteSaga(action) {

  try {
    const token = yield select(getToken);
    yield AddressService.delete(token, action.id);
    yield put(actions.addressDeleteSuccess());
    yield put(actions.fetchAddresses());
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.addressDeleteError(errorObj));
  }
}
