import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { LeaderboardConsentButton, PartnerEmailButton } from '../Consent';
import { logout } from '../../store/actions';
import { LogoutIcon } from '../Icons';

import './Settings.css';
import MailRemindersConsentButton from '../Consent/MailRemindersConsentButton';

const Settings = (props) => {
  
  const rewardForPartnerEmails = useSelector(state => state.system.credit_policy_partner_emails);
  const dispatcher = useDispatch();
  
  const handleLogout = () => {
    dispatcher(logout());
  };

  return (
      <div className='settings'>
        
        <div className='d-flex align-items-center justify-content-between pb-2 border-bottom mb-3'>
          <h4 className='mb-0'><FormattedMessage id="account.settings.title" /></h4>
        </div>

        <div className='d-flex align-items-stretch w-100'>

          <div className='d-flex flex-sm-row flex-column flex-fill text-center'>
            <div className='flex-fill'>
            {rewardForPartnerEmails ? <PartnerEmailButton reward={rewardForPartnerEmails} /> : null}
            <LeaderboardConsentButton />
            <MailRemindersConsentButton />
            </div>
            <div className='text-left mt-1'>
              <a href="#logout" onClick={handleLogout} className="zerocopy-link font-weight-normal" data-test="user-logout">
                <LogoutIcon className="mr-1" />
                <FormattedMessage id="toolbar.logged.logout" />
              </a>
            </div>
          </div>

        </div>
      </div>
  );
}

export default Settings;
