import React from 'react';
import PropTypes from 'prop-types';

import icon from '../../images/f_logo_RGB-Blue_58.png';

export const FacebookIcon = (props) => {
  
  return (
    <img src={icon}
      alt={props.alt}
      title={props.title}
      style={{"width": props.width, "height": props.height}}
      className={props.className}/>
  );
};

FacebookIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

FacebookIcon.defaultProps = {
  width: "32px",
  height: "32px",
  alt: "Facebook icon"
};