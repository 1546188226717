import React from 'react';

export const NewDocumentIcon = (props) => {
  return (
    <span className="fa-stack align-baseline icon">
      <i className="fas fa-file fa-stack-1x"></i>
      <i className="fas fa-plus fa-stack-1x fa-inverse" style={{"paddingTop": "15px"}}></i>
    </span>
  );
};

NewDocumentIcon.propTypes = {
};

NewDocumentIcon.defaultProps = {
};
