import React from 'react';
import { Provider } from '../../contexts/Mixpanel';

const MixpanelCustomProvider = ({ children, mixpanel }) => {

    return (
      <Provider value={mixpanel}>
        {children}
      </Provider>
    );
  
  };
  
  export default MixpanelCustomProvider;
  