import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { logInAsAnotherUser } from '../store/actions';

class SwitchToUser extends React.PureComponent {

  componentWillMount() {

    if (this.props.match.params.user !== undefined) {
      this.props.logInAsAnotherUser(this.props.match.params.user, this.props.intl);
    }

  }

  render () {

    return (
      <div>
        Loading new user....
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {

  };
}

const mapDispatchToProps = { logInAsAnotherUser }

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SwitchToUser));
