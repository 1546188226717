import { updateObject } from '../../shared/utils';

import * as actionTypes from '../actions/types';

const initialPreferences = {
  accept: false
}

const initialState = {
  isOpen: false,
  preferences: initialPreferences,
  partnerEmails: false,
  leaderboard: false
}

const consentToggle = (state, action) => {
  return updateObject(state, {
    isOpen: !state.isOpen
  });
};

const consentPartnerEmailsToggle = (state, action) => {
  return updateObject(state, {
    partnerEmails: !state.partnerEmails
  });
}

const consentLeaderboardToggle = (state, action) => {
  return updateObject(state, {
    leaderboard: !state.leaderboard
  });
}

const refreshConsentsForUser = (state, action) => {

  if (action.user.consents !== undefined && action.user.consents !== null) {
    return updateObject(state, {
      partnerEmails: action.user.consents.includes("PARTNER_EMAIL"),
      leaderboard: action.user.consents.includes("LEADERBOARD")
    })
  }

  return state;
}

const consentClean = (state, action) => {
  return updateObject(state, {
    isOpen: false,
    preferences: initialPreferences
  });
};

/**
* Redux reducer for consent actions.
*/
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CONSENT_TOGGLE: return consentToggle(state, action);
    case actionTypes.CONSENT_PARTNER_EMAILS_TOGGLE:
    case actionTypes.CONSENT_PARTNER_EMAILS_ERROR:
        return consentPartnerEmailsToggle(state, action);
    case actionTypes.CONSENT_LEADERBOARD_TOGGLE:
    case actionTypes.CONSENT_LEADERBOARD_ERROR:
        return consentLeaderboardToggle(state, action);
    case actionTypes.USER_FETCH_SUCCESS: return refreshConsentsForUser(state, action);
    case actionTypes.LOGOUT_SUCCESS: return consentClean(state, action);
    default:
      return state;
  }
};
