import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { documentShape } from '../../Shapes';
import { Button, Container } from 'reactstrap';

const RequestSummary = ({documents, docsToShow = 3, showTotal = true}) => {

  const [showAllDocs, setShowAllDocs] = useState(false);

  if (documents === undefined || documents === null || documents.length === 0) {
    return null;
  }

  let totalPages = 0;
  let totalFullPageAds = 0;

  documents.forEach(doc => {
    totalPages += doc.numberOfPages;
    totalFullPageAds += doc.numberOfFullPageAds;
  });

  let content = documents.map((doc, index) =>
      <div key={index} className={`row ${index === 0 ? "pt-1 pb-1": "pb-1"}${index > docsToShow - 1 && showAllDocs !== true ? " d-none": ""}`}>
        <div className='col-7'>
          <div className="text-wrap" title={doc.name}>{doc.name}</div>
        </div>
        <div className='col d-flex align-items-end'>
          <div>
            <FormattedMessage id="global.words.pages" values={{pages: doc.numberOfPages}} />
            {doc.numberOfFullPageAds !== 0?(<Fragment>&nbsp;+&nbsp;<FormattedMessage id="global.words.fullPageAds" values={{ads: doc.numberOfFullPageAds}} /></Fragment>):null}
          </div>
        </div>
      </div>
    );

  let total = (
      <Fragment>
        <div className="row pb-1 pt-1 border-top">
          <div className='col-7'>
            <FormattedMessage id="deliveries.request.overview.documents.total" />:
          </div>
          <div className='col'>
            {totalPages}
            
            {totalFullPageAds !== 0?(<Fragment>&nbsp;+&nbsp;<FormattedMessage id="global.words.fullPageAds" values={{ads: totalFullPageAds}} /></Fragment>):null}
          </div>
        </div>
        <div className="row pb-1 pt-1 border-top">
          <div className='col-7'>
            <FormattedMessage id="deliveries.request.overview.documents.credits" />:
          </div>
          <div className='col'>
            {totalPages}
          </div>
        </div>
      </Fragment>
    );

  if (!showTotal) {
    total = null;
  }

  return (
    <Fragment>
      <Container fluid>
      <div className='documents-overview'>
        {content}
        {documents.length > docsToShow ? 
          <div className='d-flex justify-content-center mt-2 mb-2'>
            <Button size="xs" outline onClick={e => setShowAllDocs(!showAllDocs)}><FormattedMessage id={showAllDocs === false ? "deliveries.request.overview.documents.btn.showMore" :"deliveries.request.overview.documents.btn.showLess"} /></Button>
          </div> : null}
      </div>
      {total}
      </Container>
    </Fragment>
  );

};

RequestSummary.propTypes = {
  documents: PropTypes.arrayOf(documentShape).isRequired
}

export default RequestSummary;
