import { put } from 'redux-saga/effects';
import { LocationService } from '../../Services';

import * as actions from '../actions';

export function* locationFetchCitiesSaga() {
  try {
    const response = yield LocationService.fetchCities();
    yield put(actions.fetchCitiesSuccess(response.data));
  } catch(error) {
    yield put(actions.fetchCitiesError(error));
  }
}

export function* locationFetchLocationsSaga() {
  try {
    const response = yield LocationService.fetchLocations();
    yield put(actions.fetchLocationsSuccess(response.data));
  } catch(error) {
    yield put(actions.fetchLocationsError(error));
  }
}
