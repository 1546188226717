import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { required, defaultMaxLength } from '../../shared/validations';
import Input from '../../hoc/Input';
import TextArea from '../../hoc/TextArea';
import EnchancedSelect from '../../hoc/EnchancedSelect';
import { categories } from '../../Shapes/SharedDocumentShape';
import { sortCategories, sortLabeledByLang } from '../../shared/utils';

const validate = (formProps) => {
  const errors = {};

  errors.name = required(formProps.name);
  
  if (!errors.name) {
    errors.name = defaultMaxLength(formProps.name);
  }
  
  errors.description = required(formProps.description);
  if (!errors.description) {
    errors.description = defaultMaxLength(formProps.description);
  }

  errors.category = required(formProps.category);

  errors.university = required(formProps.university);

  return errors;
};

const SharedDocumentForm = ({ handleSubmit, intl, isSubmitting, universities }) => {

  let lang = intl.locale.charAt(0).toUpperCase() + intl.locale.slice(1);

  if (!universities[0].hasOwnProperty(`label${lang}`)) {
    lang = "En";
  }

  const sortedCategories = sortCategories(categories, intl);
  const sortedUniversities = sortLabeledByLang(universities, lang);

  return (
    <form onSubmit={handleSubmit} autoComplete="off" data-test="form-to-share-document">
      <div className="container-fluid">

        <div className="row">
          <div className="col">
            <Field id="txtUniversity" name="university" className="advanced-select"
                component={EnchancedSelect}
                label={intl.formatMessage({id: "sharedDocuments.modal.field.university"})}
                placeholder={intl.formatMessage({id: "sharedDocuments.modal.field.university.placeholder"})}
                options={sortedUniversities}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option[`label${lang}`]}
                isClearable
                disabled={isSubmitting}
              />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field id="txtCategory" name="category" className="advanced-select"
                  component={EnchancedSelect}
                  label={intl.formatMessage({id: "sharedDocuments.modal.field.category"})}
                  placeholder={intl.formatMessage({id: "sharedDocuments.modal.field.category.placeholder"})}
                  options={sortedCategories}
                  isClearable
                  isSearchable={false}
                  disabled={isSubmitting}
                />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Field id="txtSharedDocumentName" name="name" type="text" className="form-control" maxLength="255"
                component={Input}
                label={intl.formatMessage({id: "sharedDocuments.modal.field.name"})}
                placeholder={intl.formatMessage({id: "sharedDocuments.modal.field.name.placeholder"})}
                disabled={isSubmitting}
              />
          </div>
        </div>
        
        <div className="row">
          <div className="col">
            <Field id="txtSharedDocumentDescription" name="description" type="text" className="form-control"
                component={TextArea}
                label={intl.formatMessage({id: "sharedDocuments.modal.field.description"})}
                placeholder={intl.formatMessage({id: "sharedDocuments.modal.field.description.placeholder"})}
                disabled={isSubmitting}
              />
          </div>
        </div>

      </div>
    </form>
  );

}

SharedDocumentForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    universities: PropTypes.array.isRequired
};

let InitializeFromStateForm = reduxForm({
  form: 'sharedDocumentForm',
  enableReinitialize: true,
  touchOnBlur: false,
  validate
})(SharedDocumentForm);

InitializeFromStateForm = connect(
  state => ({
    initialValues: {
        name: state.sharedDocuments.modal.name,
        id: state.sharedDocuments.modal.documentId,
        university: state.profile.university
    }
  }),
  {  }
)(InitializeFromStateForm);

export default InitializeFromStateForm;
