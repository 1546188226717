import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { maxLength } from '../../shared/validations';
import Input from '../../hoc/Input';
import EnchancedSelect from '../../hoc/EnchancedSelect';
import { categories } from '../../Shapes/SharedDocumentShape';
import { sortCategories, sortLabeledByLang } from '../../shared/utils';

const maxLength120 = maxLength(120);

const SharedDocumentFilter = ({handleSubmit, isSubmitting, intl, universities}) => {

  if (universities === undefined || universities.length === 0) {
    return null;
  }

  let lang = intl.locale.charAt(0).toUpperCase() + intl.locale.slice(1);

  if (!universities[0].hasOwnProperty(`label${lang}`)) {
    lang = "En";
  }

  const sortedCategories = sortCategories(categories, intl);
  const sortedUniversities = sortLabeledByLang(universities, lang);

  return (
    <form onSubmit={handleSubmit} autoComplete="off" data-test="form-shared-documents-filter">
      <div className="">
        <div className="row">
          <div className="col-10 col-md-6">
            <Field id="txtFilter" name="filter" type="text" className="form-control" maxLength="120"
                component={Input}
                label={intl.formatMessage({id: "sharedDocuments.filter.field.text"})}
                placeholder={intl.formatMessage({id: "sharedDocuments.filter.field.text.placeholder"})}
                validate={[ maxLength120 ]}
                disabled={isSubmitting}
              />
          </div>

          <div className="col-2 col-md-6 d-flex align-items-end mb-3">
            <button className="btn btn-secondary" disabled={isSubmitting} data-test="btn-shared-document-filter">
              <i className="fas fa-search d-sm-none"></i>
              <span className="d-none d-sm-block">
                <FormattedMessage id="btn.search" />
              </span>
            </button>
          </div>
        </div>
      
        <div className="row">
          <div className="col-md-6">
            <Field id="txtUniversity" name="university" className="advanced-select"
                component={EnchancedSelect}
                label={intl.formatMessage({id: "sharedDocuments.filter.field.university"})}
                placeholder={intl.formatMessage({id: "sharedDocuments.filter.field.university.placeholder"})}
                options={sortedUniversities}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>  option[`label${lang}`]}
                isClearable
                disabled={isSubmitting}
              />
          </div>
          <div className="col-md-6">
            <Field id="txtCategory" name="category" className="advanced-select"
                component={EnchancedSelect}
                label={intl.formatMessage({id: "sharedDocuments.filter.field.category"})}
                placeholder={intl.formatMessage({id: "sharedDocuments.filter.field.category.placeholder"})}
                options={sortedCategories}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => intl.formatMessage({id: `sharedDocuments.item.field.category.${option.value}`})}
                isClearable
                isSearchable={false}
                disabled={isSubmitting}
              />
          </div>
        </div>
        
      </div>
    </form>
  );

}

SharedDocumentFilter.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    universities: PropTypes.array.isRequired
};


let InitializeFromStateForm = reduxForm({
  form: 'sharedDocumentFilterForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {

    if (values.category !== previousValues.category
        || values.university !== previousValues.university) {
          props.submit();
    }

  }
})(SharedDocumentFilter);

InitializeFromStateForm = connect(
  state => ({
    initialValues: {
        university: state.profile.university
    }
  }),
  {  }
)(InitializeFromStateForm);

export default InitializeFromStateForm;
