import { put, select } from 'redux-saga/effects';
import { globalErrorHandling } from '../../shared/utils';
import { getToken } from './selectors';
import { ProfileService } from '../../Services';

import * as actions from '../actions';
import { logger } from '../../shared/Logger';

export function* fetchProfileSaga(action) {

  try {
    const token = yield select(getToken);
    const response = yield ProfileService.fetch(token);
    const groupedBySection = ProfileService.groupProfileChoicesBySection(response.data.currentChoices);

    yield put(actions.profileFetchSuccess(response.data.sections, groupedBySection));
  } catch(error) {
    // Empty error handling
    logger.error(error);
  }
}

export function* saveSectionSaga(action) {

  try {
    const token = yield select(getToken);
    yield ProfileService.save(token, action.sectionId, action.choices);
    yield put(actions.saveProfileSuccess());
    yield put(actions.fetchUser());

    if (action.finalStep === true) {
      yield put(actions.toggleProfile());
      yield put(actions.profileFetch());
    } else {
      yield put(actions.nextSection());
    }

  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.saveProfileError(errorObj));
  }
}

export function* profileUpdateSaga(action) {

  try {
    const token = yield select(getToken);
    yield ProfileService.updateExternalSystems(token);
  } catch(error) {
    logger.error(error);
  }
}

export function* fetchUniversitiesSaga(action) {

  try {
    const token = yield select(getToken);

    const response = yield ProfileService.fetchUniversities(token);
    yield put(actions.fetchUniversitiesSuccess(response.data));

  } catch(error) {
    logger.error(error);
    yield put(actions.fetchUniversitiesError());
  }
}

export function* fetchUniversitySaga(action) {

  try {
    const token = yield select(getToken);

    const response = yield ProfileService.fetchUniversity(token);
    yield put(actions.fetchUniversitySuccess(response.data));

  } catch(error) {
    logger.error(error);
    yield put(actions.fetchUniversityError());
  }
}
