import React from "react";
import { Chip, IconButton, Typography } from "@material-ui/core";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import { useMixpanel } from "../../contexts/Mixpanel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const DocumentName = ({name, backDest, className}) => {
  const mixpanel = useMixpanel();
  const history = useHistory();

  const handleBack = () => {
    mixpanel.track("AI - Back Button");
    history.push(backDest)
  }

  return (
    <div className={`mb-2 d-flex align-items-center ${className}`}>
      <IconButton onClick={handleBack}>
        <KeyboardBackspaceRounded  />
      </IconButton>
      <Typography variant="h4">
        {name}
      </Typography>
      <span> &nbsp;&nbsp; </span>
      <Chip label="BETA" color="secondary"/>
    </div>
  );
}
