import React, { Fragment } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import LanguageSelector from './LanguageSelector';

import './Footer.css';

const Footer = ({}) => {

  const companyName = useSelector(state => state.system.company_name);

  const version = (
    <span>
      <span>v{process.env.REACT_APP_VERSION}</span>
      <span className='ml-1'>
        <FormattedMessage id="footer.copyright" values={{year: new Date().getFullYear(), name: companyName}} />
      </span>
    </span>
  );

  const privacyPolicy = (
    <a href="https://zerocopy.be/privacy-policy-zerocopy/" target="_blank" className="zerocopy-link" rel="noopener noreferrer">
      <FormattedMessage id="footer.privacyAndCookie" />
    </a>
  );

  return (
    <footer className='py-1 px-2 small fixed-bottom'>
      <div className='d-flex w-100 align-items-center flex-column flex-md-row'>
        <div className='order-1 order-md-0 w-100 w-md-auto'>{version}&nbsp;|&nbsp;{privacyPolicy}</div>
        <div className='order-0 order-md-1 flex-grow-1 d-flex align-items-center justify-content-between w-100 w-md-auto'>
          <div className='ml-0 ml-md-4'>
          </div>
          <LanguageSelector />
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const SocialMediaLinks = (props) => {

  return (
    <ul className="list-inline social-media fs-5 mb-0">
      <li className="list-inline-item">
        <a href="https://www.facebook.com/Zerocopy.be" target="_blank" rel="noopener noreferrer" className="footer-sm-link">
          <i className="fab fa-facebook" aria-hidden="true"></i>
        </a>
      </li>
      <li className="list-inline-item">
        <a href="https://www.instagram.com/zerocopy.be/" target="_blank" rel="noopener noreferrer" className="footer-sm-link">
          <i className="fab fa-instagram" aria-hidden="true"></i>
        </a>
      </li>
      <li className="list-inline-item">
        <a href="https://www.linkedin.com/company/6430097" target="_blank" rel="noopener noreferrer" className="footer-sm-link">
          <i className="fab fa-linkedin" aria-hidden="true"></i>
        </a>
      </li>
      <li className="list-inline-item">
        <a href="https://www.youtube.com/channel/UCmCYT1pkSQMbElaGRmLfsDw" target="_blank" rel="noopener noreferrer" className="footer-sm-link">
          <i className="fab fa-youtube" aria-hidden="true"></i>
        </a>
      </li>
    </ul>
  );
}
