import * as actionTypes from './types';

export const toggleLogIn = () => ({
  type: actionTypes.LOG_IN_TOGGLE
});

export const directLogIn = (token, intl) => {
  return {
    type: actionTypes.LOGIN_AUTO,
    token: token,
    intl: intl
  }
};

export const directLogInError = (error) => {
  return {
    type: actionTypes.LOGIN_AUTO_ERROR,
    error: error
  }
};

export const logIn = (username, password, intl) => {
  return {
    type: actionTypes.LOGIN,
    username: username,
    password: password,
    intl: intl
  }
};

export const logInSuccess = (data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: data.token,
    user: data.user
  }
};

export const logInError = (error) => {
  return {
    type: actionTypes.LOGIN_ERROR,
    error: error
  }
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT
  }
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  }
};

export const logoutError = () => {
  return {
    type: actionTypes.LOGOUT_ERROR
  }
};

export const logInFacebook = (token, intl) => {
  return {
    type: actionTypes.LOGIN_FACEBOOK,
    token: token,
    intl: intl
  }
};

export const logInAsAnotherUser = (nextUser, intl) => {
  return {
    type: actionTypes.LOGIN_SWITCH,
    nextUser: nextUser,
    intl: intl
  }
};
