import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

export const AiContentConfirmDelete = ({isSubmitting = false, docName, cardId, open, handleClose, handleConfirm, type }) => {

  return (
      <Modal data-test="modal-confirm-ai-content-delete" isOpen={open} toggle={handleClose} centered={true}>
        <ModalHeader toggle={handleClose}>
          <FormattedMessage id={`${type}.delete.confirm.header`}/>
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id={`${type}.delete.confirm.body`} 
          values={{
            doc: docName,
          }}/>
        </ModalBody>
        <ModalFooter>
          <Button data-test="modal-confirm-flashcards-delete-ok" color="danger" onClick={() => handleConfirm(cardId)} disabled={isSubmitting}>
            <FormattedMessage id="confirm.btn.ok" />
            {isSubmitting?<Spinner size="sm" className="ml-2"/>:null}
          </Button>
          &nbsp;
          <Button color="secondary" onClick={handleClose} disabled={isSubmitting}>
            <FormattedMessage id="btn.cancel" />
          </Button>
        </ModalFooter>
      </Modal>
  );
}
