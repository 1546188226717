import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from 'redux-form';
import {reducer as notifications} from 'react-notification-system-redux';

import locale from "./locale";
import documents from "./document";
import address from "./address";
import profile from "./profile";
import user from "./user";
import security from "./security";
import locations from "./location";
import global from './global';
import ads from './ads';
import system from './system';
import consent from './consent';
import leaderboard from './leaderboard';
import sharedDocuments from './sharedDocuments';

export default combineReducers({
  form: reduxFormReducer, notifications,
  global, locale, documents, address, profile, user, security, locations, ads, system, consent, leaderboard, sharedDocuments
});
