import api from '../Api';
import { getToken } from '../shared/utils';

class GlobalLimitService {

  /**
   * Obtain counter about current month limit.
   */
  static async getCounterForCurrentMonth() {
    return api.get(`/v1/counter/global-limit`, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

}

export default GlobalLimitService;