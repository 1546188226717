import api from '../Api';
import { getToken } from '../shared/utils';

class ProfileService {

    /**
     * Obtain logged user profile.
     * @param {string} token 
     */
    static async fetch(token) {
        return api.get("/v1/profile", {headers: {'X-AUTH-TOKEN': token ? token : getToken()}});
    }

    /**
     * Save logged user profile information for specific section.
     * @param {string} token 
     * @param {number} sectionId 
     * @param {array} choices 
     */
    static async save(token, sectionId, choices) {
        return api.post(`/v1/profile/sections/${sectionId}`, choices, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Update logged user profile information in external systems.
     * @param {string} token 
     */
    static async updateExternalSystems(token) {
        return api.patch("/v1/profile/update", {}, {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Process profile choices to group by section.
     * @param {object} choices 
     */
    static groupProfileChoicesBySection(choices) {

        const groupedBySection = choices.reduce((acc, choice) => {
            if (acc[choice.sectionId] === undefined) {
              acc[choice.sectionId] = [];
            }
      
            acc[choice.sectionId].push(choice);
      
            return acc;
          }, {});
      
      
          Object.keys(groupedBySection).forEach(sectionId => {
      
            const groupedByFields = groupedBySection[sectionId].reduce((acc, choice) => {
              if (acc[choice.fieldId] === undefined) {
                acc[choice.fieldId] = [];
              }
      
              acc[choice.fieldId].push(choice);
      
              return acc;
            }, {});
      
            groupedBySection[sectionId] = groupedByFields;
      
          });

          return groupedBySection;
    }

    /**
     * Obtain universities.
     */
    static async fetchUniversities(token) {
      return api.get("/v1/universities", {headers: {'X-AUTH-TOKEN': token}});
    }

    /**
     * Obtain universities.
     */
    static async fetchUniversity(token) {
      return api.get("/v1/universities/user", {headers: {'X-AUTH-TOKEN': token}});
    }
}

export default ProfileService;