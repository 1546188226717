import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { addressShape } from '../../Shapes';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const AddressSelect = ({addresses, onSelect, onNewAddress}) => {

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const toggle = (e) => {
    e?.preventDefault();

    setOpen(!open);
  }

  const handleOnSelect = (address) => {
    toggle();
    setSelected(address);
    onSelect(address);
  }

  if (addresses === undefined || addresses === null) {
    return (
      <div><FormattedMessage id="address.choose.empty" /></div>
    );
  }

  return (
    <Dropdown size="sm" isOpen={open} toggle={toggle} data-test="select-address">
      <DropdownToggle caret>
        <FormattedMessage id={selected ? "address.choose.another": "address.choose.title"} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          data-test="create-new-address"
          onClick={onNewAddress}>
          <FormattedMessage id="address.choose.btn.add"/>
        </DropdownItem>
        {addresses.length !== 0? <DropdownItem divider /> : null}
        {addresses.map((address, index) =>
          <DropdownItem data-test={"address-option-" + index} key={address.id} onClick={() => handleOnSelect(address)}>
            {address.streetNumber + " " + address.street + ", " + address.city}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

AddressSelect.propTypes = {
  addresses: PropTypes.arrayOf(addressShape).isRequired,
  onSelect: PropTypes.func.isRequired,
  onNewAddress: PropTypes.func.isRequired
}

export default AddressSelect;
