import React, {} from 'react';
import { AiIcon } from '../../Components/Icons/AiIcon'
import { FormattedMessage } from 'react-intl';
import { emitter } from '@marvelapp/react-ab-test';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMixpanel } from '../../contexts/Mixpanel';
import { AI_USER_CLICK_AI_BUTTON } from '../../shared/EventsNames';



export const AiButton = ({location, className, onClick, style, text, iconSize, secondary, disabled, outline}) => {

  const mixpanel = useMixpanel();
  const history = useHistory();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

    
  const handleOnClick = () => {
    if (onClick === null || onClick === undefined) {
      history.push('/ai')
      emitter.emitWin("AI Call to Action")
      mixpanel.track(AI_USER_CLICK_AI_BUTTON, {location: location})
    }
    else {
      onClick()
    }
  }


  return (
    <button data-test="btn-ai-tutor" 
      disabled={disabled}
      onClick={handleOnClick}
      style={style != undefined ? style : {}}
      className={`btn ${secondary ? ( outline ? "btn-secondary-outline-ai" : "btn-secondary-ai" ) : ( outline ? "btn-outline-ai" : "btn-ai" )}  ${className ? " " + className: ""}`} > 
      <span className='font-weight-bold'> 
        <AiIcon height={iconSize} width={iconSize} /> 
        <span className={small && location === 'toolbar' ? 'd-none' : ''}>&nbsp;<FormattedMessage id={text ?  `ai.${text}` : "ai.tutor"}/></span>
      </span>
    </button>

  );

}

