import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import {LinearProgress, Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import AdSenseWrapper from '../../Components/Ads/AdSenseWrapper';
import Billboard from '../../Components/Commons/Billboard';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    color: 'var(--darkblue-color)',
  },
  colorPrimary: {
    backgroundColor: 'var(--green-light-color) !important',
  },
  barColorPrimary: {
    backgroundColor: 'var(--green-color) !important',
  },
  container: {
    marginBottom: '30rem',
  },
}));


export const LoadingScreen = ({isOpen}) => {
  const classes = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <Backdrop className={classes.backdrop} open={isOpen} >
      <Grid container spacing={3} alignContent='center' alignItems='center' style={{"marginBottom": "10rem"}} className={classes.container}>
        <Grid item xs={12}>
          <div className='fs-3 text-center'>
           <FormattedMessage id="ai.loading.screen" />
          </div>
        </Grid>
        <Grid item xs={12} >
          <LinearProgress classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}  />
        </Grid>
        <Grid item xs={12} className='text-center'>
          <AdSenseWrapper adSlot="2936680481" auto={true}>
            <Billboard show={true} style={{backgroundColor: "transparent"}}/>
          </AdSenseWrapper>
        </Grid>
      </Grid>
    </Backdrop>
  );
}
