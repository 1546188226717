import React, { useEffect, useState } from "react";
import './Option.css'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const idx = ["A", "B", "C", "D"]

export const Option = ({ value, index, handleClick, selected, correctAnswer, summary }) => {
  const [selectedClassName, setSelectedClassName] = useState("")

  useEffect(() => {
    setSelectedClassName( `${summary && value === correctAnswer ? "correct" : selected ? "incorrect": ""} ${selected ? "answered" : ""}`) 
  }, [selected])



  return (
    <>
      <div
        className={`d-flex align-items-center rounded-pill py-2 px-3 mb-3 option ${!summary ? "quiz" : "summary"} ${selectedClassName}`} 
        onClick={() => {if (!summary) { handleClick(value) }}}
      >
        { summary ? 
          ( correctAnswer === value ?
          <span className="mr-2" style={{marginLeft: "-0.5rem"}}> <CheckCircleIcon style={{color: "var(--green-color)"}}/> </span> :
          selected ? <span className="mr-2" style={{marginLeft: "-0.5rem"}}> <CancelIcon style={{color: "var(--tangerine-dark-color)"}}/> </span> : null )
        : null }
        <span className="mb-0 font-weight-bold" style={{alignSelf: "flex-start"}}>{idx[index]}.&nbsp;</span>

        <span className="mb-0" style={{alignSelf: "flex-start"}}>{value}</span>
      </div>
    </>
  );
}


