import React, { useEffect, useState } from "react";
import { QuestionList } from "../QuestionList"
import { QuizSummary } from "../QuizSummary";
import { Card, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import QuestionService from "../../Services/QuestionService";
import { logger } from "../../../shared/Logger";
import CustomSpinner from "../../../Components/Commons/Spinner";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { shuffleArray } from "../../../shared/utils";
import { DocumentName } from "../../Components/DocumentName";
import '../QuizSummary.css'
import './QuizPage.css'


export const QuizPage = () => {
  const { key } = useParams();
  const [ quiz, setQuiz ] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ start, setStart ] = useState(false);
  const [ end, setEnd ] = useState(false);

  useEffect(() => {
    QuestionService.fetch(key)
        .then(response => {
          response.data.content.forEach(q => { 
            shuffleArray(q.answers) 
            q.answers.forEach(a => a.selected = false)
          })
          shuffleArray(response.data.content)
          setQuiz(response.data)
        }).catch(error => logger.error("Error fetching quiz with key:" + key, error))
        .finally(() => setLoading(false));
  }, [key]);

  if (loading) {
    return (
      <div className="text-center">
        <CustomSpinner />
      </div>
    );
  }

  const handleStart = () => {
    setStart(true)
  }

  const handleEnd = () => {
    setEnd(true)
  }



  return (
    <>
      <DocumentName className={'mb-2'} name={quiz?.documentName} backDest={'/ai/quiz'}/>
      <div className="d-flex flex-column align-items-center justify-content-center">
        { !start ? 
          <Grid container spacing={2} className={'quiz-container quiz-start mt-1'}>
            <Grid item xs={12} className="d-flex justify-content-center mb-2">
              <h1 className="mb-0">
                {quiz?.name ? quiz.name : "Quiz"}
              </h1>
            </Grid>
            <Grid item xs={12} className="font-weight-medium d-flex flex-column align-items-center" >
              <p className="d-flex justify-content-between" style={{width: "100%", fontSize: "1.4rem"}}>
                <FormattedMessage id="ai.quiz.pagesUsed" />: <span>{quiz.startPage} - {quiz.endPage}</span>
              </p>
              <p className="d-flex justify-content-between" style={{width: "100%", fontSize: "1.4rem"}}>
                <FormattedMessage id="ai.quiz.numberOfQuestions" />: <span>{quiz?.content?.length}</span>
              </p>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center">
              <Button color="primary" disabled={!quiz?.content?.length} onClick={handleStart}>
                <FormattedMessage id="ai.quiz.buttons.start" />
              </Button>
            </Grid>
          </Grid>
          : !end && <QuestionList questions={quiz.content} handleFinish={handleEnd} />
        }
        {end && <QuizSummary quiz={quiz} />}
      </div>
    </>
  );
}

