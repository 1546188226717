import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import {useDropzone} from 'react-dropzone';

import { MAX_FILE_SIZE_BYTES, allowedMimeTypes } from "../../shared/utils";
import { processUploadFiles } from "./DocumentUpload";
import { useMixpanel } from '../../contexts/Mixpanel';
import { useGlobal } from "../../contexts/GlobalContext";

const DocumentUploadButton = ({intl, style = {}, className = ""}) => {

  const isUploadEnabled = useSelector(state => state.system.system_enable_upload);
  const [uploading, setUploading] = useState(false);
  const freeLimit = useSelector((state) => state.system.credit_policy_user_print_limit);
  const user = useSelector((state) => state.user.user);

  const dispatcher = useDispatch();

  const mixpanel = useMixpanel();
  const { askProfile, userCanUpload, maxUploads } = useGlobal();

  const onDrop = (acceptedFiles, rejectedFiles) => {
    processUploadFiles(dispatcher, intl, mixpanel, acceptedFiles, rejectedFiles, setUploading, maxUploads);
  }
  
  const disabled = uploading || user?.printsForAcademicYear >= freeLimit || !userCanUpload || askProfile === true;

  const {getRootProps, getInputProps, open} = useDropzone({
    accept: allowedMimeTypes,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    disabled: disabled,
    maxFiles: maxUploads,
    maxSize: MAX_FILE_SIZE_BYTES
  });


  if (isUploadEnabled !== true) {
    return null;
  }


  return (
    <div {...getRootProps({className: ''})}>
        <input {...getInputProps()} />
      <Button color="accent" onClick={open} disabled={disabled} style={style} className={className}>
        { uploading ? <Spinner size="sm" className="mr-1" /> : <i className="fas fa-plus mr-1"></i> }
        <FormattedMessage id="btn.upload" />
      </Button>
    </div>
  );

};

export default injectIntl(DocumentUploadButton);
