import React, {Fragment, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import ChangePasswordModal from './ChangePasswordModal';
import {AccountForm} from '../Form';
import {useSelector} from 'react-redux';
import {UserService} from '../../Services';
import {Collapse, Spinner} from 'reactstrap';
import ProfileButton from '../Profile/ProfileButton';
import { PlusIcon, MinusIcon } from '../Icons';

import './MyProfile.css';
import { logger } from '../../shared/Logger';

const MyProfile = (props) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const [showChgPass, setShowChgPass] = useState(true);
  const user = useSelector(state => state.user.user);
  const lang = useSelector(state => state.locale.lang);

  useEffect(() => {

    if (user) {

      setLoading(true);

      UserService.getProfile()
        .then(response => {
          setData(response.data);
          setOpenSection(Object.keys(response.data)[0]);
        }).catch(error => {
          logger.error(error);
        }).finally(() => {
          setLoading(false);
        });

    }

  }, [user, lang]);

  const onToggle = (section) => {
    if (section == openSection) {
      setOpenSection(null)
    }
    else {
      setOpenSection(section);
    }
  }

  return (
    <div className='my-profile mb-1'>

      <div className='d-flex align-items-center justify-content-between pb-2 border-bottom mb-3'>
        <h4 className='mb-0' data-test="address-title"><FormattedMessage id="account.general.title"/></h4>
        <ProfileButton/>
      </div>

      <div>

        {user ?
          <Fragment>
            <AccountForm user={user} isOpen={setShowChgPass}/>
            <div className='mb-2 mb-md-0'>
              <ChangePasswordModal showButton={showChgPass}/>
            </div>
          </Fragment> : null}

        {loading ? <ProfileLoading/> : null}
        <div id='profile-sections' className='my-2'>
          {data ? Object.keys(data).map((section, index) => {
            return (<ProfileSection key={index} section={section} data={data[section]} onToggle={onToggle}
                                    open={section === openSection}/>);
          }) : null}
        </div>
      </div>
    </div>
  );
}

export default MyProfile;

const ProfileSection = ({section, data, onToggle, open}) => {

  const toggleCollapse = () => {
    onToggle(section);
  };

  return (
    <div className='mb-1'>
      <div className='section-title' onClick={toggleCollapse} aria-expanded={open}>
        <h5 className='section-title-text'>{section}</h5>
        {open ? <MinusIcon className={'fs-2'}/> :
          <PlusIcon className={'fs-2'}/> }
      </div>
      <Collapse isOpen={open}>
        <div className='section-content'>
          {Object.keys(data).map((field, index2) => {
            return (<ProfileField key={index2} field={field} values={data[field]}/>);
          })}
        </div>
      </Collapse>
    </div>
  );
};

const ProfileField = ({field, values}) => {
  return (
    <div className='d-flex flex-column flex-sm-row'>
      <span className='font-weight-bold'>{field}{field.endsWith("?") ? "" : ":"}</span>
      <div className='mx-1 flex-grow-0'/>
      {Array.isArray(values) ? values.join(", ") : values}
    </div>
  );
}

const ProfileLoading = () => {

  return (
    <div className="text-center">
      <Spinner color="primary" style={{width: "4rem", height: "4rem"}}/>
      <div>
        <FormattedMessage id="account.profile.loading"/>
      </div>
    </div>
  );

};
