import React from "react";

import { FormattedMessage } from "react-intl";
import { ConfirmationIcon } from "../../Components/Icons/ConfirmationIcon";

export const AccountCreatedMessage = ( {soMeCampaign, className} ) => {

  return (
      <div className={`successMsg d-flex flex-column justify-content-center align-items-center${className ? " " + className : ""}`}>
          <div className="mb-3">
            <ConfirmationIcon soMe={soMeCampaign}/>
          </div>
          <div className={"px-3 text-center"}>
            <FormattedMessage id="createAccount.message.success.soMe" values={{
              breakLine: <br/>,
              highlight: text => <strong>{text}</strong>
            }}/>
          </div>
      </div>
  )
  
}

