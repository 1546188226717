import React, { useState, useEffect, useRef } from 'react';
import { useAds } from '../../../contexts/AdsContext';
import { AdsService } from '../../../Services';
import { Carousel, CarouselItem } from 'reactstrap';

const SmallAd = ({className}) => {
  
  const { smallAds, currentSmallAdIndex, changeSmallAd, default_timeout_seconds } = useAds();
  const [ ad, setAd ] = useState();
  const [ animating, setAnimating ] = useState(false);
  const adRef = useRef();
  const [ hasBeenSeen, setHasBeenSeen ] = useState(false);

  useEffect(() => {
    if (ad && !hasBeenSeen) {
      setHasBeenSeen(true)
      handleNewView(ad.id);
    }
  }, [ ad ]);

  useEffect(() => {

    if (default_timeout_seconds && default_timeout_seconds > 0) {
      const firstNext = setTimeout(next, default_timeout_seconds * 1000);
      return () => {
        clearInterval(firstNext);
      }
    }

  }, [default_timeout_seconds]);

  const handleOnClick = (id) => {
    AdsService.addClick(null, id);
  }

  const handleNewView = (id) => {
    AdsService.addView(null, id);
  }

  const onExiting = () => {
    setAnimating(true);
  }

  const onExited = () => {
    setAnimating(false);
  }

  const onEnter = () => {
    setAd(smallAds[currentSmallAdIndex]);
  }

  const next = () => {
    if (animating) return;
    setHasBeenSeen(false)
    changeSmallAd(true);
  }

  const previous = () => {
    if (animating) return;
    setHasBeenSeen(false)
    changeSmallAd(false);
  }

  if (!smallAds || smallAds.length === 0) {
   return null;
  }

  const slides = smallAds.map((ad, index) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} onEnter={onEnter} key={index}
        className="text-center" style={{maxWidth: '250px', height: '250px'}}>
        <a href={ad.clickUrl} ref={adRef} target="_blank" rel="noopener noreferrer"
          onClick={() => handleOnClick(ad.id)}
          onAuxClick={() => handleOnClick(ad.id)}>
          <img src={ad.src} alt={ad.altText} loading="lazy" style={{maxWidth: '250px', height: '250px', padding: '0!important'}}/>
        </a>
      </CarouselItem>
    );
  });

  return (
    <div className={'d-flex ' + className}>
      <Carousel activeIndex={currentSmallAdIndex} style={{maxWidth: '250px', height: '250px'}}  next={next} previous={previous} interval={default_timeout_seconds * 1000}
        pause={false} ride="carousel" slide={false} className='carousel-fade'>
        {slides}
      </Carousel>
    </div>
  );
};

export { SmallAd };
