import { put, select } from 'redux-saga/effects';
import api from '../../Api';
import { consentToggle, consentPartnerEmailsError, consentLeaderboardError, leaderboardFetch }from '../actions';
import { hasLocalStorage, globalErrorHandling } from '../../shared/utils';
import { getToken } from './selectors';
import { error as errorNotification } from 'react-notification-system-redux';
import { logger } from '../../shared/Logger';

const PREFERENCE_NAME = "c_preferences";

export function* consentUpdateSaga(action) {

  if (hasLocalStorage()) {
    const preferences = {
      "accept": action.accept
    }
    const preferenceStr = btoa(JSON.stringify(preferences));

    localStorage.setItem(PREFERENCE_NAME, preferenceStr);

    yield put(consentToggle());
  }

}

export function* consentAskSaga(action) {

  if (hasLocalStorage()) {

    const preferencesStr = localStorage.getItem(PREFERENCE_NAME);

    let askForConsent = true;
    
    if (preferencesStr !== null) {
      try {
        const preferences = JSON.parse(atob(preferencesStr));
        askForConsent = preferences.accept !== true;
      } catch(error) {
        logger.error(error);
      }
      
    }

    if (askForConsent === true) {
      yield put(consentToggle());
    }

  }
}

export function* consentPartnerEmailsToggleSaga(action) {
  const token = yield select(getToken);
  const partnerEmails = yield select(state => state.consent.partnerEmails);

  try {
    if (partnerEmails === true) {
      yield api.patch("/v1/user/features/partner-email/enable", {}, {headers: {'X-AUTH-TOKEN': token}});
    } else {
      yield api.patch("/v1/user/features/partner-email/disable", {}, {headers: {'X-AUTH-TOKEN': token}});
    }
  } catch(error) {
    yield put(consentPartnerEmailsError())
    
    yield put(errorNotification({
      message: action.intl.formatMessage({ id: "partnerEmails.error"}),
      position: 'tc',
      autoDismiss: 0,
    }));
  }
}

export function* consentLeaderboardToggleSaga(action) {
  const token = yield select(getToken);
  const leaderboard = yield select(state => state.consent.leaderboard);

  try {
    if (leaderboard === true) {
      yield api.patch("/v1/user/features/leaderboard/enable", {}, {headers: {'X-AUTH-TOKEN': token}});
    } else {
      yield api.patch("/v1/user/features/leaderboard/disable", {}, {headers: {'X-AUTH-TOKEN': token}});
    }

    yield put(leaderboardFetch());

  } catch(error) {

    const errorObj = globalErrorHandling(error, "leaderboard.consent.error");
    yield put(consentLeaderboardError());
    yield put(errorNotification({
      message: action.intl.formatMessage({ id: errorObj.errorKey}),
      position: 'tc',
      autoDismiss: 0,
    }));
  }
}
