import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { submit } from 'redux-form';
import { consentUpdate, consentAsk } from '../../store/actions';
import { ConsentCookies } from './ConsentCookies';

const OUR_COOKIES = [
  {
    "domain": "Our website",
    "name": "lang",
    "description": "Preferred language",
    "expire": -1,
    "expireUnit": undefined,
    "more": undefined
  },
  {
    "domain": "Our website",
    "name": "token",
    "description": "Security information",
    "expire": 0,
    "expireUnit": undefined,
    "more": undefined
  },
  {
    "domain": "Our website",
    "name": "facebook_logIn",
    "description": "Identify if user access to Zerocopy using Facebook.",
    "expire": 0,
    "expireUnit": undefined,
    "more": undefined
  },
  {
    "domain": "openstreetmap.org",
    "name": "qos_token",
    "description": "Internal usage from OpenStreetMap site, access control to their servers.",
    "expire": 1,
    "expireUnit": "day",
    "more": undefined
  },
  {
    "domain": "Our website",
    "name": "mp_XXX_mixpanel",
    "description": "Integration with our partner Mixpanel to analyze the usage of our website by our users. The XXX is a random identifier for each user.",
    "expire": 1,
    "expireUnit": "year",
    "more": undefined
  },
  {
    "domain": "Our website",
    "name": "c_preferences",
    "description": "Save information about your preferences for cookies.",
    "expire": -1,
    "expireUnit": undefined,
    "more": undefined
  }
];

const alertConsentStyle = {
  position: "fixed",
  bottom: "5px",
  left:"2%",
  width: "96%",zIndex: "100000"
};

class ConsentModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showMore: false
    }

    this.handleAccept = this.handleAccept.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
  }

  componentWillMount() {

    this.props.ask();
  }

  handleAccept(e) {
    e.preventDefault();

    this.props.accept(true);
    this.setState({showMore: false});
  }

  handleShowMore(e) {
    e.preventDefault();
    this.setState({showMore: true});
  }

  render() {

    if (this.props.loggedIn === true) {
      return null;
    }

    const description = (
      <p>
        <FormattedMessage id="consent.cookiePolicy.description.line1" values={{
          link: (<a href="https://zerocopy.be/zerocopy-cookie-policy/" target="_blank\"><FormattedMessage id="footer.privacyAndCookie" /></a>)
        }}/>
      </p>
    );

    if (this.state.showMore === true) {
      return (
        <Modal isOpen={true} toggle={this.handleAccept} backdrop="static" keyboard={false} style={{maxWidth: "85%"}}>
          <ModalHeader tag="h4">
            <FormattedMessage id="consent.cookiePolicy.title" />
          </ModalHeader>
          <ModalBody>
            {description}
            <p><FormattedMessage id="consent.cookiePolicy.description.line2" /></p>
            <ConsentCookies list={OUR_COOKIES} />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-dark" onClick={this.handleAccept}>
              <FormattedMessage id="consent.cookiePolicy.btn.accept" />
            </button>
          </ModalFooter>
        </Modal>
      );
    }

    return (
      <Alert color="dark" isOpen={this.props.isOpen} style={alertConsentStyle}>
        {description}
        <p>
          <FormattedMessage id="consent.cookiePolicy.description.line2" />&nbsp;
          <a href="#more-cookie-information" onClick={this.handleShowMore}>
            <FormattedMessage id="consent.cookiePolicy.btn.moreInfo" />
          </a>
        </p>
        <button className="btn btn-dark" onClick={this.handleAccept}>
          <FormattedMessage id="consent.cookiePolicy.btn.accept" />
        </button>
      </Alert>
    );
  }

}

ConsentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  accept: PropTypes.func.isRequired,
  ask: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    isOpen: state.consent.isOpen,
    preferences: state.consent.preferences,
    loggedIn: state.security.loggedIn,
    lang: state.locale.lang
  };
}

const mapDispatchToProps = { submit, accept: consentUpdate, ask: consentAsk }

export default connect(mapStateToProps, mapDispatchToProps)(ConsentModal);
