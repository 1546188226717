import React from "react";
import AiTutorPage from "../Ai/Containers/AiTutorPage";

const AiTutorContentPage = ({type}) => {

  return (
    <AiTutorPage type={type} />
  );

}

export default AiTutorContentPage;