import React from 'react';
import PropTypes from 'prop-types';

import CloseSvg from '../../images/close.svg';

export const CloseIcon = (props) => {
  return (
    <img src={CloseSvg}
    alt={props.alt}
    title={props.title}
    style={{"width": props.width, "height": props.height}}
    className={props.className}/>
  );
};

CloseIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

CloseIcon.defaultProps = {
  width: "32px",
  height: "32px",
  alt: "Close icon"
};
